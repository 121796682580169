<div class="data-import-container" [@fadeInOut]>
  <div class="header">
    <div class="title">Import Log</div>
    <div class="div-back-button">
      <button class="btn_normal btn_blue" [routerLink]="['/import-and-calculate']">
        <i class="fas fa-arrow-left"></i>
        Back to Data Import
      </button>
    </div>
  </div>

  <div class="body">
    <div class="top">
      <div class="filter-box">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="dateFilter" class="filterbox-lbl">Date:</label>
              <div class="input-wrapper">
                <kendo-datepicker
                  [id]="'importdateFilter'"
                  [(ngModel)]="importDateFilter"
                  (ngModelChange)="filterChanged()"
                  [navigation]="false"
                  [bottomView]="'month'"
                  [topView]="'decade'"
                  [placeholder]="'Choose import date'"
                >
                </kendo-datepicker>
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group">
              <label for="dateFilter" class="filterbox-lbl">As of Date:</label>
              <div class="input-wrapper">
                <kendo-datepicker
                  [id]="'asOfdateFilter'"
                  [(ngModel)]="asOfDateFilter"
                  (ngModelChange)="filterChanged()"
                  [navigation]="false"
                  [bottomView]="'month'"
                  [topView]="'decade'"
                  [placeholder]="'Choose As of Date'"
                >
                </kendo-datepicker>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="dataSourceFilter" class="filterbox-lbl">Data Source:</label>
              <div class="input-wrapper">
                <kendo-multiselect
                  [id]="'dataSourceFilter'"
                  [(ngModel)]="dataSourceFilter"
                  (ngModelChange)="filterChanged()"
                  [placeholder]="'Select data sources'"
                  [data]="dataSourceOptions"
                  class="custom-multiselect"
                ></kendo-multiselect>
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group">
              <label for="status" class="filterbox-lbl">Status:</label>
              <div class="input-wrapper">
                <kendo-multiselect
                  [id]="'status'"
                  [(ngModel)]="statusFilter"
                  (ngModelChange)="filterChanged()"
                  [placeholder]="'Select status'"
                  [data]="statusOptions"
                  class="custom-multiselect"
                ></kendo-multiselect>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="uploadedByFilter" class="filterbox-lbl">Uploaded by:</label>
              <div class="input-wrapper">
                <kendo-multiselect
                  [id]="'uploadedByFilter'"
                  [(ngModel)]="uploadedByFilter"
                  (ngModelChange)="filterChanged()"
                  [placeholder]="'Select uploaded by'"
                  [data]="uploadedByOptions"
                  class="custom-multiselect"
                ></kendo-multiselect>
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group">
              <label></label>
              <div class="input-wrapper">
                <button class="applyFiltersbtn btn_normal btn_green" [disabled]="!filtersModified" (click)="applyFilters()">
                  Apply Filters
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="grid-section-toggles" class="flex-grow flex-container horizontal centered toggle-style">
      <ng-container>
        <label class="checkbox-lbl">
          <input type="checkbox" [(ngModel)]="displayFileNameColumn" (click)="toggleFileNameColumn()" />
          File Name
        </label>
        <label class="checkbox-lbl">
          <input type="checkbox" [(ngModel)]="displayTabNameColumn" (click)="toggleTabNameColumn()" />
          Tab Name
        </label>
        <label class="checkbox-lbl">
          <input type="checkbox" [(ngModel)]="displayStatsColumn" (click)="toggleStatsColumn()" />
          Stats
        </label>
      </ng-container>
    </div>
  </div>

  <kendo-grid
    #grid
    [kendoGridBinding]="filteredImportLogs"
    [pageSize]="state.take"
    [skip]="state.skip"
    [loading]="false"
    [resizable]="true"
    [sortable]="{ mode: 'multiple' }"
    [resizable]="true"
    [pageable]="pagerSettings"
    [class]="'import-details'"
    [scrollable]="'scrollable'"
  >
    <!-- Import Date Column -->
    <kendo-grid-column field="ImportDateTimeCreated" title="Date" [sortable]="true" [width]="150">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ getFormatDate(dataItem.ImportDateTimeCreated, 'MM/dd/yyyy HH:mm') }}
      </ng-template>
    </kendo-grid-column>

    <!-- Data Source Name Column -->
    <kendo-grid-column field="DataSourceName" title="Data Source" [sortable]="true" [width]="200">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.DataSourceName ?? '&mdash;' }}
      </ng-template>
    </kendo-grid-column>

    <!-- Uploaded By Column -->
    <kendo-grid-column field="UploadedBy" title="Uploaded By" [sortable]="true" [width]="200">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.UploadedBy ?? '&mdash;' }}
      </ng-template>
    </kendo-grid-column>

    <!-- File Name Column -->
    <kendo-grid-column *ngIf="displayFileNameColumn" field="FileName" title="File Name" [sortable]="true" [width]="300">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.FileName ?? '&mdash;' }}
      </ng-template>
    </kendo-grid-column>

    <!-- Tab Name Column -->
    <kendo-grid-column *ngIf="displayTabNameColumn" field="TabName" title="Tab Name" [sortable]="true" [width]="250">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.TabName || '&mdash;' }}
      </ng-template>
    </kendo-grid-column>

    <!-- AsOfDate Column -->
    <kendo-grid-column field="AsOfDate" title="As Of Date" [sortable]="true" [width]="150">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.AsOfDate ? (dataItem.AsOfDate | date : 'MM/dd/yyyy') : '&mdash;' }}
        <strong *ngIf="dataItem.HasMultipleAsOfDates">*</strong>
      </ng-template>
    </kendo-grid-column>

    <!-- Status Column -->
    <kendo-grid-column field="OverallImportStatus" title="Status" [sortable]="true" [width]="170">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span>{{ dataItem.OverallImportStatus ?? '&mdash;' }}</span>
        <span class="status-icon" *ngIf="dataItem.ImportStatusSuccess && dataItem.IsActiveRecord" (click)="onArrowRightClicked(dataItem)">
          <i
            class="fas fa-arrow-right"
            [matTooltip]="showViewDataImportTooltip(dataItem.OverallImportStatus)"
            [matTooltipShowDelay]="600"
            [matTooltipPosition]="'above'"
          ></i>
        </span>

        <span
          class="status-icon"
          *ngIf="!dataItem.ImportStatusSuccess"
          (click)="ShowImportFailReasonPopup(dataItem.ImportFailureReason)"
          [matTooltip]="'View'"
          [matTooltipShowDelay]="600"
          [matTooltipPosition]="'above'"
        >
          <i class="fas fa-question"></i>
        </span>
      </ng-template>
    </kendo-grid-column>

    <!-- Stats Column -->
    <kendo-grid-column-group *ngIf="displayStatsColumn" title="Stats">
      <kendo-grid-column field="TotalRowCount" title="Total Rows" [sortable]="true" [headerClass]="'sub-header'" [width]="150">
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem.TotalRowCount !== 0 ? (dataItem.TotalRowCount | number) : '&mdash;' }}
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="ValidationValidCount" title="Valid Rows" [sortable]="true" [headerClass]="'sub-header'" [width]="150">
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem.ValidationValidCount !== 0 ? (dataItem.ValidationValidCount | number) : '&mdash;' }}
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="ValidationWarningCount" title="Warning Rows" [sortable]="true" [headerClass]="'sub-header'" [width]="150">
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem.ValidationWarningCount !== 0 ? (dataItem.ValidationWarningCount | number) : '&mdash;' }}
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="ValidationInvalidCount" title="Invalid Rows" [sortable]="true" [headerClass]="'sub-header'" [width]="150">
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem.ValidationInvalidCount !== 0 ? (dataItem.ValidationInvalidCount | number) : '&mdash;' }}
        </ng-template>
      </kendo-grid-column>
    </kendo-grid-column-group>
  </kendo-grid>

  <div>
    <ng-template #empty>&mdash;</ng-template>
  </div>
</div>
<i class="fa-solid fa-spinner fa-spin fa-2x loader" [@fadeInOut]="isLoading" *ngIf="isLoading"></i>
