import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavigationService } from '@services/navigation/navigation.service';
import { fadeInOut } from '@shared/animations';
import { Observable } from 'rxjs-compat';
import { mapObjectToComponentFields } from '../navigation-functions';
import { getNumberOfDescendants, NavigationElement } from '../navigation-interfaces';

@Component({
  selector: 'emp-navigation-element',
  templateUrl: './navigation-element.component.html',
  styleUrls: ['./navigation-element.component.scss'],
  animations: [fadeInOut(400)]
})
export class NavigationElementComponent implements OnInit {
  @Input() public element: NavigationElement;
  @Output() public popUpOpened = new EventEmitter();

  public getNumberOfDescendants = getNumberOfDescendants;

  get isNavigationBarActive$(): Observable<boolean> {
    return this.navigationService.isNavigationBarActive$.asObservable();
  }

  constructor(private readonly navigationService: NavigationService) {}

  public ngOnInit(): void {
    mapObjectToComponentFields(this, this.element);
  }

  public toggleExpand(element: NavigationElement) {
    element.isExpanded = !element.isExpanded;
    if (element.isExpanded) {
      this.popUpOpened.emit(this);
    }

    this.navigationService.storeExpandStatus(element);
  }
}
