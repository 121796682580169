<div class="product_title">
  <h1 class="product_name ellipsis">{{ modalTitle }}</h1>
</div>
<div class="menu_pin">
  <div id="header_quick_links" class="clearfix">
    <app-user-manuals-download></app-user-manuals-download>
    <button type="button" class="btn_normal btn_inverse btn_big_icon" id="btn_pin_menu" (click)="togglePin()" [class.pin_active]="pinned">
      <span class="btn_icon"><i class="fas fa-thumbtack"></i></span>
      <span class="btn_text hidden_text">Pin Menu </span>
    </button>
  </div>
</div>
<div class="product_grid">
  <div id="header_quick_links" class="clearfix">
    <button type="button" class="btn_normal btn_inverse btn_big_icon" id="btn_quicklinks" (click)="toggleQuickLinks()">
      <span class="btn_icon"><i class="fa-solid fa-th"></i></span>
      <span class="btn_text hidden_text">Quick Links </span>
    </button>
    <app-application-switcher [quickLinksOpen]="isOpen"></app-application-switcher>
  </div>
</div>
