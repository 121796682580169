import { InterruptSource, EventTargetInterruptOptions, InterruptArgs } from '@ng-idle/core';
import { Observable, Subject, Subscription } from 'rxjs';

export const networkCallInterrupt = new Subject();

export class InterruptOnNetworkCall extends InterruptSource {
  private eventSrc: Observable<any>;
  private eventSubscription: Subscription = new Subscription();
  protected throttleDelay: number;

  constructor(throttle?: number) {
    super();

    this.throttleDelay = throttle;

    this.eventSrc = networkCallInterrupt;

    const handler = (innerArgs: any) => this.onInterrupt.emit(new InterruptArgs(this, innerArgs));

    this.attachFn = () => (this.eventSubscription = this.eventSrc.subscribe(handler));

    this.detachFn = () => this.eventSubscription.unsubscribe();
  }

  /**
   * Checks to see if the event should be filtered. Always returns false unless overriden.
   * @param event - The original event object.
   * @return True if the event should be filtered (don't cause an interrupt); otherwise, false.
   */
  protected filterEvent(event: any): boolean {
    return false;
  }

  /**
   * Returns the current options being used.
   * @return The current option values.
   */
  get options(): EventTargetInterruptOptions {
    return { throttleDelay: this.throttleDelay };
  }
}
