import { isEmpty } from '@shared/arrays';
import { IValidable } from '@shared/interfaces/spread-cell-validation-interfaces';
import { createLookupObject } from './spread-base';
import { getColumnMapping, ISpreadColumn, ISpreadColumnMap } from './spread-settings';

/**
 * Creates a validator class with options to obtain the column name from a column definition and generate default error messages
 *
 * @example
 * const validator = new SpreadValidation(BU_COLUMNS);
 */
export default class SpreadValidation {
  private readonly COLUMNS: ISpreadColumn[];
  private readonly spreadColIndex: ISpreadColumnMap<any>;

  /**
   *
   * @param columnDefiniton An object that contains the name and location of the columns to generate the error messages
   */
  constructor(columnDefiniton: ISpreadColumn[]) {
    this.COLUMNS = columnDefiniton;
    this.spreadColIndex = getColumnMapping(columnDefiniton);
  }

  /**
   * Validates that the item cannot be empty
   *
   * @param item An object that contains the value to be validated
   * @param key The object property that will be validated
   * @param error Optional error message override the default one
   * @returns If the validation is successful or failed with an error
   */
  public cannotBeEmpty = <T extends IValidable>(item: T, key: keyof T, error?: string) => {
    const validationRules = cannotBeEmptyRule(item, key);
    if (!validationRules) {
      item.errorsArray.push({
        col: this.spreadColIndex[key],
        colName: key.toString(),
        error: error || `${this.COLUMNS[this.spreadColIndex[key]].displayName} is required.`
      });
    }
    return validationRules;
  };

  /**
   * Validates that the item must be empty
   *
   * @param item An object that contains the value to be validated
   * @param key The object property that will be validated
   * @param error Optional error message override the default one
   * @returns If the validation is successful or failed with an error
   */
  public mustBeEmpty = <T extends IValidable>(item: T, key: keyof T, error?: string) => {
    const validationRules = mustBeEmptyRule(item, key);
    if (!validationRules) {
      item.errorsArray.push({
        col: this.spreadColIndex[key],
        error: error || `${this.COLUMNS[this.spreadColIndex[key]].displayName} must be empty.`
      });
    }
    return validationRules;
  };

  /**
   * Validates that the value of the item cannot appear in other rows for the same column/property
   *
   * @param item An object that contains the value to be validated
   * @param key The object property that will be validated
   * @param model An array of objects of the same type that we will test for validation
   * @param column Optional property that will be checked in the model for the value in item[key]
   * @param error Optional error message override the default one
   * @returns If the validation is successful or failed with an error
   */
  public valueCannotAppearInOtherRows = <T extends IValidable>(
    item: T,
    key: keyof T,
    model: T[],
    column?: keyof T,
    caseSensitive?: boolean,
    error?: string
  ) => {
    const validationRules = mustNotExistsInModelRule(item, key, model, column, caseSensitive);
    if (!validationRules) {
      item.errorsArray.push({
        col: this.spreadColIndex[key],
        error: error || `${this.COLUMNS[this.spreadColIndex[key]].displayName} cannot be used in multiple rows.`
      });
    }
    return validationRules;
  };

  /**
   * Validates that the value of the item cannot appear in other rows for the same column/property
   *
   * @param item An object that contains the value to be validated
   * @param key The object property that will be validated
   * @param model An array of objects of the same type that we will test for validation
   * @param column Optional property that will be checked in the model for the value in item[key]
   * @param error Optional error message override the default one
   * @returns If the validation is successful or failed with an error
   */
  public valueMustAppearInOtherRows = <T extends IValidable>(item: T, key: keyof T, model: T[], column?: keyof T, error?: string) => {
    const validationRules = mustExistsInModelRule(item, key, model, column);
    if (!validationRules) {
      item.errorsArray.push({
        col: this.spreadColIndex[key],
        error: error || `${this.COLUMNS[this.spreadColIndex[key]].displayName} is not valid.`
      });
    }
    return validationRules;
  };

  /**
   * Validates that the item is unique in the model
   *
   * @param item An object that contains the value to be validated
   * @param key The object property that will be validated
   * @param model An array of objects of the same type that we will test for validation
   * @param rule An primary rule that will be checked (i.e. case-sensitive)
   * @param error Optional error message override the default one
   * @returns If the validation is successful or failed with an error
   */
  public mustBeUnique = <T extends IValidable>(item: T, key: keyof T, model: T[], rule?: () => boolean, error?: string) => {
    const validationRules = rule ? rule() : mustBeUniqueRule(item, key, model);
    if (!validationRules) {
      item.errorsArray.push({
        col: this.spreadColIndex[key],
        error: error || `${this.COLUMNS[this.spreadColIndex[key]].displayName} must be unique.`
      });
    }
    return validationRules;
  };

  /**
   * Validates that the item is a number
   *
   * @param item An object that contains the value to be validated
   * @param key The object property that will be validated
   * @param error Optional error message override the default one
   * @returns If the validation is successful or failed with an error
   */
  public mustBeNumeric = <T extends IValidable>(item: T, key: keyof T, error?: string) => {
    const validationRules = mustBeNumericRule(item, key);
    if (!validationRules) {
      item.errorsArray.push({
        col: this.spreadColIndex[key],
        error:
          key === 'ID'
            ? `The ${this.COLUMNS[this.spreadColIndex[key]].displayName} is invalid. It must be a Numeric value (up to 9 digits)`
            : error
            ? error
            : `${this.COLUMNS[this.spreadColIndex[key]].displayName} must be numeric.`
      });
    }
    return validationRules;
  };
  /**
   * Validates that the item is a whole number
   *
   * @param item An object that contains the value to be validated
   * @param key The object property that will be validated
   * @param error Optional error message override the default one
   * @returns If the validation is successful or failed with an error
   */
  public mustBeWholeNumber = <T extends IValidable>(item: T, key: keyof T, error?: string) => {
    const validationRules = mustBeWholeNumber(item, key);
    if (!validationRules) {
      item.errorsArray.push({
        col: this.spreadColIndex[key],
        error: error || `${this.COLUMNS[this.spreadColIndex[key]].displayName} must be a whole number.`
      });
    }
    return validationRules;
  };

  /**
   * Validates that the item is a number and that the value is equal or higher than the minimum
   *
   * @param item An object that contains the value to be validated
   * @param key The object property that will be validated
   * @param minimumValue The minimum value allowed in the field
   * @param error Optional error message override the default one
   * @returns If the validation is successful or failed with an error
   */
  public hasMinValue = <T extends IValidable>(item: T, key: keyof T, minimumValue: number, error?: string) => {
    const validationRules = hasMinValueRule(item, key, minimumValue);
    if (!validationRules) {
      item.errorsArray.push({
        col: this.spreadColIndex[key],
        error: error || `${this.COLUMNS[this.spreadColIndex[key]].displayName} must be higher than ${minimumValue}.`
      });
    }
    return validationRules;
  };

  /**
   * Validates that the item is a number and is equal or lower than the maximum value
   *
   * @param item An object that contains the value to be validated
   * @param key The object property that will be validated
   * @param maximumValue The maximum value allowed in the field
   * @param error Optional error message override the default one
   * @returns If the validation is successful or failed with an error
   */
  public hasMaxValue = <T extends IValidable>(item: T, key: keyof T, maximumValue: number, error?: string) => {
    const validationRules = hasMaxValueRule(item, key, maximumValue);
    if (!validationRules) {
      item.errorsArray.push({
        col: this.spreadColIndex[key],
        error: error || `${this.COLUMNS[this.spreadColIndex[key]].displayName} must be lower than ${maximumValue}.`
      });
    }
    return validationRules;
  };

  /**
   * Validates that the item is a number exists within a range
   * @param item An object that contains the value to be validated
   * @param key The object property that will be validated
   * @param minimumValue Value must be greater than or equal to this number
   * @param maximumValue Value must be less than or equal to this number
   * @param error Optional error message override the default one
   * @returns If the validation is successful or failed with an error
   */
  public inRangeValue = <T extends IValidable>(item: T, key: keyof T, minimumValue: number, maximumValue: number, error?: string) => {
    const validationRules = inRangeValueRule(item, key, minimumValue, maximumValue);
    if (!validationRules) {
      item.errorsArray.push({
        col: this.spreadColIndex[key],
        error: error || `${this.COLUMNS[this.spreadColIndex[key]].displayName} must be at minimum ${minimumValue} and at maximum ${maximumValue}.`
      });
    }
    return validationRules;
  };

  /**
   * Validates that the item is a number and is multiple of a value
   *
   * @param item An object that contains the value to be validated
   * @param key The object property that will be validated
   * @param divisor A non-zero value used for testing if the original value is multiple of
   * @param error Optional error message override the default one
   * @returns If the validation is successful or failed with an error
   */
  public isDivisibleBy = <T extends IValidable>(item: T, key: keyof T, divisor: number, error?: string) => {
    const validationRules = isDivisibleByRule(item, key, divisor);
    if (!validationRules) {
      item.errorsArray.push({
        col: this.spreadColIndex[key],
        error: error || `${this.COLUMNS[this.spreadColIndex[key]].displayName} must be multiple of ${divisor}.`
      });
    }
    return validationRules;
  };

  /**
   * Validates that the item is alphanumeric
   *
   * @param item An object that contains the value to be validated
   * @param key The object property that will be validated
   * @param additionalCharacters An optional array of characters that are also allowed among 0-9 and a-z
   * @param error Optional error message override the default one
   * @returns If the validation is successful or failed with an error
   */
  public mustBeAlphanumeric = <T extends IValidable>(item: T, key: keyof T, additionalCharacters?: string[], error?: string) => {
    const validationRules = mustBeAlphanumericRule(item, key, additionalCharacters);
    if (!validationRules) {
      item.errorsArray.push({
        col: this.spreadColIndex[key],
        error: error || `${this.COLUMNS[this.spreadColIndex[key]].displayName} can only contain alphanumeric characters.`
      });
    }
    return validationRules;
  };

  /**
   * Validates that the each item in the list is alphanumeric (allows spaces between each item)
   *
   * @param item An object that contains the value to be validated
   * @param key The object property that will be validated
   * @param additionalCharacters An optional array of characters that are also allowed among 0-9 and a-z
   * @param error Optional error message override the default one
   * @returns If the validation is successful or failed with an error
   */
  public commaSeparatedValuesMustBeAlphanumeric = <T extends IValidable>(
    item: T,
    key: keyof T,
    additionalCharacters?: string[],
    error?: string
  ) => {
    const validationRules = commaSeparatedValuesMustBeAlphanumericRule(item, key, additionalCharacters);
    if (!validationRules) {
      item.errorsArray.push({
        col: this.spreadColIndex[key],
        error: error || `${this.COLUMNS[this.spreadColIndex[key]].displayName} can only contain alphanumeric characters.`
      });
    }
    return validationRules;
  };

  /**
   * Validates that the item is alphanumeric or underscore
   *
   * @param item An object that contains the value to be validated
   * @param key The object property that will be validated
   * @param error Optional error message override the default one
   * @returns If the validation is successful or failed with an error
   */
  public mustBeAlphanumericUnderscore = <T extends IValidable>(item: T, key: keyof T, error?: string) => {
    const validationRules = mustBeAlphanumericRule(item, key, ['_']);
    if (!validationRules) {
      item.errorsArray.push({
        col: this.spreadColIndex[key],
        error: error || `${this.COLUMNS[this.spreadColIndex[key]].displayName} can only contain alphanumeric characters and underscore "_".`
      });
    }
    return validationRules;
  };

  /**
   * Validates that the item does not contain the characters specified
   *
   * @param item An object that contains the value to be validated
   * @param key The object property that will be validated
   * @param disallowedCharacters An array of characters that will not pass validation for this item
   * @param error Optional error message override the default one
   * @returns If the validation is successful or failed with an error
   */
  public disallowCharacters = <T extends IValidable>(item: T, key: keyof T, disallowedCharacters: string[], error?: string) => {
    const validationRules = disallowCharactersRule(item, key, disallowedCharacters);
    if (!validationRules) {
      item.errorsArray.push({
        col: this.spreadColIndex[key],
        error:
          error ||
          `The following characters are not valid for ${this.COLUMNS[this.spreadColIndex[key]].displayName}: ${disallowedCharacters.join(
            ', '
          )}.`
      });
    }
    return validationRules;
  };

  /**
   * Validates that the item does not contain the characters ' and \
   *
   * @param item An object that contains the value to be validated
   * @param key The object property that will be validated
   * @param error Optional error message override the default one
   * @returns If the validation is successful or failed with an error
   */
  public disallowRegisteredCharacters = <T extends IValidable>(item: T, key: keyof T, error?: string) => {
    const disallowedCharacters = ["'", '\\'];
    const validationRules = disallowCharactersRule(item, key, disallowedCharacters);
    if (!validationRules) {
      item.errorsArray.push({
        col: this.spreadColIndex[key],
        error:
          error ||
          `The following characters are not valid for ${this.COLUMNS[this.spreadColIndex[key]].displayName}: ${disallowedCharacters.join(
            ', '
          )}.`
      });
    }
    return validationRules;
  };

  /**
   * Validates that the item can be mapped to a property of the object
   *
   * @param item An object that contains the value to be validated
   * @param key The object property that will be validated
   * @param objectsToMap The array of objects that needs to match with the value
   * @param propertyToMap The property in this object that will be used to test validation
   * @param error Optional error message override the default one
   * @returns If the validation is successful or failed with an error
   */
  public mustMapToValue = <T extends IValidable, M extends { [key: string]: any }>(
    item: T,
    key: keyof T,
    objectsToMap: M[],
    propertyToMap: keyof M,
    error?: string
  ) => {
    const validationRules = mustMapToValueRule(item, key, objectsToMap, propertyToMap);
    if (!validationRules) {
      item.errorsArray.push({
        col: this.spreadColIndex[key],
        colName: key.toString(),
        error: error || `Selected value for ${this.COLUMNS[this.spreadColIndex[key]].displayName} does not exists.`
      });
    }
    return validationRules;
  };

  /**
   * Validates that the item is a string, that contains a comma separated list of values and that these values
   * appear in another list provided as parameter
   *
   * @param item An object that contains the value to be validated
   * @param key The object property that will be validated
   * @param list An array of strings that will be matched against the values that appear in the item
   * @param error Optional error message override the default one
   * @returns If the validation is successful or failed with an error
   */
  public commaSeparatedValuesMustBelongToListOfValues = <T extends IValidable>(item: T, key: keyof T, list: string[], error?: string) => {
    const validationRules = commaSeparatedValuesMustBelongToListRule(item, key, list);
    if (!validationRules) {
      item.errorsArray.push({
        col: this.spreadColIndex[key],
        error: error || `${this.COLUMNS[this.spreadColIndex[key]].displayName} not found.`
      });
    }
    return validationRules;
  };

  /**
   * Validates that the item is a string, contans a comma separated list of values and that these values do not repeat in the model
   *
   * @param item An object that contains the value to be validated
   * @param key The object property that will be validated
   * @param model An array of objects of the same type that contain the fields with other comma separated string of values
   * @param error Optional error message override the default one
   * @returns If the validation is successful or failed with an error
   */
  public commaSeparatedValuesMustBeUnique = <T extends IValidable>(item: T, key: keyof T, model: T[], error?: string) => {
    const validationRules = commaSeparatedValuesMustBeUniqueInModel(item, key, model);
    if (!validationRules) {
      item.errorsArray.push({
        col: this.spreadColIndex[key],
        error: error || `A ${this.COLUMNS[this.spreadColIndex[key]].displayName} cannot be assigned to more than one row.`
      });
    }
    return validationRules;
  };

  /**
   * Validates that a single value of any type belongs to a list of values of the same type
   *
   * @param item An object that contains the value to be validated
   * @param key The object property that will be validated
   * @param list An array of objects of any type that will be used to test the value of the field
   * @param error Optional error message override the default one
   * @returns If the validation is successful or failed with an error
   */
  public valueMustBelongToListOfValues = <T extends IValidable>(item: T, key: keyof T, list: any[], error?: string) => {
    const validationRules = mustBelongsToListRule(item, key, list);
    if (!validationRules) {
      item.errorsArray.push({
        col: this.spreadColIndex[key],
        error: error || `${this.COLUMNS[this.spreadColIndex[key]].displayName} not found.`
      });
    }
    return validationRules;
  };

  /**
   * Validates that the item cannot be same
   *
   * @param item An object that contains the value to be validated
   * @param key The object property that will be validated
   * @param keyToCompare The object property to compare to
   * @param error Optional error message override the default one
   * @returns If the validation is successful or failed with an error
   */
  public cannotBeSameValue = <T extends IValidable>(item: T, key: keyof T, keyToCompare: keyof T, error?: string) => {
    const validationRules = mustBeDifferentValueRule(item, key, keyToCompare);
    if (!validationRules) {
      item.errorsArray.push({
        col: this.spreadColIndex[key],
        error: error || `The ${this.COLUMNS[this.spreadColIndex[key]].displayName} cannot be itself.`
      });
    }
    return validationRules;
  };

  /**
   * Validates that the list have to be same
   *
   * @param item An object that contains the value to be validated
   * @param list List of values to be compared
   * @param key The object property that will be validated
   * @param error Optional error message override the default one
   * @returns If the validation is successful or failed with an error
   */
  public allValuesMustBeSame = <T extends IValidable>(item: T, list: string[], key: keyof T, error?: string) => {
    const validationRules = list.every(c => c === list[0]);
    if (!validationRules) {
      item.errorsArray.push({
        col: this.spreadColIndex[key],
        error: error || `The ${this.COLUMNS[this.spreadColIndex[key]].displayName} cannot be different.`
      });
    }
    return validationRules;
  };

  public mustNotHaveCircularRef = <T extends IValidable>(
    item: T,
    key: keyof T,
    model: T[],
    parentKey: keyof T,
    error?: string
  ) => {
    const validationRules = mustNotHaveCircularRefRule(item, key, model, parentKey);
    if (!validationRules) {
      item.errorsArray.push({
        col: this.spreadColIndex[key],
        error: error || `The ${this.COLUMNS[this.spreadColIndex[key]].displayName} cannot have a circular reference.`
      });
    }
    return validationRules;
  };
}

/**
 * Validation rule: Item must be empty
 * @param item An object that contains the value to be validated
 * @param key The object property that will be validated
 * @returns True if the item pass validation, false otherwise
 */
export const mustBeEmptyRule = <T extends IValidable>(item: T, key: keyof T): boolean => isEmpty(item[key]);

/**
 * Validation rule: Item cannot be empty
 * @param item An object that contains the value to be validated
 * @param key The object property that will be validated
 * @returns True if the item pass validation, false otherwise
 */
export const cannotBeEmptyRule = <T extends IValidable>(item: T, key: keyof T): boolean => !isEmpty(item[key]);

/**
 * Validation rule: Item must appear somewhere else in the model
 * @param item An object that contains the value to be validated
 * @param key The object property that will be validated
 * @param model An array of objects of the same type that will be used for validation
 * @param column Optional property that will be checked in the model for the value in item[key]
 * @returns True if the item pass validation, false otherwise
 */
export const mustExistsInModelRule = <T extends IValidable>(item: T, key: keyof T, model: T[], column?: keyof T): boolean => {
  const matchingKey = column || key;
  const valueMatch = model.filter(t => t[matchingKey] && item[key] && t[matchingKey].toString() === item[key].toString());
  return valueMatch && valueMatch.length >= 1;
};

/**
 * Validation rule: Item must appear somewhere else in the model
 * @param item An object that contains the value to be validated
 * @param key The object property that will be validated
 * @param model An array of objects of the same type that will be used for validation
 * @param column Optional property that will be checked in the model for the value in item[key]
 * @returns True if the item pass validation, false otherwise
 */
export const mustNotExistsInModelRule = <T extends IValidable>(
  item: T,
  key: keyof T,
  model: T[],
  column?: keyof T,
  caseSensitive?: boolean
): boolean => {
  const matchingKey = column || key;
  let valueMatch;
  if (caseSensitive) {
    valueMatch = model.filter(
      t => t[matchingKey] && item[key] && t[matchingKey].toString().toLocaleLowerCase() === item[key].toString().toLocaleLowerCase()
    );
  } else {
    valueMatch = model.filter(t => t[matchingKey] && item[key] && t[matchingKey].toString() === item[key].toString());
  }
  return valueMatch && valueMatch.length <= 1;
};

/**
 * Validation rule: Item must be unique in model case-insensitive
 * @param item An object that contains the value to be validated
 * @param key The object property that will be validated
 * @param model An array of objects of the same type that will be used for validation
 * @returns True if the item pass validation, false otherwise
 */
export const mustBeUniqueRule = <T extends IValidable>(item: T, key: keyof T, model: T[]) => {
  const valueMatch = model.filter(t => t[key] === item[key]);
  return valueMatch && valueMatch.length <= 1;
};

/**
 * Validation rule: Item must be unique in model case-sensitive
 * @param item An object that contains the value to be validated
 * @param key The object property that will be validated
 * @param model An array of objects of the same type that will be used for validation
 * @returns True if the item pass validation, false otherwise
 */
export const mustBeCaseSensitiveUniqueRule = <T extends IValidable>(item: T, key: keyof T, model: T[]) => {
  const valueMatch = model.filter(t => t[key] && t[key].toString().toLocaleLowerCase() === item[key].toString().toLocaleLowerCase());
  return valueMatch && valueMatch.length <= 1;
};

/**
 * Validation rule: Item must numeric
 * @param item An object that contains the value to be validated
 * @param key The object property that will be validated
 * @returns True if the item pass validation, false otherwise
 */
export const mustBeNumericRule = <T extends IValidable>(item: T, key: keyof T) => !isNaN(+item[key]);

/**
 * Validation rule: Item must be whole number
 * @param item An object that contains the value to be validated
 * @param key The object property that will be validated
 * @returns True if the item pass validation, false otherwise
 */

export const mustBeWholeNumber = <T extends IValidable>(item: T, key: keyof T) => Number.isInteger(+item[key]);

/**
 * Validation rule: Item must be numeric and higher or equal than a minimum value
 * @param item An object that contains the value to be validated
 * @param key The object property that will be validated
 * @param minimumValue The value that will be used to test against
 * @returns True if the item pass validation, false otherwise
 */
export const hasMinValueRule = <T extends IValidable>(item: T, key: keyof T, minimumValue: number) =>
  mustBeNumericRule(item, key) && +item[key] >= minimumValue;

/**
 * Validation rule: Item must be numeric and equals or lower than a maximum value
 * @param item An object that contains the value to be validated
 * @param key The object property that will be validated
 * @param maximumValue The value that will be used to test against
 * @returns True if the item pass validation, false otherwise
 */
export const hasMaxValueRule = <T extends IValidable>(item: T, key: keyof T, maximumValue: number) =>
  mustBeNumericRule(item, key) && +item[key] <= maximumValue;

/**
 * Validation rule: Item must be numeric and be within a range of 2 values
 * @param item An object that contains the value to be validated
 * @param key The object property that will be validated
 * @param minimumValue Value must be greater than or equal to this number
 * @param maximumValue Value must be less than or equal to this number
 * @returns True if the item pass validation, false otherwise
 */
export const inRangeValueRule = <T extends IValidable>(item: T, key: keyof T, minimumValue: number, maximumValue: number) =>
 mustBeNumericRule(item, key) && +item[key] >= minimumValue && +item[key] <= maximumValue;

/**
 * Validation rule: Item must be numeric and divisible by a number
 * @param item An object that contains the value to be validated
 * @param key The object property that will be validated
 * @param divisor A non-zero number that will be used to test if the value is divisible by
 * @returns True if the item pass validation, false otherwise
 */
export const isDivisibleByRule = <T extends IValidable>(item: T, key: keyof T, divisor: number) =>
  mustBeNumericRule(item, key) && divisor !== 0 && +item[key] % divisor === 0;

/**
 * Validation rule: Item must be alphanumeric and ***
 * @param item An object that contains the value to be validated
 * @param key The object property that will be validated
 * @param additionalCharacters Optional array of characters of values that will also be accepted apart from 0-9 and A-Z
 * @returns True if the item pass validation, false otherwise
 */
export const mustBeAlphanumericRule = <T extends IValidable>(item: T, key: keyof T, additionalCharacters?: string[]) => {
  const regex = additionalCharacters ? new RegExp(`^[a-z0-9${additionalCharacters.join()}]+$`, 'i') : new RegExp(/^[a-z0-9]+$/i);
  return typeof item[key] === 'string' && regex.test(item[key].toString());
};

/**
 * Validation rule: Item must be alphanumeric, underscore is also valid
 * @param item An object that contains the value to be validated
 * @param key The object property that will be validated
 * @returns True if the item pass validation, false otherwise
 */
export const mustBeAlphanumericUnderscoreRule = <T extends IValidable>(item: T, key: keyof T) =>
  typeof item[key] === 'string' && /^[a-z0-9_]+$/i.test(item[key].toString());

/**
 * Validation rule: Item cannot contain specified values
 * @param item An object that contains the value to be validated
 * @param key The object property that will be validated
 * @param disallowedCharacters An array of characters that will make the validation to fail is present
 * @returns True if the item pass validation, false otherwise
 */
export const disallowCharactersRule = <T extends IValidable>(item: T, key: keyof T, disallowedCharacters: string[]) =>
  isEmpty(item[key]) || (disallowedCharacters && disallowedCharacters.every(char => item[key] && !item[key].toString().includes(char)));

/**
 * Validation rule: Item must appear in another list of objects and map to a valid value
 * @param item An object that contains the value to be validated
 * @param key The object property that will be validated
 * @param objectsToMap The list of objects where the value must be found
 * @param propertyToMap The property that contains these values that will be used for validation
 * @returns True if the item pass validation, false otherwise
 */
export const mustMapToValueRule = <T extends IValidable, M extends { [key: string]: any }>(
  item: T,
  key: keyof T,
  objectsToMap: M[],
  propertyToMap: keyof M
) => {
  const mappingObject = createLookupObject(
    objectsToMap,
    x => x[propertyToMap],
    x => null
  );
  return item[key] !== null && item[key] !== undefined && Object.keys(mappingObject).includes(item[key].toString());
};

/**
 * Validation rule: Value must appear in a list of values of any type
 * @param item An object that contains the value to be validated
 * @param key The object property that will be validated
 * @param allowedValues The list of values where we will attempt to find the item to validate
 * @returns True if the item pass validation, false otherwise
 */
export const mustBelongsToListRule = <T extends IValidable>(item: T, key: keyof T, allowedValues: any[]) =>
  allowedValues.indexOf(item[key]) >= 0;

/**
 * Validation rule: Item must be a string of comma separated values, and these values must appear on a list
 * @param item An object that contains the value to be validated
 * @param key The object property that will be validated
 * @param allowedValues The list of values where we will attempt to find the item to validate
 * @returns True if the item pass validation, false otherwise
 */
export const commaSeparatedValuesMustBelongToListRule = <T extends IValidable>(item: T, key: keyof T, allowedValues: any[]) =>
  typeof item[key] === 'string' &&
  ((item[key] as unknown) as string)
    .split(',')
    .map(e => e.trim())
    .every(substrings => allowedValues.includes(substrings));

/**
 * Validation rule: Item must be a string of comma separated values, and these values must be alphanumeric
 * @param item An object that contains the value to be validated
 * @param key The object property that will be validated
 * @param additionalCharacters Optional array of characters of values that will also be accepted apart from 0-9 and A-Z
 * @returns True if the item pass validation, false otherwise
 */
export const commaSeparatedValuesMustBeAlphanumericRule = <T extends IValidable>(
  item: T,
  key: keyof T,
  additionalCharacters?: string[]
) => {
  const regex = additionalCharacters ? new RegExp(`^[a-z0-9${additionalCharacters.join()}]+$`, 'i') : new RegExp(/^[a-z0-9]+$/i);
  return (
    typeof item[key] === 'string' &&
    ((item[key] as unknown) as string)
      .split(',')
      .map(e => e.trim())
      .every(substrings => regex.test(substrings))
  );
};

/**
 * Validation rule: Item must be a string of comma separated values, and these values must be unique
 * @param item An object that contains the value to be validated
 * @param key The object property that will be validated
 * @param model An array of objects of the same type where we will attempt to match the values from the first
 * string in any of the other strings
 * @returns True if the item pass validation, false otherwise
 */
export const commaSeparatedValuesMustBeUniqueInModel = <T extends IValidable>(item: T, key: keyof T, model: T[]) => {
  if (typeof item[key] === 'string') {
    const elems = ((item[key] as unknown) as string).split(',').map(e => e.trim().toLocaleUpperCase());

    if (elems.length !== new Set(elems).size) {
      return false;
    }

    const matches = model.filter(
      m =>
        m &&
        m[key] &&
        elems.some(e =>
          m[key]
            .toString()
            .split(',')
            .some(modelCode => e.toLocaleUpperCase() === modelCode.toLocaleUpperCase())
        )
    );

    return matches.length <= 1;
  }
  return true;
};

/**
 * Validation rule: Item compared to other other item
 * @param item An object that contains the value to be validated
 * @param key The object property that will be validated
 * @param keyToCompare The object property that will be compared to
 * @returns True if the item has same value, false otherwise
 */
export const mustBeDifferentValueRule = <T extends IValidable>(item: T, key: keyof T, keyToCompare: keyof T) => {
  return item[key] !== item[keyToCompare];
};

/**
 * Validation rule: Check if there is a circular reference from a starting item \
 * Assumes that each node can only have a single parent
 * @param item An object that contains the value to be validated
 * @param key The object property that will be validated
 * @param model An array of objects of the same type that will be used for validation
 * @param parentKey The object property that identifies a parent node
 * @returns True if the item is part of a circular ref, false otherwise
 */
export const mustNotHaveCircularRefRule = <T extends IValidable>(item: T, key: keyof T, model: T[], parentKey: keyof T) => {
  const visited = new Set();
  const checkNextParent = childItem => {
    const nextParentKey = childItem[parentKey];
    if (nextParentKey === -1) {
      // -- root node reached, no circular ref found
      return true;
      // tslint:disable-next-line: unnecessary-else
    } else if (visited.has(nextParentKey)) {
      // -- we already found this node - a circular ref exists
      return false;
      // tslint:disable-next-line: unnecessary-else
    } else {
      // -- mark the current node as visited and continue searching up the tree
      visited.add(childItem[key]);
      const nextParent = model.find(i => i[key] === nextParentKey);
      return checkNextParent(nextParent);
    }
  };
  // -- recursivley check for a circular ref
  return checkNextParent(item);
};
