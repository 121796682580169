export const DASH = '—'; // &mdash;
export const NDASH = '–'; // &ndash;
export const ELLIPSIS = '…'; // &hellip;
export const VELLIPSIS = '︙'; // &vellip;

export const toTitleCase = words =>
  words
    .toLowerCase()
    .split(/[\s_]/)
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

/**
 * Accepts string to verify for invalid characters: ' or \ or %
 * @param word Input string to check
 */
export const hasInvalidCharacters = (word: string) => {
  const invalidCharacters = new RegExp(/(['%\\])/m);
  return invalidCharacters.test(word);
};

/**
 * Removes leading and trailing spaces from all values in a comma delimited string
 */
export const trimCommaDelimitedString = (commaDelimetedString: string): string => {
  if (commaDelimetedString == null || commaDelimetedString === '') {
    return '';
  }

  return commaDelimetedString
    .toString()
    .split(',')
    .map(value => value.trim())
    .join(',');
};

export const pascalCaseToKebabCase = (str: string) => (str ? str.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase() : str);

export const kebabCaseToTitleCase = (str: string) =>
  str ? str.replace(/-/g, ' ').replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()) : str;

export const createId = (length = 8): string => {
  let result = '';
  const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';

  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
};

export const createNumericId = (digits = 8): number => {
  const max = 10 ** digits;
  const min = max / 10;
  return Math.floor(Math.random() * (max - min + 1)) + min;
};


export const isValidUrl = (url: string) => {
  try {
    const u = new URL(url);
    return true;
  } catch (e) {
    return false;
  }
};
