import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { OpenIdConnectService } from '@services/openid/openid-connect.service';

@Component({
  selector: 'app-unable-to-load-page',
  templateUrl: './unable-to-load-page.component.html',
  styleUrls: ['./unable-to-load-page.component.scss']
})
export class UnableToLoadPageComponent implements OnInit {
  constructor(private readonly _location: Location, private readonly auth: OpenIdConnectService) {}

  ngOnInit() {}

  public goBack() {
    this._location.back();
  }

  public signin() {
    this.auth.triggerSignOut();
    this.auth.triggerSignIn();
  }
}
