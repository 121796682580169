<ng-container *ngIf="!isLoading && asOfDates?.length > 0">
  <kendo-dropdownlist
    title="Select an As Of Date to Import & Calculate for"
    #AODSelection
    [data]="asOfDates"
    [class.expanded]="isNavigationBarPinned"
    [loading]="isLoading"
    textField="Date"
    valueField="Date"
    [(ngModel)]="selectedDate"
    (selectionChange)="onSelectionChange($event)"
    (open)="onOpen()"
    (close)="onClose($event)"
    [@fadeInOut]="asOfDates?.length"
  >
    <ng-template kendoDropDownListNoDataTemplate>
      <div class="no-dates">
        <i class="fa fa-calendar"></i>
        <h4>No dates in the list.</h4>
      </div>
    </ng-template>

    <ng-template kendoDropDownListValueTemplate let-dataItem>
      <ng-container
        [ngTemplateOutlet]="asOfDateItem"
        [ngTemplateOutletContext]="{ asOfDate: dataItem, isValue: true, expanded: isOpen }"
      ></ng-container>
    </ng-template>
    <ng-template kendoDropDownListItemTemplate let-dataItem>
      <ng-container
        [ngTemplateOutlet]="asOfDateItem"
        [ngTemplateOutletContext]="{ asOfDate: dataItem, isValue: false, expanded: false }"
      ></ng-container>
    </ng-template>
  </kendo-dropdownlist>

  <ng-template #asOfDateItem let-asOfDate="asOfDate" let-expanded="expanded" let-isValue="isValue">
    <div *ngIf="asOfDate" class="as-of-date-item" [class.is-value]="isValue">
      <div class="left">
        <span class="icon-container">
          <i
            [@fadeInOut]="asOfDate?.ImportState"
            [ngClass]="asOfDate?.ImportState === IMPORT_STATES.COMPLETE ? 'fas fa-check-circle complete' : 'fas fa-hourglass'"
          ></i>
        </span>
        <span class="selected-date" [class.faded]="isOpen && isValue">{{ asOfDate?.Date | date : 'MMMM y' }}</span>
      </div>

      <div class="right" [class.expanded]="expanded">
        <span *ngIf="asOfDate?.IsGlReconDirty && asOfDate?.IsLocked" class="icon-container" [title]="'GL Recon needs to be re-calculated'">
          <i class="fa-solid fa-calculator"></i>
        </span>
        <span *ngIf="asOfDate?.IsLocked" class="icon-container" [title]="'This date is locked for editing'">
          <i class="fas fa-lock"></i>
        </span>
      </div>
    </div>
  </ng-template>

  <button
    *ngIf="isAdmin"
    #addPriorAsOfDateButton
    empAddAsOfDate
    [isPrior]="true"
    [asOfDates]="asOfDates"
    (asOfDatesUpdated)="onAsOfDatesUpdated($event)"
    class="add-prior-as-of-date"
    [class.show]="isOpen"
    [title]="'Add prior date Model Data'"
  >
    <i class="fas fa-plus"></i>
    Prior
  </button>

  <button
    *ngIf="isAdmin"
    #addAsOfDateButton
    empAddAsOfDate
    [asOfDates]="asOfDates"
    (asOfDatesUpdated)="onAsOfDatesUpdated($event)"
    class="add-as-of-date"
    [class.show]="isOpen"
    [disabled]="isAddDateDisabled()"
    [title]="isAddDateDisabled() || 'Add Date'"
  >
    <i class="fas fa-plus"></i>
    Date
  </button>
</ng-container>

<ng-container *ngIf="!isLoading && !(asOfDates?.length > 0)">
  <button
    #addFirstAsOfDateButton
    empAddAsOfDate
    [asOfDates]="asOfDates"
    (asOfDatesUpdated)="onAsOfDatesUpdated($event)"
    class="add-as-of-date first"
    [class.show]="isOpen"
    [disabled]="isAddDateDisabled()"
    [title]="isAddDateDisabled() || 'Add Date'"
    [@fadeInOut]="asOfDates?.length"
  >
    <i class="fas fa-plus"></i>
    Add First As of Date
  </button>
</ng-container>
