import { Injectable } from '@angular/core';
import { ActiveToast, IndividualConfig, ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ToasterService {
  constructor(private readonly toastrService: ToastrService) {}

  public pop(toast: Toast): ActiveToast<any> {
    const { message, title, type, options, onHidden } = this.ngxToastAdapter(toast);
    const activeToast = this.toastrService.show(message, title, options, type);
    if (onHidden) activeToast.onHidden.pipe(take(1)).subscribe(() => onHidden(activeToast));
    return activeToast;
  }

  public popAsync(toast: Toast): Observable<ActiveToast<any>> {
    const activeToast = this.pop(toast);
    return activeToast.onShown.pipe(
      take(1),
      map(() => activeToast)
    );
  }

  public clear(id?: number): void {
    this.toastrService.clear(id);
  }

  private ngxToastAdapter(toast: Toast): NgxToast {
    return {
      message: toast.body,
      title: toast.title,
      type: `toast-${toast.type}`,
      options: {
        closeButton: toast.showCloseButton,
        extendedTimeOut: toast.timeout,
        timeOut: toast.timeout,
        disableTimeOut: toast.timeout === 0,
        enableHtml: toast.bodyOutputType === BodyOutputType.TrustedHtml,
        progressBar: toast.progressBar,
        progressAnimation: toast.progressBarDirection
      },
      onHidden: toast.onHideCallback,
      onShown: toast.onClickCallback
    };
  }
}

/** ngx-toastr toastrService.show() arguements */
type NgxToast = {
  /** Body text of the toast. Set enableHtml to true to display html. */
  message: string;
  /** Title text of the toast. */
  title: string;
  /** Type of toast to show */
  type: ToastrIconClass;
  /** Additional toast options */
  options: Partial<IndividualConfig>;
  /** callback when the toast is removed */
  onHidden?: (toast: ActiveToast<any>) => void;
  /** callback when the toast is displayed */
  onShown?: (toast: ActiveToast<any>) => void;
};

/** ngx-toastr types supported by toastrService.show() */
export type ToastrIconClass = 'toast-success' | 'toast-info' | 'toast-warning' | 'toast-error';

/** ngx-toastr ProgressBarDirection selection */
export type ProgressBarDirection = 'decreasing' | 'increasing';

export type ToastType = 'success' | 'info' | 'warning' | 'error';

export enum BodyOutputType {
  TrustedHtml = 1
}

export interface Toast {
  type: ToastType;
  title?: string;
  body?: any;
  onHideCallback?: (toast: any) => void;
  onClickCallback?: (toast: any) => void;
  timeout?: number;
  progressBar?: boolean;
  progressBarDirection?: ProgressBarDirection;
  bodyOutputType?: BodyOutputType;
  showCloseButton?: boolean;
}
