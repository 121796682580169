<div class="header">
  <ng-container *ngIf="asOfDates?.length === 0">
    <div class="title">Create First As of Date</div>
    <div class="sub-title">Please select the first Month and Year for your institution's data.</div>
  </ng-container>
  <ng-container *ngIf="asOfDates?.length > 0">
    <div class="title">Add As of Date</div>
    <div class="sub-title">Please select a new Month and Year for your institution's data.</div>
  </ng-container>
</div>
<div class="body">
  <div class="warning" *ngIf="asOfDates?.length > 0">
    <div>The system will also create each month in-between this new date and the current earliest date.</div>
    <div class="extra-months" [class.show]="monthsToCreate">
      {{ monthsToCreate > 1 ? monthsToCreate + ' months will be created' : '' }}
      <ng-container *ngIf="monthsToCreate > 1">
        <br />

        <ng-container *ngIf="selectedDate.isBefore(firstDate)">
          ({{ selectedDate.format('MMMM yyyy') }}{{ monthsToCreate > 2 ? ' to ' : ', ' }}{{ previousMonth.format('MMMM yyyy') }})
        </ng-container>

        <ng-container *ngIf="selectedDate.isAfter(lastDate)">
          ({{ nextMonth.format('MMMM yyyy') }}{{ monthsToCreate > 2 ? ' to ' : ', ' }}{{ selectedDate.format('MMMM yyyy') }})
        </ng-container>
      </ng-container>
    </div>
  </div>
  <form novalidate [formGroup]="formGroup">
    <mat-form-field class="form-field date-picker-container" appearance="fill">
      <input
        matInput
        [min]="minDate"
        [max]="maxDate"
        [matDatepicker]="picker"
        [matDatepickerFilter]="usedDates"
        formControlName="date"
        readonly
      />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker
        #picker
        color="primary"
        startView="multi-year"
        [startAt]="selectedDate"
        (monthSelected)="chosenMonthHandler($event, picker)"
        [dateClass]="dateClass"
      ></mat-datepicker>
      <mat-error>{{ getErrorMessage() }}</mat-error>
    </mat-form-field>

    <div class="footer">
      <button class="btn_normal btn_blue btn_wide" (click)="closeDialog()">Close</button>
      <button class="btn_normal btn_green btn_wide" [disabled]="!formGroup?.valid" (click)="saveNewAsOfDate()">Save</button>
    </div>
  </form>
</div>
