<a
  class="navigation-link"
  [class.collapsed]="!isNavigationBarActive"
  [ngStyle]="{ paddingLeft: isNavigationBarActive ? '0.5rem' : '0' }"
  (click)="toggleExpand()"
>
  <span class="icon"><i class="{{ importAndCalculate.iconClass }}"></i></span>
  <span class="text ellipsis">{{ importAndCalculate.label }}</span>
  <span class="status"></span>
  <span class="expand-icon">
    <i class="fas fa-chevron-right" [class.expanded]="importAndCalculate?.isExpanded"></i>
  </span>
</a>

<span class="children-container" [class.expanded]="importAndCalculate?.isExpanded">
  <emp-navigation-element
    *ngFor="let element of importAndCalculateChildren"
    [element]="element"
    [class.completed]="isCompleted(element)"
    [title]="getTooltip(element)"
  >
  </emp-navigation-element>
</span>
