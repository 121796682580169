import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Globals } from '@models/globals';
import { OpenIdConnectService } from '@services/openid/openid-connect.service';
import { Observable } from 'rxjs';

@Injectable()
export class AddAuthorizationHeaderInterceptor implements HttpInterceptor {
  constructor(private readonly openIdConnectService: OpenIdConnectService) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!Globals.importUpload) {
      if (this.openIdConnectService.userAvailable) {
        const { token_type, access_token } = this.openIdConnectService.user;

        request = request.clone({
          setHeaders: {
            Authorization: `${token_type} ${access_token}`
          }
        });
      }
    }

    return next.handle(request);
  }
}
