import { NestedTreeControl } from '@angular/cdk/tree';
import { ChangeDetectorRef, Component, Input, OnChanges, SimpleChange } from '@angular/core';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { INodeable } from '@models/tree/nodeable';
import { ITreeConfigFilterHint } from '@models/tree/tree-config';
import { TreeNode } from '@models/tree/tree-node';
import { ExpandedNodesService } from '@services/tree/expanded-nodes.service';

const ROOT_ID = -1;
const UNMATCHED_ROOT_ID = -2;

@Component({
  selector: 'app-tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.scss']
})
export class TreeComponent implements OnChanges {
  @Input() nodes: INodeable[];
  @Input() name: string;
  @Input() tooltipKey: string;
  @Input() isAllExpanded: boolean;
  @Input() filterHint: ITreeConfigFilterHint;
  private tree: TreeNode[];
  public nestedDataSource: MatTreeNestedDataSource<TreeNode> = new MatTreeNestedDataSource();
  public nestedTreeControl: NestedTreeControl<TreeNode>;
  public errorsDisplayingTheTreeRoot = false;
  public otherTreeErrors = false;
  private expandedNodes: number[] = [];
  public isManualToggle = false;
  get rootId() {
    return ROOT_ID;
  }
  get unmatchedRootId() {
    return UNMATCHED_ROOT_ID;
  }

  constructor(private readonly changeDetector: ChangeDetectorRef, private readonly expandedNodeService: ExpandedNodesService) {}

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    for (const propName of Object.keys(changes)) {
      switch (propName) {
        case 'nodes':
          if (this.nodes) {
            this.nestedTreeControl = new NestedTreeControl<TreeNode>(this._getChildren);
            this.expandedNodes = this.expandedNodeService.getExpandedNodes(this.name);
            this.nestedDataSource.data = this.buildTree(this.nodes);
            this.restoreExpandedNodes();
            this.changeDetector.detectChanges();
          }
          break;
        case 'isAllExpanded':
          if (this.isAllExpanded !== undefined) {
            if (this.isAllExpanded) {
              this.expandAllNodes();
            } else if (!this.isAllExpanded) {
              this.collapseAllNodes();
            }
          }
          break;
      }
    }
  }

  private isNodeEmpty(node: INodeable): boolean {
    const checkKeys = ['Id', 'ParentId', 'Mnemonic', 'Name', 'DisplayOrder'];

    return !Object.keys(node)
      .filter(key => checkKeys.includes(key))
      .some(key => node[key] !== null);
  }

  private buildTree(inputNodes: INodeable[]): TreeNode[] {
    this.errorsDisplayingTheTreeRoot = false;
    this.otherTreeErrors = false;
    const rootNodes = this.nodes.filter(node => node.ParentId === ROOT_ID);
    const nonEmptyNodes = this.sortNodes(inputNodes.filter(node => !this.isNodeEmpty(node)));

    const titleRootNode: INodeable = {
      Name: this.name,
      Mnemonic: this.name,
      Id: ROOT_ID,
      ParentId: ROOT_ID,
      DisplayOrder: null
    };

    const rootTreeNode = this.buildTreeNode(titleRootNode, nonEmptyNodes);

    const tree = [rootTreeNode];

    if (rootTreeNode.children.length === 0 && nonEmptyNodes.length > 0) {
      this.errorsDisplayingTheTreeRoot = true;
    }
    // UNMATCHED ELEMENTS
    const unmatchedNodes = nonEmptyNodes.filter(node => {
      const parentCondition = parentCandidate => parentCandidate.Id === node.ParentId;
      const isUnallocated = (!nonEmptyNodes.some(parentCondition) && !rootNodes.includes(node)) || node.ParentId === null;
      return isUnallocated;
    });

    const unMatchedTreeNodes = unmatchedNodes.map(unmatchedNode => this.buildTreeNode(unmatchedNode, nonEmptyNodes));

    if (unMatchedTreeNodes && unMatchedTreeNodes.length > 0) {
      const unmatchedTitleTreeNode: TreeNode = {
        children: unMatchedTreeNodes,
        name: `Unmatched ${this.name}`,
        mnemonic: `Unmatched ${this.name}`,
        Id: UNMATCHED_ROOT_ID,
        expanded: this.expandedNodes.includes(UNMATCHED_ROOT_ID)
      };

      tree.push(unmatchedTitleTreeNode);
    }

    this.tree = tree;

    return tree;
  }

  /**
   * This methods builds a TreeNode
   * @param nodes: the full list of nodes that will build the tree
   * @param ancestorNode: the ancestor node
   */
  private buildTreeNode(ancestorNode: INodeable, nodes: INodeable[]): TreeNode {
    if (!ancestorNode.Id) {
      return null;
    }
    if (ancestorNode.Id === ROOT_ID && ancestorNode.Name !== this.name) {
      return null;
    }
    if (ancestorNode.Id === ancestorNode.ParentId && ancestorNode.Id !== ROOT_ID) {
      return null;
    }
    if ((ancestorNode.Name || ancestorNode.Mnemonic) && typeof ancestorNode.Id === 'number') {
      const treeNode: TreeNode = {
        name: ancestorNode.Name ?? ancestorNode.Mnemonic,
        mnemonic: ancestorNode.Mnemonic ?? ancestorNode.Name,
        Id: ancestorNode.Id,
        children: [],
        expanded: this.expandedNodes.includes(ancestorNode.Id)
      };
      try {
        treeNode.children = this.getChildrenFromNodeId(ancestorNode.Id, nodes);
        this.setNodeToolTip(ancestorNode, treeNode);
      } catch (err) {
        console.warn(err);
      }
      return treeNode;
    } else {
      return null;
    }
  }

  /**
   * This method assign its possible children to a given INodeable ID
   */
  private getChildrenFromNodeId(ancestorNodeId: number, nodes: INodeable[]): TreeNode[] {
    const childrenNodes = nodes.filter(node => node.ParentId === ancestorNodeId && node.Id !== null);

    if (childrenNodes.length === 0) {
      return [];
    }

    const childrenTreeNodes = childrenNodes
      .map((child: INodeable) => {
        if (child.Id !== null) {
          const childrenTreeNode = this.buildTreeNode(child, nodes);
          return childrenTreeNode;
        }
      })
      .filter(Boolean);

    return childrenTreeNodes;
  }

  private sortNodes(nodes: INodeable[]) {
    nodes.sort((a, b) => Number(a.ParentId) - Number(b.ParentId));
    nodes.sort((a, b) => Number(a.Id) - Number(b.Id));
    nodes.sort((a, b) => Number(a.DisplayOrder) - Number(b.DisplayOrder));
    return nodes;
  }

  public hasNestedChild = (_: number, node: TreeNode) => {
    try {
      return node.children !== null && node.children.length > 0;
    } catch (err) {
      this.otherTreeErrors = true;
      return false;
    }
  };

  private readonly _getChildren = (node: TreeNode) => node.children;

  public isExpandedNode = (node: TreeNode) => this.nestedTreeControl.isExpanded(node);

  public toggleNode = (toggledNode: TreeNode) => {
    this.isManualToggle = true;
    this.nestedTreeControl.toggle(toggledNode);
    this.changeDetector.detectChanges();
    toggledNode.expanded = !toggledNode.expanded;
    if (toggledNode.expanded) {
      this.expandedNodeService.registerExpandedNode({ treeName: this.name, expandedIds: [toggledNode.Id] });
    } else {
      this.expandedNodeService.unregisterExpandedNode({ treeName: this.name, expandedIds: [toggledNode.Id] });
    }
    this.changeDetector.detectChanges();
  };

  public expandAllNodes(): void {
    this.nestedTreeControl.dataNodes = this.tree;
    this.nestedTreeControl.expandAll();
    this.tree.forEach(parentNode => this.setExpandedTrueForTree(parentNode));
  }

  private restoreExpandedNodes(tree: TreeNode[] = this.tree) {
    if (this.errorsDisplayingTheTreeRoot) {
      return null;
    }
    tree.forEach(treeNode => {
      if (treeNode === null) {
        return null;
      }
      if (treeNode.expanded) {
        this.nestedTreeControl.expand(this.findNestedDataSourceNodeByNodeId(treeNode.Id));
      }
      if (treeNode.children.length > 0) {
        this.restoreExpandedNodes(treeNode.children);
      }
    });
  }

  public collapseAllNodes(): void {
    this.nestedTreeControl.dataNodes = this.tree;
    this.nestedDataSource.data.forEach(rootNode => this.nestedTreeControl.collapseDescendants(rootNode));
    this.tree.forEach(parentNode => this.setExpandedFalseForTree(parentNode));
    this.expandedNodes = [];
  }

  private setExpandedTrueForTree(parentNode: TreeNode): void {
    parentNode.expanded = true;
    this.expandedNodes = Array.from(new Set([...this.expandedNodes, parentNode.Id]));
    if (parentNode.children && parentNode.children.length > 0) {
      parentNode.children.forEach(children => this.setExpandedTrueForTree(children));
    }
  }

  private setExpandedFalseForTree(parentNode: TreeNode): void {
    parentNode.expanded = false;
    this.expandedNodes = [];
    if (parentNode.children && parentNode.children.length > 0) {
      parentNode.children.forEach(children => this.setExpandedFalseForTree(children));
    }
  }

  private setNodeToolTip(node: INodeable, treeNode: TreeNode) {
    if (node && node[this.tooltipKey]) {
      treeNode.tooltipNumber = node[this.tooltipKey].length;
      treeNode.tooltipMessage = Array.isArray(node[this.tooltipKey]) ? node[this.tooltipKey].join(', ') : node[this.tooltipKey];
    }
  }

  private findNestedDataSourceNodeByNodeId(nodeId: number, nodes: TreeNode[] = this.tree): TreeNode {
    for (const node of nodes) {
      if (node.Id === nodeId) {
        return node;
      }
      if (node.children && node.children.length > 0) {
        return this.findNestedDataSourceNodeByNodeId(nodeId, node.children);
      }
    }
  }
}
