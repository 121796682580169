import { Injectable } from '@angular/core';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { Observable, Subject } from 'rxjs';
import { AppConfigProvider } from '@app/app.config.provider';
import { DependencyService } from '@services/dependency/dependency.service';
import { SharedService } from '@services/shared/shared-service';
import { NavigationService } from '@services/navigation/navigation.service';
import { OpenIdConnectService } from '@services/openid/openid-connect.service';
import { WebSocketRequest } from '@models/websocket/websocket-request';
import { sleep } from '@shared/sleep';
import { FtpStatusService } from '@services/ftp/ftp-status.service';
import { BIService } from '@services/BI/all-instruments/bi.service';
import { WebsocketMessageTasks } from '@models/websocket/message-tasks';

@Injectable({
  providedIn: 'root'
})
export class WebSocketService {
  private websocketSubject$: WebSocketSubject<any>;
  private reconnectInterval: number = 5000; // Reconnect every 5 seconds
  private reconnectAttempts: number = 10;
  private connectionAttempts: number = 0;
  private connectionStatus$: Subject<boolean> = new Subject<boolean>();
  private webSocketURL: string;

  public get _asOfDate$() {
    return this.sharedService.selectedAsOfDate$;
  }

  public get _pageUrl$() {
    return this.navigationService.pageUrlNotNull$;
  }

  constructor(
    protected readonly appConfig: AppConfigProvider,
    private readonly dependencyService: DependencyService,
    private readonly sharedService: SharedService,
    private readonly navigationService: NavigationService,
    private readonly openIdConnectService: OpenIdConnectService,
    private readonly ftpStatusService: FtpStatusService,
    private readonly biService: BIService
  ) {
    this.appConfig.environment.subscribe(env => {
      this.webSocketURL = env.API.DIMENSIONS.replace(/(^\w+:|^)/, 'wss:') + '/ws?jwt=';
    });
    this.watchAOD();
    this.watchUrl();
  }

  private watchAOD() {
    this._asOfDate$.subscribe(aod => this.updateAsOfDate(aod?.Date));
  }

  private watchUrl() {
    this._pageUrl$.subscribe(page => this.updatePageUrl(page));
  }

  public startConnection(): void {
    if ((!this.websocketSubject$ || this.websocketSubject$.closed) && this.openIdConnectService.userAvailable) {
      const { access_token } = this.openIdConnectService.user;
      this.websocketSubject$ = webSocket(this.webSocketURL + access_token);

      this.websocketSubject$.subscribe(
        message => this.handleMessage(message),
        error => this.handleError(error),
        () => this.handleComplete()
      );
      this.connectionStatus$.next(true);
      this.connectionAttempts = 0;
      this.dependencyService.notifyPageValidation();
    }
  }

  private reconnect(): void {
    this.connectionAttempts++;
    if (this.connectionAttempts <= this.reconnectAttempts) {
      setTimeout(() => this.startConnection(), this.reconnectInterval);
    } else {
      this.connectionStatus$.next(false);
    }
  }

  // Main message handler.
  private handleMessage(message): void {
    switch (message.TaskName) {
      case WebsocketMessageTasks.NotifyNewValidationsDV:
        this.dependencyService.notifyPageValidation();
        break;
      case WebsocketMessageTasks.RunFTPResult:
        this.ftpStatusService.fetchFTPResultsSummary();
        break;
      case WebsocketMessageTasks.UpdateAITProcess:
        this.biService.updateAITProcessRunning(message.Data);
        break;
      default:
        break;
    }
  }

  private handleError(error: any): void {
    console.error('WebSocket error:', error);
    this.reconnect();
  }

  private handleComplete(): void {
    this.reconnect();
  }

  public send(taskName: string, message: string): void {
    let send = false;
    while (!send) {
      if (this.websocketSubject$ && !this.websocketSubject$.closed) {
        this.websocketSubject$.next(JSON.parse(JSON.stringify(this.buildRequest(taskName, message))));
        send = true;
      } else {
        sleep(100);
      }
    }
  }

  public getConnectionStatus(): Observable<boolean> {
    return this.connectionStatus$.asObservable();
  }

  public disconnect(): void {
    if (this.websocketSubject$ && !this.websocketSubject$.closed) {
      this.websocketSubject$.complete();
      this.connectionStatus$.next(false);
    }
  }

  private buildRequest(taskName: string, message: string): WebSocketRequest {
    const { access_token } = this.openIdConnectService.user;
    let req = new WebSocketRequest();
    req.JWT = access_token;
    req.TaskName = taskName;
    req.Data = message;
    return req;
  }

  // Updates current location URL so WS can send events only to users on certain pages
  private async updatePageUrl(pageUrl: string) {
    this.send('UpdatePageUrl', pageUrl);
  }

  // updates current AsOfDate for Page Validations dependant of AsOfDate
  private async updateAsOfDate(asOfDate: string) {
    this.send('UpdateAsOfDate', asOfDate);
  }
}
