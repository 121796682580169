import { AccountMapping } from '@models/recon-setting/account-mapping';
import { isEmpty } from '@shared/arrays';
import * as GC from '@grapecity/spread-sheets';
import { IDropdownItem, addSelectDropDownToCell } from '@shared/spread/spread-styles';
import { InstrumentType } from '@models/general-ledger/instrument-type';
import { getLockedCellStyle } from '@shared/spread/spread-settings';

export const GL_ACCOUNT_ONLY_TEXT = 'GL ONLY ACCOUNT';
export const GL_ACCOUNT_ONLY_ID = -100;

export const isAccountLeafNode = (row: AccountMapping, mappings: AccountMapping[]): boolean => {
  return !mappings.some(other => other.ParentAccountID === row.GLAccountID);
};

const getReconAccount = (row: AccountMapping, othersrow: AccountMapping[]): AccountMapping => {
  const isReconAccountReconcilingToItself = row.ReconAccountNumber === row.GLAccountID;
  return isReconAccountReconcilingToItself ? row : othersrow.find(val => val.GLAccountID === row.ReconAccountNumber);
};

const isAccountAReconAccount = (bs: AccountMapping, othersBs: AccountMapping[]): boolean => {
  return othersBs.some(val => val.ReconAccountNumber === bs.GLAccountID);
};

export const addErrorToRow = (row: AccountMapping, col: number, error: string) => {
  row.errorsArray.push({
    col,
    error
  });
};

export const getLeafAccounts = (accountList: AccountMapping[]): AccountMapping[] => {
  const leafNodes: AccountMapping[] = [];
  for (const account of accountList) {
    const children = accountList.filter(acc => acc.ParentAccountID === Number(account.GLAccountID));
    const isALeafNode = children.length === 0;
    if (isALeafNode) {
      leafNodes.push(account);
    }
  }
  return leafNodes;
};

export const buildReconDropdownList = (sheet: GC.Spread.Sheets.Worksheet, balances: AccountMapping[], columnNumber: number) => {
  const leafNodes = getLeafAccounts(balances);
  const leafs: IDropdownItem[] = leafNodes.map(mapping => ({ value: mapping.GLAccountNumber }));

  if (leafs.some(val => val.value === GL_ACCOUNT_ONLY_ID)) {
    leafs.find(val => val.value === GL_ACCOUNT_ONLY_ID).value = GL_ACCOUNT_ONLY_TEXT;
  } else {
    leafs.unshift({ value: GL_ACCOUNT_ONLY_TEXT });
  }
  addSelectDropDownToCell(sheet, -1, columnNumber, leafs);
};

export const validateReconAccount = (
  sheet: GC.Spread.Sheets.Worksheet,
  row: AccountMapping,
  othersrow: AccountMapping[],
  aType: string,
  column: number
): AccountMapping => {
  // Recon Account: can be empty but must be selected if Income Expense Source is selected, must be either
  // itself or its parent. cannot have leaf nodes, must be set if it is a leaf node
  /*
    Assumption made on input: only leaf nodes are passed to this validation method
    Recon Account Validation Rules
      * Account cannot have leaf nodes
      * Must be set if account is a leaf node
      * must be selected if Income Expense Source is selected
      *
  */
  const isReconEmpty = isEmpty(row.ReconAccountNumber);
  const isLeafNode = isAccountLeafNode(row, othersrow);
  const isGLAccountOnly = row.ReconAccountNumber === GL_ACCOUNT_ONLY_ID;

  if (isLeafNode && isReconEmpty) {
    addErrorToRow(row, column, `Reconciliation Account is required for all Leaf Nodes (e.g., Accounts with no children)`);
    return row;
  } else if (isLeafNode && !isGLAccountOnly) {
    const recon = getReconAccount(row, othersrow);

    if (!recon) {
      addErrorToRow(row, column, `Reconciliation Account is required`);
    } else if (recon.InstrumentType !== row.InstrumentType) {
      addErrorToRow(row, column, `Recon Account must be the account itself or another leaf node account with the same Instrument Type`);
    } else if (
      recon.GLAccountID === row.ReconAccountNumber &&
      recon.ReconAccountNumber === row.GLAccountID &&
      recon.GLAccountID !== row.GLAccountID
    ) {
      addErrorToRow(
        row,
        column,
        `Reconciliation Account cannot be an account which has been already assigned this account as its reconciliation account`
      );
    } else if (recon.ReconAccountNumber === GL_ACCOUNT_ONLY_ID) {
      addErrorToRow(
        row,
        column,
        `Reconciliation Account cannot be an account with its Reconciliation Account as "${GL_ACCOUNT_ONLY_TEXT}"`
      );
    } else if (isAccountAReconAccount(row, othersrow) && row.ReconAccountNumber !== row.GLAccountID) {
      addErrorToRow(
        row,
        column,
        `This account is designated as a Recon Account for other "${aType}"
          Accounts. Therefore you must specify this "${aType}"
          Account Number as its own Recon Account`
      );
    }
    return row;
  }
  return row;
};

export const validateInstrumentType = (
  row: AccountMapping,
  otherRows: AccountMapping[],
  InstrumentTypes: InstrumentType[],
  columnNumber: number
): AccountMapping => {
  // Recon Account: can be empty but must be selected if IE Source type is selected, must be either itself or
  // its parent. cannot have leaf nodes, must be set if it is a leaf node
  const isInstrumentEmpty = isEmpty(row.InstrumentType);
  const isLeafNode = isAccountLeafNode(row, otherRows);
  const isValidInstrumentType = InstrumentTypes.some(type => type.InstrumentID === row.InstrumentType);
  const isGLAccountOnly = row.ReconAccountNumber === GL_ACCOUNT_ONLY_ID;
  const type = 'Instrument Type';

  if (!isValidInstrumentType && !isInstrumentEmpty) {
    addErrorToRow(row, columnNumber, `Please enter a valid ${type} or select one from the dropdown list.`);
  } else if ((isInstrumentEmpty && !isGLAccountOnly) || (!isInstrumentEmpty && isGLAccountOnly)) {
    if (isLeafNode) {
      addErrorToRow(
        row,
        columnNumber,
        `${type} is required for all Leaf Nodes (e.g., Accounts with no children). Except for those with Reconciliation Account = "${GL_ACCOUNT_ONLY_TEXT}"`
      );
    }
  } else if (!isGLAccountOnly) {
    const recon = getReconAccount(row, otherRows);
    if (!isLeafNode) {
      addErrorToRow(row, columnNumber, `Only Accounts that are Leaf Nodes (e.g., Accounts with no children) can be assigned an ${type}.`);
    } else if (recon && recon.InstrumentType !== row.InstrumentType) {
      addErrorToRow(
        row,
        columnNumber,
        `Leaf Node Accounts of the same Parent (e.g., Accounts with no children) must have the same ${type}`
      );
    }
  }

  return row;
};

export const lockRange = (range: GC.Spread.Sheets.CellRange) => {
  range.setStyle(getLockedCellStyle());
};

// Sort Account Mappings by number, then strings. In increasing, and alphabetical order, respectively
export const sortModels = (a: AccountMapping, b: AccountMapping): number => {
  const aTrim = a.GLAccountNumber.toString().trim();
  const bTrim = b.GLAccountNumber.toString().trim();
  if (isNaN(Number(aTrim)) && isNaN(Number(bTrim))) {
    // Both string
    return aTrim.localeCompare(bTrim);
  } else if (!isNaN(Number(aTrim)) && !isNaN(Number(bTrim))) {
    // Both number
    return Number(aTrim) > Number(bTrim) ? 1 : -1;
  } else {
    // a exor b string
    return isNaN(Number(aTrim)) ? 1 : -1;
  }
};
