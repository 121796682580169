import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigProvider } from '@app/app.config.provider';
import { AppConfigAPI } from '@models/appconfig';
import { AssignmentFlag } from '@models/modal-screen/assignment-ruleset/account-model';
import { LocalStorageKeys } from '@models/storage/local-storage-keys';
import { LocalStorageRepository } from '@services/local-storage/local-storage';
import { catchError } from 'rxjs/operators';
import { BehaviorSubject, Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AccountService extends LocalStorageRepository {
  private GLReconURL: string; // Get Active Users
  private environment: AppConfigAPI;
  public balanceSheetDataChange = new BehaviorSubject<AssignmentFlag[]>([]);

  public constructor(private readonly http: HttpClient, private readonly appConfig: AppConfigProvider) {
    super(LocalStorageKeys.MODALSCREEN, 'modal-screen');
    this.appConfig.environment.subscribe(env => {
      this.environment = env.API;
      this.GLReconURL = `${this.environment.DIMENSIONS}/api/Assignment`;
    });
  }

  public saveAssignmentFlag(flag: number, dataSourceId: number, assignmentValue: number, useOtherValue: string): Observable<void> {
    return this.http.post<void>(`${this.GLReconURL}/AssignmentFlags`, {
      flag: flag,
      dataSourceId: dataSourceId,
      assignmentValue: assignmentValue,
      useOtherValue: useOtherValue
    });
  }

  public getAssignmentStatus = async (dataSourceId: number, assignmentValue: number): Promise<AssignmentFlag[]> => {
    return await this.http
      .get<AssignmentFlag[]>(`${this.GLReconURL}/GetAssignmentStatus?dataSourceId=${dataSourceId}`)
      .pipe(
        catchError(error => {
          console.error('Error fetching data:', error);
          return of(null);
        })
      )
      .toPromise()
      .then(result => {
        if (result !== null && result !== undefined) {
          return result;
        } else {
          console.error('Unexpected null or undefined result from API');
          return [];
        }
      })
      .catch(error => {
        console.error('Error converting to Promise:', error);
        return [];
      });
  };
}
