<!--  Loading  -->
<ng-container *ngIf="!items; else unallocated">
    <div class="content-container">
        <i class="fa-solid fa-spinner fa-spin fa-2x loader" [@fadeInOut]="!items"></i>
    </div>
</ng-container>
<!-- Unallocated List -->
<ng-template #unallocated>
    <ng-container *ngIf="items.length > 0; else noUnallocated" >
        <div *ngFor="let item of items" [@fadeInOut]="items.length > 0">
            {{ displayPropFn(item) }}
        </div>
    </ng-container>
</ng-template>
<!-- All Items Allocated -->
<ng-template #noUnallocated>
    <div class="content-container no-unallocated" [@fadeInOut]="items?.length === 0">
        <i class="fa-solid fa-3x fa-circle-check"></i>
        {{noResultsText}}
    </div>
</ng-template>
