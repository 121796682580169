<div class="container">
  <div class="radio-buttons">
    <label>
      <input
        type="radio"
        name="radioSPGroup"
        [(ngModel)]="selectedSubProductRadio"
        value="ImportedSubProductValue"
        (change)="onSubProductRadioChange()"
        checked
      />
      Use Imported Value
    </label>
    <label>
      <input
        type="radio"
        name="radioSPGroup"
        [(ngModel)]="selectedSubProductRadio"
        value="AssignmentSubProductRuleset"
        (change)="onSubProductRadioChange()"
        (click)="onAddYieldCurveClicked()"
      />
      Use Assignment Ruleset
    </label>
    <label>
      <input
        type="radio"
        name="radioSPGroup"
        [(ngModel)]="selectedSubProductRadio"
        value="SingleSubProductValue"
        (change)="onSubProductRadioChange()"
      />
      Use Single Value
    </label>
    <form [formGroup]="textFormGroup" *ngIf="selectedSubProductRadio === 'SingleSubProductValue'">
      <label for="numericField"></label>
      <input
        type="text"
        id="numericField"
        formControlName="numericField"
        [ngClass]="{
          error:
            textFormGroup.get('numericField').invalid &&
            (textFormGroup.get('numericField').dirty || textFormGroup.get('numericField').touched)
        }"
      />
      <div *ngIf="textFormGroup.get('numericField').hasError('pattern')">
        <span class="error-message"
          >Invalid entry. Incorrect SubProductID, value must be Alphanumeric (no spaces or special characters are allowed except for '_'
          ).</span
        >
      </div>
      <div *ngIf="textFormGroup.get('numericField').hasError('maxlength')">
        <span class="error-message">Invalid entry. Incorrect SubProductID, value cannot exceed 255 characters in length.</span>
      </div>
    </form>
  </div>

  <div class="button">
    <button class="btn btn_normal btn_blue btn_wide" [disabled]="disableEditButton" (click)="onAddYieldCurveClicked()">
      <span>EDIT DIMENSIONS</span>
    </button>
  </div>
</div>

<ng-container
  class="prompt-message-container flex-container centered vertical full-height"
  *ngIf="selectedSubProductRadio === 'ImportedSubProductValue'"
>
  <h3 class="prompt-title">Assignment Ruleset not Enabled.</h3>
  <p class="prompt-message">This field will be populated based on the imported files for this {{ this.selectedNameMessage }}.</p>
</ng-container>

<ng-container
  class="prompt-message-container flex-container centered vertical full-height"
  *ngIf="selectedSubProductRadio === 'AssignmentSubProductRuleset'"
>
  <div><app-invalid-rows [invalidRowCount]="invalidRows.length" (click)="onClickInvalidRow()"></app-invalid-rows></div>
  <div [class.hidden]="isLoading" class="spreadsheet-container fadein" (mousemove)="onMouseMove($event)">
    <gc-spread-sheets #spreadDiv class="spreadsheet fadein" (workbookInitialized)="workBookInit($event)"></gc-spread-sheets>
  </div>
</ng-container>

<ng-container
  class="prompt-message-container flex-container centered vertical full-height"
  *ngIf="selectedSubProductRadio === 'SingleSubProductValue'"
>
  <h3 class="prompt-title">Assignment Ruleset not Enabled.</h3>
  <p class="prompt-message">This field will be populated based on the inputted Single Value.</p>
</ng-container>

<div footer class="footer">
  <button id="discard-button" class="btn" (click)="closeModal()">Close</button>
  <button id="save-button" class="btn" (click)="onClickSave()" [disabled]="isSaveButtonDisabled()">Save</button>
</div>
