import { formatDate } from '@angular/common';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { importAndCalculateElements } from '@core/navigation/navigation-elements';
import { NavigationElement } from '@core/navigation/navigation-interfaces';
import { AsOfDate } from '@models/as-of-date/as-of-date';
import { DataSourcesService } from '@services/data-sources/data-sources.service';
import { NavigationService } from '@services/navigation/navigation.service';
import { OpenIdConnectService } from '@services/openid/openid-connect.service';
import { Subscription } from 'rxjs';
import { AsOfDateSelectionComponent } from '../as-of-date-selection/as-of-date-selection.component';
import { SharedService } from '@services/shared/shared-service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'emp-model-data-navigation',
  templateUrl: './model-data-navigation.component.html',
  styleUrls: ['./model-data-navigation.component.scss']
})
export class ModelDataNavigationComponent implements OnInit, OnDestroy {
  @Input() public isAdmin = false;

  @ViewChild(AsOfDateSelectionComponent)
  private readonly asOfDateSelectionComponent: AsOfDateSelectionComponent;

  public importAndCalculate: NavigationElement = this.filteredImportList();
  public importAndCalculateChildren: NavigationElement[] = this.importAndCalculate.children;

  private readonly subscription: Subscription = new Subscription();
  private isDateExists = false;

  // public isExpanded = false;
  public formatDate = formatDate;

  get selectedDate(): AsOfDate {
    return this.asOfDateSelectionComponent?.selectedDate;
  }

  get isDateSelectorOpen() {
    return this.asOfDateSelectionComponent.isOpen;
  }

  get isNavigationBarActive(): boolean {
    return this.navigationService.isNavigationBarActive$.value;
  }

  constructor(
    private readonly dataSourcesService: DataSourcesService,
    private readonly navigationService: NavigationService,
    private readonly openIDConnectService: OpenIdConnectService,
    private readonly sharedService: SharedService
  ) {}

  public ngOnInit(): void {
    this.navigationService.setExpandStatus([this.importAndCalculate]);
    this.navigationService.storeExpandStatus(this.importAndCalculate);
    this.subscription.add(this.dataSourcesService.selectedAsOfDate$.subscribe(asOfDate => this.onDateSelected(asOfDate)));
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public toggleExpand(): void {
    this.importAndCalculate.isExpanded = !this.importAndCalculate.isExpanded;
    this.navigationService.storeExpandStatus(this.importAndCalculate);
  }

  /**
   * Checks if any date exists and sets the value of isDateExists accordingly.
   * If an error occurs while fetching the dates, it emits the currently active date again
   * so that promises waiting on an as-of-date change can resolve.
   * Also, Using ?.length() > 0 would not work because dates is not a string or an array, but an object.
   * Therefore, we need to check if dates is an array and if it has a length greater than 0.
   */
  private async checkIfAnyDateExists(): Promise<boolean> {
    const dates = await this.sharedService.getAsOfDates().pipe(take(1)).toPromise();

    if (dates instanceof Array) {
      return dates.length > 0;
    } else if (dates.error) {
      // On error, emit the currently active date again so promises waiting on an as-of-date change can resolve.
      // sharedService.lockUnlockAsOfDate waits for selectedAsOfDate to emit before resolving its promise.
      return this.selectedDate.Date !== undefined;
    }
    return false;
  }

  public async onDateSelected(date: AsOfDate): Promise<void> {
    // -- if no date exists, disable the 'GL Reconciliation ' and 'Funds Transfer Pricing' pages (specifically for clean install)
    this.isDateExists = await this.checkIfAnyDateExists();

    const glReconElement = this.importAndCalculateChildren.find(child => child.label === 'GL Reconciliation');
    if (glReconElement) {
      glReconElement.isDisabled = !date?.IsLocked || !this.isDateExists;
    }

    const ftpElement = this.importAndCalculateChildren.find(child => child.label === 'Funds Transfer Pricing');
    if (ftpElement) {
      ftpElement.isDisabled = !date?.IsLocked || !date?.IsLockedRecon || !this.isDateExists;
    }
  }

  public isCompleted(element: NavigationElement) {
    switch (element.label) {
      case 'Data Import':
        return this.selectedDate?.IsLocked;
      case 'GL Reconciliation':
        return false;
      default:
        return false;
    }
  }

  public isDisabled(element: NavigationElement) {
    switch (element.label) {
      case 'GL Reconciliation':
        return !this.selectedDate?.IsLocked;
      case 'Funds Transfer Pricing':
        return !this.selectedDate?.IsLocked || !this.selectedDate?.IsLockedRecon;
      default:
        return false;
    }
  }

  public getTooltip(element: NavigationElement) {
    if (!this.selectedDate) {
      return '';
    }

    switch (element.label) {
      case 'Data Import':
        return this.isCompleted(element) ? `Import Completed for ${formatDate(this.selectedDate?.Date, 'MMMM y', 'en-US')}` : '';
      case 'GL Reconciliation':
        return this.isDisabled(element)
          ? `Please complete Data Import for ${formatDate(this.selectedDate?.Date, 'MMMM y', 'en-US')}`
          : 'GL Reconciliation';
      case 'Funds Transfer Pricing':
        return this.isDisabled(element)
          ? `Please complete GL Reconciliation for ${formatDate(this.selectedDate?.Date, 'MMMM y', 'en-US')}`
          : 'Funds Transfer Pricing';
      default:
        return '';
    }
  }

  private filteredImportList(): NavigationElement {
    let navElement = {} as NavigationElement;
    navElement.children = [];
    const userLevels = new Set(this.openIDConnectService.userLevelList.map(level => level.level_id));

    importAndCalculateElements[0].roles.forEach(role => {
      if (userLevels.has(role)) {
        navElement = importAndCalculateElements[0];
        return;
      }
    });

    let allowedSubs = [] as NavigationElement[];

    navElement?.children?.forEach(child => {
      child?.roles.forEach(role => {
        if (userLevels.has(role) && allowedSubs.indexOf(child) === -1) {
          allowedSubs.push(child);
        }
      });
    });

    navElement.children = allowedSubs;

    return navElement;
  }
}
