import * as GC from '@grapecity/spread-sheets';
import { clone } from '@shared/arrays';

export class SpreadContextMenu {
  private static _allOptions: GC.Spread.Sheets.ContextMenu.IMenuItemData[] = null;

  static get allOptions(): GC.Spread.Sheets.ContextMenu.IMenuItemData[] {
    if (SpreadContextMenu._allOptions === null) {
      const contextMenu = new GC.Spread.Sheets.ContextMenu.ContextMenu();
      SpreadContextMenu._allOptions = clone(contextMenu.menuData);
    }
    return SpreadContextMenu._allOptions;
  }

  public static setSimpleCopy(book: GC.Spread.Sheets.Workbook) {
    const enabledCommands = ['gc.spread.copy'];
    book.contextMenu.menuData = book.contextMenu.menuData.filter(menuItem => enabledCommands.includes(menuItem.name));
    return this;
  }

  public static disableHideRows(book: GC.Spread.Sheets.Workbook) {
    const disabledCommands = ['gc.spread.hideRows', 'gc.spread.unhideRows'];
    book.contextMenu.menuData = book.contextMenu.menuData.filter(menuItem => !disabledCommands.includes(menuItem.name));
    return this;
  }

  public static disableHideColumns(book: GC.Spread.Sheets.Workbook) {
    const disabledCommands = ['gc.spread.hideColumns', 'gc.spread.unhideColumns'];
    book.contextMenu.menuData = book.contextMenu.menuData.filter(menuItem => !disabledCommands.includes(menuItem.name));
    return this;
  }

  public static disableInsertRows(book: GC.Spread.Sheets.Workbook) {
    const sheet: GC.Spread.Sheets.Worksheet = book.getActiveSheet();
    sheet.options.protectionOptions.allowInsertRows = false;
    const disabledCommands = ['gc.spread.insertRows'];
    book.contextMenu.menuData = book.contextMenu.menuData.filter(menuItem => !disabledCommands.includes(menuItem.name));
    return this;
  }

  public static disableDeleteRows(book: GC.Spread.Sheets.Workbook) {
    const sheet: GC.Spread.Sheets.Worksheet = book.getActiveSheet();
    sheet.options.protectionOptions.allowDeleteRows = false;

    const disabledCommands = ['gc.spread.deleteRows'];
    book.contextMenu.menuData = book.contextMenu.menuData.filter(menuItem => !disabledCommands.includes(menuItem.name));
    return this;
  }

  public static enableDeleteRows(book: GC.Spread.Sheets.Workbook) {
    const sheet: GC.Spread.Sheets.Worksheet = book.getActiveSheet();
    sheet.options.protectionOptions.allowDeleteRows = true;

    const option = SpreadContextMenu.allOptions.find(({ name }) => name === 'gc.spread.deleteRows');
    book.contextMenu.menuData.push({ type: 'separator' }, option);
    return this;
  }

  public static enableInsertRowsAboveAndBelow(book: GC.Spread.Sheets.Workbook) {
    const insertAbove = {
      text: 'Insert Row Above',
      name: 'addRowAboveCmd',
      workArea: 'rowHeader',
      command: 'addRowAboveCmd'
    };

    const insertBelow = {
      text: 'Insert Row Below',
      name: 'addRowBelowCmd',
      workArea: 'rowHeader',
      command: 'addRowBelowCmd'
    };

    book.contextMenu.menuData.push({ type: 'separator' }, insertAbove, insertBelow);

    return this;
  }

  public static disableInsertColumns(book: GC.Spread.Sheets.Workbook) {
    const sheet: GC.Spread.Sheets.Worksheet = book.getActiveSheet();
    sheet.options.protectionOptions.allowInsertColumns = false;
    const disabledCommands = ['gc.spread.insertColumns'];
    book.contextMenu.menuData = book.contextMenu.menuData.filter(menuItem => !disabledCommands.includes(menuItem.name));
    return this;
  }

  public static disableDeleteColumns(book: GC.Spread.Sheets.Workbook) {
    const sheet: GC.Spread.Sheets.Worksheet = book.getActiveSheet();
    sheet.options.protectionOptions.allowDeleteColumns = false;
    const disabledCommands = ['gc.spread.deleteColumns'];
    book.contextMenu.menuData = book.contextMenu.menuData.filter(menuItem => !disabledCommands.includes(menuItem.name));
    return this;
  }

  public static disableClearContent(book: GC.Spread.Sheets.Workbook) {
    const disabledCommands = ['gc.spread.clearContents'];
    book.contextMenu.menuData = book.contextMenu.menuData.filter(menuItem => !disabledCommands.includes(menuItem.name));
    return this;
  }

  public static disableInsertComments(book: GC.Spread.Sheets.Workbook) {
    const disabledCommands = ['gc.spread.insertComment'];
    book.contextMenu.menuData = book.contextMenu.menuData.filter(menuItem => !disabledCommands.includes(menuItem.name));
    return this;
  }

  public static disableFilter(book: GC.Spread.Sheets.Workbook) {
    const disabledCommands = ['gc.spread.filter'];
    book.contextMenu.menuData = book.contextMenu.menuData.filter(menuItem => !disabledCommands.includes(menuItem.name));
    return this;
  }

  public static disableSort(book: GC.Spread.Sheets.Workbook) {
    const disabledCommands = ['gc.spread.sort'];
    book.contextMenu.menuData = book.contextMenu.menuData.filter(menuItem => !disabledCommands.includes(menuItem.name));
    return this;
  }

  public static disableInsertComment(book: GC.Spread.Sheets.Workbook) {
    const disabledCommands = ['gc.spread.insertComment'];
    book.contextMenu.menuData = book.contextMenu.menuData.filter(menuItem => !disabledCommands.includes(menuItem.name));
    return this;
  }

  public static setCopyCutPaste(book: GC.Spread.Sheets.Workbook) {
    // Clean the context menu to allow copy, cut, paste only
    const allowedPasteCommand = 'gc.spread.pasteValues';
    const allowedCommands = ['gc.spread.copy', 'gc.spread.cut', allowedPasteCommand];
    book.contextMenu.menuData = book.contextMenu.menuData.filter(menuItem => allowedCommands.includes(menuItem.name));

    const pasteOption = book.contextMenu.menuData.find(c => c.name === allowedPasteCommand);
    if (pasteOption) {
      delete pasteOption.group;

      pasteOption.text = 'Paste (Use Ctrl + V instead)';
      pasteOption.disable = true;
    }

    return this;
  }

  public static enableSimplePaste(book: GC.Spread.Sheets.Workbook) {
    const allowedPasteCommand = 'gc.spread.pasteValues';
    const disabledCommands = ['gc.spread.pasteOptions', 'gc.spread.pasteAll', 'gc.spread.pasteFormula', 'gc.spread.pasteFormatting'];

    book.contextMenu.menuData = book.contextMenu.menuData.filter(menuItem => !disabledCommands.includes(menuItem.name));

    const pasteOption = book.contextMenu.menuData.find(c => c.name === allowedPasteCommand);
    if (pasteOption) {
      delete pasteOption.group;
      pasteOption.text = 'Paste';
    }
    return this;
  }

  public static setLockedContextMenu(book: GC.Spread.Sheets.Workbook) {
    // Clean the context menu to allow copy, cut, paste only
    this.setSimpleCopy(book);
    // const allowedCommands = ['gc.spread.copy'];
    // book.contextMenu.menuData = book.contextMenu.menuData.filter(menuItem => allowedCommands.includes(menuItem.name));
    return this;
  }

  // COPY, CUT, PASTE + OPTIONAL INSERT/DELETE ROWS
  public static setUnlockedContextMenu(book: GC.Spread.Sheets.Workbook, enableInsertRows = true, enableDeleteRows = true) {
    // Clean the context menu to allow copy, cut, paste only
    const allowedPasteCommand = 'gc.spread.pasteValues';
    const allowedCommands = ['gc.spread.copy', 'gc.spread.cut', allowedPasteCommand];
    // Insert and delete rows
    if (enableInsertRows) {
      allowedCommands.push('gc.spread.insertRows');
    }
    if (enableDeleteRows) {
      allowedCommands.push('gc.spread.deleteRows');
    }

    const pasteOption = book.contextMenu.menuData.find(c => c.name === allowedPasteCommand);
    if (pasteOption) {
      delete pasteOption.group;
      pasteOption.text = 'Paste (Use Ctrl + V instead)';
      pasteOption.disable = true;
    }
    book.contextMenu.menuData = [...book.contextMenu.menuData.filter(menuItem => allowedCommands.includes(menuItem.name))];
    return this;
  }
}
