import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SignoutOidcComponent } from '@core/auth/signout-oidc/signout-oidc.component';
import { PlaceholderComponent } from '@core/placeholder/placeholder.component';
import { SigninOidcComponent } from './core/auth/signin-oidc/signin-oidc.component';
import { SilentRenewRedirectComponent } from './core/auth/silent-renew-redirect/silent-renew-redirect.component';
import { Error401Component } from './core/error-pages/error401/error401.component';
import { TimeoutComponent } from './core/error-pages/timeout/timeout.component';
import { UnableToLoadPageComponent } from './core/error-pages/unable-to-load-page/unable-to-load-page.component';
import { AuthGuard } from './services/auth-guard/auth-guard.service';
import { ModalScreenComponent } from '@components/modal-screen/modal-screen.component';
import { EmptyComponent } from '@core/navigation/navigation-modal/empty/empty.component';
import { CanComponentDeactivateGuard } from '@services/auth-guard/can-component-deactivate-guard.service';
import { DataImportLogComponent } from '@components/model-data/data-import/data-import-log/data-import-log.component';

export const routes: Routes = [
  // OpenID Connect
  { path: 'signin-oidc', component: SigninOidcComponent },
  { path: 'signout-oidc', component: SignoutOidcComponent },
  { path: 'redirect-silentrenew', component: SilentRenewRedirectComponent },

  // Error pages
  { path: 'error/timeout', component: TimeoutComponent },
  { path: 'error/401', component: Error401Component },
  { path: 'error', component: UnableToLoadPageComponent },
  { path: 'empty', component: EmptyComponent, outlet: 'navigation-modal-outlet' },

  // ALL
  {
    path: 'hierarchies-and-dimensions/view-only/:name',
    loadChildren: () => import('./components/view-only/view-only.module').then(m => m.ViewOnlyModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'general-ledger/balance-sheet',
    loadChildren: () => import('./components/general-ledger/balance-sheet/balance-sheet.module').then(m => m.BalanceSheetModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'general-ledger/income-statement',
    loadChildren: () => import('./components/general-ledger/income-statement/income-statement.module').then(m => m.IncomeStatementModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'general-ledger/daily-gl/dates-imported',
    loadChildren: () => import('./components/general-ledger/dates-imported/dates-imported.module').then(m => m.DatesImportedModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'general-ledger/daily-gl/:tab',
    loadChildren: () => import('./components/general-ledger/daily-gl/daily-gl.module').then(m => m.DailyGLModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'gl-recon-settings',
    loadChildren: () => import('./components/gl-recon-settings/gl-recon-settings.module').then(m => m.GLReconSettingsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'hierarchies-and-dimensions/organizational/legal-entity',
    loadChildren: () => import('./components/legal-entity/legal-entity.module').then(m => m.LegalEntityModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'hierarchies-and-dimensions/organizational/department',
    loadChildren: () => import('./components/department/department.module').then(m => m.DepartmentModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'hierarchies-and-dimensions/organizational/department-legal-entity',
    loadChildren: () =>
      import('./components/department-legal-entity/department-legal-entity.module').then(m => m.DepartmentLegalEntityModule),
    canActivate: [AuthGuard]
  },
  // {
  //   path: 'account-assignment/modal-screen',
  //   loadChildren: () => import('./components/modal-screen/modal-screen.module').then(m => m.ModalScreenModule),
  //   canActivate: [AuthGuard]
  // },
  // {
  //   path: 'account-assignment/calculated-fields',
  //   loadChildren: () =>
  //     import('./components/account assignment/calculated-fields/calculated-fields.module').then(m => m.CalculatedFieldsModule),
  //   canActivate: [AuthGuard]
  // },
  // {
  //   path: 'account-assignment/assignments',
  //   loadChildren: () => import('./components/account assignment/assignments/assignments.module').then(m => m.AssignmentsModule),
  //   canActivate: [AuthGuard]
  // },
  // {
  //   path: 'account-assignment/results-report',
  //   loadChildren: () => import('./components/account assignment/results-report/results-report.module').then(m => m.ResultsReportModule),
  //   canActivate: [AuthGuard]
  // },
  // {
  //   path: 'account-assignment/assignments/:tab',
  //   loadChildren: () => import('./components/modal-screen/modal-screen.module').then(m => m.ModalScreenModule),
  //   canActivate: [AuthGuard]
  // },
  {
    path: 'import-and-calculate',
    loadChildren: () => import('./components/model-data/model-data.module').then(m => m.ModelDataModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'import-and-calculate/data-import-log',
    component: DataImportLogComponent
  },

  // Placeholder routes
  { path: 'placeholder/:id1', component: PlaceholderComponent, canActivate: [AuthGuard] },
  { path: 'placeholder/:id1/:id2', component: PlaceholderComponent, canActivate: [AuthGuard] },
  { path: 'placeholder/:id1/:id2/:id3', component: PlaceholderComponent, canActivate: [AuthGuard] },
  { path: 'placeholder/:id1/:id2/:id3/:id4', component: PlaceholderComponent, canActivate: [AuthGuard] },
  { path: 'placeholder/:id1/:id2/:id3/:id4/:id5', component: PlaceholderComponent, canActivate: [AuthGuard] },

  { path: '**', redirectTo: 'import-and-calculate' }
];

const modelSettingsRoutes: Routes = [
  {
    canActivate: [AuthGuard],
    canDeactivate: [CanComponentDeactivateGuard],
    component: ModalScreenComponent,
    loadChildren: () => import('./components/modal-screen/modal-screen.module').then(m => m.ModalScreenModule),
    outlet: 'navigation-modal-outlet',
    path: 'account-assignment/:id/:tab'
  }
];

@NgModule({
  imports: [RouterModule.forRoot([...routes])],
  exports: [RouterModule]
})
export class AppRoutingModule {}
