import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'app-invalid-rows',
  templateUrl: './invalid-rows.component.html',
  styleUrls: ['./invalid-rows.component.scss']
})
export class InvalidRowsComponent {
  @Input() public invalidRowCount = 0;
  @HostBinding('class.valid') get hostClass() {
    return !(this.invalidRowCount > 0);
  }
}
