<div id="page_container_outer">
  <div id="page_container">
    <div id="page_header_alt">
      <div class="page_inner_container clearfix">
        <div id="login_container" class="logout-page">
          <h1 class="login_title">You have been timed out!</h1>
          <div class="space_divider"></div>

          <div>
            <div class="panel-body">
              <p>For security reasons, your connection times out after you've been inactive for a while.</p>
              <p><button (click)="goBack()">Log Back In</button></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="page_content">
      <div class="page_inner_container clearfix"></div>
    </div>
  </div>
</div>
