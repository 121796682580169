<div id="page_container_outer">
  <div id="page_container">
    <div id="page_header_alt">
      <div class="page_inner_container clearfix">
        <div id="login_container" class="logout-page">
          <h1 class="login_title">401 - Not Authorized</h1>
          <div class="space_divider"></div>

          <div>
            <div class="panel-body">
              <p>You don't have permissions to access this module.</p>
              <p>Please contact your system administrator or log back again.</p>
              <p>
                <span><button (click)="goBack()">Go Back</button></span>
                <span><button (click)="signin()">Login With a Different Account</button></span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="page_content">
      <div class="page_inner_container clearfix"></div>
    </div>
  </div>
</div>
