import { Location } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { IEmpTab } from '@core/UI/emp-tab-strip/emp-tab-strip.component';
import { NavigationModalComponent } from '@core/navigation/navigation-modal/navigation-modal.component';
import * as GC from '@grapecity/spread-sheets';
import { AsOfDate } from '@models/as-of-date/as-of-date';
import { Flags, assignmentValues } from '@models/assignments/assignments';
import { ASSIGNMENT_VALUE, AssignmentFlag, localStorageLoc } from '@models/modal-screen/assignment-ruleset/account-model';
import {
  AssignmentDimension,
  AssignmentRuleset,
  AssignmentRulesetSave,
  COLUMNS,
  DataSourceTypeIds,
  aliasValues,
  assignmentRuleset,
  noAliasValues
} from '@models/modal-screen/assignment-ruleset/assignment-ruleset-model';
import { IPageValidation } from '@models/page-validation/page-validation';
import { PAGE_IDS } from '@models/page/page';
import { SelectEvent } from '@progress/kendo-angular-layout/dist/es2015/tabstrip/tabstrip-events';
import { CanComponentDeactivateGuard, ICanComponentDeactivate } from '@services/auth-guard/can-component-deactivate-guard.service';
import { IDeactivatableLocalStorage } from '@services/auth-guard/can-component-discard-localstorage-guard.service';
import { DataSourcesService } from '@services/data-sources/data-sources.service';
import { DependencyService } from '@services/dependency/dependency.service';
import { AccountService } from '@services/modal-screen/assignment-ruleset/account-service';
import { AssignmentRulesetsService, AssignmentValueSave } from '@services/modal-screen/assignment-ruleset/assignment-ruleset-service';
import { ModalScreenService, assignment } from '@services/modal-screen/modal-screen-service';
import { SpreadHoverNotificationService } from '@services/notification/spread-hover-notification.service';
import { SharedService } from '@services/shared/shared-service';
import { configureAASpreadsheet, setUpWorkbook } from '@shared/spread/spread-base';
import SpreadValidation from '@shared/spread/spread-base-validation';
import {
  ISpreadColumn,
  SPREAD_FONT_SIZE,
  borders,
  colours,
  getColumnMapping,
  getDefaultCellStyle,
  getInvalidCellStyle,
  getInvalidRowStyle,
  getModifiedCellStyle,
  spreadFont
} from '@shared/spread/spread-settings';
import { suspendDoAndResume } from '@shared/spread/suspend-and-resume';
import { BodyOutputType, Toast, ToastType, ToasterService } from '@services/toaster/toaster.service';
import { Subject, Subscription } from 'rxjs';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { setColumnSizes } from '../account-assignment-shared/account-assignment-spread';
import { openAssignmentDialog } from '../assignment-reset-dialog/assignment-reset-dialog';
import { ModalScreenBaseComponent, TAB_IDS } from '../modal-screen-base/modal-screen-base.component';
import { getDropDownValues } from '../modal-screen.data';
import { PageValidationsComponent } from '../page-validations/page-validations.component';
import { SharedAssignmentService } from '@services/modal-screen/assignment-ruleset/account-assignment-shared';
import { AssignmentRulesetComponent } from '../assignment-ruleset/assignment-ruleset.component';

@Component({
  selector: 'bud-account',
  templateUrl: './bud-account.component.html',
  styleUrls: ['./bud-account.component.scss']
})
export class BudAccountComponent
  extends ModalScreenBaseComponent
  implements OnInit, OnDestroy, IDeactivatableLocalStorage, ICanComponentDeactivate
{
  @Input() localStorageKey: number;
  @Input() dataSourceId: number;
  @Input() selectBUD: number;
  @Input() datasourcetypeid: number;

  @ViewChild(PageValidationsComponent)
  private readonly pageValidations: PageValidationsComponent;

  public pageId: number = PAGE_IDS.BUDAccount;
  public tabId: number = TAB_IDS.BUD_ACCOUNT;
  private radioButtons = ['ImportedBudValue', 'AssignmentBudRuleset', 'AssignmentBudRuleset', 'AssignmentBudValue', 'SingleBudValue'];
  private destroy$: Subject<void> = new Subject<void>();

  public selectedBudRadio: string = 'ImportedBudValue';
  public selectedBudNameMessage: string;
  public disableEditButton: boolean = true;

  public component: ICanComponentDeactivate = null;

  public selectedNameMessage: string;
  public dbFlag: number = 0;
  public assignmentFlag: AssignmentFlag[] = [];
  public disableSaveButton: boolean;
  public isDiscardDisabled: boolean = true;
  public discardDialogOpen: boolean = false;
  private disableSave: boolean = false;

  private readonly subscriptions: Subscription = new Subscription();
  public idSelected: number;
  private storageId: number;
  private modelBackup: AssignmentRuleset[] = [];
  public isLoading: boolean;
  private sheet: GC.Spread.Sheets.Worksheet = null;
  private spread: GC.Spread.Sheets.Workbook = null;
  public model: AssignmentRuleset[] = [];
  public isValid = false;
  public isAdmin = true;
  public backToALM = false;

  public textFormGroup: FormGroup;

  private readonly spreadColIndex = getColumnMapping<AssignmentRuleset>(COLUMNS);
  public invalidRows: Array<number> = [];
  public displayWorksheet: boolean = false;
  public assignmentRuleset: AssignmentRuleset[];
  public assignmentRulesetArray: any[] = [];
  private assignmentDimension: AssignmentDimension[];
  private dimesionsList: { value: string; text: string; table: string }[];
  private withSetFlag: string[] = [];
  public setFlagData: { value: string; text: string }[];
  public otherAssignmentValue: string;
  public singleValue: number;
  public defaultItem: { value: string; text: string };
  public dataSourceType: number;
  public readonly budAccountString: string = 'BUD Account #';

  public readonly balanceLocalStorageKey = 1;
  public readonly incomeLocalStorageKey = 2;
  public subTabSelection: any;
  public activeSubTabId: number = 0;
  public noData: boolean = false;
  public enumDataSourceType = DataSourceTypeIds;
  public flagElement: string;

  get selectedAsOfDate(): AsOfDate {
    return this.dataSourceService.selectedAsOfDate;
  }

  public get asOfDate$() {
    return this.sharedService.selectedAsOfDate$.pipe(map(aod => aod.Date));
  }

  public get asOfDateOnce$() {
    return this.asOfDate$.pipe(take(1));
  }

  constructor(
    private readonly dialogRef: MatDialogRef<NavigationModalComponent>,
    private readonly canDeactivateService: CanComponentDeactivateGuard,
    private readonly location: Location,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly modalScreenService: ModalScreenService,
    private readonly budAccountService: AccountService,
    private readonly toasterService: ToasterService,
    private readonly spreadHoverNotification: SpreadHoverNotificationService,
    private readonly dataSourceService: DataSourcesService,
    private readonly sharedService: SharedService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly assignmentRulesetService: AssignmentRulesetsService,
    private readonly sharedAssignmentService: SharedAssignmentService,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private readonly dependencyService: DependencyService
  ) {
    super();
  }

  public async ngOnInit() {
    this.textFormGroup = this.fb.group({
      numericField: ['', [Validators.required, Validators.pattern(/^-?\d*\.?\d*$/), Validators.maxLength(15)]]
    });

    this.idSelected = this.dataSourceId;
    this.storageId = this.dataSourceId;
    if (this.selectBUD === 1) {
      await this.getAssignmentStatus(this.dataSourceId, 1);
      this.dbFlag = this.assignmentFlag[1]?.flag;
      this.dbFlag != 0 ? (this.selectedBudRadio = this.radioButtons[this.dbFlag]) : (this.selectedBudRadio = 'ImportedBudValue');
      this.dbFlag === 0 && this.selectedBudRadio === 'ImportedBudValue'
        ? (this.disableSaveButton = true)
        : (this.disableSaveButton = false);
      this.selectedNameMessage = assignment.find(a => a.DataSourceID === this.idSelected).Name;
    }
    this.storeInitialValues();
    this.validateAssignmentData(true);
    this.initListeners();
    this.setValueChangedEventSheet();
  }

  public ngAfterViewInit() {
    // Call method from PageValidation Componet
    this.pageValidations?._initPageValidationSub(this.idSelected);
  }

  //  to handle and react to the data emitted from the child component,
  //  it serves as a callback function for processing and updating the state of the parent component
  public handleDataEvent(data: { tab: IEmpTab; pageValidation: IPageValidation; tooltip: string }) {
    const tab = data.tab;
    const pageValidation = data.pageValidation;
    const tooltip = data.tooltip;

    this.pageValidations?.setSubTabDataEvent(this.subTab, tab, pageValidation, tooltip);

    // Manually trigger change detection after making changes to avoid ExpressionChangedAfterItHasBeenCheckedError
    this.changeDetector.detectChanges();
  }

  private initListeners(): void {
    this.subscriptions.add(
      this.router.events
        .pipe(
          takeUntil(this.destroy$),
          filter(event => event instanceof NavigationEnd)
        )
        .subscribe(e => {
          if (e instanceof NavigationEnd) {
            setTimeout(() => {
              if (e.urlAfterRedirects.includes('account-assignment')) {
                // @ts-ignore
                this.idSelected = Number(this.route.params._value.id);
                this.storageId = this.idSelected;
                if (this.selectBUD === 1) {
                  if (this.idSelected === this.dataSourceId) {
                    this.getAssignmentStatus(this.dataSourceId, 1);
                    this.selectedNameMessage = assignment.find(a => a.DataSourceID === this.idSelected).Name;
                    // this.loadData();
                  }
                }
              }
            }, 100);
          }
        })
    );
  }

  private storeInitialValues(): void {
    COLUMNS.length = 0;
    assignmentRuleset.length = 0;
    noAliasValues.length = 0;
    aliasValues.length = 0;
    const localStorage = this.modalScreenService.fetchFromLocalStorage<ISpreadColumn[]>(this.idSelected * 1000 + 21);
    if (localStorage && COLUMNS.length !== 0) {
      COLUMNS?.push(...localStorage?.payload);
    }
    const localStorageNAV = this.modalScreenService.fetchFromLocalStorage<any[]>(this.idSelected * 1000 + 22);
    if (localStorageNAV) {
      noAliasValues?.push(...localStorageNAV?.payload);
    }
    const localStorageAV = this.modalScreenService.fetchFromLocalStorage<any[]>(this.idSelected * 1000 + 23);
    if (localStorageAV) {
      aliasValues?.push(...localStorageAV?.payload);
    }
  }

  public readonly getAssignmentRuleset = async (): Promise<void> => {
    assignmentRuleset.length = 0;
    this.assignmentRulesetArray = [];
    this.assignmentRuleset = [];
    COLUMNS.length = 0;
    this.assignmentRuleset = await this.assignmentRulesetService
      .getBUDAccountAssignmentRuleset(noAliasValues, aliasValues, this.idSelected, this.selectedAsOfDate.Date)
      .toPromise()
      .catch(async err => {
        this.showToast(`Error loading assignment status request`, 'Error', 'error');
        console.warn(err);
        return [];
      });

    if (this.assignmentRuleset.length > 0) {
      for (const assignment of this.assignmentRuleset) {
        if (assignment.Columns) {
          this.assignmentRulesetArray.push(assignment.Columns);
        }
      }

      if (this.assignmentRuleset[0].Columns) {
        const columnConfig = Object.keys(this.assignmentRuleset[0].Columns).map(key => {
          return {
            name: key,
            displayName: key === 'Assignment' ? 'Assignment BUDAccountNum' : key,
            key: key,
            width: key.length
          };
        });
        COLUMNS.push(...columnConfig);
      }
      assignmentRuleset.push(...this.assignmentRulesetArray);
    }
  };

  private async loadData() {
    const localStorage = this.modalScreenService.fetchFromLocalStorage<string>(this.localStorageKey);
    if (localStorage && localStorage.payload.length > 0) {
      this.selectedBudRadio = localStorage.payload;
    }

    const localStorageOtherValue = this.modalScreenService.fetchFromLocalStorage<string>(
      this.idSelected * 1000 + localStorageLoc.BUDAssignmentValue
    );
    if (localStorageOtherValue && localStorageOtherValue.payload.length > 0) {
      this.onAssignmentSelected(localStorageOtherValue.payload);
      this.defaultItem = { value: '0', text: localStorageOtherValue.payload };
    }

    const localStorageSingleValue = this.modalScreenService.fetchFromLocalStorage<string>(
      this.idSelected * 1000 + localStorageLoc.BUDSingleValue
    );
    if (localStorageSingleValue && localStorageSingleValue.payload.length > 0) {
      this.textFormGroup.get('numericField').setValue(localStorageSingleValue.payload);
    }
  }

  get localStorageService(): ModalScreenService {
    return this.modalScreenService;
  }

  private storeInLocalStorage(): void {
    this.modalScreenService.storeInLocalStorage(this.selectedBudRadio, this.localStorageKey);
  }

  public clearFromLocalStorage() {
    this.modalScreenService.clearFromLocalStorage(this.localStorageKey);
  }

  public async closeModal(): Promise<void> {
    if (typeof this.component?.canDeactivate === 'function') {
      if (this.component.canDeactivate()) {
        await this.closeDialog();
      } else {
        const discardChanges = await this.canDeactivateService.canDeactivatePopupTabAR(this.component.componentName());
        if (discardChanges) {
          this.clearFromLocalStorage();
          await this.closeDialog();
        }
      }
    } else {
      const localStorage = this.modalScreenService.fetchFromLocalStorage<string>(this.localStorageKey);
      if (localStorage) {
        const discardChanges = await this.canDeactivateService.canDeactivatePopupTabAR(this.selectedNameMessage);
        if (discardChanges) {
          this.clearFromLocalStorage();
          await this.closeDialog();
        }
        this.discardDialogOpen = !this.discardDialogOpen;
      } else {
        await this.closeDialog();
      }
    }
  }

  public onClickDiscard = async () => {
    this.discardDialogOpen = !this.discardDialogOpen;
  };

  public onSubTabSelectedEvent = async (event: SelectEvent) => {
    this.subTabSelection = event.index;
    event.preventDefault();
    const selectedTab = this.subTab[event.index];
    if (!selectedTab.disabled) {
      this.activeSubTabId = selectedTab.id;
    }
  };

  public ngOnDestroy(): Promise<void> {
    this.destroy$.next();
    this.destroy$.complete();
    return;
  }

  public workBookInit(args) {
    this.spread = args.spread;
    this.sheet = this.spread.getActiveSheet();
    setUpWorkbook(this.spread, this.isAdmin);
    if (COLUMNS.length > 0) {
      this.configureWorksheet();
    }
    this.setUpSpreadSheetListeners();
    // this._spreadReady$.next(true);
  }

  private async configureWorksheet() {
    await configureAASpreadsheet(this.sheet, this.isAdmin, COLUMNS, this.selectedAsOfDate.Date);
    setColumnSizes(this.sheet, COLUMNS);
    this.populateAll();
    this.assignmentRulesetService.setDatabaseAssignmentRulesetCopy(assignmentRuleset);
    this.setUpSpreadSheetListeners();
    this._lockAssignmentCodeColumn();
  }
  private setUpSpreadSheetListeners() {
    this.setValueChangedEventSheet();
  }

  private setValueChangedEventSheet() {
    this.sheet?.bind(GC.Spread.Sheets.Events.ValueChanged, (event, info) => {
      const { row } = info;
      this.sheet.suspendEvent();

      this.onValueChanged(row);
      this.changeDetector.detectChanges();
      this.sheet.resumeEvent();
    });
  }

  private getValuesFromRow(rowIndex: number, item: string) {
    const colIndexAssignment = COLUMNS.findIndex(a => a.key === 'Assignment');
    const Assignment = this.sheet.getValue(rowIndex, colIndexAssignment);

    return {
      Assignment
    };
  }

  public onValueChanged(row: number, isLastRecord: boolean = true) {
    for (let i = 0; i < COLUMNS.length - 4; i++) {
      const item = COLUMNS[i].key;
      const valuesFromRow = this.getValuesFromRow(row, item);

      // const valuesFromRow = this.getValuesFromRow(row);

      valuesFromRow.Assignment =
        assignmentRuleset.find(x => x?.Assignment === valuesFromRow.Assignment)?.Assignment ?? valuesFromRow.Assignment;

      assignmentRuleset[row] = {
        ...assignmentRuleset[row],
        ...valuesFromRow
      };

      assignmentRuleset[row].isModified = !this.assignmentRulesetService.isAccountRulesetEqualToDBCopy(assignmentRuleset[row], item);
      assignmentRuleset[row].AssignmentValue = 'BUDAccountNum';

      if (isLastRecord) {
        this.validateAssignmentData(true);
        if (assignmentRuleset.some(x => x.isModified)) {
          this.storeInLocalStorage();
        } else {
          this.clearFromLocalStorage();
        }
      }

      const almLocalStorage = this.modalScreenService.fetchFromLocalStorage(this.localStorageKey);
    }

    this.validateAssignmentData(true);
    this.disableSaveButton = false;
    //this.assignmentRulesetService.setDatabaseAssignmentRulesetCopy(assignmentRuleset);
  }

  public populateAll() {
    this.modelBackup = [...assignmentRuleset];
    this.sheet.setRowCount(assignmentRuleset.length);
    populateAssignmentRulesetData(this.sheet, assignmentRuleset);
  }

  public onMouseMove(event) {
    this.spreadHoverNotification.onMouseMove(event, this.spread.getActiveSheet(), assignmentRuleset, `Invalid`);
  }

  private _lockAssignmentCodeColumn() {
    const baseStyle = new GC.Spread.Sheets.Style();
    baseStyle.cellPadding = `0 ${SPREAD_FONT_SIZE / 2}`;
    baseStyle.vAlign = GC.Spread.Sheets.VerticalAlign.center;
    baseStyle.font = spreadFont.normal;
    baseStyle.backColor = colours.WHITE;
    baseStyle.foreColor = colours.BLACK;
    baseStyle.borderBottom = borders.SUBTLE;
    baseStyle.borderTop = borders.SUBTLE;
    baseStyle.borderLeft = borders.SUBTLE;
    baseStyle.borderRight = borders.SUBTLE;

    const lockedRange = new GC.Spread.Sheets.Range(0, 0, this.sheet.getRowCount(), COLUMNS.length - 4);
    if (lockedRange) {
      const lockedSytle = new GC.Spread.Sheets.Style();
      lockedSytle.backColor = colours.LIGHT_GREY;
      this.sheet.setDefaultStyle(baseStyle);
      this.sheet.cellStates.add(lockedRange, GC.Spread.Sheets.CellStatesType.readonly, lockedSytle);
    }
    const lockedColumnsRange = this.sheet.getRange(0, 0, this.sheet.getRowCount(), COLUMNS.length - 1);
    lockedColumnsRange.locked(true);
    return;
  }

  private readonly getAssignmentStatus = async (dataSourceId: number, assignmentValue: number): Promise<void> => {
    try {
      this.withSetFlag = [];
      this.setFlagData = [];
      const assignmentFlags: AssignmentFlag[] = await this.budAccountService.getAssignmentStatus(dataSourceId, assignmentValue);
      this.assignmentFlag = assignmentFlags;

      // To determine if any other Assignment is set to Assignment Ruleset -
      // (Missing/Complete are flags set based on data provided in AR)
      this.assignmentFlag.forEach((element, index) => {
        if (
          element.assignmentValue !== assignmentValues.BUDAccountNum &&
          (element.flag === Flags.Complete || element.flag === Flags.Missing)
        ) {
          this.withSetFlag.push(ASSIGNMENT_VALUE[index]);

          this.setFlagData = this.withSetFlag.map((item, index) => ({
            value: index.toString(),
            text: item
          }));
        }
      });
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    this.dbFlag = this.assignmentFlag[1]?.flag;
    this.dbFlag === 0 && this.selectedBudRadio === 'ImportedBudValue' ? (this.disableSaveButton = true) : (this.disableSaveButton = false);
    this.dbFlag != 0 ? (this.selectedBudRadio = this.radioButtons[this.dbFlag]) : (this.selectedBudRadio = 'ImportedBudValue');

    this.isButtonDisabled();

    //load worksheet for Assignment Ruleset
    if (this.selectedBudRadio === 'AssignmentBudRuleset' && DataSourceTypeIds.GL !== this.datasourcetypeid) {
      this.loadWorksheetForAR();
    }
  };

  private loadWorksheetForAR = async (): Promise<void> => {
    this.storeInitialValues();
    await this.getAssignmentRuleset();
    this.sheet.reset();
    this.configureWorksheet();
    this.validateAssignmentData(true);
  };

  public async closeDiscardChanges(): Promise<void> {
    this.isLoading = true;
    this.discardDialogOpen = false;
    this.clearFromLocalStorage();
    this.isLoading = false;
    this.closeDialog();
  }

  private onNavigationError(err): void {
    console.error(err);
  }

  public async onBudRadioChange() {
    this.storeInLocalStorage();
    try {
      const assignmentFlags: AssignmentFlag[] = await this.budAccountService.getAssignmentStatus(this.dataSourceId, 1);
      this.assignmentFlag = assignmentFlags;
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    this.dbFlag = this.assignmentFlag[1]?.flag;
    this.dbFlag === 0 && this.selectedBudRadio === 'ImportedValue' ? (this.disableSaveButton = true) : (this.disableSaveButton = false);
    const almLocalStorage = this.modalScreenService.fetchFromLocalStorage(this.localStorageKey);
    this.isButtonDisabled();
  }

  public isButtonDisabled() {
    this.selectedBudRadio === 'AssignmentBudRuleset' ? (this.disableEditButton = false) : (this.disableEditButton = true);
  }

  public showToast = (body: string, title: string, type: ToastType = 'success') => {
    const toast: Toast = { type, title, body, showCloseButton: true, timeout: 5000, bodyOutputType: BodyOutputType.TrustedHtml };
    this.toasterService.pop(toast);
  };

  public isSaveButtonDisabled(): boolean {
    return this.disableSaveButton;
  }

  public async closeDialog(): Promise<void> {
    const navParam = [{ outlets: { 'navigation-modal-outlet': ['empty'] } }];

    await this.router
      .navigate(navParam, { skipLocationChange: true })
      .catch(this.onNavigationError)
      .finally(() => {
        this.dialogRef.close();
        const currentUrl = this.router.url;
        let url = '';
        const includesTabs = ['alm-account', 'bud-account', 'glbs-account', 'glis-account', 'sub-product'].some(value =>
          currentUrl.includes(value)
        );

        if (includesTabs) {
          url = '/account-assignment/assignments';
          this.router.url.replace(/\(navigation-modal-outlet:.*\)/, '');
          this.router.navigate([`account-assignment/assignments`]);
        } else {
          url = this.router.url.replace(/\(navigation-modal-outlet:.*\)/, '');
        }
        this.location.replaceState(url);
      });
  }

  /** Discard button click event handler */
  public readonly discardClicked = async (): Promise<void> => {
    this.discardChanges();
  };

  private readonly discardChanges = (): void => {
    this.clearFromLocalStorage();
  };

  public canDeactivate() {
    return !this.hasLocalStorage();
  }

  public discardLocalStorage() {
    this.budAccountService.clearFromLocalStorage(this.localStorageKey);
  }

  private hasLocalStorage(): boolean {
    const balanceLocalStorage = this.budAccountService.fetchFromLocalStorage(this.localStorageKey);
    if (balanceLocalStorage) {
      return true;
    }
    return false;
  }

  public componentName() {
    return this.budAccountString;
  }

  private async checkForResetSelection(): Promise<boolean> {
    if (this.dbFlag === Flags.Complete || this.dbFlag === Flags.Missing) {
      const otherValues: string[] = [];

      this.assignmentFlag.forEach(element => {
        if (element.useOtherValue === String(assignmentValues.BUDAccountNum)) {
          otherValues.push(ASSIGNMENT_VALUE[element.assignmentValue]);
        }
      });

      if (otherValues.length > 0 && this.selectedBudRadio != 'AssignmentBudRuleset') {
        const result = await openAssignmentDialog('Bud Account #', otherValues);
        if (result.isConfirmed) {
          otherValues.forEach(item => {
            const assignmentVal = ASSIGNMENT_VALUE.findIndex(el => el === item);
            this.budAccountService
              .saveAssignmentFlag(0, this.idSelected, assignmentVal, null)
              .toPromise()
              .then(async res => {
                try {
                  this.isLoading = true;
                  this.clearFromLocalStorage();
                } catch {}
              })
              .catch(err => {
                this.showToast(`Error saving BUD Account`, 'Error', 'error');
                this.isLoading = false;
              });
          });
          return true;
        } else {
          return false;
        }
      }
    }
    return true;
  }

  public async onClickSave() {
    this.disableSaveButton = true;

    const continueSaving: boolean = await this.checkForResetSelection();
    if (!continueSaving) {
      this.disableSaveButton = false;
      return;
    }

    let flag: number;
    if (this.selectedBudRadio === 'ImportedBudValue') {
      flag = Flags.ImportedValue;
    } else if (this.selectedBudRadio === 'AssignmentBudRuleset' && this.invalidRows.length <= 0) {
      flag = Flags.Complete;
    } else if (this.selectedBudRadio === 'AssignmentBudRuleset' && this.invalidRows.length > 0) {
      flag = Flags.Missing;
    } else if (this.selectedBudRadio === 'AssignmentBudValue') {
      flag = Flags.AssignmentValue;
    } else if (this.selectedBudRadio === 'SingleBudValue') {
      flag = Flags.SingleValue;
    }

    this.budAccountService
      .saveAssignmentFlag(flag, this.idSelected, 1, null)
      .toPromise()
      .then(async res => {
        try {
          this.showToast(`BUD Account Flag saved successfully`, 'Success', 'success');
          this.isLoading = true;
          this.clearFromLocalStorage();
        } catch {}
      })
      .catch(err => {
        this.showToast(`Error saving BUD Account`, 'Error', 'error');
        this.isLoading = false;
      });

    if (this.selectedBudRadio === 'AssignmentBudRuleset') {
      const saveRequest: AssignmentRulesetSave = {
        AssignmentRuleset: assignmentRuleset.filter(x => x.isModified)
      };
      let result = {
        AssignmentRuleset: saveRequest.AssignmentRuleset.map(item => {
          const columns = [];
          for (const key in item) {
            columns.push({
              Name: key,
              Value: item[key]
            });
          }
          return {
            AssignmentRuleset: columns
          };
        })
      };

      const assignmentValueSave = new AssignmentValueSave();
      assignmentValueSave.Mappings = JSON.stringify(result, null, 2);
      assignmentValueSave.date = this.selectedAsOfDate.Date;
      assignmentValueSave.noAliasValues = noAliasValues;
      assignmentValueSave.aliasValues = aliasValues;
      assignmentValueSave.dataSourceId = this.dataSourceId;

      this.assignmentRulesetService
        .saveAssignmentRuleset(assignmentValueSave)
        .toPromise()
        .then(async res => {
          try {
            this.showToast(`Assignment Ruleset saved successfully`, 'Success', 'success');
            this.isLoading = true;

            this.clearFromLocalStorage();
            this.modalScreenService.clearFromLocalStorage(this.idSelected * 1000 + localStorageLoc.BUDAssignmentValue);
            this.modalScreenService.clearFromLocalStorage(this.idSelected * 1000 + localStorageLoc.BUDSingleValue);
            await this.assignmentRulesetService.setDatabaseAssignmentRulesetCopy(assignmentRuleset);
            suspendDoAndResume(this.sheet, () => {
              this.sheet.clear(
                0,
                0,
                this.sheet.getRowCount(),
                this.sheet.getColumnCount(),
                GC.Spread.Sheets.SheetArea.viewport,
                GC.Spread.Sheets.StorageType.style
              );
            });
            this.validateAssignmentData(true);
            this.isDiscardDisabled = true;
            this.isLoading = false;
            this._lockAssignmentCodeColumn();
            this.dependencyService.notifyPageValidation();
          } catch {}
        })
        .catch(err => {
          this.showToast(`Error saving Assignment Ruleset`, 'Error', 'error');
          this.isLoading = false;
        })
        .finally(() => {
          this.disableSave = false;
        });
    } else if (this.selectedBudRadio === 'AssignmentBudValue') {
      const assignmentValueSave = new AssignmentValueSave();
      assignmentValueSave.date = this.selectedAsOfDate.Date;
      assignmentValueSave.noAliasValues = noAliasValues;
      assignmentValueSave.aliasValues = aliasValues;
      assignmentValueSave.dataSourceId = this.dataSourceId;
      assignmentValueSave.otherAssignment = this.otherAssignmentValue;
      assignmentValueSave.assignmentValue = 'BUDAccountNum';

      this.assignmentRulesetService
        .updateOtherAssignment(assignmentValueSave)
        .toPromise()
        .then(async res => {
          try {
            this.showToast(`Assignment Value saved successfully`, 'Success', 'success');
            this.isLoading = true;
            this.modalScreenService.storeInLocalStorage(
              this.otherAssignmentValue,
              this.idSelected * 1000 + localStorageLoc.BUDAssignmentValue
            );

            this.clearFromLocalStorage();
            this.modalScreenService.clearFromLocalStorage(this.idSelected * 1000 + localStorageLoc.BUDColumns);
            this.modalScreenService.clearFromLocalStorage(this.idSelected * 1000 + localStorageLoc.BUDNonAlias);
            this.modalScreenService.clearFromLocalStorage(this.idSelected * 1000 + localStorageLoc.BUDAlias);
            this.modalScreenService.clearFromLocalStorage(this.idSelected * 1000 + localStorageLoc.BUDSingleValue);
            await this.assignmentRulesetService.setDatabaseAssignmentRulesetCopy(assignmentRuleset);
            suspendDoAndResume(this.sheet, () => {
              this.sheet.clear(
                0,
                0,
                this.sheet.getRowCount(),
                this.sheet.getColumnCount(),
                GC.Spread.Sheets.SheetArea.viewport,
                GC.Spread.Sheets.StorageType.style
              );
            });
            this.validateAssignmentData(true);
            this.isDiscardDisabled = true;
            this.isLoading = false;
            this._lockAssignmentCodeColumn();
            // this.dependencyService.notifyPageValidation();
          } catch {}
        })
        .catch(err => {
          this.showToast(`Error saving Assignment Value`, 'Error', 'error');
          this.isLoading = false;
        })
        .finally(() => {
          this.disableSaveButton = false;
        });
    } else if (this.selectedBudRadio === 'SingleBudValue') {
      const assignmentValueSave = new AssignmentValueSave();
      assignmentValueSave.date = this.selectedAsOfDate.Date;
      assignmentValueSave.dataSourceId = this.dataSourceId;
      assignmentValueSave.singleValue = this.textFormGroup.controls.numericField.value;
      assignmentValueSave.assignmentValue = 'BUDAccountNum';

      await this.assignmentRulesetService
        .updateUsingSingleValue(assignmentValueSave)
        .toPromise()
        .then(async res => {
          try {
            this.showToast(`Assignment Value updated successfully`, 'Success', 'success');
            this.isLoading = true;

            this.modalScreenService.storeInLocalStorage(
              this.textFormGroup.controls.numericField.value,
              this.idSelected * 1000 + localStorageLoc.BUDSingleValue
            );

            this.clearFromLocalStorage();
            this.modalScreenService.clearFromLocalStorage(this.idSelected * 1000 + localStorageLoc.BUDColumns);
            this.modalScreenService.clearFromLocalStorage(this.idSelected * 1000 + localStorageLoc.BUDNonAlias);
            this.modalScreenService.clearFromLocalStorage(this.idSelected * 1000 + localStorageLoc.BUDAlias);
            this.modalScreenService.clearFromLocalStorage(this.idSelected * 1000 + localStorageLoc.BUDAssignmentValue);
            await this.assignmentRulesetService.setDatabaseAssignmentRulesetCopy(assignmentRuleset);
            suspendDoAndResume(this.sheet, () => {
              this.sheet.clear(
                0,
                0,
                this.sheet.getRowCount(),
                this.sheet.getColumnCount(),
                GC.Spread.Sheets.SheetArea.viewport,
                GC.Spread.Sheets.StorageType.style
              );
            });
            this.validateAssignmentData(true);
            this.isDiscardDisabled = true;
            this.isLoading = false;
            this._lockAssignmentCodeColumn();
            // this.dependencyService.notifyPageValidation();
          } catch {}
        })
        .catch(err => {
          this.showToast(`Error saving Assignment Value`, 'Error', 'error');
          this.isLoading = false;
        })
        .finally(() => {
          this.disableSaveButton = false;
        });
    }
  }

  public onAssignmentSelected(newValue: any): void {
    this.otherAssignmentValue = newValue.text;
  }

  private validateAssignmentData(validateAll: boolean = false) {
    this.isValid = this.validateData(validateAll, assignmentRuleset);
  }

  private validateData(validateAll: boolean = false, model: AssignmentRuleset[]): boolean {
    let isValid = true;
    this.invalidRows = [];
    model.forEach((row, index) => {
      if (validateAll || row.isModified || !row.isValid || row.errorsArray?.length > 0) {
        isValid = this.validateRow(row, model) && isValid;
        if (!row.isValid) {
          this.invalidRows.push(index);
        }
      }
    });
    model.forEach((row, index) => {
      this.styleRow(row, index);
    });
    this.isDiscardDisabled = !model.some(x => x.isModified);
    return isValid;
  }

  private addErrorToRow(row: AssignmentRuleset, col: number, error: string) {
    if (!row.errorsArray) {
      row.errorsArray = [];
    }
    row.errorsArray.push({ col, error });
  }

  private validateRow(row: AssignmentRuleset, model: AssignmentRuleset[]): boolean {
    row.errorsArray = [];
    const validator = new SpreadValidation(COLUMNS);
    if (
      !validator.mustBeNumeric(row, 'Assignment', `Assignment Value must be numeric.`) ||
      !validator.cannotBeEmpty(row, 'Assignment', `Assignment Value must be numeric.`)
    ) {
      this.addErrorToRow(row, spreadColIndex.Assignment, 'BUD Account # Assignment value must be a number.');
    }
    row.isValid = row.errorsArray.length === 0;
    return row.isValid;
  }

  private styleRow(row: AssignmentRuleset, rowIndex: number) {
    suspendDoAndResume(this.sheet, () => {
      const rowRange = this.sheet.getRange(rowIndex, 0, 1, this.sheet.getColumnCount());
      rowRange.clear(GC.Spread.Sheets.StorageType.style);
      row.isValid = row.errorsArray === undefined || row.errorsArray.length === 0;
      if (row.isValid) {
        if (!row.isModified) {
          this.styleModifiedRow(rowRange);
        } else {
          this.styleDefaultRow(rowRange);
        }
      } else {
        this.styleInvalidRow(rowRange, row, rowIndex);
      }
    });
  }

  private styleDefaultRow(rowRange: GC.Spread.Sheets.CellRange) {
    rowRange.setStyle(getDefaultCellStyle());
  }

  private styleModifiedRow(rowRange: GC.Spread.Sheets.CellRange) {
    rowRange.setStyle(getModifiedCellStyle());
  }

  private styleInvalidRow(rowRange: GC.Spread.Sheets.CellRange, row: AssignmentRuleset, rowIndex: number) {
    rowRange.setStyle(getInvalidRowStyle());

    row.errorsArray.forEach((error: { col: number }) => {
      const cell = this.sheet.getCell(rowIndex, error.col);
      cell.setStyle(getInvalidCellStyle());
    });
  }

  public onClickInvalidRow() {
    if (this.invalidRows && this.invalidRows.length > 0) {
      this.spread.getActiveSheet().showRow(this.invalidRows[0], GC.Spread.Sheets.VerticalPosition.center);
    }
  }

  public async onAddYieldCurveClicked(): Promise<void> {
    noAliasValues.length = 0;
    aliasValues.length = 0;
    this.displayWorksheet = false;
    const localStorage = this.modalScreenService.fetchFromLocalStorage<string>(this.localStorageKey);
    const ycSetup = localStorage?.payload ? localStorage.payload : null;

    this.dataSourceType = this.sharedAssignmentService.findMatchedElement(assignment, this.dataSourceId);

    const assignmentValue = ASSIGNMENT_VALUE[assignmentValues.BUDAccountNum];
    const dimensionList = getDropDownValues(assignmentValue)
      .get(this.dataSourceType)
      .sort((a, b) => {
        return a.text.localeCompare(b.text);
      });

    if (DataSourceTypeIds.GL !== this.datasourcetypeid) {
      const dialogReference = this.dialog.open(AssignmentRulesetComponent, {
        data: {
          ycSetup,
          dataSourceId: this.dataSourceId,
          assignment: assignment,
          assignmentValue: assignmentValue,
          isEditing: false,
          dimesionsList: dimensionList
        },
        position: { left: '450px' },
        minWidth: '450px',
        maxWidth: '500px',
        width: '500px',
        minHeight: '300px',
        maxHeight: '350px',
        height: '350px',
        panelClass: ['no-padding'],
        disableClose: true,
        closeOnNavigation: false
      });
      dialogReference.afterClosed().subscribe(async res => {
        if (this.selectedBudRadio === 'AssignmentBudRuleset') {
          this.sheet.reset();
          this.configureWorksheet();
        }
        this.modalScreenService.storeInLocalStorage(COLUMNS, this.idSelected * 1000 + localStorageLoc.BUDColumns);
        this.modalScreenService.storeInLocalStorage(noAliasValues, this.idSelected * 1000 + localStorageLoc.BUDNonAlias);
        this.modalScreenService.storeInLocalStorage(aliasValues, this.idSelected * 1000 + localStorageLoc.BUDAlias);
      });
    }
  }
}

export const spreadColIndex = getColumnMapping<AssignmentRuleset>(COLUMNS);

export const populateAssignmentRulesetData = (sheet: GC.Spread.Sheets.Worksheet, legalEntities: AssignmentRuleset[]): void => {
  if (sheet && legalEntities && legalEntities.length > 0) {
    sheet.suspendPaint();

    COLUMNS.forEach(column => {
      sheet.setArray(
        0,
        getColumnMapping<AssignmentRuleset>(COLUMNS)[column.key],
        legalEntities.map(cp => cp[column.key])
      );
    });
    sheet.resumePaint();
  }
};
