export interface IBlobStorageSettings {
  Container: string;
  File_Location: string;
  SAS: string;
  StorageAccount: string;
  Url: string;
  Use_Azure: boolean;
}

export const CENTRALIZED_SAS_SETTING_NAME = 'CENTRALIZED_SAS';
export const CENTRALIZED_URL_SETTING_NAME = 'CENTRALIZED_URL';
export const CENTRALIZED_STORAGE_ACCOUNT_SETTING_NAME = 'CENTRALIZED_STORAGE_ACCOUNT';
export const USER_MANUALS_CONTAINER_SETTING_NAME = 'USER_MANUALS_CONTAINER';
export const DATAVERSE_MANUAL_FILE_SETTING_NAME = 'DATAVERSE_MANUAL_FILE';
export const AZURE_STORAGE_SETTING_GROUP = 'AZURESTORAGE';
export const USER_MANUALS_SETTING_GROUP = 'USERMANUALS';
