export enum LocalStorageKeys {
  DEPARTMENT = 'department',
  DATES_IMPORTED = 'dates-imported',
  GL_BALANCE_SHEET = 'general-ledger-balance-sheet',
  GL_INCOME_STATEMENT = 'general-ledger-income-statement',
  GL_RECON_ACCOUNT_MAPPING = 'general-ledger-recon-account-remapping',
  GL_RECON_RECON_ACCOUNTS = 'general-ledger-recon-recon-accounts',
  GL_RECON_GL_ONLY_ACCOUNTS = 'general-ledger-recon-gl-only-accounts',
  GL_RECON_DEPARTMENTS = 'general-ledger-recon-departments',
  GL_RECON_INSTRUMENT = 'general-ledger-recon-instrument',
  GLOBAL_SETTINGS = 'global-settings',
  LEGAL_ENTITY = 'legal-entity',
  MODALSCREEN = 'modal-screen',
  CALC_FIELD = 'calculated-field'
}
