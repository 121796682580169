// @shared/spread-action-keys.ts
import * as GC from '@grapecity/spread-sheets';
import { flat } from '@shared/arrays';

enum Direction {
  up,
  down,
  right,
  left
}

interface IGreenArea {
  page: string;
  startRow?: number;
  startCol?: number;
  paddingRows?: number;
  paddingCols?: number;
}

/**
 * *Green area* is a cell range where the selection keybindings will be constrained.
 *
 * (Works for: `Ctrl + A, Ctrl + Space, Shift + Space, Ctrl + Shift + Arrows`)
 */
const defaultGreenArea: IGreenArea = {
  page: 'default',
  startRow: 0,
  startCol: 0,
  paddingRows: 0,
  paddingCols: 0
};

const greenAreas: IGreenArea[] = [defaultGreenArea];

export const setGreenArea = (area: IGreenArea) => {
  const existingAreaIndex = greenAreas.findIndex(({ page }) => page === area.page);
  const existingArea = greenAreas[existingAreaIndex];

  if (existingAreaIndex < 0) {
    greenAreas.push({ ...defaultGreenArea, ...area });
  } else {
    greenAreas[existingAreaIndex] = { ...existingArea, ...area };
  }
};

export const getGreenArea = (page: string): IGreenArea => {
  const existingAreaIndex = greenAreas.findIndex(ga => ga.page === page);
  return existingAreaIndex >= 0 ? greenAreas[existingAreaIndex] : null;
};

/**
 * **Maps keybindings:**
 *   * `F2`: Start Edit
 *   * `Ctrl + A`: Select All
 *   * `Ctrl + Space`: Select Column
 *   * `Shift + Space`: Select Row
 *   * `Ctrl + Shift + [Arrows]`: Select Row or Column in the arrow direction, up until the last non-empty cell
 */
export const mapActionKeys = (spread: GC.Spread.Sheets.Workbook, sheet: GC.Spread.Sheets.Worksheet, page: string) => {
  const commandManager = spread.commandManager();
  const selectionMatchGreenArea = (selection: GC.Spread.Sheets.Range): boolean => {
    const greenArea = greenAreas.find(ga => ga.page === page) || defaultGreenArea;
    const greenRowCount = sheet.getRowCount() - (greenArea.startRow + greenArea.paddingRows);
    const greenColCount = sheet.getColumnCount() - (greenArea.startCol + greenArea.paddingCols);

    return (
      selection.row === greenArea.startRow &&
      selection.col === greenArea.startCol &&
      selection.rowCount === greenRowCount &&
      selection.colCount === greenColCount
    );
  };

  const menuList = spread.contextMenu.menuData;
  const deleteRowsElement = menuList.find(obj => obj.name === 'gc.spread.deleteRows');
  if (deleteRowsElement) {
    deleteRowsElement.text = 'Delete Rows';
  }

  // Command actions
  const addRowAboveCommand = {
    canUndo: true,
    execute: (context, options, isUndo) => {
      const Commands = GC.Spread.Sheets.Commands;
      if (isUndo) {
        context.suspendPaint();
        Commands.undoTransaction(context, options);
        context.resumePaint();
      } else {
        Commands.startTransaction(context, options);
        context.suspendPaint();
        context.getActiveSheet().addRows(options.selections[0].row, 1);
        context.resumePaint();
        Commands.endTransaction(context, options);
      }
      return true;
    }
  };
  // Register command
  commandManager.register('addRowAboveCmd', addRowAboveCommand, null, false, false, false, false);

  const addRowBelowCommand = {
    canUndo: true,
    execute: (context, options, isUndo) => {
      const Commands = GC.Spread.Sheets.Commands;
      if (isUndo) {
        context.suspendPaint();
        Commands.undoTransaction(context, options);
        context.resumePaint();
        return true;
      } else {
        Commands.startTransaction(context, options);
        context.suspendPaint();
        context.getActiveSheet().addRows(options.selections[0].row + options.selections[0].rowCount, 1);
        context.resumePaint();
        Commands.endTransaction(context, options);
      }
    }
  };
  // Register command
  commandManager.register('addRowBelowCmd', addRowBelowCommand, null, false, false, false, false);

  // ⌨ F2 key
  commandManager.register('f2KeyEdit', (e, info) => {
    sheet.startEdit(false);
  });
  commandManager.setShortcutKey('f2KeyEdit', 113);

  // ⌨ Ctrl + A
  commandManager.register('selectAll', (e, info) => {
    if (!sheet.isEditing()) {
      const greenArea = greenAreas.find(ga => ga.page === page) || defaultGreenArea;

      sheet.suspendPaint();
      const selections = sheet.getSelections();
      const selection = selections[selections.length - 1];
      const rowCount = sheet.getRowCount();
      const colCount = sheet.getColumnCount();
      const greenRowCount = sheet.getRowCount() - (greenArea.startRow + greenArea.paddingRows);
      const greenColCount = sheet.getColumnCount() - (greenArea.startCol + greenArea.paddingCols);
      const row = sheet.getActiveRowIndex();
      const col = sheet.getActiveColumnIndex();

      const wasSheetProtected = sheet.options.isProtected;
      sheet.clearSelection();
      sheet.setActiveCell(row, col);

      if (row < greenArea.startRow || row >= rowCount || col < greenArea.startCol || col >= colCount) {
        // Out of green area
        sheet.setSelection(0, 0, rowCount, colCount);
      } else {
        // In green area
        if (selectionMatchGreenArea(selection)) {
          // Green area was already selected
          sheet.setSelection(0, 0, rowCount, colCount);
          sheet.options.isProtected = true;
        } else {
          sheet.setSelection(greenArea.startRow, greenArea.startCol, greenRowCount, greenColCount);
          sheet.options.isProtected = wasSheetProtected;
        }
      }

      sheet.resumePaint();
    }
  });
  commandManager.setShortcutKey('selectAll', GC.Spread.Commands.Key.a, true);

  // ⌨ Ctrl + Space => Select Column
  commandManager.register('selectColumn', (e, info) => {
    if (!sheet.isEditing()) {
      const greenArea = greenAreas.find(ga => ga.page === page) || defaultGreenArea;

      sheet.suspendPaint();
      const rowCount = sheet.getRowCount() - (greenArea.startRow + greenArea.paddingRows);
      const rowOriginal = sheet.getActiveRowIndex();
      const row = rowOriginal >= greenArea.startRow ? rowOriginal : greenArea.startRow;
      const col = sheet.getActiveColumnIndex();

      // Lock active cell to avoid modification by space key
      const activeCellWasLocked = sheet.getCell(row, col).locked();
      sheet.getCell(row, col).locked(true);

      setTimeout(() => {
        const selections = sheet.getSelections();
        const selection = selections[selections.length - 1];
        sheet.clearSelection();
        sheet.setActiveCell(row, col);

        if (rowOriginal >= greenArea.startRow) {
          // Green area
          if (
            selection.col === col &&
            selection.row === greenArea.startRow &&
            selection.colCount === 1 &&
            selection.rowCount === rowCount
          ) {
            // Column was already selected
            sheet.setSelection(0, col, rowCount + greenArea.startRow, 1);
          } else {
            sheet.setSelection(greenArea.startRow, col, rowCount, 1);
          }
        } else {
          sheet.setSelection(0, col, rowCount + greenArea.startRow, 1);
        }

        // Unlock active cell
        sheet.getCell(row, col).locked(activeCellWasLocked);
        sheet.resumePaint();
      });
    }
  });
  commandManager.setShortcutKey('selectColumn', GC.Spread.Commands.Key.space, true);

  // ⌨ Shift + Space => Select Row
  commandManager.register('selectRow', (e, info) => {
    if (!sheet.isEditing()) {
      const greenArea = greenAreas.find(ga => ga.page === page) || defaultGreenArea;

      sheet.suspendPaint();
      const colCount = sheet.getColumnCount() - (greenArea.startCol + greenArea.paddingCols);
      const row = sheet.getActiveRowIndex();
      const colOriginal = sheet.getActiveColumnIndex();
      const col = colOriginal >= greenArea.startCol ? colOriginal : greenArea.startCol;

      // Lock active cell to avoid modification by space key
      const activeCellWasLocked = sheet.getCell(row, col).locked();
      const wasSheetProtected = sheet.options.isProtected;

      sheet.getCell(row, col).locked(true);
      sheet.options.isProtected = true;

      setTimeout(() => {
        const selections = sheet.getSelections();
        const selection = selections[selections.length - 1];
        sheet.clearSelection();
        sheet.setActiveCell(row, col);
        if (colOriginal >= greenArea.startCol) {
          // Green area
          if (
            selection.row === row &&
            selection.col === greenArea.startCol &&
            selection.rowCount === 1 &&
            selection.colCount === colCount
          ) {
            // Row was already selected
            sheet.setSelection(row, 0, 1, colCount + greenArea.startCol);
          } else {
            sheet.setSelection(row, greenArea.startCol, 1, colCount);
          }
        } else {
          // Out of Green area
          sheet.setSelection(row, 0, 1, colCount + greenArea.startCol);
        }

        // Unlock active cell
        if (activeCellWasLocked) {
          sheet.getCell(row, col).locked(activeCellWasLocked);
        }

        // Restoring protection to sheet
        sheet.options.isProtected = wasSheetProtected;

        sheet.resumePaint();
      });
    }
  });
  commandManager.setShortcutKey('selectRow', GC.Spread.Commands.Key.space, false, true);

  //
  // ⌨ Ctrl + Shift + Arrows
  //
  const selectDirection = (direction: Direction) => {
    if (!sheet.isEditing()) {
      sheet.suspendPaint();

      // Viewport columns / rows to decide if scroll is needed after selection
      const topRow = sheet.getViewportTopRow(1);
      const bottomRow = sheet.getViewportBottomRow(1);
      const leftColumn = sheet.getViewportLeftColumn(1);
      const rightColumn = sheet.getViewportRightColumn(1);

      const col = sheet.getActiveColumnIndex();
      const row = sheet.getActiveRowIndex();
      const colCount = sheet.getColumnCount();
      const rowCount = sheet.getRowCount();

      const selections = sheet.getSelections();
      const selection = selections[selections.length - 1];

      let arrayValues: number[];
      let nonEmptyItem: number;
      let selectionStart: number;
      let selectionEnd: number;

      if (direction === Direction.up || direction === Direction.down) {
        arrayValues = flat(sheet.getArray(0, col, rowCount, 1));
        nonEmptyItem = row;
        selectionStart = selection.row;
        selectionEnd = selection.row + selection.rowCount;
      } else if (direction === Direction.left || direction === Direction.right) {
        arrayValues = sheet.getArray(row, 0, 1, colCount)[0];
        nonEmptyItem = col;
        selectionStart = selection.col;
        selectionEnd = selection.col + selection.colCount;
      }

      if (direction === Direction.left || direction === Direction.up) {
        nonEmptyItem = findPreviousBeforeOrAfterEmptyIndex(arrayValues, selectionStart);
      } else if (direction === Direction.right || direction === Direction.down) {
        nonEmptyItem = findNextBeforeOrAfterEmptyIndex(arrayValues, selectionEnd);
      }

      const selectionStartItem = Math.min(selectionStart, nonEmptyItem);
      const selectionEndItem = Math.max(selectionEnd - 1, nonEmptyItem);
      const selectionItemCount = selectionEndItem - selectionStartItem + 1;

      setTimeout(() => {
        sheet.clearSelection();
        sheet.setActiveCell(row, col);

        // Set the selection and scroll if / where needed

        switch (direction) {
          case Direction.up:
            sheet.setSelection(selectionStartItem, col, selectionItemCount, selection.colCount);
            if (selectionStartItem < topRow) {
              sheet.showRow(selectionStartItem, GC.Spread.Sheets.VerticalPosition.top);
            } else {
              sheet.showRow(bottomRow, GC.Spread.Sheets.VerticalPosition.bottom);
            }
            break;

          case Direction.down:
            sheet.setSelection(selectionStartItem, col, selectionItemCount, selection.colCount);
            if (selectionStartItem + selectionItemCount > bottomRow) {
              sheet.showRow(selectionStartItem + selectionItemCount, GC.Spread.Sheets.VerticalPosition.bottom);
            } else {
              sheet.showRow(topRow, GC.Spread.Sheets.VerticalPosition.top);
            }
            break;

          case Direction.left:
            sheet.setSelection(row, selectionStartItem, selection.rowCount, selectionItemCount);
            if (selectionStartItem < leftColumn) {
              sheet.showColumn(selectionStartItem, GC.Spread.Sheets.HorizontalPosition.left);
            } else {
              sheet.showColumn(rightColumn, GC.Spread.Sheets.HorizontalPosition.right);
            }
            break;

          case Direction.right:
            sheet.setSelection(row, selectionStartItem, selection.rowCount, selectionItemCount);
            if (selectionStartItem + selectionItemCount > rightColumn) {
              sheet.showColumn(selectionStartItem + selectionItemCount, GC.Spread.Sheets.HorizontalPosition.right);
            } else {
              sheet.showColumn(leftColumn, GC.Spread.Sheets.HorizontalPosition.left);
            }
            break;

          default:
            break;
        }

        sheet.resumePaint();
      });
    }
  };

  // ⌨ Ctrl + Shift + Right Arrow
  commandManager.register('selectToRight', () => selectDirection(Direction.right));
  commandManager.setShortcutKey('selectToRight', GC.Spread.Commands.Key.right, true, true);

  // ⌨ Ctrl + Shift + Left Arrow
  commandManager.register('selectToLeft', () => selectDirection(Direction.left));
  commandManager.setShortcutKey('selectToLeft', GC.Spread.Commands.Key.left, true, true);

  // ⌨ Ctrl + Shift + Up Arrow
  commandManager.register('selectToTop', () => selectDirection(Direction.up));
  commandManager.setShortcutKey('selectToTop', GC.Spread.Commands.Key.up, true, true);

  // ⌨ Ctrl + Shift + Down Arrow
  commandManager.register('selectToBottom', () => selectDirection(Direction.down));
  commandManager.setShortcutKey('selectToBottom', GC.Spread.Commands.Key.down, true, true);
};

/**
 * Returns the index of the next element **before** or **after** an empty element in an array.
 * Used for _Control +Shift + Right Arrows_ logic.
 */
const findNextBeforeOrAfterEmptyIndex = (arr: any[], startIndex = 0): number => {
  for (let i = startIndex; i < arr.length - 1; i++) {
    if ((!isEmpty(arr[i]) && isEmpty(arr[i + 1])) || (i > startIndex && !isEmpty(arr[i]) && isEmpty(arr[i - 1]))) {
      return i;
    }
  }
  return arr.length - 1;
};

/**
 * Returns the index of the previous element **before** or **after** an empty element in an array.
 * Used for _Control +Shift + Left Arrows_ logic.
 */
const findPreviousBeforeOrAfterEmptyIndex = (arr: any[], startIndex = 0): number => {
  for (let i = startIndex - 1; i >= 0; i--) {
    if ((!isEmpty(arr[i]) && isEmpty(arr[i - 1])) || (i < startIndex && !isEmpty(arr[i]) && isEmpty(arr[i + 1]))) {
      return i;
    }
  }
  return 0;
};

const isEmpty = (value: any): boolean => {
  return value === null || value === '' || value === undefined;
};
