import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from '@services/cookies/cookie.service';
import { OpenIdConnectService } from '@services/openid/openid-connect.service';
import { environment as env } from '../environments/environment';

import * as GC from '@grapecity/spread-sheets';
if (!env.productionUrls.some(uri => window.location.href.includes(uri))) {
  window['GC'] = GC;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public title = 'Dataverse';

  constructor(
    private readonly auth: OpenIdConnectService,
    private readonly router: Router,
    private readonly cookieService: CookieService
  ) {}

  ngOnInit(): void {
    this.cookieService.processQueryString(new URL(window.location.href));
    this.cookieService.processCookies();
  }

  get isLoggedIn() {
    return this.auth.userAvailable && !this.auth.user.expired;
  }

  get isErrorRoute() {
    return this.router.url.includes('error');
  }
}
