import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { NavigationService } from '@services/navigation/navigation.service';
import { OpenIdConnectService } from '@services/openid/openid-connect.service';

@Injectable({ providedIn: 'root' })
class AuthGuardService {
  constructor(private readonly auth: OpenIdConnectService, private readonly navigationService: NavigationService) {}

  public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.auth.userAvailable && !this.auth.user.expired) {
      setTimeout(() => {
        this.navigationService.storeUrl(state.url);
      }, 50);
      return true;
    }

    const queryParamStart = state.url.indexOf('?');
    const initialUrl = state.url.slice(0, queryParamStart > -1 ? queryParamStart : undefined);
    this.auth.triggerSignIn({ initialUrl });
    return false;
  }
}

export const AuthGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  return inject(AuthGuardService).canActivate(next, state);
};
