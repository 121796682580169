import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-timeout',
  templateUrl: './timeout.component.html'
})
export class TimeoutComponent implements OnInit {
  constructor(private readonly router: Router) {}

  ngOnInit() {}

  public goBack() {
    this.router.navigate(['']);
  }
}
