import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ImportLog } from '@models/import-log/import-log';
import { AppConfigProvider } from '@app/app.config.provider';
import { ImportRequest } from '@models/data-sources/data-sources';

@Injectable({
    providedIn: 'root'
})
export class ImportLogService {
    private importLogsURL: string

    constructor(
        private readonly http: HttpClient,
        private readonly appConfig: AppConfigProvider,
    ) {
        this.appConfig.environment.subscribe(env => {
            this.importLogsURL = `${env.API.DIMENSIONS}/api/ImportLog`;
        });
    }

    public getImportLogs(): Observable<ImportLog[]> {
        return this.http.get<ImportLog[]>(`${this.importLogsURL}`);
    }

    public addImportLogForDuplicateFiles(importRequest: ImportRequest[]): Observable<any> {
        return this.http.post(`${this.importLogsURL}/duplicate`, importRequest);
    }

    public addImportLogsForDisabledDsUploads(importRequest: ImportRequest[]): Observable<any> {
        return this.http.post(`${this.importLogsURL}/disabledDataSourceUpload`, importRequest);
    }
}
