import Swal, { SweetAlertResult } from "sweetalert2";

export async function openAssignmentDialog(assignment: string, otherValue: string[]): Promise<SweetAlertResult<any>> {
   const assignedValue = otherValue.join(', ');
  return await Swal.fire({
    title: `Other Assignments Impacted`,
    icon: 'warning',
    html: `<p>${assignment} has been selected as the Other Assignment for the below assignment value[s]:<p>` +
    `<p>${assignedValue} ` +
    `<p>Continuing to save your changes will reset the ruleset for the above assignment value[s] to Use Imported Value.  </p>` +
    `<p>Press Cancel to return & <b>NOT</b> save your updates, or Continue to save your updates.</p>`,
    heightAuto: false,
    showCancelButton: true,
    confirmButtonText: 'Continue',
    cancelButtonText: 'Cancel',
    reverseButtons: true,
    allowEscapeKey: true
  });
}
