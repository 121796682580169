import { Location, NgClass, NgFor, NgIf } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild, forwardRef } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { isValidUrl } from '@app/shared/strings';
import { AsOfDate } from '@models/as-of-date/as-of-date';
import { IPageValidation } from '@models/page-validation/page-validation';
import { DataSourcesService } from '@services/data-sources/data-sources.service';
import { DependencyService } from '@services/dependency/dependency.service';
import { OpenIdConnectService } from '@services/openid/openid-connect.service';
import { fadeInOut } from '@shared/animations';
import { combineLatest } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { mapObjectToComponentFields, positionPopupElement } from '../navigation-functions';
import {
  INavigationChildrenElement,
  INavigationExtendedElement,
  INavigationOptionsElement,
  NavigationElement,
  NavigationElementState
} from '../navigation-interfaces';
import { NavigationOptionsModalComponent } from '../navigation-options-modal/navigation-options.component';
import { NavigationSubElementComponent } from '../navigation-sub-element/navigation-sub-element.component';

@Component({
  selector: 'empyrean-navigation-element',
  templateUrl: './navigation-element-modal.component.html',
  styleUrls: ['./navigation-element-modal.component.scss'],
  standalone: true,
  animations: [fadeInOut(400)],
  imports: [
    RouterLink,
    NgIf,
    NgClass,
    NgFor,
    NavigationSubElementComponent,
    forwardRef(() => NavigationElementModalComponents),
    NavigationOptionsModalComponent
  ]
})
export class NavigationElementModalComponents implements OnInit {
  @Input() public label: string;
  @Input() public iconClass: string;
  @Input() public alertClass: string;
  @Input() public item: INavigationExtendedElement;
  @Input() public INavigationExtendedElement: INavigationExtendedElement[];
  @Input() public isCollapsed: boolean;
  // @Input() public clickAction: (args?: any) => any;
  @Input() public routerLink: string;
  @Output() public popUpOpened = new EventEmitter();

  @ViewChild('optionsDropdown') public optionsDropdown: ElementRef;
  @Input() public children: INavigationChildrenElement[];
  public loadedChildren: INavigationChildrenElement[] = null;

  @ViewChild('optionsBtn') public optionsBtn: ElementRef;
  @Input() public options: INavigationOptionsElement[];
  public loadedOptions: INavigationOptionsElement[] = null;

  @Input() public subNavElements: NavigationElement[];
  public loadedSubNavElements: NavigationElement[];

  public childrenSize: number;
  public isExternalUrl = false;

  constructor(
    private readonly renderer: Renderer2,
    private readonly router: Router,
    private readonly location: Location,
    private readonly dependencyService: DependencyService,
    private readonly dsService: DataSourcesService,
    private readonly oidc: OpenIdConnectService
  ) {
    this.childrenSize = 0;
    this._startNavigationMenuState();
  }

  public ngOnInit(): void {
    mapObjectToComponentFields(this, this.item);
    this.isExternalUrl = (this.item?.routerLink ?? '').match(/^(http|https):\/\//) && isValidUrl(this.item?.routerLink);

    if (this.children && this.options) {
      throw new Error('You cannot render sub elements and options at the same time');
    }
  }

  /** Starts a subscription that updates the navigation elements when dependecies are updated */
  private _startNavigationMenuState() {
    const source = combineLatest([this.dependencyService._pageValidations$, this.dsService.selectedAsOfDate$]).pipe(debounceTime(500)); //.subscribe();
    source.subscribe(([pageValidations, asofdate]) => {
      this._setElementStatesAR(this.item, { pageValidations, asofdate });
    });
  }

  /** recursively sets the current state of the nav elements tree */
  private _setElementStatesAR(item: INavigationExtendedElement, deps: { pageValidations: IPageValidation[]; asofdate: AsOfDate }) {
    if (item) {
      if (item && item.children) {
        this._setElementStatesAR(item, deps);
      }
      this._setElementStateAR(item, deps);
      item.stateClass = this.getIconClass(item).join(' ');
      item.tooltip = this.getTooltip(item);
    }
  }

  private _setElementStateAR(item: INavigationExtendedElement, deps: { pageValidations: IPageValidation[]; asofdate: AsOfDate }) {
    const { pageValidations } = deps;
    if (pageValidations && this.oidc.isAdminUser()) {
      let pageIsValid: boolean = true;
      let pageIsAllocated: boolean = true;
      let pageIsSetup: boolean = true;
      let pageIsActive = true;
      if (item.children && item.children.length > 0) {
        pageIsValid = !item.children.some(child => child.elementState === 'invalid' || child.elementState === 'invalid unallocated');
      } else {
        const dataSourceID = item.dataSourceId;
        const pageValid = pageValidations.find(p => p.DataSourceID === dataSourceID);
        pageIsValid = pageValid?.IsValid === false ? false : true;
        pageIsAllocated = pageValid?.IsAllocated === false ? false : true;
        pageIsSetup = pageValid?.IsSetup === false ? false : true;
        pageIsActive = pageValid?.IsSetup === null ? false : true;
      }
      let state: NavigationElementState = 'ok';
      if (pageIsSetup === true && pageIsValid === false) {
        // -- page is invalid and has unallocated
        state = pageIsAllocated ? 'invalid' : 'invalid unallocated';
      } else if (pageIsSetup === true && pageIsAllocated === false) {
        // -- page has unallocated items, but not required to allocated
        state = 'unallocated';
      } else if (pageIsSetup === false) {
        // -- page is ready to be set up
        state = 'not setup';
      } else if (pageIsActive === false) {
        // -- page has dependencies not met so cannot be set up or page set up not required based on other settings
        state = 'not required';
      }
      item.elementState = state;
    } else {
      item.elementState = 'ok';
    }
  }

  public getIconClass(element: NavigationElement): string[] {
    const state = element.elementState;
    let stateClasses: Array<string> = [];
    const isALeaf = !element.children ? true : false;
    if (isALeaf && state === 'invalid') {
      stateClasses.push('fa-solid', 'fa-triangle-exclamation');
    } else if (!element.iconClass) {
      stateClasses.push('fa-solid', '');
    }
    return stateClasses;
  }

  public getTooltip(element: NavigationElement): string {
    const state = element.elementState;
    let tooltip: string = '';
    if (state !== 'disabled') {
      switch (state) {
        case 'invalid':
          tooltip = `Validation Errors must be fixed in ${element.label}`;
          break;
        case 'not required':
          tooltip = `${element.label} is disabled because other pages must be setup first`;
          break;
        default:
          break;
      }
    }
    return tooltip;
  }

  public toggleChildren(): void {
    this.loadedChildren = this.loadedChildren ? null : this.children;
    this.loadedSubNavElements = this.loadedSubNavElements ? null : this.subNavElements;
    if (this.loadedChildren) {
      this.popUpOpened.emit(this);
    }
  }

  public hasChildren(): boolean {
    return this.item?.children?.length > 0;
  }

  public async clickAction(): Promise<void> {
    if (this.hasChildren()) {
      this.toggleChildren();
    } else {
      if (!this.item.clickAction) {
        this.item.clickAction = () => this.onMenuItemClicked(this.item as INavigationExtendedElement);
      }

      await this.item.clickAction();

      // if (this.menu === NavigationMenu.Main) {
      //   setTimeout(() => {
      //     const url = this.router.url.replace(/\(navigation-modal-outlet:.*\)/, '');
      //     this.location.replaceState(url);
      //   });
      // }
    }
  }

  private async onMenuItemClicked(element: INavigationExtendedElement): Promise<boolean | void> {
    if (element?.navigationParam) {
      return this.router.navigate(element.navigationParam).catch(console.error);
    }
  }

  public toggleOptions(eventSource): void {
    this.loadedOptions = this.loadedOptions ? null : this.options;

    if (this.loadedOptions) {
      this.popUpOpened.emit(this);
      positionPopupElement(this.optionsDropdown.nativeElement, this.optionsBtn.nativeElement, this.renderer, eventSource);
    }
  }

  public isItemActive(): boolean {
    if (this.item.navigationParam) {
      const navigationParamAsString = `(navigation-modal-outlet:${this.item.navigationParam[0].outlets['navigation-modal-outlet'].reduce(
        (curr, prev) => (curr = `${curr}/${prev}`)
      )})`;

      return this.router.url.includes(navigationParamAsString);
    }

    return this.router.url === `/${this.item.routerLink}`;
  }

  public onSubNavVisible(size: number): void {
    this.childrenSize += size;
  }
}
