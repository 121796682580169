import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { IDropdownItem } from '../spread/spread-styles';
import { NgIf, NgFor } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-search-dialog',
  templateUrl: './search-dialog.component.html',
  styleUrls: ['./search-dialog.component.scss'],
  standalone: true,
  imports: [FormsModule, NgIf, NgFor]
})
export class SearchDialogComponent implements OnInit {
  @ViewChild('inputElement') public inputElement: ElementRef;

  public header = '';
  public searchableItems: IDropdownItem[];
  public searchText = '';
  public allowMultiSelection: boolean;
  public isSearchModified = false;
  public selectAllButtonText = 'Select All';

  public items: any[] = [];
  public selectedItems = [];
  public originalSelectedItems: any;

  public constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: ISearchDialogParams,
    private readonly dialogRef: MatDialogRef<SearchDialogComponent>,
    public dialog: MatDialog
  ) {
    this.header = this.dialogData.Title;
    this.searchableItems = this.dialogData.SearchableItems;
    this.allowMultiSelection = this.dialogData.IsMultiSelectionAllowed;
    this.originalSelectedItems = this.dialogData.AlreadySelectedItems;
  }

  public ngOnInit(): void {
    if (Array.isArray(this.dialogData.AlreadySelectedItems)) {
      this.setAlreadySelected();
    } else {
      this.toggleSelection(this.searchableItems.find(item => item.value === this.dialogData.AlreadySelectedItems));
    }
  }

  private setAlreadySelected(): void {
    this.originalSelectedItems.forEach(element => {
      this.selectedItems.push(this.searchableItems.find(x => x.value === element));
    });
    if (this.selectedItems.length === this.searchableItems.length) {
      this.selectAllButtonText = 'Unselect All';
    }
  }

  public get filteredItems(): IDropdownItem[] {
    if (!this.searchText) {
      return this.searchableItems;
    }
    const searchTextLower = this.searchText.toLowerCase();
    return this.searchableItems.filter(
      item => item.text?.toLowerCase().includes(searchTextLower) || item.value?.toString().includes(searchTextLower)
    );
  }

  public isSelected(item: IDropdownItem): boolean {
    return this.selectedItems.includes(item);
  }

  public toggleSelection(item: IDropdownItem): void {
    if (this.allowMultiSelection) {
      if (this.isSelected(item)) {
        this.selectedItems = this.selectedItems.filter(selected => selected !== item);
      } else {
        this.selectedItems.push(item);
      }
      let mappedSelectedItems = [];
      if (this.selectedItems.length > 0) {
        mappedSelectedItems = this.selectedItems?.map(x => x?.value).sort((a, b) => b - a);
      }
      this.isSearchModified = JSON.stringify(mappedSelectedItems) !== JSON.stringify(this.originalSelectedItems?.sort((a, b) => b - a));
      if (this.selectedItems.length !== this.dialogData.SearchableItems.length) {
        this.selectAllButtonText = 'Select All';
      } else if (this.selectedItems.length === this.dialogData.SearchableItems.length) {
        this.selectAllButtonText = 'Unselect All';
      }
    } else {
      this.selectedItems = this.selectedItems[0] === item ? [] : [item];
      this.isSearchModified = this.selectedItems[0]?.value != this.originalSelectedItems;
    }
  }
  public toggleSelectAll(): void {
    if (this.allowMultiSelection) {
      if (this.selectAllButtonText === 'Select All') {
        this.selectAllButtonText = 'Unselect All';
        this.selectedItems = this.filteredItems.slice();
      } else if (this.selectAllButtonText === 'Unselect All') {
        this.selectAllButtonText = 'Select All';
        this.selectedItems = [];
      }
      this.isSearchModified = true;
    }
  }

  public closeDialog(): void {
    this.dialogRef.close({ isDiscard: true });
  }

  public confirmDialog(): void {
    this.isSearchModified = false;
    this.dialogRef.close({ isDiscard: false, data: this.selectedItems });
  }
}

export interface ISearchDialogParams {
  SearchableItems: IDropdownItem[];
  Title: string;
  IsMultiSelectionAllowed: boolean;
  AlreadySelectedItems: number[] | number;
}

export const SearchDialogWidth = '500px';
