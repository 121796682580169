import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigProvider } from '@app/app.config.provider';
import { AppConfigAPI } from '@models/appconfig';
import { Assignment } from '@models/assignments/assignments';
import { LocalStorageKeys } from '@models/storage/local-storage-keys';
import { LocalStorageRepository } from '@services/local-storage/local-storage';
import { BehaviorSubject, Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ModalScreenService extends LocalStorageRepository {

  public constructor(
    private readonly http: HttpClient,
    private readonly appConfig: AppConfigProvider
  ) {
    super(LocalStorageKeys.MODALSCREEN, 'modal-screen');
  }


}

export const  assignment: Assignment[] = [];
