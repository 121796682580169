import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfigProvider } from '@app/app.config.provider';
import { EmpyreanApplicationName, IUserLevel, UserLevel, getUserLevelForApplication } from '@models/user-level';
import { isEmpty } from '@shared/arrays';
import { User, UserManager } from 'oidc-client';
import { ReplaySubject } from 'rxjs';
import { TimeoutService } from './timeout.service';

const ENABLE_DEBUGGING = false;

@Injectable({ providedIn: 'root' })
export class OpenIdConnectService {
  private userManager: UserManager;
  private currentUser: User;

  public userLoaded$ = new ReplaySubject<boolean>(1);

  get userAvailable(): boolean {
    return Boolean(this.currentUser);
  }

  get user(): User {
    return this.currentUser;
  }

  get userId(): number {
    if (this.userAvailable) {
      return Number(this.currentUser.profile.sub);
    }

    this.triggerSignIn();
  }

  get customerId(): number {
    const customerId = Number(this.currentUser.profile.Custaim);
    if (!isNaN(customerId) && typeof customerId === 'number') {
      return Number(customerId);
    }

    console.warn('customer_id not found for current user');
    return null;
  }

  private getDimensionsLevelFromProfile(profile) {
    return getUserLevelForApplication(EmpyreanApplicationName.Dimensions, JSON.parse(profile.userlevels));
  }

  get userLevelList(): IUserLevel[] {
    if (this.userAvailable && this.currentUser.profile.userlevels) {
      return JSON.parse(this.currentUser.profile.userlevels);
    }

    return [{ level_id: -1, level_text: 'User level not found' }];
  }

  get userLevel(): IUserLevel {
    if (this.userAvailable && this.currentUser.profile.userlevels) {
      return this.getDimensionsLevelFromProfile(this.currentUser.profile);
    }

    return { level_id: -1, level_text: 'User level not found' };
  }

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly router: Router,
    private readonly timeoutService: TimeoutService,
    private readonly appConfig: AppConfigProvider
  ) {
    this.appConfig.environment.subscribe(async env => {
      let oidcSettings = env.openIdConnectSettings;

      let { host } = window.location;
      const allowedHost = env.ALLOWED_URLS.find((url: string) => host.includes(url));

      const fragments = host.split(`${allowedHost}`);

      if (allowedHost && fragments && fragments.length > 0 && !isEmpty(fragments[0])) {
        //
        let idpFragment = fragments[0];
        if (idpFragment.indexOf('.') === idpFragment.length - 1) {
          idpFragment = idpFragment.substring(0, idpFragment.indexOf('.'));
        }
        host = host.replace(/\/\s*$/, ''); // removes backlash at the end, if exists
        oidcSettings = {
          ...env.openIdConnectSettings,
          acr_values: `idp:${idpFragment}`,
          silent_redirect_uri: `https://${host}/redirect-silentrenew`,
          redirect_uri: `https://${host}/signin-oidc`,
          post_logout_redirect_uri: `https://${host}`
        };
      }

      this.userManager = new UserManager(oidcSettings);

      await this.userManager.clearStaleState();
      this.userManager.events.addUserLoaded(user => {
        if (ENABLE_DEBUGGING) {
          console.log(`User loaded ${new Date()}`, this.currentUser, { user });
        }

        const incomingUserLevel = this.getDimensionsLevelFromProfile(user.profile);
        try {
          if (this.userLevel && incomingUserLevel && this.userLevel.level_id !== incomingUserLevel.level_id) {
            this.currentUser = user;
            this.timeoutService.startTracking();
            this.userManager.startSilentRenew();
            this.userLoaded$.next(true);
          } else {
            if (this.getDimensionsLevelFromProfile(user.profile)) {
              this.currentUser = user;
              this.timeoutService.startTracking();
              this.userManager.startSilentRenew();
            } else {
              if (ENABLE_DEBUGGING) {
                console.error('OpenIdConnectService -> No dataverse user found in token');
              }
              this.document.location.href = 'error/401';
            }
          }
        } catch (err) {
          if (ENABLE_DEBUGGING) {
            console.error('OpenIdConnectService -> Unable to load token', err);
          }
          this.document.location.href = 'error/401';
        }
      });

      this.userManager.events.addUserUnloaded(() => {
        if (ENABLE_DEBUGGING) {
          console.log('User unloaded');
        }
        this.currentUser = null;
        this.userLoaded$.next(false);
        this.timeoutService.stopTracking();
      });

      this.timeoutService.userTimedOut$.subscribe(async timedOut => {
        if (timedOut) {
          await this.onUserTimeout();
        }
      });
    });
  }
  /**
   * Triggers the OIDC routine to send an user to the OIDC endpoint to retrieve the token
   */
  public triggerSignIn(state?) {
    this.userManager
      .signinRedirect({ state })
      .then(() => {
        if (ENABLE_DEBUGGING) {
          console.log('Redirection to signin triggered.');
        }
      })
      .catch(async err => {
        if (ENABLE_DEBUGGING) {
          console.error('Unhandled error when triggering login in', err);
        }
        await this.router.navigate(['/error']);
      });
  }

  /**
   * Triggers the OIDC routine to store the token in the session store and in memory
   */
  public handleCallBack() {
    this.userManager
      .signinRedirectCallback()
      .then(user => {
        this.currentUser = user;
        if (ENABLE_DEBUGGING) {
          console.log('Callback after signin triggered.', user);
        }
      })
      .catch(err => {
        if (ENABLE_DEBUGGING) {
          console.error('OpenIdConnectService -> handleCallBack ->', err, typeof err);
        }
        if (err.toString().includes('Error: No matching state found in storage')) {
          this.triggerSignIn();
        }
      });
  }

  public handleSilentCallback() {
    this.userManager
      .signinSilentCallback()
      .then(user => {
        if (ENABLE_DEBUGGING) {
          console.log('Callback after silent signin handled.', user);
          // We may need this
          // https://stackoverflow.com/questions/50416649/no-user-in-signinsilentcallback-using-identityserver-and-oidc-client-of-javascri
        }
      })
      .catch(async err => {
        if (ENABLE_DEBUGGING) {
          console.error('Unhandled error when silently triggering login in', err);
        }
        await this.router.navigate(['/error']);
      });
  }

  /**
   * Triggers the SingOut process for the OIDC library
   */
  public async triggerSignOut() {
    await this.userManager
      .signoutRedirect()
      .then(res => {
        if (ENABLE_DEBUGGING) {
          console.log('Redirection to signout triggered.', res);
        }
      })
      .catch(err => {
        if (ENABLE_DEBUGGING) {
          console.error('Unhandled error when signing out', err);
        }
      })
      .finally(() => this.removeUserFromStorage());
  }

  public async removeUserFromStorage() {
    await this.userManager.removeUser();
    await this.userManager.revokeAccessToken();
  }

  public isAdminUser() {
    return this.userLevelList.some(x => x.level_id === UserLevel.DataverseAdministrator);
  }

  public isDepositsAdminOnly() {
    return this.userLevelList.some(x => x.level_id === UserLevel.DataverseAdministrator) ||
      this.userLevelList.some(x => x.level_id === UserLevel.ProfitabilityAdministrator)
      ? false
      : this.userLevelList.some(x => x.level_id === UserLevel.DepositsAdministrator);
  }

  /**
   * Routine that will be executed after Idle times the user out
   * It removes the popup if still active, then creates a call to the OIDC endpoint to remove the user
   * from the Identity Server and the frontend.
   * Then, it will stop all tracking services and navigate the user to the Timeout page
   */
  private async onUserTimeout() {
    await this.userManager
      .createSignoutRequest({ id_token_hint: this.user.id_token, extraQueryParams: { reason: 'timeout' } })
      .then(signoutRequest => {
        this.document.location.href = signoutRequest.url;
      })
      .catch(async err => {
        await this.triggerSignOut();
      })
      .finally(async () => {
        this.timeoutService.stopTracking();
        await this.userManager.removeUser();
        setTimeout(() => {
          // This code should never execute. The try/catch should redirect to identity server in any case
          // in case the signout request cannot be created this redirect the user to a page that contains no
          // sensitive information
          this.document.location.href = '/error/timeout'; // Bypasses angular router
        }, 10000);
      });
  }
}
