import { ILocalStorage } from '@models/storage/local-storage';
import { LocalStorageKeys } from '@models/storage/local-storage-keys';
import { ICanComponentDiscardLocalStorageAndDeactivate } from '@services/auth-guard/can-component-discard-localstorage-guard.service';

export const EXPIRATION_DAYS_LS = 60;

export class LocalStorageRepository implements ICanComponentDiscardLocalStorageAndDeactivate {
  public readonly componentName: string;
  private readonly type: LocalStorageKeys;
  public currentkey: number;
  constructor(type: LocalStorageKeys, componentName: string) {
    this.type = type;
    this.componentName = componentName;
  }

  public storeInLocalStorage<T>(data: T, productId?: number) {
    // Step One we create the key
    const key = `${this.type}${productId || ''}`;

    // Create the storage object
    const storageObject: ILocalStorage<T> = {
      storeDate: new Date().toISOString(),
      payload: data
    };
    // Store this item in the local storage
    localStorage.setItem(key, JSON.stringify(storageObject));
  }

  public fetchFromLocalStorage<T>(id?: number): ILocalStorage<T> {
    const key = `${this.type}${id || ''}`;
    const localStorageItemAsString = localStorage.getItem(key);
    if (localStorageItemAsString) {
      try {
        return JSON.parse(localStorageItemAsString) as ILocalStorage<T>;
      } catch (error) {
        console.error(error);
        // If we have an error parsing we are going to clear the local storage
        this.clearFromLocalStorage(id);
        return null;
      }
    } else {
      return null;
    }
  }

  /**
   * Removes one item from the local storage based on type
   */
  public clearFromLocalStorage(productId?: number): void {
    const key = `${this.type}${productId || ''}`;
    localStorage.removeItem(key);
  }

  public isLocalStorageExpired(key?: number) {
    const oneDay = 24 * 60 * 60 * 1000;
    const localStorageDate: Date = new Date(this.fetchFromLocalStorage(key).storeDate);
    const todayDate = new Date();

    const diffDays = Math.round(Math.abs((localStorageDate.getTime() - todayDate.getTime()) / oneDay));

    return diffDays > EXPIRATION_DAYS_LS;
  }

  public canDeactivate(key?: number): boolean {
    const storage = this.fetchFromLocalStorage(key);
    return !!(storage && storage.payload);
  }
}
