<div class="container">
  <div class="radio-buttons">
    <label>
      <input
        type="radio"
        name="radioGLBSGroup"
        [(ngModel)]="selectedGLBSRadio"
        value="ImportedGLBSValue"
        (change)="onGLBSRadioChange()"
        checked
      />
      Use Imported Value
    </label>
    <label>
      <input
        type="radio"
        name="radioGLBSGroup"
        [(ngModel)]="selectedGLBSRadio"
        value="AssignmentGLBSRuleset"
        (change)="onGLBSRadioChange()"
        (click)="onAddYieldCurveClicked()"
      />
      Use Assignment Ruleset
    </label>
    <label>
      <input type="radio" name="radioGLBSGroup" [(ngModel)]="selectedGLBSRadio" value="SingleGLBSValue" (change)="onGLBSRadioChange()" />
      Use Single Value
    </label>
    <form [formGroup]="textFormGroup" *ngIf="selectedGLBSRadio === 'SingleGLBSValue'">
      <label for="numericField"></label>
      <input
        type="text"
        id="numericField"
        formControlName="numericField"
        [ngClass]="{
          error:
            textFormGroup.get('numericField').invalid &&
            (textFormGroup.get('numericField').dirty || textFormGroup.get('numericField').touched)
        }"
        (click)="openDialog()"
        autocomplete="off"
      />
      <div *ngIf="textFormGroup.get('numericField').hasError('invalidGLBSNumber')">
        <span class="error-message"> {{ textFormGroup.get('numericField').errors['invalidGLBSNumber']?.errorMsg }}</span>
      </div>
    </form>
  </div>

  <div class="button">
    <button class="btn btn_normal btn_blue btn_wide" [disabled]="disableEditButton" (click)="onAddYieldCurveClicked()">
      <span>EDIT DIMENSIONS</span>
    </button>
  </div>
</div>

<ng-container
  class="prompt-message-container flex-container centered vertical full-height"
  *ngIf="selectedGLBSRadio === 'ImportedGLBSValue'"
>
  <h3 class="prompt-title">Assignment Ruleset not Enabled.</h3>
  <p class="prompt-message">This field will be populated based on the imported files for this {{ this.selectedNameMessage }}.</p>
</ng-container>

<ng-container
  class="prompt-message-container flex-container centered vertical full-height"
  *ngIf="selectedGLBSRadio === 'AssignmentGLBSRuleset'"
>
  <div><app-invalid-rows [invalidRowCount]="invalidRows.length" (click)="onClickInvalidRow()"></app-invalid-rows></div>
  <div [class.hidden]="isLoading" class="spreadsheet-container fadein" (mousemove)="onMouseMove($event)">
    <gc-spread-sheets #spreadDiv class="spreadsheet fadein" (workbookInitialized)="workBookInit($event)"></gc-spread-sheets>
  </div>
</ng-container>

<ng-container class="prompt-message-container flex-container centered vertical full-height" *ngIf="selectedGLBSRadio === 'SingleGLBSValue'">
  <h3 class="prompt-title">Assignment Ruleset not Enabled.</h3>
  <p class="prompt-message">This field will be populated based on the inputted Single Value.</p>
</ng-container>

<div footer class="footer">
  <button id="discard-button" class="btn" (click)="closeModal()">Close</button>
  <button id="save-button" class="btn" (click)="onClickSave()" [disabled]="isSaveButtonDisabled()">Save</button>
</div>
