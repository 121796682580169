import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-toggle-switch',
  templateUrl: './toggle-switch.component.html',
  styleUrls: ['./toggle-switch.component.scss']
})
export class ToggleSwitchComponent implements OnInit {
  @Output() isExpandedAll = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {}

  public expandAll() {
    this.isExpandedAll.emit(true);
  }

  public collapseAll() {
    this.isExpandedAll.emit(false);
  }
}
