export const TYPES_OF_DATASOURCES = {
  GeneralLedger: 1,
  LoansLeasesCC: 2,
  TimeDeposits: 3,
  NonMaturityDeposits: 4,
  Investment: 5,
  Swaps: 6,
  Derivatives: 7,
  Borrowing: 8,
  AncillaryProduct: 12
};

export const DIMENSIONS_DROPDOWN = new Map<number, { value: string; text: string; table: string }[]>([
  [
    TYPES_OF_DATASOURCES.GeneralLedger,
    [
      { value: '0', text: 'ALMAccountNum', table: '0' },
      { value: '1', text: 'BUDAccountNum  ', table: '0' },
      { value: '2', text: 'GL_Acct_Num', table: '0' }
    ]
  ],
  [
    TYPES_OF_DATASOURCES.LoansLeasesCC,
    [
      { value: '0', text: 'AccrualCode', table: '0' },
      { value: '1', text: 'ALMAccountNum', table: '0' },
      { value: '2', text: 'ALMOrgUnitID', table: '0' },
      { value: '3', text: 'AmortCode', table: '0' },
      { value: '4', text: 'B3ExposureType', table: '0' },
      { value: '5', text: 'BankQualifiedFlag', table: '0' },
      { value: '6', text: 'BiLatFlag', table: '0' },
      { value: '7', text: 'BranchSID', table: '0' },
      { value: '8', text: 'BUDAccountNum', table: '0' },
      { value: '9', text: 'ClosedFlag', table: '0' },
      { value: '10', text: 'DeptCode', table: '0' },
      { value: '11', text: 'ExerciseCode', table: '0' },
      { value: '12', text: 'FVCode', table: '0' },
      { value: '13', text: 'GL_BS_Acct', table: '0' },
      { value: '14', text: 'GL_IS_Acct', table: '0' },
      { value: '15', text: 'HVCREFlag', table: '0' },
      { value: '16', text: 'IntCompoundFreq', table: '0' },
      { value: '17', text: 'InterCompanyALMOrgID', table: '0' },
      { value: '18', text: 'InterCompanyLegalEntityID', table: '0' },
      { value: '19', text: 'InterestPayFreq', table: '0' },
      { value: '20', text: 'IntResetCode', table: '0' },
      { value: '21', text: 'IRCapFlag', table: '0' },
      { value: '22', text: 'IRFloorFlag', table: '0' },
      { value: '23', text: 'IRSwapFlag', table: '0' },
      { value: '24', text: 'LCFlag', table: '0' },
      { value: '25', text: 'LeaseType', table: '0' },
      { value: '26', text: 'LegalEntityID', table: '0' },
      { value: '27', text: 'LnSvcFlag', table: '0' },
      { value: '28', text: 'Naics_sid', table: '0' },
      { value: '29', text: 'OffBSFlag', table: '0' },
      { value: '30', text: 'OptionCode', table: '0' },
      { value: '31', text: 'OptionStyle', table: '0' },
      { value: '32', text: 'OriginalTerm', table: '0' },
      { value: '33', text: 'OverdraftFlag', table: '0' },
      { value: '34', text: 'PartitionNum', table: '0' },
      { value: '35', text: 'PartLoanFlag', table: '0' },
      { value: '36', text: 'PayFreq', table: '0' },
      { value: '37', text: 'PrinPayFreq', table: '0' },
      { value: '38', text: 'ProductCode', table: '0' },
      { value: '39', text: 'ResetIndex', table: '0' },
      { value: '40', text: 'RLOCFlag', table: '0' },
      { value: '41', text: 'RWAWgt', table: '0' },
      { value: '42', text: 'SyndLeadBankFlag', table: '0' },
      { value: '43', text: 'SyndLoanFlag', table: '0' },
      { value: '44', text: 'TaxExemptFlag', table: '0' },
      { value: '45', text: 'UCCFlag', table: '0' },
      { value: '46', text: 'AccrualStatus', table: '1' },
      { value: '47', text: 'AcquisitionID', table: '1' },
      { value: '48', text: 'CreditClass', table: '1' },
      { value: '49', text: 'FDICHRLFlag', table: '1' },
      { value: '50', text: 'LoanStatus', table: '1' },
      { value: '51', text: 'NonAccrualFlag', table: '1' },
      { value: '52', text: 'RemTermInMonths', table: '1' },
      { value: '53', text: 'ResetMths', table: '1' },
      { value: '54', text: 'ResetPricingTerm', table: '1' }
    ]
  ],
  [
    TYPES_OF_DATASOURCES.TimeDeposits,
    [
      { value: '0', text: 'AccrualCode', table: '0' },
      { value: '1', text: 'ALMAccountNum', table: '0' },
      { value: '2', text: 'ALMOrgUnitID', table: '0' },
      { value: '3', text: 'AmortCode', table: '0' },
      { value: '4', text: 'BranchCode', table: '0' },
      { value: '5', text: 'BUDAccountNum', table: '0' },
      { value: '6', text: 'ClosedFlag', table: '0' },
      { value: '7', text: 'DeptCode', table: '0' },
      { value: '8', text: 'ExerciseCode', table: '0' },
      { value: '9', text: 'GL_BS_Acct', table: '0' },
      { value: '10', text: 'GL_IS_Acct', table: '0' },
      { value: '11', text: 'IBDepFlag', table: '0' },
      { value: '12', text: 'IntCompoundFreq', table: '0' },
      { value: '13', text: 'InterCompanyALMOrgID', table: '0' },
      { value: '14', text: 'InterCompanyLegalEntityID', table: '0' },
      { value: '15', text: 'InterestPayFreq', table: '0' },
      { value: '16', text: 'IntResetCode', table: '0' },
      { value: '17', text: 'IRCapFlag', table: '0' },
      { value: '18', text: 'IRFloorFlag', table: '0' },
      { value: '19', text: 'LegalEntityID', table: '0' },
      { value: '20', text: 'NAICS', table: '0' },
      { value: '21', text: 'OpDepositFlag', table: '0' },
      { value: '22', text: 'OptionCode', table: '0' },
      { value: '23', text: 'OptionStyle', table: '0' },
      { value: '24', text: 'OriginalTerm', table: '0' },
      { value: '25', text: 'PartitionNum', table: '0' },
      { value: '26', text: 'PrinPayFreq', table: '0' },
      { value: '27', text: 'ProductCode', table: '0' },
      { value: '28', text: 'ResetIndex', table: '0' },
      { value: '29', text: 'TreasuryMgmtFlag', table: '0' },
      { value: '30', text: 'AcquisitionID', table: '1' },
      { value: '31', text: 'ResetMths', table: '1' },
      { value: '32', text: 'ResetPricingTerm', table: '1' }
    ]
  ],
  [
    TYPES_OF_DATASOURCES.NonMaturityDeposits,
    [
      { value: '0', text: 'AccrualCode', table: '0' },
      { value: '1', text: 'ALMAccountNum', table: '0' },
      { value: '2', text: 'ALMOrgUnitID', table: '0' },
      { value: '3', text: 'BranchCode', table: '0' },
      { value: '4', text: 'BUDAccountNum', table: '0' },
      { value: '5', text: 'ClosedFlag', table: '0' },
      { value: '6', text: 'DeptCode', table: '0' },
      { value: '7', text: 'GL_BS_Acct', table: '0' },
      { value: '8', text: 'GL_IS_Acct', table: '0' },
      { value: '9', text: 'IBDepFlag', table: '0' },
      { value: '10', text: 'InterCompanyALMOrgID', table: '0' },
      { value: '11', text: 'InterCompanyLegalEntityID', table: '0' },
      { value: '12', text: 'IntResetCode', table: '0' },
      { value: '13', text: 'IRCapFlag', table: '0' },
      { value: '14', text: 'IRFloorFlag', table: '0' },
      { value: '15', text: 'LegalEntityID', table: '0' },
      { value: '16', text: 'NAICS', table: '0' },
      { value: '17', text: 'OpDepositFlag', table: '0' },
      { value: '18', text: 'PartitionNum', table: '0' },
      { value: '19', text: 'ProductCode', table: '0' },
      { value: '20', text: 'TreasuryMgmtFlag', table: '0' },
      { value: '21', text: 'AcquisitionID', table: '1' }
    ]
  ],
  [
    TYPES_OF_DATASOURCES.Investment,
    [
      { value: '0', text: 'AccrualCode', table: '0' },
      { value: '1', text: 'ALMAccountNum', table: '0' },
      { value: '2', text: 'ALMOrgUnitID', table: '0' },
      { value: '3', text: 'AmortCode', table: '0' },
      { value: '4', text: 'BUDAccountNum', table: '0' },
      { value: '5', text: 'ClosedFlag', table: '0' },
      { value: '6', text: 'CUSIP', table: '0' },
      { value: '7', text: 'DeptCode', table: '0' },
      { value: '8', text: 'ExerciseCode', table: '0' },
      { value: '9', text: 'FVCode', table: '0' },
      { value: '10', text: 'GL_BS_Acct', table: '0' },
      { value: '11', text: 'GL_IS_Acct', table: '0' },
      { value: '12', text: 'IntCompoundFreq', table: '0' },
      { value: '13', text: 'InterCompanyALMOrgID', table: '0' },
      { value: '14', text: 'InterCompanyLegalEntityID', table: '0' },
      { value: '15', text: 'InterestPayFreq', table: '0' },
      { value: '16', text: 'InterestPayFreq2', table: '0' },
      { value: '17', text: 'IntResetCode', table: '0' },
      { value: '18', text: 'IntResetCode2', table: '0' },
      { value: '19', text: 'IRCapFlag', table: '0' },
      { value: '20', text: 'IRFloorFlag', table: '0' },
      { value: '21', text: 'LegalEntityID', table: '0' },
      { value: '22', text: 'OriginalTerm', table: '0' },
      { value: '23', text: 'PartitionNum', table: '0' },
      { value: '24', text: 'PrinPayFreq', table: '0' },
      { value: '25', text: 'ProductCode', table: '0' },
      { value: '26', text: 'ResetIndex', table: '0' },
      { value: '27', text: 'RWAWgt', table: '0' },
      { value: '28', text: 'ResetMths', table: '1' },
      { value: '29', text: 'ResetPricingTerm', table: '1' }
    ]
  ],
  [
    TYPES_OF_DATASOURCES.Swaps,
    [
      { value: '0', text: 'AccrualCode', table: '0' },
      { value: '1', text: 'ALMAccountNum', table: '0' },
      { value: '2', text: 'ALMOrgUnitID', table: '0' },
      { value: '3', text: 'AmortCode', table: '0' },
      { value: '4', text: 'BUDAccountNum', table: '0' },
      { value: '5', text: 'ClosedFlag', table: '0' },
      { value: '6', text: 'CUSIP', table: '0' },
      { value: '7', text: 'DeptCode', table: '0' },
      { value: '8', text: 'ExerciseCode', table: '0' },
      { value: '9', text: 'FVCode', table: '0' },
      { value: '10', text: 'GL_BS_Acct', table: '0' },
      { value: '11', text: 'GL_IS_Acct', table: '0' },
      { value: '12', text: 'IntCompoundFreq', table: '0' },
      { value: '13', text: 'InterCompanyALMOrgID', table: '0' },
      { value: '14', text: 'InterCompanyLegalEntityID', table: '0' },
      { value: '15', text: 'InterestPayFreq', table: '0' },
      { value: '16', text: 'InterestPayFreq2', table: '0' },
      { value: '17', text: 'IntResetCode', table: '0' },
      { value: '18', text: 'IntResetCode2', table: '0' },
      { value: '19', text: 'IRCapFlag', table: '0' },
      { value: '20', text: 'IRFloorFlag', table: '0' },
      { value: '21', text: 'EntitySid', table: '0' },
      { value: '22', text: 'OriginalTerm', table: '0' },
      { value: '23', text: 'PartitionNum', table: '0' },
      { value: '24', text: 'PrinPayFreq', table: '0' },
      { value: '25', text: 'ProductCode', table: '0' },
      { value: '26', text: 'ResetIndex', table: '0' },
      { value: '27', text: 'RWAWgt', table: '0' },
      { value: '28', text: 'ResetMths', table: '1' },
      { value: '29', text: 'ResetPricingTerm', table: '1' }
    ]
  ],
  [
    TYPES_OF_DATASOURCES.Derivatives,
    [
      { value: '0', text: 'AccrualCode', table: '0' },
      { value: '1', text: 'ALMAccountNum', table: '0' },
      { value: '2', text: 'ALMOrgUnitID', table: '0' },
      { value: '3', text: 'BUDAccountNum', table: '0' },
      { value: '4', text: 'ClosedFlag', table: '0' },
      { value: '5', text: 'DepartmentSID', table: '0' },
      { value: '6', text: 'ExerciseCode', table: '0' },
      { value: '7', text: 'FVCode', table: '0' },
      { value: '8', text: 'GL_BS_Acct', table: '0' },
      { value: '9', text: 'GL_IS_Acct', table: '0' },
      { value: '10', text: 'InterCompanyALMOrgID', table: '0' },
      { value: '11', text: 'InterCompanyLegalEntityID', table: '0' },
      { value: '12', text: 'IntResetCode', table: '0' },
      { value: '13', text: 'LegalEntityID', table: '0' },
      { value: '14', text: 'OptionCode', table: '0' },
      { value: '15', text: 'OptionStyle', table: '0' },
      { value: '16', text: 'PartitionNum', table: '0' },
      { value: '17', text: 'PayFreq', table: '0' },
      { value: '18', text: 'product_code_sid', table: '0' },
      { value: '19', text: 'RateDerivativeCode', table: '1' },
      { value: '20', text: 'RWAWgt', table: '0' }
    ]
  ],
  [
    TYPES_OF_DATASOURCES.Borrowing,
    [
      { value: '0', text: 'AccrualCode', table: '0' },
      { value: '1', text: 'ALMAccountNum', table: '0' },
      { value: '2', text: 'ALMOrgUnitID', table: '0' },
      { value: '3', text: 'AmortCode', table: '0' },
      { value: '4', text: 'BUDAccountNum', table: '0' },
      { value: '5', text: 'ClosedFlag', table: '0' },
      { value: '6', text: 'CUSIP', table: '0' },
      { value: '7', text: 'DeptCode', table: '0' },
      { value: '8', text: 'ExerciseCode', table: '0' },
      { value: '9', text: 'FVCode', table: '0' },
      { value: '10', text: 'GL_BS_Acct', table: '0' },
      { value: '11', text: 'GL_IS_Acct', table: '0' },
      { value: '12', text: 'IntCompoundFreq', table: '0' },
      { value: '13', text: 'InterCompanyALMOrgID', table: '0' },
      { value: '14', text: 'InterCompanyLegalEntityID', table: '0' },
      { value: '15', text: 'InterestPayFreq', table: '0' },
      { value: '16', text: 'IntResetCode', table: '0' },
      { value: '17', text: 'IRCapFlag', table: '0' },
      { value: '18', text: 'IRFloorFlag', table: '0' },
      { value: '19', text: 'LegalEntityID', table: '0' },
      { value: '20', text: 'OriginalTerm', table: '0' },
      { value: '21', text: 'PartitionNum', table: '0' },
      { value: '22', text: 'PrinPayFreq', table: '0' },
      { value: '23', text: 'ProductCode', table: '0' },
      { value: '24', text: 'ResetIndex', table: '0' },
      { value: '25', text: 'ResetMths', table: '1' },
      { value: '26', text: 'ResetPricingTerm', table: '1' }
    ]
  ],
  [
    TYPES_OF_DATASOURCES.AncillaryProduct,
    [
      { value: '0', text: 'BranchCode', table: '0' },
      { value: '1', text: 'ClosedFlag', table: '0' },
      { value: '2', text: 'DeptCode', table: '0' },
      { value: '3', text: 'GL_IS_Acct', table: '0' },
      { value: '4', text: 'NAICS', table: '0' },
      { value: '5', text: 'ProductCode', table: '0' },
      { value: '6', text: 'AcquisitionID', table: '1' }
    ]
  ]
]);

export const getDropDownValues = (assignmentValue?) => {
  const filteredDropdownList = new Map<number, { value: string; text: string; table: string }[]>();

  for (const [key, records] of DIMENSIONS_DROPDOWN) {
    const filteredRecords = records.filter(record => record.text !== assignmentValue);
    filteredDropdownList.set(key, filteredRecords);
  }
  return filteredDropdownList;
};
