<div id="main-container" class="main-container">
  <ng-container *ngTemplateOutlet="treeStructure"></ng-container>
</div>

<ng-template #treeStructure>
  <ng-container *ngIf="nestedDataSource?.data?.length > 0; else loading">
    <div class="tree-panel">
      <div id="tree-warning" class="tree-warning" *ngIf="errorsDisplayingTheTreeRoot">
        <span>The tree view cannot be displayed properly due to the deletion of root.</span>
      </div>
      <div id="tree-warning" class="tree-warning fadein" *ngIf="otherTreeErrors">
        <span>The tree view cannot be displayed properly due to errors in the hierarchy sheet.</span>
      </div>
      <ul id="tree-nodes" class="tree-nodes" [hidden]="otherTreeErrors">
        <li class="tree-option-list">
          <mat-tree [dataSource]="nestedDataSource" [treeControl]="nestedTreeControl" class="tree">
            <!-- Child Nodes -->
            <mat-nested-tree-node *matTreeNodeDef="let node" class="tree-node tree-node-child">
              <div class="node-container">
                <div class="node-name">
                  <span class="tree-text" title="{{ node.name }}">{{ node.name }} </span>
                </div>
                <div *ngIf="node.tooltipNumber > 0" class="tooltip-number" [matTooltip]="node.tooltipMessage" matTooltipPosition="above">
                  {{ node.tooltipNumber }}
                </div>
              </div>
            </mat-nested-tree-node>

            <!-- Parent Nodes -->
            <mat-nested-tree-node *matTreeNodeDef="let node; when: hasNestedChild" class="tree-node tree-node-parent">
              <div class="node-container">
                <div class="parent-node-name">
                  <button
                    type="button"
                    class="tree_link"
                    [class.fadein]="isManualToggle"
                    [class.tree_link_active]="isExpandedNode(node)"
                    (click)="toggleNode(node)"
                  >
                    <span
                      class="tree-link-text"
                      title="{{ node.name }}"
                      [class.root-node]="node.Id === rootId || node.Id === unmatchedRootId"
                      [class.parent-node]="node.children"
                      >{{ node.name }} <span *ngIf="filterHint"> ( {{ filterHint.totalAmount }} )</span>
                    </span>
                  </button>
                </div>
                <div *ngIf="node.tooltipNumber > 0" class="tooltip-number" [matTooltip]="node.tooltipMessage" matTooltipPosition="above">
                  {{ node.tooltipNumber }}
                </div>
              </div>
              <!-- Children nested sublist -->
              <div class="tree-container" *ngIf="nestedTreeControl.isExpanded(node)">
                <span *ngIf="filterHint?.tooltip" class="filter-hint-text"> {{ filterHint.tooltip }}</span>
                <ul class="tree-option-list" [class.fadein]="isManualToggle">
                  <li><ng-container matTreeNodeOutlet></ng-container></li>
                </ul>
              </div>
            </mat-nested-tree-node>
          </mat-tree>
        </li>
      </ul>
    </div>
  </ng-container>
</ng-template>

<ng-template #loading><p>Loading...</p> </ng-template>
