import * as GC from '@grapecity/spread-sheets';
import { SpreadModel } from '@shared/interfaces/spread-model';
import Swal from 'sweetalert2';
import { ISpreadModel } from '../interfaces/i-spread-model';
import { IValidable } from '../interfaces/spread-cell-validation-interfaces';
import { mapActionKeys } from './spread-action-keys';
import { SpreadContextMenu } from './spread-context-menu';
import { borders, colours, ICellRange, ISpreadColumn, spreadFont, SPREAD_FONT_SIZE, getModifiedLightBlueCellStyle, getModifiedCellStyle, getDefaultCellStyle, getInvalidRowStyle, getInvalidCellStyle } from './spread-settings';
import { spreadFormatter } from './spread-styles';
import { suspendDoAndResume } from './suspend-and-resume';

export const DEFAULT_MIN_COL_WIDTH = 90;
export const DEFAULT_MAX_COL_WIDTH = 350;
export const DEFAULT_MIN_ROWS = 30;
export const DEFAULT_EMPTY_BOTTOM_ROWS = 2;

export const setUpWorkbook = (spread: GC.Spread.Sheets.Workbook, isAdmin: boolean, overrides: any = {}) => {
  if (!spread) {
    console.warn(`ERROR sheet or spread objects are invalid`);
    return;
  }

  spread.options.allowExtendPasteRange = true;
  spread.options.allowUserDragDrop = false;
  spread.options.autoFitType = GC.Spread.Sheets.AutoFitType.cellWithHeader;
  spread.options.defaultDragFillType = GC.Spread.Sheets.Fill.AutoFillType.fillWithoutFormatting;
  spread.options.highlightInvalidData = false;
  spread.options.newTabVisible = false;
  spread.options.showDragFillSmartTag = false;
  spread.options.tabStripVisible = false;
  spread.options.enableFormulaTextbox = false;
  spread.options.allowUserEditFormula = false;

  // Scroll
  spread.options.showHorizontalScrollbar = true;
  spread.options.scrollbarShowMax = true;
  spread.options.scrollbarMaxAlign = true;
  spread.options.scrollIgnoreHidden = true;

  GC.Spread.Sheets.Commands.autoFitColumn.canUndo = false;

  spread.sheets.forEach(async sheet => {
    await suspendDoAndResume(sheet, () => {
      sheet.rowOutlines.direction(GC.Spread.Sheets.Outlines.OutlineDirection.backward);
      sheet.defaults.rowHeight = 20;
      sheet.defaults.colWidth = 100;
      sheet.options.allowCellOverflow = true;
      sheet.options.clipBoardOptions = GC.Spread.Sheets.ClipboardPasteOptions.values;
      sheet.options.colHeaderVisible = true;
      sheet.options.frozenlineColor = 'transparent';

      setSheetProtection(spread, sheet, isAdmin);
    });

    mapActionKeys(spread, sheet, sheet.name());
  });
};

const setSheetProtection = (spread: GC.Spread.Sheets.Workbook, sheet: GC.Spread.Sheets.Worksheet, isAdmin = false) => {
  sheet.options.isProtected = true;

  sheet.options.protectionOptions = {
    ...sheet.options.protectionOptions,

    allowDeleteColumns: false,
    allowDragInsertColumns: false,
    allowDragInsertRows: false,
    allowEditObjects: false,
    allowFilter: true,
    allowInsertColumns: false,
    allowResizeColumns: true,
    allowResizeRows: true,
    allowSelectLockedCells: true,
    allowSelectUnlockedCells: true,
    allowSort: true,

    allowInsertRows: isAdmin,
    allowDeleteRows: isAdmin
  };

  sheet.getRange(-1, 0, -1, sheet.getColumnCount()).locked(!isAdmin);

  if (isAdmin) {
    SpreadContextMenu.setCopyCutPaste(spread).enableInsertRowsAboveAndBelow(spread).enableDeleteRows(spread);
  } else {
    SpreadContextMenu.setSimpleCopy(spread);
  }
};

export const clearSheet = async (sheet: GC.Spread.Sheets.Worksheet, columns: ISpreadColumn[]) => {
  await suspendDoAndResume(sheet, () => {
    const range = sheet.getRange(0, 0, sheet.getRowCount(), columns.length);
    range.clear(GC.Spread.Sheets.StorageType.style);
    range.clear(GC.Spread.Sheets.StorageType.data);
  });
};

export const configureSheetHeaders = (sheet: GC.Spread.Sheets.Worksheet, columns: ISpreadColumn[]) => {
  const header = GC.Spread.Sheets.SheetArea.colHeader;

  sheet.suspendPaint();
  sheet.suspendEvent();

  sheet.setRowHeight(0, 40, header);
  sheet.setColumnCount(columns.length);
  columns.forEach((column, ind) => sheet.setValue(0, ind, column.displayName, header));

  const headerRange = sheet.getRange(0, 0, 1, columns.length, header);

  headerRange
    .font(spreadFont.bold)
    .cellPadding(`0 ${SPREAD_FONT_SIZE / 2}`)
    .wordWrap(true)
    .vAlign(GC.Spread.Sheets.VerticalAlign.center)
    .hAlign(GC.Spread.Sheets.HorizontalAlign.center)
    .backColor(colours.LIGHT_BLUE)
    .setBorder(borders.SUBTLE, { all: true, outline: true });

  sheet.resumeEvent();
  sheet.resumePaint();
};

export const configureAASheetHeaders = (sheet: GC.Spread.Sheets.Worksheet, columns: ISpreadColumn[],date:string,dataSourceTypeID?: number) => {
  const header = GC.Spread.Sheets.SheetArea.colHeader;

  const dateformatted = new Date(date);
  const monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  const year = dateformatted.getFullYear();
  const month = monthNames[dateformatted.getMonth()];
  const formattedDateString = `${month} ${year}`;

  sheet.suspendPaint();
  sheet.suspendEvent();

  sheet.setRowCount(2, GC.Spread.Sheets.SheetArea.colHeader);

  if(dataSourceTypeID !== 1){
  sheet.addSpan(0, columns.length-3, 1, 2, GC.Spread.Sheets.SheetArea.colHeader);
  sheet.addSpan(0,columns.length-4, 2, 1, GC.Spread.Sheets.SheetArea.colHeader);
  sheet.addSpan(0, columns.length-1, 2, 1, GC.Spread.Sheets.SheetArea.colHeader);

  for(let i = 0; i < columns.length-4; i++){
    sheet.addSpan(0, i, 2, 1, GC.Spread.Sheets.SheetArea.colHeader);
  }

  sheet.setColumnCount(columns.length);
  columns.forEach((column, ind) => sheet.setValue(0, ind, column.displayName, header));
  columns.forEach(() => sheet.setValue(0, columns.length-3, formattedDateString, header));
  columns.forEach((column, ind) => sheet.setValue(1, ind, column.displayName, header));

  }
  else{
  sheet.addSpan(0, columns.length-2, 1, 1, GC.Spread.Sheets.SheetArea.colHeader);
  sheet.addSpan(0, columns.length-1, 2, 1, GC.Spread.Sheets.SheetArea.colHeader);

  for(let i = 0; i < columns.length-2; i++){
    sheet.addSpan(0, i, 2, 1, GC.Spread.Sheets.SheetArea.colHeader);
  }

  sheet.setColumnCount(columns.length);
  columns.forEach((column, ind) => sheet.setValue(0, ind, column.displayName, header));
  columns.forEach(() => sheet.setValue(0, columns.length-2, formattedDateString, header));
  columns.forEach((column, ind) => sheet.setValue(1, ind, column.displayName, header));

  }
  const headerRange = sheet.getRange(0, 0, 1, columns.length, header);

  headerRange
    .font(spreadFont.bold)
    .cellPadding(`0 ${SPREAD_FONT_SIZE / 2}`)
    .wordWrap(true)
    .vAlign(GC.Spread.Sheets.VerticalAlign.center)
    .hAlign(GC.Spread.Sheets.HorizontalAlign.center)
    .backColor(colours.LIGHT_BLUE)
    .setBorder(borders.SUBTLE, { all: true, outline: true });

  sheet.resumeEvent();
  sheet.resumePaint();
};

export const outOfDatePopUp = (onClickDiscard): Promise<boolean> => {
  const promise: Promise<boolean> = new Promise<boolean>((resolve, reject) => {
    Swal.fire({
      title: `Out of Date Records`,
      icon: 'info',
      text: `You are working with an out of date set of records. Press Load Latest to view the newer version, press Save Anyway to overwrite it, or press Cancel to return to the page`,
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: 'Save Anyway',
      cancelButtonText: 'Cancel',
      denyButtonText: `Load Latest`,
      reverseButtons: true,
      allowEscapeKey: true,
      heightAuto: false
    })
      .then(result => {
        if (result.isConfirmed) {
          resolve(true);
        } else if (result.isDenied) {
          onClickDiscard();
          resolve(false);
        } else {
          resolve(false);
        }
      })
      .catch(err => {
        reject(true);
      });
  });

  return promise;
};

export const outOfDatePopUpAsync = (onClickDiscard: () => Promise<any>): Promise<boolean> => {
  const promise: Promise<boolean> = new Promise<boolean>((resolve, reject) => {
    Swal.fire({
      title: `Out of Date Records`,
      icon: 'info',
      text: `You are working with an out of date set of records. Press Load Latest to view the newer version, press Save Anyway to overwrite it, or press Cancel to return to the page`,
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: 'Save Anyway',
      cancelButtonText: 'Cancel',
      denyButtonText: `Load Latest`,
      reverseButtons: true,
      allowEscapeKey: true,
      heightAuto: false
    })
      .then(async result => {
        if (result.isConfirmed) {
          resolve(true);
        } else if (result.isDenied) {
          const discardResult = await onClickDiscard();
          resolve(false);
        } else {
          resolve(false);
        }
      })
      .catch(err => {
        reject(true);
      });
  });

  return promise;
};

export const configureSpreadsheet = async (sheet: GC.Spread.Sheets.Worksheet, isAdmin: boolean, columnConfiguration: ISpreadColumn[]) => {
  await suspendDoAndResume(sheet, async () => {
    await clearSheet(sheet, columnConfiguration);
    configureSheetHeaders(sheet, columnConfiguration);
  });
};

export const configureAASpreadsheet = async (sheet: GC.Spread.Sheets.Worksheet, isAdmin: boolean, columnConfiguration: ISpreadColumn[],date: string, dataSourceTypeID?: number) => {
  await suspendDoAndResume(sheet, async () => {
    await clearSheet(sheet, columnConfiguration);
    configureAASheetHeaders(sheet, columnConfiguration,date,dataSourceTypeID);
  });
};

export const populateDataToSpreadSheet = (sheet: GC.Spread.Sheets.Worksheet, data: any[], columnConfiguration: ISpreadColumn[]) => {
  if (sheet && data && data.length > 0) {
    sheet.suspendPaint();
    sheet.suspendEvent();

    sheet.getRange(0, 0, sheet.getRowCount(), sheet.getColumnCount()).clear(GC.Spread.Sheets.StorageType.style);
    const fullRange = sheet.getRange(0, 0, sheet.getRowCount(), sheet.getColumnCount());
    fullRange
      .font(spreadFont.normal)
      .vAlign(GC.Spread.Sheets.VerticalAlign.center);

    const dataTable = data.map(row => columnConfiguration.map(col => row[col.key]));
    sheet.setArray(0, 0, dataTable);

    sheet.resumeEvent();
    sheet.resumePaint();
  }
};

/**
 * @deprecated Use createLookupObject for a more performant variant of this function
 */
export const getMappingDictionary = <T extends { [key: string]: any }>(objectsToMap: T[], key: keyof T, value: keyof T) => {
  return objectsToMap.reduce((prev, cur) => ({ ...prev, [cur[key]]: cur[value] }), {});
};

/**
 * Convert an array of objects in a dictionary using one of the properties of the object as key and another as value
 * @param items The list of objects that will be used to create the lookup
 * @param keySelector The property from these objects that will act as key
 * @param valueSelector The property from these objects that will act as value
 * @example
 * const myObjects = [{ name: "Bank", code: "BA001"}, { name: "Bank2", code: "BA002"}, { name: "Bank3", code: "BA003"}]
 * const lookup = createLookupObject(myobjects, x => x.code, x => x.name)
 *    result = {"BA001": "Bank", "BA002": "Bank2", "BA003": "Bank3"}
 */
export const createLookupObject = <T, V>(items: T[], keySelector: (x: T) => string, valueSelector: (x: T) => V): Record<string, V> =>
  Object.fromEntries(items.map(ce => [keySelector(ce), valueSelector(ce)]));

export const mapValue = <T extends { [key: string]: any }>(value: any, dictionary: T[], from: keyof T, to: keyof T) => {
  const mappingObject = createLookupObject(
    dictionary,
    x => x[from],
    x => x[to]
  );
  if (value !== null && value !== undefined && mappingObject.hasOwnProperty(value)) {
    return mappingObject[value];
  }
  return value;
};

export const mapValueColumns = <T extends { [key: string]: any }>(
  sheet: GC.Spread.Sheets.Worksheet,
  column: number,
  dictionary: T[],
  from: keyof T,
  to: keyof T
) => {
  const mappingObject = createLookupObject(
    dictionary,
    x => x[from],
    x => x[to]
  );
  for (let i = 0; i < sheet.getRowCount(); i++) {
    const cellValue = sheet.getCell(i, column) ? sheet.getCell(i, column).value() : null;

    if (cellValue !== null && cellValue !== undefined && mappingObject.hasOwnProperty(cellValue)) {
      sheet.getCell(i, column).value(mappingObject[cellValue]);
    }
  }
};

export const setBottomEmptyRows = <T extends ISpreadModel<T> | SpreadModel>(
  sheet: GC.Spread.Sheets.Worksheet,
  model: T[],
  getNew: () => T
) => {
  sheet.suspendPaint();
  sheet.suspendEvent();

  const currentModelLength = model.length;

  // Note: Make sure that the newly inserted items are flagged as empty, otherwise this may end up on infinite loop
  let indexOfLastNonEmptyElement = -1;
  for (let i = currentModelLength - 1; i >= 0; i--) {
    if (!model[i].isEmpty([model[i].nameOfSidColumn])) {
      indexOfLastNonEmptyElement = i;
      break;
    }
  }

  const difference = currentModelLength - (indexOfLastNonEmptyElement + 1);

  if (difference <= DEFAULT_EMPTY_BOTTOM_ROWS) {
    const rowsToBeAdded = DEFAULT_EMPTY_BOTTOM_ROWS - difference;
    for (let i = 0; i < rowsToBeAdded; i++) {
      model.push(getNew());
    }
  } else if (difference > DEFAULT_EMPTY_BOTTOM_ROWS) {
    model.splice(indexOfLastNonEmptyElement + DEFAULT_EMPTY_BOTTOM_ROWS, difference - DEFAULT_EMPTY_BOTTOM_ROWS);
  }

  while (model.length < DEFAULT_MIN_ROWS) {
    model.push(getNew());
  }

  sheet.setRowCount(model.length);

  const bottom2Rows = sheet.getRange(sheet.getRowCount()-2, 0, 2);
  bottom2Rows.setStyle(getDefaultCellStyle());
  bottom2Rows.locked(false);
  if (currentModelLength < DEFAULT_MIN_ROWS) {
    const emptyRows = sheet.getRange(currentModelLength, 0, sheet.getRowCount()-currentModelLength-2);
    emptyRows.setStyle(getDefaultCellStyle());
    emptyRows.locked(false);
  }
  sheet.resumeEvent();
  sheet.resumePaint();
};

export const updateStyling = <T extends (ISpreadModel<T> & IValidable) | SpreadModel>(
  sheet: GC.Spread.Sheets.Worksheet,
  model: T[],
  columnDefinition: ISpreadColumn[],
  t: any = undefined
) => {
  sheet.suspendPaint();

  sheet.getRange(0, 0, sheet.getRowCount(), sheet.getColumnCount()).clear(GC.Spread.Sheets.StorageType.style);

  const fullRange = sheet.getRange(0, 0, sheet.getRowCount(), sheet.getColumnCount());
  fullRange.cellPadding(`0 ${SPREAD_FONT_SIZE / 2}`).vAlign(GC.Spread.Sheets.VerticalAlign.center);

  if (t) {
    t.addValidators();
  }
  // Using formatter for cells to threat as text @
  columnDefinition.forEach((col, colIndex) =>
    sheet.getRange(0, colIndex, sheet.getRowCount()).formatter(col.formatter || spreadFormatter.text)
  );

  model.forEach((rt, row) => {
    // Getting range of row modified
    const rowRange = sheet.getRange(row, 0, 1, sheet.getColumnCount());

    const foreColor = rt.hasOwnProperty('depth') && (rt as any).depth > 0 ? colours.DARKER_GREY : colours.BLACK;

    if (rt.isValid) {
      if (rt[rt.nameOfSidColumn] === -1 && !rt.isEmpty()) {
        // New Entry
        rowRange.setStyle(getModifiedLightBlueCellStyle());
      } else if (rt.isModified && (!rt.isEmpty() || rt[rt.nameOfSidColumn] !== null)) {
        // Modified Entry
        rowRange.setStyle(getModifiedCellStyle());
      } else {
        // Unchanged Entry
        rowRange.setStyle(getDefaultCellStyle());
      }
    } else {
      // Invalid Entry
      rowRange.setStyle(getInvalidRowStyle(foreColor));

      rt.errorsArray.forEach(e => {
        sheet.getCell(row, e.col).setStyle(getInvalidCellStyle());
      });
    }
  });

  sheet.resumePaint();
};

/**
 * Similar to `sheet.getArray()` but also properly returns formula strings. This is used to avoid the #NAME?
 * error when pasting formulas from Excel.
 * @param info info object from GC.Spread.Sheets.Events.ClipboardPasted event
 */
export const getSheetArray = (info: {
  cellRange?: ICellRange;
  fillRange?: ICellRange;
  pasteData?: { text: string };
  sheet: GC.Spread.Sheets.Worksheet;
}) => {
  const range = info?.cellRange ?? info?.fillRange;
  const { col, colCount, row, rowCount } = range;
  const text = info.pasteData?.text ?? null;

  const pastedCells = info.sheet.getArray(row, col, rowCount, colCount);

  if (text === null) {
    return pastedCells;
  }

  /** `sheet.getArray()` returns an error object when values are pasted from Excel, in the format: {_error: '#NAME?', _code: 29} */
  const hasError = c => c?._code === 29 || c?._error || c?._calcError;

  const pastedCellsText = text.split(/\n/).map(e => e.split(/\t/));

  return pastedCells.map((r, i) => r.map((c, j) => (hasError(c) ? pastedCellsText[i][j] : c)));
};
