import { Injectable } from '@angular/core';
import { Flags, assignmentValues } from '@models/assignments/assignments';
import { ASSIGNMENT_VALUE, selectedRadioValues } from '@models/modal-screen/assignment-ruleset/account-model';
import { openAssignmentDialog } from '../../../components/modal-screen/assignment-reset-dialog/assignment-reset-dialog';
import { AccountService } from '@services/modal-screen/assignment-ruleset/account-service';
import { BodyOutputType, Toast, ToastType, ToasterService } from '@services/toaster/toaster.service';

@Injectable({
  providedIn: 'root'
})
export class SharedAssignmentService {
  public listLength: number;
  constructor(private readonly accountService: AccountService, private readonly toasterService: ToasterService) {}

  public showToast = (body: string, title: string, type: ToastType = 'success') => {
    const toast: Toast = { type, title, body, showCloseButton: true, timeout: 5000, bodyOutputType: BodyOutputType.TrustedHtml };
    this.toasterService.pop(toast);
  };

  public async checkForResetSelection(childFlag?, assignmentFlag?, selectedRadio?, idSelected?, assignment?): Promise<boolean> {
    const assignmentValue = assignment === 'ALMAccountNum' ? assignmentValues.ALMAccountNum : assignmentValues.BUDAccountNum;
    if (childFlag === Flags.Complete || childFlag === Flags.Missing) {
      const otherValues: string[] = [];

      assignmentFlag.forEach(element => {
        if (element.useOtherValue === String(assignmentValue)) {
          otherValues.push(ASSIGNMENT_VALUE[element.assignmentValue]);
        }
      });

      if (
        otherValues.length > 0 &&
        selectedRadio !== selectedRadioValues.ALMAssignmentRuleset &&
        selectedRadio !== selectedRadioValues.BUDAssignmentRuleset
      ) {
        const result = await openAssignmentDialog(assignment, otherValues);
        if (result.isConfirmed) {
          otherValues.forEach(async item => {
            const assignmentVal = ASSIGNMENT_VALUE.findIndex(el => el === item);
            try {
              const res = await this.accountService.saveAssignmentFlag(Flags.ImportedValue, idSelected, assignmentVal, null).toPromise();
              // Handle success using 'res'
            } catch (err) {
              this.showToast(`Error saving ${assignment} Account`, 'Error', 'error');
            }
          });
          return true;
        } else {
          return false;
        }
      }
    }
    return true;
  }

  public getAvailableDimensions(index: number, selectedValue: string, dimensionsList, selectedValues): object {
    // If a value is already selected at this index, add it back to dropdownData
    if (selectedValues[index]) {
      dimensionsList.push(selectedValues[index]);
    }

    // Remove the newly selected value from dropdownData
    dimensionsList = dimensionsList.filter(item => item !== selectedValue);

    // Update the selected value at this index
    selectedValues[index] = selectedValue;

    dimensionsList.sort((a, b) => {
      return a.text.localeCompare(b.text);
    });

    return dimensionsList;
  }

  public onRemoveTerm(indexToRemoved, dimesionsList, selectedValues): object {
    if (selectedValues[indexToRemoved]) {
      dimesionsList.push(selectedValues[indexToRemoved]);
    }

    dimesionsList.sort((a, b) => {
      return a.text.localeCompare(b.text);
    });

    return dimesionsList;
  }

  public findMatchedElement(assignment, dataSourceId): number {
    const matchedElement = assignment.find(element => element.DataSourceID === dataSourceId);

    if (matchedElement) {
      return matchedElement.DataSourceTypeID;
    }
  }
}
