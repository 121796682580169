<button
  type="button"
  class="btn_normal btn_inverse btn_big_icon"
  id="btn_question"
  (click)="loadData()"
  [matTooltip]="'Click this button to open our User Guide in a new tab'"
  [matTooltipPosition]="'below'"
  [matTooltipShowDelay]="300"
>
  <span class="btn_icon"><i class="fa-solid fa-circle-question"></i></span>
  <span class="btn_text hidden_text">Download Manual</span>
</button>
