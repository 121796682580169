
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ImportLog } from '@models/import-log/import-log';
import { ImportLogService } from '@services/import-log/import-log.service';
import { BodyOutputType, Toast, ToastType, ToasterService } from '@services/toaster/toaster.service';
import { fadeInOut, filterAnimationTable, highlight } from '@shared/animations';
import Swal from 'sweetalert2';
import { State } from '@progress/kendo-data-query';
import { PagerSettings } from '@progress/kendo-angular-grid';
import { AsOfDate } from '@models/as-of-date/as-of-date';
import { DataSourcesService } from '@services/data-sources/data-sources.service';
import { DatePipe, formatDate } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-data-import-log',
  templateUrl: './data-import-log.component.html',
  styleUrls: ['./data-import-log.component.scss'],
  animations: [highlight(500), filterAnimationTable(350), fadeInOut(500)]
})

export class DataImportLogComponent implements OnInit, OnDestroy {
  public displayStatsColumn: boolean = true;
  public displayFileNameColumn: boolean = true;
  public displayTabNameColumn: boolean = true;
  public importDateFilter: Date = null;
  public asOfDateFilter: Date = null;
  public dataSourceFilter: string[] = [];
  public uploadedByFilter: string[] = [];
  public statusFilter: string[] = [];
  public filtersModified: boolean = false;
  public isLoading: boolean;
  public importLogs: ImportLog[] = [];
  public filteredImportLogs: ImportLog[] = [];
  public dataSourceOptions: string[] = [];
  public uploadedByOptions: string[] = [];
  public statusOptions: string[] = [];
  private readonly defaultPagerSettings: PagerSettings = { buttonCount: 5, pageSizes: [20, 50, 100] };
  public pagerSettings: boolean | PagerSettings = false;

  public state: State = {
    skip: 0,
    take: 20,
    group: [],
    filter: { logic: 'and', filters: [] },
  };

  constructor(private importLogService: ImportLogService,
    private readonly toasterService: ToasterService,
    private readonly dataSourcesService: DataSourcesService,
    private datePipe: DatePipe,
    private router: Router) { }

  public async ngOnInit(): Promise<void> {
    this.isLoading = true;
    await this.getImportLogs();
    this.populateFilterOptions();
    this.pagerSettings = this.defaultPagerSettings.pageSizes[0] < this.filteredImportLogs.length ? this.defaultPagerSettings : false;
    this.isLoading = false;
  }

  public ngOnDestroy(): void {
  }

  private getImportLogs = async () => {
    try {
      this.importLogs = await this.importLogService.getImportLogs().toPromise();

      this.importLogs.sort((a, b) => {
        return new Date(b.ImportDateTimeCreated).getTime() - new Date(a.ImportDateTimeCreated).getTime();
      });
      this.filteredImportLogs = this.importLogs;

    } catch (err) {
      this.showErrorToast(`No import logs found`, 'Error');
      console.warn(err);
      this.importLogs = [];
      this.filteredImportLogs = [];
    }
  }

  private readonly showToast = (body: string, title: string = '', type: ToastType = 'success', timeout = 5000) => {
    const toast: Toast = {
      type,
      title,
      body: body.replace(/\n/g, '<br />'),
      bodyOutputType: BodyOutputType.TrustedHtml,
      showCloseButton: true,
      timeout,
      progressBar: true,
      progressBarDirection: 'increasing'
    };
    return this.toasterService.pop(toast);
  };

  private showErrorToast(body: string, title: string = '', timeout: number = 5000) {
    this.showToast(body, title, 'error', timeout);
  }

  protected async toggleStatsColumn() {
    this.displayStatsColumn = !this.displayStatsColumn;
  }

  protected async toggleFileNameColumn() {
    this.displayFileNameColumn = !this.displayFileNameColumn;
  }

  protected async toggleTabNameColumn() {
    this.displayTabNameColumn = !this.displayTabNameColumn;
  }

  public filterChanged() {
    this.filtersModified = (
      this.importDateFilter !== null ||
      this.asOfDateFilter !== null ||
      this.dataSourceFilter !== null ||
      this.uploadedByFilter !== null ||
      this.statusFilter !== null
    );
  }

  public applyFilters(): void {
    this.filteredImportLogs = this.importLogs.filter(log => {
      const logImportDate = new Date(log.ImportDate);
      const logAsOfDate = new Date(log.AsOfDate);
      const importDateFilter = this.importDateFilter ? new Date(this.importDateFilter) : null;
      const asOfDateFilter = this.asOfDateFilter ? new Date(this.asOfDateFilter) : null;

      return (!importDateFilter || this.isSameDate(logImportDate, importDateFilter))
        && (!asOfDateFilter || this.isSameDate(logAsOfDate, asOfDateFilter))
        && (this.dataSourceFilter.length === 0 || this.dataSourceFilter.includes(log.DataSourceDescription))
        && (this.uploadedByFilter.length === 0 || this.uploadedByFilter.includes(log.UploadedBy))
        && (this.statusFilter.length === 0 || this.statusFilter.includes(log.OverallImportStatus));
    });
    this.filtersModified = false;
  }

  private isSameDate(date1: Date, date2: Date): boolean {
    return date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate();
  }

  private populateFilterOptions(): void {
    this.dataSourceOptions = this.getDistinctValues('DataSourceDescription');
    this.uploadedByOptions = this.getDistinctValues('UploadedBy');
    this.statusOptions = this.getDistinctValues('OverallImportStatus');
  }

  private getDistinctValues(propertyName: string): string[] {
    const values = this.importLogs.map(log => log[propertyName]);
    const distinctValuesSet = new Set(values);
    const distinctValuesArray = Array.from(distinctValuesSet);
    distinctValuesArray.sort((a, b) => a.localeCompare(b));
    return distinctValuesArray;
  }

  public async ShowImportFailReasonPopup(failureReason: string) {
    // Regular expression to match text between <strong> tags
    const strongRegex = /<strong>(.*?)<\/strong>/;

    // Extract the text between <strong> tags using the regular expression
    const match = failureReason.match(strongRegex);

    // Use the matched text as the title, or fallback to a default title
    const title = match ? match[1] : 'Import Failure';

    // Remove the matched text from the failure reason string
    const cleanedFailureReason = failureReason.replace(strongRegex, '');

    // Display Swal popup with extracted title and cleaned failure reason
    await Swal.fire({
      title: `${title}`,
      icon: 'question',
      iconColor: '#9de0f6',
      html: `${cleanedFailureReason}`,
      heightAuto: false,
      confirmButtonText: `Close`,
    });
  }

  public onArrowRightClicked(importLogRow: any) {
    try {
      const date = new Date(importLogRow.AsOfDate);
      const lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      const formattedDate = this.datePipe.transform(lastDayOfMonth, 'yyyy-MM-dd');
      const asOfDateToSet = new AsOfDate({ Date: formattedDate });

      if (asOfDateToSet.Date) {
        this.dataSourcesService.selectedAsOfDate = asOfDateToSet;
        this.router.navigate(['/app-data-import']);
      }
    } catch (error) {
      console.error('Error occurred while processing AsOfDate:', error);
    }
  }

  public showViewDataImportTooltip(OverallImportStatus: string): string {
    if (OverallImportStatus === 'Imported' || OverallImportStatus === 'Awaiting Import') {
      return 'View Data Import';
    }
  }

  public getFormatDate(date: string | Date, format: string = 'MMMM y'): string {
    return formatDate(date ? `${date}Z` : null, format, 'en-US');
  }
}
