import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigProvider } from '@app/app.config.provider';
import { AppConfigAPI } from '@models/appconfig';
import { GlobalSetting, GlobalSettingBase } from '@models/global-settings/global-setting';
import { DataverseReconSettingsOptions } from '@models/global-settings/recon-settings-options';
import { LocalStorageKeys } from '@models/storage/local-storage-keys';
import { ICanComponentDiscardLocalStorageAndDeactivate } from '@services/auth-guard/can-component-discard-localstorage-guard.service';
import { LocalStorageRepository } from '@services/local-storage/local-storage';
import { deepClone } from '@shared/clone';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class GlobalSettingsService extends LocalStorageRepository implements ICanComponentDiscardLocalStorageAndDeactivate {
  private environment: AppConfigAPI;
  public globalSettingsURL: string;
  private dataverseSettings: GlobalSetting[] = [];
  private initialDatabaseCopy: GlobalSetting[];
  private latestDatabaseCopy: GlobalSetting[];

  constructor(private readonly http: HttpClient, private readonly appConfig: AppConfigProvider) {
    super(LocalStorageKeys.GLOBAL_SETTINGS, 'Global Settings');

    this.appConfig.environment.subscribe(env => {
      this.environment = env.API;
      this.globalSettingsURL = `${this.environment.DIMENSIONS}/api/global-settings/dataverse`;
    });
  }

  public getLatestDatabaseCopy(): GlobalSetting[] {
    return this.latestDatabaseCopy;
  }

  public setDatabaseGlobalSettingCopy(globalSetting: GlobalSetting[]) {
    this.initialDatabaseCopy = deepClone(globalSetting);
  }

  public isGlobalSettingEqualToDBCopy(setting: GlobalSetting): boolean {
    const itemInDatabase = this.initialDatabaseCopy?.find(other => other.settingName === setting.settingName);

    const comparisonKeys = ['settingValue'];

    // Rest of properties
    if (itemInDatabase && setting) {
      for (const [key, value] of Object.entries(setting)) {
        if (comparisonKeys.includes(key) && value !== itemInDatabase[key]) {
          // any of the keys in comparisonKeys has changed
          return false;
        }
      }
      // no key has changed
      return true;
    }
    // OnDatabase or settingValue is not equal
    return false;
  }

  private mapApiResponseIntoGlobalSettings = (settings: GlobalSetting[]) => settings.map(setting => new GlobalSetting(setting));

  public getGlobalSettings = (): Observable<GlobalSetting[]> =>
    this.http.get<GlobalSetting[]>(this.globalSettingsURL).pipe(map(this.mapApiResponseIntoGlobalSettings));

  public getReconSettingOptions = (): Observable<GlobalSetting[]> =>
    this.http.get<GlobalSetting[]>(`${this.globalSettingsURL}/recon`).pipe(map(this.mapApiResponseIntoGlobalSettings));

  public async getReconSettingOption(settingName: DataverseReconSettingsOptions): Promise<string> {
    await this.getReconSettingOptions()
      .toPromise()
      .then(settings => (this.dataverseSettings = settings));
    return this.dataverseSettings.find(x => x.settingName === settingName).settingValue;
  }

  public getGlobalSettingLocalStorage(): GlobalSetting[] {
    let globalSetting$: GlobalSetting[] = [];
    const globalSettingLocalStorage = this.fetchFromLocalStorage<GlobalSetting[]>();

    const shouldRefetch = !globalSettingLocalStorage?.payload || this.isLocalStorageExpired();

    if (shouldRefetch) {
      this.clearFromLocalStorage();
    } else {
      globalSetting$ = globalSettingLocalStorage.payload;
    }

    return globalSetting$;
  }

  private async setLatestDatabaseCopy() {
    this.latestDatabaseCopy = await this.getReconSettingOptions().toPromise();
  }

  public saveDataverseGLobalSettings = (settings: GlobalSettingBase[]): Promise<GlobalSetting[]> =>
    this.http.post<GlobalSetting[]>(this.globalSettingsURL, settings).toPromise();

  public async hasDBChangedDuringSession(): Promise<boolean> {
    const initialGSettingCopy = this.initialDatabaseCopy;
    await this.setLatestDatabaseCopy();
    const latestGSettingCopy = this.latestDatabaseCopy;

    for (const setting of initialGSettingCopy) {
      const latestSetting = latestGSettingCopy.find(x => x.settingName === setting.settingName);
      if (latestSetting && setting.settingValue !== latestSetting.settingValue) {
        return true;
      }
    }
    return false;
  }
}
