<!-- Deletion-Button -->

<button
  class="deletion_button btn_normal btn_red delete-user-field fadeinslow"
  *ngIf="isVisible()"
  title="{{ title }}"
  (click)="deleteUnalocatedAccounts()"
>
  {{ this.btnTxt }}
</button>
