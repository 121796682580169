import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { filterAnimation, highlight } from '@shared/animations';
import { round } from '@shared/number';
import { kebabCaseToTitleCase } from '@shared/strings';

@Component({
  selector: 'emp-placeholder',
  templateUrl: './placeholder.component.html',
  styleUrls: ['./placeholder.component.scss'],
  animations: [highlight(1000), filterAnimation(300)]
})
export class PlaceholderComponent implements OnInit, OnDestroy {
  @Input() public pageTitle: string = null;

  // Grid is n x n;
  protected minN = 10;
  protected maxN = 20;
  protected interval: NodeJS.Timeout = null;
  protected prevOpacity = '0.5';

  public breadCrumbs = '';
  public routeTitle = '';
  public n = 1;
  public cellNumber = -1;
  public elements: { value: number; angle: number }[] = [];

  constructor(private readonly activatedRoute: ActivatedRoute) {}

  public ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      const { id1, id2, id3, id4, id5 } = params;

      const tokens = [id1, id2, id3, id4, id5].filter(t => t).map(kebabCaseToTitleCase);

      this.routeTitle = tokens.pop();
      this.breadCrumbs = tokens.join(' > ');

      this.n = Math.floor(Math.random() * (this.maxN - this.minN)) + this.minN;
      while (this.n ** 2 === this.elements.length) {
        this.n = Math.floor(Math.random() * (this.maxN - this.minN)) + this.minN;
      }

      this.elements = Array.from({ length: this.n ** 2 }, (_, i) => ({
        value: round(Math.floor(Math.random() * 8) * 0.1 + 0.3),
        angle: (this.elements?.[i - 1]?.angle ?? 0) + round(Math.random() * 1.5 - 0.75, 1)
      }));

      clearInterval(this.interval);

      this.interval = setInterval(() => {
        const indices = Array.from({ length: this.n }, () => Math.floor(Math.random() * this.elements.length));
        indices.forEach(i => {
          const element = this.elements[i];

          let newValue = element.value + round(Math.random() * 1.5 - 0.75, 1);
          let newAngle = element.angle + round(Math.random() * 2 - 1, 1);

          if (newAngle < -5) {
            newAngle = -5;
          }
          if (newAngle > 5) {
            newAngle = 5;
          }
          if (newValue < 0.2) {
            newValue = 0.2;
          }
          if (newValue > 0.8) {
            newValue = 0.8;
          }

          element.angle = newAngle;
          element.value = newValue;
        });
      }, 2000);
    });
  }

  public ngOnDestroy(): void {
    clearInterval(this.interval);
  }
}
