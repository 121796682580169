export interface IUserLevel {
  level_id: UserLevel;
  level_text: string;
}

export interface IUserLevelClient extends IUserLevel {
  client_id: number;
  client_name: string;
}

export enum EmpyreanApplicationName {
  Liquidity = 'LIQUIDITY',
  Deposits = 'DEPOSITS',
  UserManager = 'USERMANAGER',
  Dimensions = 'DIMENSIONS',
  Planning = 'PLANNING',
  Payroll = 'PAYROLL',
  Profitability = 'PROFITABILITY'
}

/**
 * Matches Identity's dbo.Levels
 */
export enum UserLevel {
  SystemAdministrator = 1001,

  LiquidityAdministrator = 1002,
  LiquidityUser = 1003,
  LiquidityReader = 1004,

  DepositsAdministrator = 2001,
  DepositsUser = 2002,
  DepositsReader = 2003,

  DataverseAdministrator = 3001,
  DataverseReader = 3002,

  ProfitabilityAdministrator = 4001,
  ProfitabilityUser = 4002,
  ProfitabilityReader = 4003,

  PlanningAdministrator = 5001,
  PlanningContributor = 5002, // equivalent to 'Planning User'
  PlanningReader = 5003,

  HRSystemAdministrator = 7000,
  PayrollAdministrator = 7001,
  PayrollContributor = 7002,

  BiReportCreator = 8001,
  BiReportViewer = 8002,

  OrganizationalProfitabilityAdministrator = 13001,
  OrganizationalProfitabilityUser = 13002
}

export const isUserLevelForApplication = (applicationName: EmpyreanApplicationName, levels: IUserLevel[]) => {
  return levels.some(level => UserLevelApplicationLinks[applicationName].includes(level.level_id));
};

export const getUserLevelForApplication = (applicationName: EmpyreanApplicationName, levels: IUserLevel[]) => {
  return levels.find(level => UserLevelApplicationLinks[applicationName].includes(level.level_id));
};

export const dvOnlyUserLevels: UserLevel[] = [UserLevel.DataverseAdministrator, UserLevel.DataverseReader] as UserLevel[];

export const profUserLevels: UserLevel[] = [
  UserLevel.ProfitabilityAdministrator,
  UserLevel.ProfitabilityReader,
  UserLevel.ProfitabilityUser
] as UserLevel[];

export const depositLevels: UserLevel[] = [UserLevel.DepositsAdministrator] as UserLevel[];

const UserLevelApplicationLinks: { [key in EmpyreanApplicationName]: number[] } = {
  [EmpyreanApplicationName.UserManager]: [UserLevel.SystemAdministrator, UserLevel.HRSystemAdministrator],
  [EmpyreanApplicationName.Liquidity]: [UserLevel.LiquidityAdministrator, UserLevel.LiquidityUser, UserLevel.LiquidityReader],
  [EmpyreanApplicationName.Deposits]: [UserLevel.DepositsAdministrator, UserLevel.DepositsUser, UserLevel.DepositsReader],
  [EmpyreanApplicationName.Dimensions]: [UserLevel.DataverseAdministrator, UserLevel.DataverseReader, UserLevel.DepositsAdministrator],
  [EmpyreanApplicationName.Planning]: [
    UserLevel.PlanningAdministrator,
    UserLevel.PlanningContributor,
    UserLevel.PlanningReader,
    UserLevel.PayrollAdministrator,
    UserLevel.PayrollContributor
  ],
  [EmpyreanApplicationName.Payroll]: [UserLevel.PayrollAdministrator, UserLevel.PayrollContributor],
  [EmpyreanApplicationName.Profitability]: [
    UserLevel.ProfitabilityAdministrator,
    UserLevel.ProfitabilityReader,
    UserLevel.ProfitabilityUser,
    UserLevel.OrganizationalProfitabilityAdministrator,
    UserLevel.OrganizationalProfitabilityUser
  ]
};
