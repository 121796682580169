import {
  AfterViewInit,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { AsOfDate } from '@models/as-of-date/as-of-date';
import { MAX_IDLE_TIME } from '@models/global-settings/global-setting';
import { Customer } from '@models/user/customer';
import { DataSourcesService } from '@services/data-sources/data-sources.service';
import { GlobalSettingsService } from '@services/global-settings/global-settings.service';
import { NavigationService } from '@services/navigation/navigation.service';
import { OpenIdConnectService } from '@services/openid/openid-connect.service';
import { TimeoutService } from '@services/openid/timeout.service';
import { ResizeService } from '@services/resize/resize.service';
import { UserService } from '@services/user/user.service';
import { fadeInOut } from '@shared/animations';
import { Subscription } from 'rxjs';
import { NavigationElementComponent } from './navigation-element/navigation-element.component';
import { navigationElements } from './navigation-elements';
import { NavigationHeaderComponent } from './navigation-header/navigation-header.component';
import { NavigationElement } from './navigation-interfaces';
import { NavigationProfileComponent } from './navigation-profile/navigation-profile.component';

@Component({
  selector: 'emp-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  animations: [fadeInOut(350)]
})
export class NavigationComponent implements AfterViewInit, OnInit, OnDestroy {
  public navigationElements: NavigationElement[] = null;
  public isHover = false;
  public customerName: string;

  private readonly subscriptions = new Subscription();

  @ViewChildren(NavigationElementComponent) public childrenElements: QueryList<NavigationElementComponent>;
  @ViewChild(NavigationProfileComponent) public userProfileComponent: NavigationProfileComponent;
  @ViewChild(NavigationHeaderComponent) public headerComponent: NavigationHeaderComponent;

  @ViewChild('#btn_quicklinks') public quicklinksBtn: ElementRef;

  @HostBinding('class.active')
  public get active(): boolean {
    return this.pinned || this.isHover || this.isDateSelectorOpen;
  }

  @HostBinding('class.pinned') public pinned = localStorage.getItem('nav-bar-pinned') === 'true';
  @HostBinding('id') public id = 'side_navigation';

  @HostListener('mouseenter') public onMouseEnter(): void {
    this.isHover = true;
    this.navigationService.setNavigationBarActive(this.active);
  }
  @HostListener('mouseleave') public onMouseLeave(): void {
    this.isHover = false;
    this.navigationService.setNavigationBarActive(this.active);

    if (!this.active) {
      this.unloadPopups();
    }
  }

  public get isDateSelectorOpen() {
    return this.navigationService.isDateSelectorOpen$.value;
  }

  constructor(
    private readonly resizeService: ResizeService,
    private readonly openIDConnectService: OpenIdConnectService,
    private readonly navigationService: NavigationService,
    private readonly userService: UserService,
    private readonly dataSourcesService: DataSourcesService,
    private readonly globalSettingsService: GlobalSettingsService,
    private readonly timeoutService: TimeoutService
  ) {}

  public async ngOnInit(): Promise<void> {
    this.openIDConnectService.userLoaded$.subscribe(async userLoaded => {
      this.subscriptions.add(
        this.globalSettingsService.getGlobalSettings().subscribe(settings => {
          if (settings.some(setting => setting.settingName === MAX_IDLE_TIME)) {
            const idleTime = settings.find(setting => setting.settingName === MAX_IDLE_TIME).settingValue;
            this.timeoutService.setMaxIdleTime(Number(idleTime) * 60);
          }
        })
      );

      if (!this.openIDConnectService.isDepositsAdminOnly()) {
        this.navigationService.setExpandStatus(navigationElements);

        this.navigationElements = navigationElements;
        this.navigationService.navigationElements = navigationElements;
      } else {
        this.navigationService.navigationElements = this.navigationElements = [] as NavigationElement[];
      }

      const customer: Customer = await this.userService.getCustomer().toPromise();
      this.customerName = customer.customerName;
    });

    this.navigationService.isDateSelectorOpen$.subscribe(_ => {
      this.navigationService.setNavigationBarActive(this.active);
    });
  }

  public ngAfterViewInit(): void {
    for (const child of this.childrenElements.toArray()) {
      if (child.popUpOpened) {
        const popUpOpenedEvent$ = child.popUpOpened.subscribe(component => {
          this.unloadPopups(component);
        });
        this.subscriptions.add(popUpOpenedEvent$);
      }
    }
    if (this.userProfileComponent?.popUpOpened) {
      this.subscriptions.add(
        this.userProfileComponent.popUpOpened.subscribe(component => {
          this.unloadPopups(component);
        })
      );
    }

    this.subscriptions.add(this.headerComponent.pinnedEvent.subscribe(this.onPinnedChanged));

    if (this.headerComponent?.popUpOpened) {
      this.subscriptions.add(
        this.headerComponent.popUpOpened.subscribe(component => {
          this.unloadPopups(component);
        })
      );
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public unloadPopups(componentToKeep?): void {
    if (componentToKeep !== this.userProfileComponent && this.userProfileComponent.isOpen) {
      this.userProfileComponent.isOpen = false;
    }
    if (componentToKeep !== this.headerComponent && this.headerComponent.isOpen) {
      this.headerComponent.isOpen = false;
    }
  }

  private readonly onPinnedChanged = (pinned: boolean): void => {
    if (this.pinned !== pinned) {
      this.pinned = pinned;
      this.resizeService.emitResize();
      this.navigationService.setNavigationBarActive(this.active);
    }
  };

  public onDateSelected(date: AsOfDate): void {
    if (date) {
      this.dataSourcesService.selectedAsOfDate = date;
    }
  }
}
