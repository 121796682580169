import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { RouterModule } from '@angular/router';
import { AddAsOfDateComponent } from '@components/model-data/data-import/add-asofdate/add-asofdate.component';
import { AppAddAsOfDateDirective } from '@components/model-data/data-import/add-asofdate/add-asofdate.directive';
import { NotificationsModule } from '@components/notifications/notifications.module';
import { ApplicationSwitcherModule } from '@core/navigation/application-switcher/application-switcher.module';
import { UserProfileComponent } from '@core/navigation/user-profile/user-profile.component';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { PICK_FORMATS } from '@shared/date.adapter';
import { AsOfDateSelectionComponent } from './model-data/as-of-date-selection/as-of-date-selection.component';
import { ModelDataNavigationComponent } from './model-data/model-data-navigation/model-data-navigation.component';
import { NavigationDividerComponent } from './navigation-divider/navigation-divider.component';
import { NavigationElementModalComponents } from './navigation-element-modal/navigation-element-modal.component';
import { NavigationElementComponent } from './navigation-element/navigation-element.component';
import { NavigationHeaderComponent } from './navigation-header/navigation-header.component';
import { NavigationModalHeaderComponent } from './navigation-modal-header/navigation-modal-header.component';
import { NavigationModalComponent } from './navigation-modal/navigation-modal.component';
import { NavigationProfileComponent } from './navigation-profile/navigation-profile.component';
import { NavigationSectionComponent } from './navigation-section/navigation-section.component';
import { NavigationComponent } from './navigation.component';
import { UserManualsDownloadModule } from './user-manuals/user-manuals-download.module';

@NgModule({
  declarations: [
    AsOfDateSelectionComponent,
    AddAsOfDateComponent,
    AppAddAsOfDateDirective,
    ModelDataNavigationComponent,
    NavigationComponent,
    NavigationDividerComponent,
    NavigationElementComponent,
    NavigationHeaderComponent,
    NavigationProfileComponent,
    UserProfileComponent,
    NavigationSectionComponent,
    NavigationProfileComponent,
    NavigationHeaderComponent,
    NavigationModalComponent,
    NavigationModalHeaderComponent
  ],
  exports: [NavigationComponent],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: PICK_FORMATS }
  ],
  imports: [
    ApplicationSwitcherModule,
    CommonModule,
    DropDownsModule,
    FormsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
    RouterModule,
    TooltipModule,
    NavigationElementModalComponents,
    NotificationsModule,
    UserManualsDownloadModule
  ]
})
export class NavigationModule {}
