<app-page-validations></app-page-validations>
<div class="container">
  <div class="radio-buttons">
    <label>
      <input
        type="radio"
        name="radioBudGroup"
        [(ngModel)]="selectedBudRadio"
        value="ImportedBudValue"
        (change)="onBudRadioChange()"
        checked
      />
      Use Imported Value
    </label>
    <label>
      <input
        type="radio"
        name="radioBudGroup"
        [(ngModel)]="selectedBudRadio"
        value="AssignmentBudRuleset"
        (change)="onBudRadioChange()"
        (click)="onAddYieldCurveClicked()"
      />
      Use Assignment Ruleset
    </label>
    <label>
      <input type="radio" name="radioBudGroup" [(ngModel)]="selectedBudRadio" value="AssignmentBudValue" (change)="onBudRadioChange()" />
      Use Other Assignment
    </label>
    <div *ngIf="selectedBudRadio === 'AssignmentBudValue'">
      <kendo-dropdownlist
        type="number"
        class="otherAssignment"
        min="0"
        step="1"
        [data]="setFlagData"
        textField="text"
        valueField="value"
        style="margin-left: 10px"
        (valueChange)="onAssignmentSelected($event)"
        [defaultItem]="this.defaultItem"
        [disabled]="this.setFlagData.length === 0"
        [matTooltip]="this.setFlagData.length === 0 ? 'There is no other Assignemnt field using an Assignment Ruleset.' : null"
        [matTooltipPosition]="'below'"
        [matTooltipShowDelay]="500"
      ></kendo-dropdownlist>
    </div>
    <label>
      <input type="radio" name="radioBudGroup" [(ngModel)]="selectedBudRadio" value="SingleBudValue" (change)="onBudRadioChange()" /> Use
      Single Value
    </label>
    <form [formGroup]="textFormGroup" *ngIf="selectedBudRadio === 'SingleBudValue' && enumDataSourceType.GL !== this.datasourcetypeid">
      <label for="numericField"></label>
      <input
        type="text"
        id="numericField"
        formControlName="numericField"
        [ngClass]="{
          error:
            textFormGroup.get('numericField').invalid &&
            (textFormGroup.get('numericField').dirty || textFormGroup.get('numericField').touched)
        }"
      />
      <div *ngIf="textFormGroup.get('numericField').hasError('pattern')">
        <span class="error-message">Invalid entry. Incorrect BUDAccountNum Assignment, value must be a number.</span>
      </div>
      <div *ngIf="textFormGroup.get('numericField').hasError('maxlength')">
        <span class="error-message">Invalid entry. BUDAccountNum Assignment, cannot exceed 15 characters in length.</span>
      </div>
    </form>
  </div>

  <div class="button">
    <button
      class="btn btn_normal btn_blue btn_wide"
      [disabled]="disableEditButton"
      (click)="onAddYieldCurveClicked()"
      *ngIf="enumDataSourceType.GL !== this.datasourcetypeid"
    >
      <span>EDIT DIMENSIONS</span>
    </button>
  </div>
</div>

<ng-container *ngIf="enumDataSourceType.GL === this.datasourcetypeid">
  <kendo-tabstrip [keepTabContent]="true" class="kendo-container">
    <kendo-tabstrip-tab
      [selected]="true"
      [class.next]="subTab[2].pageValidation?.IsSetup === false"
      [class.inactive]="subTab[2].pageValidation?.IsSetup === null"
      [class.warn]="subTab[2].pageValidation?.IsValid === false"
    >
      <ng-template kendoTabTitle>
        <div
          kendoTooltip
          [title]="subTab[2].tooltip"
          class="flex-container horizontal"
          [class.warn]="subTab[2].pageValidation?.IsValid === false"
          [class.next]="subTab[2].pageValidation?.IsSetup === false"
        >
          <div class="tab-icon left" *ngIf="subTab[2].pageValidation?.IsValid === false">
            <i class="fas fa-triangle-exclamation"></i>
          </div>
          <span class="tab-title"> Balance Sheet </span>
          <div class="tab-icon right" *ngIf="subTab[2].pageValidation?.IsSetup === false">
            <i class="fas fa-wrench"></i>
          </div>
          <div class="tab-icon right" *ngIf="subTab[2].pageValidation && subTab[2].pageValidation.IsSetup === null">
            <i class="fas fa-lock"></i>
          </div>
        </div>
      </ng-template>
      <ng-template kendoTabContent>
        <bud-balance-sheet
          [selectedBudRadioBS]="selectedBudRadio"
          [localStorageKey]="incomeLocalStorageKey"
          [dbFlag]="dbFlag"
          [idSelected]="idSelected"
          [otherAssignmentValue]="otherAssignmentValue"
          [assignmentFlag]="assignmentFlag"
          [disableSaveButton]="disableSaveButton"
        >
        </bud-balance-sheet>
      </ng-template>
    </kendo-tabstrip-tab>

    <kendo-tabstrip-tab
      [class.next]="subTab[3].pageValidation?.IsSetup === false"
      [class.inactive]="subTab[3].pageValidation?.IsSetup === null"
      [class.warn]="subTab[3].pageValidation?.IsValid === false"
    >
      <ng-template kendoTabTitle>
        <div
          kendoTooltip
          [title]="subTab[3].tooltip"
          class="flex-container horizontal"
          [class.warn]="subTab[3].pageValidation?.IsValid === false"
          [class.next]="subTab[3].pageValidation?.IsSetup === false"
        >
          <div class="tab-icon left" *ngIf="subTab[3].pageValidation?.IsValid === false">
            <i class="fas fa-triangle-exclamation"></i>
          </div>
          <span class="tab-title"> Income Statement </span>
          <div class="tab-icon right" *ngIf="subTab[3].pageValidation?.IsSetup === false">
            <i class="fas fa-wrench"></i>
          </div>
          <div class="tab-icon right" *ngIf="subTab[3].pageValidation && subTab[3].pageValidation.IsSetup === null">
            <i class="fas fa-lock"></i>
          </div>
        </div>
      </ng-template>
      <ng-template kendoTabContent>
        <bud-income-statement
          [selectedBudRadioIS]="selectedBudRadio"
          [localStorageKey]="incomeLocalStorageKey"
          [dbFlag]="dbFlag"
          [idSelected]="idSelected"
          [otherAssignmentValue]="otherAssignmentValue"
          [assignmentFlag]="assignmentFlag"
          [disableSaveButton]="disableSaveButton"
        ></bud-income-statement>
      </ng-template>
    </kendo-tabstrip-tab>
  </kendo-tabstrip>
</ng-container>

<ng-container
  class="prompt-message-container flex-container centered vertical full-height"
  *ngIf="selectedBudRadio === 'ImportedBudValue' && enumDataSourceType.GL !== this.datasourcetypeid"
>
  <h3 class="prompt-title">Assignment Ruleset not Enabled.</h3>
  <p class="prompt-message">This field will be populated based on the imported files for {{ this.selectedNameMessage }}.</p>
</ng-container>

<ng-container
  class="prompt-message-container flex-container centered vertical full-height"
  *ngIf="selectedBudRadio === 'AssignmentBudRuleset' && enumDataSourceType.GL !== this.datasourcetypeid"
>
  <div><app-invalid-rows [invalidRowCount]="invalidRows.length" (click)="onClickInvalidRow()"></app-invalid-rows></div>
  <div class="spreadsheet-container fadein" (mousemove)="onMouseMove($event)">
    <gc-spread-sheets #spreadDiv class="spreadsheet fadein" (workbookInitialized)="workBookInit($event)"></gc-spread-sheets>
  </div>
</ng-container>

<ng-container
  class="prompt-message-container flex-container centered vertical full-height"
  *ngIf="selectedBudRadio === 'AssignmentBudValue' && enumDataSourceType.GL !== this.datasourcetypeid"
>
  <h3 class="prompt-title">Assignment Ruleset not Enabled.</h3>
  <p class="prompt-message">This field will be populated based on the selected Other Assignment.</p>
</ng-container>

<ng-container
  class="prompt-message-container flex-container centered vertical full-height"
  *ngIf="selectedBudRadio === 'SingleBudValue' && enumDataSourceType.GL !== this.datasourcetypeid"
>
  <h3 class="prompt-title">Assignment Ruleset not Enabled.</h3>
  <p class="prompt-message">This field will be populated based on the inputted Single Value.</p>
</ng-container>

<div footer class="footer" *ngIf="enumDataSourceType.GL !== this.datasourcetypeid">
  <button id="discard-button" class="btn" (click)="closeModal()">Close</button>
  <button id="save-button" class="btn" (click)="onClickSave()" [disabled]="isSaveButtonDisabled()">Save</button>
</div>

<app-page-validations (tabDataEvent)="handleDataEvent($event)"></app-page-validations>
