import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigProvider } from '@app/app.config.provider';
import {
  AZURE_STORAGE_SETTING_GROUP,
  CENTRALIZED_SAS_SETTING_NAME,
  CENTRALIZED_STORAGE_ACCOUNT_SETTING_NAME,
  CENTRALIZED_URL_SETTING_NAME,
  DATAVERSE_MANUAL_FILE_SETTING_NAME,
  IBlobStorageSettings,
  USER_MANUALS_CONTAINER_SETTING_NAME,
  USER_MANUALS_SETTING_GROUP
} from '@models/azure-storage-settings/azure-storage-setting';
import { GlobalSetting } from '@models/global-settings/global-setting';
import { map, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CustomerConfigurationService {
  constructor(private readonly http: HttpClient, private readonly appConfig: AppConfigProvider) {}

  public getBlobStorageSettings() {
    return this.appConfig.environment.pipe(
      mergeMap(env => {
        return this.http.get<GlobalSetting[]>(`${env.API.CUSTOMERCONFIG}/api/GlobalSettings/All`);
      }),
      map(res => {
        const azureStorageSettings = res.filter(setting =>
          [AZURE_STORAGE_SETTING_GROUP, USER_MANUALS_SETTING_GROUP].includes(setting.settingsGroup)
        );
        if (azureStorageSettings.length > 0) {
          const storageSettings: IBlobStorageSettings = {
            SAS: azureStorageSettings.find(setting => setting.settingName === CENTRALIZED_SAS_SETTING_NAME)?.settingValue,
            Url: azureStorageSettings.find(setting => setting.settingName === CENTRALIZED_URL_SETTING_NAME)?.settingValue,
            StorageAccount: azureStorageSettings.find(setting => setting.settingName === CENTRALIZED_STORAGE_ACCOUNT_SETTING_NAME)
              ?.settingValue,
            Container: azureStorageSettings.find(setting => setting.settingName === USER_MANUALS_CONTAINER_SETTING_NAME)?.settingValue,
            Use_Azure: true,
            File_Location: azureStorageSettings.find(setting => setting.settingName === DATAVERSE_MANUAL_FILE_SETTING_NAME)?.settingValue
          };
          return storageSettings;
        }

        return {} as IBlobStorageSettings;
      })
    );
  }
}
