import { deepClone } from './clone';

//
//  Helper functions for arrays
//

/**
 *  Is it `null`, `undefined` or empty string
 */
export const isEmpty = (value: any) => value === null || value === undefined || value === '';

// export const isNumber = input => input === Number(input);
export const isNumber = input => !isNaN(input) && input !== null;
export const isNumberWithCommas = (input: string) => {
  const result = input.toString().match(/\d{1,2}(,\d{3})*(\.\d+)?/) !== null;
  // console.log(`isNumberWithCommas`, { input, result });
  return result;
};

export const arrayContainsOnlyAllowedText = (arr: string[]): boolean =>
  arr.every(el => el === null || el.toString().match(/^[\w_]+$/) !== null);

export const arrayContainsOnlyNumbers = (arr: any[]): boolean => arr.filter(v => !isEmpty(v)).every(isNumber);
// export const arrayContainsOnlyNumbers = (arr: any[]): boolean => !arr.some(isNaN(val));

export const arrayContainsOnlyDates = (arr: any[]): boolean => {
  const usDateFormat = new RegExp(/[0-1]?[0-9]\/[0-3]?[0-9]\/(?:\d{4}|\d{2})/);
  // Matches 1-2 digit for month. 1-2 digit for day. 2 or 4 digits for year. US format: MM/dd/yyyy
  return arr.every(str => {
    const date = new Date(str);
    return str.match(usDateFormat) && date.toString() !== 'Invalid Date';
  });
};

/**
 * @returns Returns an exact copy of the object
 */

export const simpleClone = value => JSON.parse(JSON.stringify(value));
export const clone = deepClone;
// export const clone = value => simpleClone(value);

/**
 * @description To be use as a filter to get unique array values
 * @example
 *
 * arr.filter(getUnique);
 */
export const getUnique = (value, index, self) => self.indexOf(value) === index;

// export const getUnique = (value, index, self) => self.indexOf(value) === index;

/**
 *
 * @returns Wether the array contains duplicate values or not
 */
export const arrayHasDuplicates = (arr: any[]): boolean => {
  const newArr: any[] = [];
  for (const element of arr) {
    // tslint:disable-next-line:radix
    if (Number(element) === parseInt(element)) {
      // tslint:disable-next-line:radix
      newArr.push(parseInt(element));
    } else {
      newArr.push(element);
    }
  }
  return newArr.length !== new Set(newArr).size;
};

/**
 *
 * @returns Wether the array contains the value just once or not
 */
export const isUniqueInArray = (value: any, arr: any[]): boolean => arr.indexOf(value) === arr.lastIndexOf(value);

/**
 *
 * @returns An array containing duplicates values found in the array
 */
export const getDuplicatedValues = (arr: any[]): any[] => {
  const uniq = arr
    .map(name => {
      return { count: 1, name: name };
    })
    .reduce((a, b) => {
      a[b.name] = (a[b.name] || 0) + b.count;
      return a;
    }, {});

  const duplicates = Object.keys(uniq).filter(a => uniq[a] > 1);
  return duplicates;
};

export const flat = (data): any[] => {
  return Array.isArray(data) ? data.reduce((r, e) => (Array.isArray(e) ? (r = r.concat(flat(e))) : r.push(e) && r), []) : data;
};

export const flatMapToArray = data => {
  if (data instanceof Map) {
    const result = [];
    for (const v of data.values()) {
      result.push(v);
    }
    return result;
  }
  return [];
};

export const getUniqueValues = arr => Array.from(new Set(arr));

/**
 *
 * @param stringArray array of strings to join
 * @returns string of enumerated elements
 * Example: ['one', 'two', 'three', 'four'] to
 * 'one, two, three and four'
 */
export const enumerateArrayElements = (stringArray: string[]): string => {
  if (stringArray.length === 1) {
    return stringArray?.[0] ?? '';
  }
  const allButLast = stringArray.slice(0, stringArray.length - 1);
  const lastMessage = stringArray[stringArray.length - 1];
  return `${allButLast.join(', ')} and ${lastMessage}`;
};
