// tslint:disable: deprecation

export const keyboardKeycodes = {
  v: 86,
  c: 67,
  x: 88,
  rightClick: 3,
  leftClick: 1,
  esc: 27,
  ctrl: 17,
  alt: 18,
  shift: 16
};

export const isCtrl = (ke: KeyboardEvent) =>
  (ke &&
    ke.ctrlKey &&
    (ke.key === 'Control' || ke.code.includes('Control') || ke.keyCode === keyboardKeycodes.ctrl || ke.which === keyboardKeycodes.ctrl)) ||
  false;

export const isAlt = (ke: KeyboardEvent) =>
  (ke &&
    ke.altKey &&
    (ke.key === 'Alt' || ke.code.includes('Alt') || ke.keyCode === keyboardKeycodes.alt || ke.which === keyboardKeycodes.alt)) ||
  false;

export const isShift = (ke: KeyboardEvent) =>
  (ke &&
    ke.shiftKey &&
    (ke.key === 'Shift' || ke.code.includes('Shift') || ke.keyCode === keyboardKeycodes.alt || ke.which === keyboardKeycodes.alt)) ||
  false;

export const isEscape = (ke: KeyboardEvent) =>
  (ke && ke.key === 'Escape') || ke.code === 'Escape' || ke.keyCode === keyboardKeycodes.esc || ke.which === keyboardKeycodes.esc || false;

export const isCtrlC = (ke: KeyboardEvent) =>
  (ke &&
    ke.ctrlKey &&
    (ke.keyCode === keyboardKeycodes.c || ke.key === 'c' || ke.code === 'KeyC') &&
    !(ke.altKey || ke.shiftKey || isEscape(ke))) ||
  false;

export const isAltLeftArrow = (ke: KeyboardEvent) =>
  (ke.altKey &&
    (ke.code === 'AltLeft' || ke.keyCode === keyboardKeycodes.alt || ke.which === keyboardKeycodes.alt) &&
    !(ke.shiftKey || ke.ctrlKey)) ||
  false;
