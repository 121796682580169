<div id="page_container_outer">
  <div id="page_container">
    <div id="page_header_alt">
      <div class="page_inner_container clearfix">
        <div id="login_container" class="logout-page">
          <div class="icon">
            <div class="swal2-icon swal2-error swal2-animate-error-icon" style="display: flex">
              <span class="swal2-x-mark"><span class="swal2-x-mark-line-left"> </span><span class="swal2-x-mark-line-right"></span></span>
            </div>
          </div>
          <h1 class="login_title">Unknown error</h1>
          <div class="space_divider"></div>

          <div>
            <div class="panel-body">
              <p>An unknown error occurred while loading the page. Please contact your administrator or try to log back in.</p>
              <span><button (click)="goBack()">Go Back</button></span>
              <span><button (click)="signin()">Log Back In</button></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="page_content">
      <div class="page_inner_container clearfix"></div>
    </div>
  </div>
</div>
