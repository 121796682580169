import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { AsOfDate, IMPORT_STATES } from '@models/as-of-date/as-of-date';
import { CookieService } from '@services/cookies/cookie.service';
import * as queryParams from '@services/cookies/query-params';
import { DataSourcesService } from '@services/data-sources/data-sources.service';
import { NavigationService } from '@services/navigation/navigation.service';
import { OpenIdConnectService } from '@services/openid/openid-connect.service';
import { SharedService } from '@services/shared/shared-service';
import { fadeInOut } from '@shared/animations';
import { sleep } from '@shared/sleep';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-as-of-date-selection',
  templateUrl: './as-of-date-selection.component.html',
  styleUrls: ['./as-of-date-selection.component.scss'],
  animations: [fadeInOut(300)]
})
export class AsOfDateSelectionComponent implements OnInit {
  @ViewChild('addAsOfDateButton') public addAsOfDateButton: ElementRef;
  @ViewChild('addFirstAsOfDateButton') public addFirstAsOfDateButton: ElementRef;

  public asOfDates: AsOfDate[] = [];
  public isLoading = true;
  public isOpen = false;
  public selectedDate: AsOfDate = null;
  public IMPORT_STATES = IMPORT_STATES;
  public isAdmin = false;

  @Output() public dateSelected = new EventEmitter<AsOfDate>();

  get isLoggedIn(): boolean {
    return this.auth.userAvailable;
  }

  get isNavigationBarPinned(): boolean {
    return this.navigationService.isNavigationBarActive$.value;
  }

  constructor(
    private readonly auth: OpenIdConnectService,
    private readonly navigationService: NavigationService,
    private readonly sharedService: SharedService,
    private readonly dataSourcesService: DataSourcesService,
    private readonly cookieService: CookieService
  ) { }

  public async ngOnInit(): Promise<void> {
    while (!this.isLoggedIn) {
      await sleep(100);
    }

    this.dataSourcesService.selectedAsOfDate$.subscribe(date => {
      this.selectedDate = date;
    });

    this.sharedService.addAsOfDateClicked$.subscribe(() => {
      if (this.addFirstAsOfDateButton) {
        this.addFirstAsOfDateButton.nativeElement.click();
      } else if (this.addAsOfDateButton) {
        this.addAsOfDateButton.nativeElement.click();
      } else {
        console.warn('Could not find button. Find it under Model Data menu.');
      }
    });

    this.dataSourcesService.asOfDateStatusChanged.subscribe(() => this.getAsOfDates());

    this.getAsOfDates();
    this.isAdmin = this.auth.isAdminUser() || this.auth.isDepositsAdminOnly();
  }

  private getAsOfDates(): void {
    this.sharedService
      .getAsOfDates()
      .pipe(take(1))
      .subscribe(dates => {
        const cookies = this.cookieService.cookieCollection.getValue();
        const dateFromCookie = cookies[queryParams.AsOfDate];
        if (dates instanceof Array) {
          this.asOfDates = dates;

          const dateToMatch = this.selectedDate?.Date || dateFromCookie;
          const existing = this.asOfDates.find(aod => aod.Date === dateToMatch);
          this.selectedDate = existing ?? this.asOfDates[this.asOfDates.length - 1];
          this.dataSourcesService.selectedAsOfDate = this.selectedDate;

          this.isLoading = false;
          this.onSelectionChange(this.selectedDate);
        } else if (dates.error) {
          // -- on error emit the currently active date again so promises waiting on an as of date change resolve:
          // -- sharedService.lockUnlockAsOfDate waits for selectedAsOfDate to emit before resovling its promise
          this.dataSourcesService.selectedAsOfDate = this.selectedDate;
        }
      });
  }

  public onSelectionChange = (asOfDate: AsOfDate) => {
    this.dateSelected.emit(asOfDate);
  };

  public onAsOfDatesUpdated(asOfDates: AsOfDate[]): void {
    const isInsertedPriorFirst = this.asOfDates.length === 0 || asOfDates.some(aod => aod.Date < this.asOfDates[0].Date);
    this.asOfDates = asOfDates;
    this.selectedDate = isInsertedPriorFirst ? asOfDates[0] : asOfDates[asOfDates.length - 1];
    this.onSelectionChange(this.selectedDate);
  }

  public isAddDateDisabled = (): string => {
    if (this.asOfDates.length === 0) {
      return '';
    }

    const lastAsOfDate = this.asOfDates[this.asOfDates.length - 1];

    const lastDate = new Date(lastAsOfDate.Date);
    const lastDateMonth = lastDate.getMonth() + 1;
    const lastDateYear = lastDate.getFullYear();
    const currentMonth = new Date().getMonth() + 1;
    const currentYear = new Date().getFullYear();

    const isFutureDate = lastDateMonth === currentMonth && lastDateYear === currentYear;

    return isFutureDate ? 'Cannot add future dates' : '';
  };

  public onOpen = () => {
    setTimeout(() => {
      this.isOpen = true;
      this.navigationService.setIsDateSelectorOpen(true);
    }, 250);
  };

  public onClose = (event: any) => {
    setTimeout(() => {
      this.isOpen = false;
      this.navigationService.setIsDateSelectorOpen(false);
    }, 250);
  };
}
