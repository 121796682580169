import { Injectable } from '@angular/core';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { BehaviorSubject, Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { InterruptOnNetworkCall } from './interrupt-idle-on-network-call';

const TIMEOUT_TIMER = 30; // 30s till timeout
const DEFAULT_IDLE_TIMER = 30 * 60; // 30 min idle

@Injectable({ providedIn: 'root' })
export class TimeoutService {
  private countdownTimer: number;
  private popup;

  public _maxIdleTime = new BehaviorSubject<number>(DEFAULT_IDLE_TIMER);
  public _userTimedOut: Subject<boolean> = new Subject();

  public get maxIdleTime$() {
    return this._maxIdleTime.asObservable();
  }

  public setMaxIdleTime(timeInSeconds: number) {
    this._maxIdleTime.next(timeInSeconds);
  }

  public get maxIdleTime() {
    return this._maxIdleTime.getValue();
  }

  public get userTimedOut$() {
    return this._userTimedOut.asObservable();
  }

  constructor(private readonly idle: Idle) {
    let idleTimer = DEFAULT_IDLE_TIMER;
    // sets an idle timeout of DEFAULT_IDLE_TIMER seconds
    this.idle.setIdle(idleTimer);

    // sets a timeout period of TIMEOUT_TIMER seconds.
    this.idle.setTimeout(TIMEOUT_TIMER);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idle.setInterrupts([...DEFAULT_INTERRUPTSOURCES, new InterruptOnNetworkCall()]);

    this._maxIdleTime.subscribe(time => {
      // Refresh the idle time if the database is different
      idleTimer = time;

      // Stops the Idle timer if it's running, update it with the new value, then start again if User is logged in
      if (this.idle.isRunning()) {
        this.idle.stop();
        this.idle.setIdle(idleTimer);
        this.idle.watch();
      } else {
        this.idle.setIdle(idleTimer);
      }
    });

    // ***********
    // Idle Events
    // ***********
    // this.idle.onIdleEnd.subscribe(() => {
    //   if (ENABLE_DEBUGGING) console.log('No longer idle.');
    // });

    this.idle.onTimeout.subscribe(() => {
      this._userTimedOut.next(true);
    });

    this.idle.onIdleStart.subscribe(() => {
      let timerInterval;
      if (!this.popup) {
        this.popup = Swal.fire({
          title: "You're about to be signed out",
          html:
            '<p>For security reasons, your connection will time out after you’ve been inactive for a while. ' +
            ' Click the button to stay signed in. <span id="timeoutAmt"></span></p>',
          timer: (TIMEOUT_TIMER + 1) * 1000,
          confirmButtonText: 'Stay Signed In',
          allowOutsideClick: false,
          // This creates the interval that will handle the countdown on the SWAL element
          didOpen: () => {
            const content = Swal.getHtmlContainer();
            this.countdownTimer = TIMEOUT_TIMER * 10;

            timerInterval = setInterval(() => {
              this.countdownTimer--;

              content.querySelector('#timeoutAmt').textContent =
                this.countdownTimer > 0 ? `Remaining time: ${Math.floor(this.countdownTimer / 10)} s.` : '';
              if (this.countdownTimer <= 0) {
                clearInterval(timerInterval);
                this._userTimedOut.next(true);
              }
            }, 100);
          },
          willClose: () => {
            this.countdownTimer = TIMEOUT_TIMER;
            this.popup = null;
            clearInterval(timerInterval);
          }
        });
      }
    });
  }

  public startTracking() {
    this.idle.watch();
  }

  public stopTracking() {
    if (this.popup) {
      this.popup.close();
    }

    this.idle.stop();
  }
}
