import { Component, Input } from '@angular/core';
import { BlobServiceClient } from '@azure/storage-blob';
import { IBlobStorageSettings } from '@models/azure-storage-settings/azure-storage-setting';
import { CustomerConfigurationService } from '@services/customer-configuration/customer-configuration';
import { Toast, ToasterService } from '@services/toaster/toaster.service';

@Component({
  selector: 'app-user-manuals-download',
  templateUrl: './user-manuals-download.component.html',
  styleUrls: ['./user-manuals-download.component.scss']
})
export class UserManualsDownloadComponent {
  private blob: BlobServiceClient;
  private blobUrl: string;
  private config: IBlobStorageSettings;
  private blobSas: string;

  @Input() public downloadItem: any;

  constructor(
    private readonly customerConfigurationService: CustomerConfigurationService,
    private readonly toasterService: ToasterService
  ) {}

  public loadData(): void {
    if (this.config) {
      this.downloadUserManuals();
    } else {
      this.customerConfigurationService.getBlobStorageSettings().subscribe(
        blobSettings => {
          this.config = blobSettings;
          this.downloadUserManuals();
        },
        err => {
          console.error(err);
          const errorToast: Toast = {
            title: `Error loading settings`,
            type: 'error',
            body: '',
            showCloseButton: true
          };
          this.toasterService.pop(errorToast);
        }
      );
    }
  }

  public downloadUserManuals(): void {
    try {
      this.blobSas = this.config.SAS;
      this.blobUrl = this.config.Url;
      this.blob = new BlobServiceClient(`${this.blobUrl}?${this.blobSas}`);
      const manualContainer = this.config.Container;
      const containerClient = this.blob.getContainerClient(manualContainer);
      const blockBlobClient = containerClient.getBlockBlobClient(this.config.File_Location);
      window.open(blockBlobClient.url);
    } catch (err) {
      console.error(err);
      const errorToast: Toast = {
        title: `Error downloading User Manual`,
        type: 'error',
        body: '',
        showCloseButton: true
      };
      this.toasterService.pop(errorToast);
    }
  }
}
