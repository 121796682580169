import { IValidable } from '@shared/interfaces/spread-cell-validation-interfaces';
import { ISpreadColumn } from '@shared/spread/spread-settings';

export class AssignmentRuleset implements IValidable {
  public Columns: string;
  public name: string;
  public value: any;
  public isParentNew?: boolean;
  public isNew = false;
  public Name: string = null;
  public errorsArray = [];
  public isValid = true;
  public isModified = false;
  public DateTimeCreated?: Date = null;
  public DateTimeModified?: Date = null;
  public Assignment: number;
  public AssignmentValue: string = null;
  public item: any;
  public is_balance_sheet: boolean;

  constructor(assignmentRuleset?: Partial<AssignmentRuleset>) {
    this.isNew = assignmentRuleset?.isNew ?? true;
    this.Name = assignmentRuleset?.Name ?? null;
    this.isModified = assignmentRuleset?.isModified ?? false;
    this.isValid = assignmentRuleset?.isValid ?? true;
    this.DateTimeModified = assignmentRuleset?.DateTimeModified ?? null;
  }
}

export enum DataSourceTypeIds {
  GL = 1,
  LLC = 2,
  TimeDeposits = 3,
  NonMaturityDeposits = 4,
  Investments = 5,
  Swaps = 6,
  Derivatives = 7,
  Borrowings = 8,
  AncillaryProducts = 12
}

export const COLUMNS: ISpreadColumn[] = [];

export const assignmentRuleset: AssignmentRuleset[] = [];

export const noAliasValues: any[] = [];

export const aliasValues: any[] = [];

export class AssignmentRulesetSave {
  AssignmentRuleset: AssignmentRuleset[];
}

export const formValue: any[] = [];

export class AssignmentDimension {
  public Dimensions: string;
  public TableValue: string;
  public UserFieldName: string;
  public GeneralLedger: string;
  public LoansLeasesCC: string;
  public TimeDeposits: string;
  public NonMaturityDeposits: string;
  public Investment: string;
  public Swaps: string;
  public Derivatives: string;
  public Borrowing: string;
  public AncillaryProduct: string;
}

export class InstrumentType {
  public DataSourceTypeID: number;
  public InstrumentTypeID: number;
}
