<ng-container *ngIf="loading">
  <div
    class="lds-empyrean fadein"
    [@fadeInOut]
    [ngStyle]="{
      width: width + 'px',
      maxWidth: '100%',
      height: height + 'px',
      maxHeight: '95%',
      gridGap: gridGap + 'px',
      left: 'calc(50% - (' + width + 'px/2))',
      top: 'calc(50% - (' + height + 'px/2))',
      display: 'grid',
      position: relative ? 'relative' : 'absolute'
    }"
  >
    <ng-container *ngFor="let bar of bars; let i = index">
      <div
        [ngStyle]="{
          background: color,
          maxHeight: getHeight(i) + 'px',
          height: getHeight(i) + 'px',
          animation: 'lds-empyrean ' + getSpeed() + 's ease-in-out infinite',
          animationDelay: bar * -0.12 + 's'
        }"
        [class.plain]="plain"
        [class.static]="static"
      >
        <!-- {{ i }} [{{bar}}]: {{ getHeight(i) }} -->
      </div>
    </ng-container>
    <div
      class="loader-message"
      *ngIf="message"
      [ngStyle]="{
        width: width + 'px',
        maxWidth: '100%',
        textShadow: '1px 1px 2px ' + color + ', -1px -1px 2px ' + color + ', 0px 0px 8px ' + color
      }"
    >
      {{ message }}
    </div>
  </div>
</ng-container>
