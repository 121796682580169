import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { OpenIdConnectService } from '@services/openid/openid-connect.service';
import { WebSocketService } from '@services/websocket/websocket.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements AfterViewInit, OnDestroy {
  constructor(private readonly wsService: WebSocketService, private readonly openIDConnectService: OpenIdConnectService) {}

  public ngAfterViewInit(): void {
    this.openIDConnectService.userLoaded$.subscribe(user => {
      this.wsService.startConnection();
    });
  }

  public async ngOnDestroy(): Promise<void> {
    this.wsService.disconnect();
  }
}
