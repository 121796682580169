import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigProvider } from '@app/app.config.provider';
import { DeletionButtonComponent } from '@core/tree/deletion-button/deletion-button.component';
import { AppConfigAPI } from '@models/appconfig';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import Swal from 'sweetalert2';


export enum UNALLOCATED_TYPES {
  'general ledger income statement' = 0,
  'general ledger balance sheet' = 1,
  'department' = 2,
}

interface IUnallocatedEndpoints {
  checkUnallocated: string;
  getUnallocated: string;
  deleteUnallocated: string;
}
const UNALLOCATED_ENDPOINTS: { [key: number]: IUnallocatedEndpoints } = {
  // -- Income Statement
  0: {
    checkUnallocated: '/api/GeneralLedger/IncomeStatement/Account/CheckForUnallocated',
    getUnallocated: '/api/GeneralLedger/IncomeStatement/Account/Unallocated',
    deleteUnallocated: '/api/GeneralLedger/IncomeStatement/Account/Unallocated'
  },
  // -- Balance Sheet
  1: {
    checkUnallocated: '/api/GeneralLedger/BalanceSheet/Account/CheckForUnallocated',
    getUnallocated: '/api/GeneralLedger/BalanceSheet/Account/Unallocated',
    deleteUnallocated: '/api/GeneralLedger/BalanceSheet/Account/Unallocated'
  },
  // -- Department
  2: {
    checkUnallocated: '/api/Department/CheckForUnallocated',
    getUnallocated: '/api/Department/Unallocated',
    deleteUnallocated: '/api/Department/Unallocated'
  }
};


@Injectable({
  providedIn: 'root'
})
export class DeletionButtonService {
  private environment: AppConfigAPI;
  public deletionButton: DeletionButtonComponent;

  constructor(private readonly http: HttpClient, private readonly appConfig: AppConfigProvider) {
    this.appConfig.environment.subscribe(env => {
      this.environment = env.API;
    });
  }

  /**
   * Deletes the unallocated items for the specified hierarchy type \
   * Returns obserable of containing items deleted, unrecognized hiearchyName returns [];
   * @param hierarchyName Name of hierarchy withunallocated items to be deleted. \
   * String token as defined in UNALLOCATED_TYPES enum
   */
  public deleteUnalocatedItems(hierarchy: number): Observable<any[]> {
    let deletedItems$: Observable<any> = of([]);
    if (UNALLOCATED_ENDPOINTS[hierarchy].deleteUnallocated) {
      deletedItems$ = this.http.delete<any[]>(`${this.environment.DIMENSIONS}${UNALLOCATED_ENDPOINTS[hierarchy].deleteUnallocated}`)
        .pipe(catchError(_ => of([])));
    }
    return deletedItems$;
  }

  /**
   * Checks if at least one unallocated item exists that can be safely deleted from the hierarchy
   * @param hierarchyName String that specifies the hierarchy to check \
   * Returns a boolean indicating if some unallocated items exist for the heirarchy \
   * and if they can be deleted or not by the user
   */
  public checkUnallocated(hierarchyName: string) {
    const hierarchyType = UNALLOCATED_TYPES[hierarchyName.toLowerCase()];
    const checkEndpoint = UNALLOCATED_ENDPOINTS[hierarchyType] ? UNALLOCATED_ENDPOINTS[hierarchyType].checkUnallocated : null;
    let canDeleteUnallocatedItems$: Observable<any> = of(false);
    if (checkEndpoint) {
      canDeleteUnallocatedItems$ = this.http.get<boolean>(`${this.environment.DIMENSIONS}${checkEndpoint}`)
        .pipe(catchError(_ => of(false)));
    }
    return canDeleteUnallocatedItems$;
  }

  public refreshButton() {
    this.deletionButton.refresh();
  }

  public async unallocatedPreDeletionConfirm(di: any[], hierarchyType: UNALLOCATED_TYPES): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let unalloc = [];
      let title, message;
      switch (hierarchyType) {
        case UNALLOCATED_TYPES['general ledger income statement']:
          title = `Remove Unallocated Accounts`;
          message = `Are you sure you wish to remove the following Accounts?`;
          unalloc = di.map(bc => bc.GLCode);
          break;
        case UNALLOCATED_TYPES['general ledger balance sheet']:
          title = `Remove Unallocated Accounts`;
          message = `Are you sure you wish to remove the following Accounts?`;
          unalloc = di.map(bc => bc.GLCode);
          break;
        case UNALLOCATED_TYPES['department']:
          title = `Remove Unallocated Departments`;
          message = `Are you sure you wish to remove the following Departments?`;
          unalloc = di.map(bc => bc.DepartmentCode);
          break;
        default:
          title = `Remove Unallocated Items?`;
          message = `Are you sure you wish to remove the following Items?`;
      }

      Swal.fire({
        title: title,
        icon: 'info',
        html: `<p>${message}</p>
            <br>
            <div style="max-height: 300px; overflow-y: scroll;"><strong>${unalloc.join(', ')}</strong></div>`,
        showCancelButton: true,
        confirmButtonText: 'Proceed',
        reverseButtons: true,
        allowEscapeKey: false,
        customClass: {
          cancelButton: 'order-1 right-gap',
          confirmButton: 'swal_confirm_btn'
        },
        buttonsStyling: true
      })
        .then((result) => {
          if (result.isConfirmed) {
            resolve(true);
          } else {
            resolve(false);
          }
        });
    });
  }

  public async unallocatedPostDeletionConfirm(di: any[], hierarchyType: UNALLOCATED_TYPES): Promise<boolean> {
    let unalloc = [];
    let title, message, type;
    switch (hierarchyType) {
      case UNALLOCATED_TYPES['general ledger income statement']:
        title = `Remove Unallocated Accounts Complete`;
        message = `All Unallocated Accounts that are not tied to Imported Data from Dataverse have been removed`;
        type = `Accounts`;
        unalloc = di.map(bc => bc.GLCode);
        break;
      case UNALLOCATED_TYPES['general ledger balance sheet']:
        title = `Remove Unallocated Accounts Complete`;
        message = `All Unallocated Accounts that are not tied to Imported Data from Dataverse have been removed`;
        type = `Accounts`;
        unalloc = di.map(bc => bc.GLCode);
        break;
      case UNALLOCATED_TYPES['department']:
        title = `Remove Unallocated Departments Complete`;
        message = `All Unallocated Departments that are not tied to Imported Data from Dataverse have been removed.`;
        type = `Departments`;
        unalloc = di.map(bc => bc.DepartmentCode);
        break;
      default:
        title = `Remove Unallocated Complete`;
        message = `All Unallocated items that are not tied to Imported Data from Dataverse have been removed.`;
        type = `Items`;
    }

    const promise: Promise<boolean> = new Promise<boolean>((resolve, reject) => {
      Swal.fire({
        title: title,
        icon: 'info',
        html: `<p>${message}</p>
          <br>
          <p><strong>Number of ${type} removed: ${di.length}</strong></p>
          <br>
          <div style="max-height: 300px; overflow-y: scroll;"><strong>${unalloc.join(', ')}</strong></div>`,
        showCancelButton: false,
        confirmButtonText: 'Close',
        reverseButtons: true,
        allowEscapeKey: true,
        customClass: {
          confirmButton: 'swal_confirm_btn'
        },
        buttonsStyling: true
      })
        .then(result => {
          if (result && result.value) {
            resolve(true);
          } else {
            resolve(false);
          }
        })
        .catch(err => {
          reject(true);
        });
    });
    return promise;
  }
}
