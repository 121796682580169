

export class DimensionSetup {
  public yc_setup_sid: number;
  public Mnemonic: string;
  public Name: string;
  public InterpolationMtd: number;
  public default_terms: TermPoint[];
  public Source: number;
  public DateTimeCreated?: Date = null;
  public DateTimeModified?: Date = null;
  public isLinkedToIndex = false;
  public isValid = true;
  public ExtrapolationTerm?: number;

  public isEmpty = () => {
    let areTermsDefaults = false;
    if (this.default_terms.length === 1) {
      areTermsDefaults = this.default_terms[0].Months === 0;
    }
    return !this.Mnemonic && !this.Name && !this.InterpolationMtd && this.yc_setup_sid === -1 && areTermsDefaults;
  };
}

export class TermPoint {
  public Position: number;
  public Months: number;
}

export class InterpolationMethod {
  public Code: number;
  public Value: string;
}

export const DefaultTermsNewSetup: TermPoint[] = [{ Position: 1, Months: 0 }];

export interface ISetup {
  Name: string;
  Mnemonic: string;
}


