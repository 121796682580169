import { ClipboardModule } from '@angular/cdk/clipboard';
import { DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import '@angular/compiler';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from '@core/core.module';
import { HoverNotificationComponent } from '@core/hover-notification/hover-notification.component';
import { KendoModule } from '@core/kendo/kendo.module';
import { NavigationModule } from '@core/navigation/navigation.module';
import { SpreadSheetsModule } from '@grapecity/spread-sheets-angular';
import { NgIdleModule } from '@ng-idle/core';
import { httpInterceptorProviders } from '@services/http-interceptors/interceptor-providers';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppConfigProvider } from './app.config.provider';
import { ToastrModule } from 'ngx-toastr';

export const appConfigProviderFactory = (provider: AppConfigProvider) => () => provider.load();

@NgModule({
  declarations: [AppComponent, HoverNotificationComponent],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    ClipboardModule,
    CoreModule,
    HttpClientModule,
    KendoModule,
    NavigationModule,
    NgIdleModule.forRoot(),
    SpreadSheetsModule,
    ToastrModule.forRoot()
  ],
  providers: [
    AppConfigProvider,
    DatePipe,
    { provide: APP_INITIALIZER, useFactory: appConfigProviderFactory, deps: [AppConfigProvider], multi: true },
    httpInterceptorProviders
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
