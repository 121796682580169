import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { InvalidRowsComponent } from '@core/invalid-rows/invalid-rows.component';
import { SigninOidcComponent } from './auth/signin-oidc/signin-oidc.component';
import { EmpyreanLoaderComponent } from './empyrean-loader/empyrean-loader.component';
import { EmpyreanLoaderModule } from './empyrean-loader/empyrean-loader.module';
import { PlaceholderComponent } from './placeholder/placeholder.component';
import { DeletionButtonComponent } from './tree/deletion-button/deletion-button.component';
import { ToggleSwitchComponent } from './tree/toggle-switch/toggle-switch.component';
import { TreePanelComponent } from './tree/tree-panel.component';
import { TreeComponent } from './tree/tree.component';
import { EmpUnallocatedListComponent } from './UI/emp-unallocated-list-component/emp-unallocated-list.component';
import { SignoutOidcComponent } from './auth/signout-oidc/signout-oidc.component';

const MODULES = [EmpyreanLoaderModule, CommonModule, MatTooltipModule, MatTreeModule];

@NgModule({
  declarations: [
    DeletionButtonComponent,
    EmpUnallocatedListComponent,
    InvalidRowsComponent,
    PlaceholderComponent,
    SigninOidcComponent,
    SignoutOidcComponent,
    ToggleSwitchComponent,
    TreeComponent,
    TreePanelComponent,
    SignoutOidcComponent
  ],
  imports: MODULES,
  exports: [
    DeletionButtonComponent,
    EmpUnallocatedListComponent,
    EmpyreanLoaderComponent,
    InvalidRowsComponent,
    PlaceholderComponent,
    TreeComponent,
    TreePanelComponent
  ]
})
export class CoreModule {}
