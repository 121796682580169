import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ExpandedNodesService {
  private readonly expandedTreeNodesRegistry: IExpandedNodes[] = [];

  constructor() {}

  public registerExpandedNode(expandedNode: IExpandedNodes) {
    const isTreeExists = this.expandedTreeNodesRegistry.some(treeRegistry => treeRegistry.treeName === expandedNode.treeName);
    if (isTreeExists) {
      const matchedNodeInRegistry = this.expandedTreeNodesRegistry.find(treeRegistry => expandedNode.treeName === treeRegistry.treeName);
      if (matchedNodeInRegistry) {
        matchedNodeInRegistry.expandedIds = Array.from(new Set([...matchedNodeInRegistry.expandedIds, ...expandedNode.expandedIds]));
      }
    } else {
      this.expandedTreeNodesRegistry.push(expandedNode);
    }
  }

  public unregisterExpandedNode(collapsedNode: IExpandedNodes) {
    const matchedNodeInRegistry = this.expandedTreeNodesRegistry.find(treeRegistry => collapsedNode.treeName === treeRegistry.treeName);
    if (matchedNodeInRegistry) {
      matchedNodeInRegistry.expandedIds = matchedNodeInRegistry.expandedIds.filter(ex => !collapsedNode.expandedIds.includes(ex));
    }
  }

  public getExpandedNodes(treeName: string): number[] {
    const expandNodes = this.expandedTreeNodesRegistry.find(tree => tree.treeName === treeName);
    if (expandNodes && expandNodes.expandedIds) {
      // TODO undefined
      return this.expandedTreeNodesRegistry.find(tree => tree.treeName).expandedIds;
    }
    return [];
  }
}

export interface IExpandedNodes {
  treeName: string;
  expandedIds: number[];
}
