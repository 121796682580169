<ng-container *ngTemplateOutlet="navElement; context: { element: element }"> </ng-container>
<emp-navigation-divider *ngIf="element?.level === 0"></emp-navigation-divider>

<ng-template #navElement let-element="element">
  <div kendoTooltip position="right" [showAfter]="400">
    <a
      class="navigation-link"
      [class.placeholder]="element?.routerLink?.includes('placeholder')"
      [class.collapsed]="!(isNavigationBarActive$ | async)"
      [class.root]="element?.level === 0"
      [class.warn]="element?.elementState === 'invalid' || element?.elementState === 'invalid unallocated'"
      [class.next]="element?.elementState === 'not setup'"
      [class.inactive]="element?.elementState === 'not required'"
      [routerLink]="!element?.isDisabled ? element?.routerLink || null : null"
      routerLinkActive="{{ !element?.isDisabled && element?.routerLink ? 'status_active' : null }}"
      [attr.disabled]="!element?.isDisabled ? null : ''"
      [ngStyle]="{ paddingLeft: (isNavigationBarActive$ | async) ? 'calc(0.5rem + ' + element?.level + ' * 0.75rem)' : '0' }"
      (click)="element?.children && toggleExpand(element)"
      [attr.title]="element?.tooltip ? element.tooltip : null"
    >
      <span class="icon">
        <i [ngClass]="[element?.iconClass || element?.stateClass || 'fa-solid fa-square']"></i>
      </span>
      <span class="text ellipsis">{{ element?.label }}</span>
      <span class="status"></span>
      <span class="expand-icon" *ngIf="element?.children">
        <i class="fas fa-chevron-right" [class.expanded]="element?.isExpanded"></i>
      </span>
      <ng-container *ngIf="!element.children">
        <span *ngIf="element?.elementState === 'not setup'" class="notification-dot" type="setup"><i class="fa-solid fa-wrench"></i></span>
      </ng-container>
    </a>
  </div>

  <ng-container *ngIf="element?.children">
    <div
      class="children-container"
      [class.expanded]="element?.isExpanded"
      [style.maxHeight]="'calc(' + getNumberOfDescendants(element) + ' * var(--line-height))'"
    >
      <ng-container *ngFor="let child of element?.children">
        <ng-container *ngTemplateOutlet="navElement; context: { element: child }"> </ng-container>
      </ng-container>
    </div>
  </ng-container>
</ng-template>
