import { Component, ElementRef, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';
import { INavigationCanOpen } from '../navigation-interfaces';

@Component({
  selector: 'emp-navigation-header',
  templateUrl: './navigation-header.component.html'
})
export class NavigationHeaderComponent implements INavigationCanOpen {
  @Input() public modalTitle: string;
  @Input() public pinned: boolean;
  @Output() public pinnedEvent = new EventEmitter<boolean>();
  @Output() public popUpOpened = new EventEmitter<NavigationHeaderComponent>();

  @HostBinding('id') @Input() public id = 'nav_header';
  @HostBinding('class') public classes = 'nav_section';

  @HostListener('document:click', ['$event']) public onClickOut(event): void {
    if (!this.element) {
      return;
    }
    if (!this.element.contains(event.target) && this.isOpen) {
      this.isOpen = !this.isOpen;
      this.popUpOpened.emit(this.isOpen ? this : null);
    }
  }

  public isOpen = false;
  public element;

  constructor(el: ElementRef) {
    this.element = el.nativeElement;
  }

  public togglePin(): void {
    this.pinned = !this.pinned;

    if (this.pinned) {
      localStorage.setItem(`nav-bar-pinned`, 'true');
    } else {
      localStorage.removeItem(`nav-bar-pinned`);
    }

    this.pinnedEvent.emit(this.pinned);
  }

  public toggleQuickLinks() {
    this.isOpen = !this.isOpen;
    this.popUpOpened.emit(this.isOpen ? this : null);
  }
}
