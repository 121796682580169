<div class="app-root normal-scrollbar" [class.hidden]="!isLoggedIn && !isErrorRoute">
  <emp-navigation></emp-navigation>

  <div id="page_frame" class="offset_for_nav">
    <div class="main-container">
      <app-hover-notification></app-hover-notification>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
