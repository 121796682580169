import { IFTPResultSummaryRow } from './ftp-results-summary';

type FTPState = 'no results' | 'ready' | 'finalized' | 'outdated';

interface IFTPStatusBase {
    AsOfDate: string;
    HasFTP: boolean;
    HasProf: boolean;
    FTPState: FTPState;
    FinalizeUserId: number;
    FinalizeUserName: string;
    ResultsUserId: number;
    ResultsUserName: string;
    Results: IFTPResultSummaryRow[];
}


export interface IFTPStatus extends IFTPStatusBase {
    FinalizeDateTime: string;
    ResultsDateTime: string;
}
    

export class FTPStatus implements IFTPStatusBase {
    public AsOfDate: string;
    public HasFTP: boolean;
    public HasProf: boolean;
    public FTPState: FTPState;
    public IsFinalized: boolean;
    public FinalizeUserId: number;
    public FinalizeUserName: string;
    public FinalizeDateTime: Date;
    public ResultsUserId: number;
    public ResultsUserName: string;
    public ResultsDateTime: Date;
    public Results: IFTPResultSummaryRow[];

    constructor( ftpStatus: IFTPStatus) {
        this.AsOfDate = ftpStatus.AsOfDate;
        this.HasFTP = ftpStatus.HasFTP;
        this.HasProf = ftpStatus.HasProf;
        this.FTPState = ftpStatus.FTPState;
        this.IsFinalized = ftpStatus.FTPState === 'finalized' || ftpStatus.FTPState === 'outdated';
        this.FinalizeUserId = ftpStatus.FinalizeUserId ?? null;
        this.FinalizeUserName = ftpStatus.FinalizeUserName ?? null;
        this.FinalizeDateTime = ftpStatus.FinalizeDateTime ? new Date(ftpStatus.FinalizeDateTime ) : null;
        this.ResultsUserId = ftpStatus.ResultsUserId ?? null;
        this.ResultsUserName = ftpStatus.ResultsUserName ?? null;
        this.ResultsDateTime = ftpStatus.ResultsDateTime ? new Date(ftpStatus.ResultsDateTime) :  null;
        this.Results = ftpStatus.Results ?? null;
    }
}

