import { Component, Input, OnInit } from '@angular/core';
import { fadeInOut } from '@shared/animations';
import { BehaviorSubject } from 'rxjs';


@Component({
  selector: 'emp-unallocated-list',
  templateUrl: './emp-unallocated-list.component.html',
  styleUrls: ['./emp-unallocated-list.component.scss'],
  animations: [fadeInOut(200)]
})
export class EmpUnallocatedListComponent<T> {
  @Input() public items: T[];
  @Input() public noResultsText = 'All Items are Allocated';
  @Input() public displayProp = null;
  @Input() public displayPropFn: (item: T) => string = (item: T) => item[this.displayProp];
}
