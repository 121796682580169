<div class="add-dimension-container">
  <div class="header">
    <div class="title">Dimensions - {{ dataSourceName }}</div>
    <div class="assignment-value-container" [class.show]="assignmentValue">
      <div class="assignment-value-title">{{ assignmentValue }}</div>
    </div>
  </div>
  <div class="container">
    <div class="body">
      <form [formGroup]="dimensionSetupFormGroup">
        <div class="terms-containter">
          <div *ngFor="let term of defaultTermsControl.controls; let i = index">
            <div autocomplete="off" [formGroup]="$any(term)">
              <div class="terms-form-field">
                <label class="label-form-field" style="font-weight: bold">Column {{ i + 1 }}</label>
                <kendo-dropdownlist
                  type="number"
                  formControlName="Months"
                  class="terms-input"
                  min="0"
                  step="1"
                  class="dropdown"
                  [data]="this.data.dimesionsList"
                  textField="text"
                  valueField="value"
                  [(ngModel)]="dropdowns[i].selectedValue"
                  (valueChange)="onDropdownSelected($event, i)"
                  style="margin-left: 10px"
                ></kendo-dropdownlist>
                <button
                  class="btn_normal btn_negative btn_no_border btn_delete btn-delete-term"
                  type="button"
                  (click)="onRemoveTerm($any(term))"
                  *ngIf="i != 0"
                >
                  <span class="btn_icon">
                    <span class="fa-solid fa-trash-can"></span>
                  </span>
                  &nbsp;
                </button>
              </div>
            </div>
          </div>
          <button
            class="btn_normal btn_primary"
            type="button"
            [disabled]="listLength === 0"
            (click)="onAddTermPoint()"
            style="background-color: white; color: #51738b; text-decoration: underline"
          >
            Add Dimensions
          </button>
        </div>
      </form>
    </div>
  </div>

  <div class="footer">
    <button
      class="close_modal close close-modal-button btn_normal btn_wide btn_negative"
      id="close-button"
      type="button"
      (click)="closeDialog()"
    >
      Cancel
    </button>
    <button class="btn_normal btn_wide btn_green" type="button" (click)="openCloseDiscardChangesDialog()">Apply</button>
  </div>
  <kendo-dialog title="" *ngIf="discardChangesDialogOpen" (close)="openCloseDiscardChangesDialog()" [minWidth]="250" [width]="450">
    <p class="go_back_last_saved">Are you sure you want to reset the Assignment?</p>
    <kendo-dialog-actions layout="normal">
      <button class="btn_normal btn_negative btn_wide" (click)="openCloseDiscardChangesDialog()">Discard</button>
      <button class="btn_normal btn_blue btn_wide btn_corner" (click)="getAssignmentRuleset()">Apply</button>
    </kendo-dialog-actions>
  </kendo-dialog>
</div>
