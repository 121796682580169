<div class="sheet-status" [class.show]="invalidRowCount > 0">
  <ng-container *ngIf="invalidRowCount > 0">
    <span class="invalid"> {{ invalidRowCount }} invalid {{ invalidRowCount === 1 ? 'row' : 'rows' }} </span>
    <span class="icon_error invalid"></span>
  </ng-container>
  <ng-container *ngIf="invalidRowCount === 0">
    <span class="valid"> No invalid rows</span>
    <span class="icon_valid valid"></span>
  </ng-container>
</div>
