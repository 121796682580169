import { Component, Input } from '@angular/core';
import { BaseFilterCellComponent, FilterService } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { toTitleCase } from '@shared/strings';

@Component({
  selector: 'emp-dropdownlist-filter',
  templateUrl: './dropdownlist-filter.component.html',
  styleUrls: ['./dropdownlist-filter.component.scss']
})
export class DropdownlistFilterComponent extends BaseFilterCellComponent {
  @Input() public filter: CompositeFilterDescriptor;
  @Input() public data: any[];
  @Input() public textField: string;
  @Input() public valueField: string;
  @Input() public name: string;

  public get selectedValue(): unknown {
    const filter = this.filterByField(this.valueField);
    return filter ? filter.value : null;
  }

  public get defaultItem(): any {
    return {
      [this.textField]: 'All',
      [this.valueField]: null
    };
  }

  constructor(filterService: FilterService) {
    super(filterService);
  }

  public onChange(value: any): void {
    this.applyFilter(
      value === null // if value of the default item
        ? this.removeFilter(this.valueField) // remove the filter
        : this.updateFilter({
            // otherwise add/modify the filter for the field with the value
            field: this.valueField,
            operator: 'eq',
            value
          })
    ); // and update the root filter
  }

  public hasText(dataItem): boolean {
    return Boolean(dataItem?.[this.textField]);
  }

  public getItemText(dataItem): string {
    return (
      dataItem?.[this?.textField] ?? (dataItem?.[this?.valueField] ? `${toTitleCase(this.name)} Id: ${dataItem?.[this?.valueField]}` : '')
    );
  }
}
