import { ImportState } from './import-detail';

export interface IImportTask {
  DataImportID: number;
  DataSourceID: number;
  UserID: number;
  ImportState: ImportState;
  IsUpload: boolean;
  LastUpdated: string;
  AsOfDate: string;
}

export class ImportTask {
  public DataImportID: number;
  public DataSourceID: number;
  public UserID: number;
  public ImportState: ImportState;
  public IsUpload: boolean;
  public LastUpdated: Date;
  public AsOfDate: Date;

  constructor(task: IImportTask) {
    this.DataImportID = task.DataImportID;
    this.DataSourceID = task.DataSourceID;
    this.UserID = task.UserID;
    this.ImportState = task.ImportState;
    this.IsUpload = task.IsUpload;
    this.LastUpdated = new Date(task.LastUpdated);
    this.AsOfDate = new Date(task.AsOfDate);
  }
}
