import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { UserManualsDownloadComponent } from './user-manuals-download.component';

@NgModule({
  declarations: [UserManualsDownloadComponent],
  imports: [CommonModule, MatDialogModule, MatFormFieldModule, MatInputModule, MatSelectModule, MatTooltipModule],
  exports: [UserManualsDownloadComponent]
})
export class UserManualsDownloadModule {}
