import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'empyrean-navigation-section',
  templateUrl: './navigation-section.component.html',
  styleUrls: ['./navigation-section.component.scss']
})
export class NavigationSectionComponent {
  @HostBinding('class.scrollable') @Input() public scrollable = true;
  @HostBinding('id') @Input() public id: string = null;
  @HostBinding('class') public classes = 'nav_section';
}
