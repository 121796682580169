import { HttpClient, HttpEvent, HttpParams, HttpRequest } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { AppConfigProvider } from '@app/app.config.provider';
import { AsOfDate } from '@models/as-of-date/as-of-date';
import { IBlobStorageSettings } from '@models/azure-storage-settings/azure-storage-setting';
import { CancelReason, ImportUploadCancellation } from '@models/data-sources/cancel-reasons';
import {
  DataSource,
  DataSourceToggleRequest,
  DataSourceType,
  DataSourceTypeColumns,
  DataSourceUpdateRequest,
  ImportRequest
} from '@models/data-sources/data-sources';
import { ErrorZip, ImportError, ImportErrorsSummary, ImportedDataSource, zipTimeouts } from '@models/data-sources/import-detail';
import { IImportTask, ImportTask } from '@models/data-sources/import-task';
import { CookieType } from '@services/cookies/cookie-type';
import { CookieService } from '@services/cookies/cookie.service';
import { sleep } from '@shared/sleep';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import * as queryParams from '../cookies/query-params';

@Injectable({ providedIn: 'root' })
export class DataSourcesService {
  private apiUrl: string;
  private datasourcesURL: string;
  private importSettingsURL: string;
  private importServiceURL: string;
  private excelImportURL: string;
  private DataSourceTypesCache: DataSourceType[] = null;

  private readonly fetchingImportIds: Set<number> = new Set();
  private readonly errorsCache: Map<number, ImportError[]> = new Map<number, ImportError[]>();
  private readonly errorsSummaryCache: Map<number, ImportErrorsSummary> = new Map<number, ImportErrorsSummary>();

  private readonly _selectedAsOfDate$: BehaviorSubject<AsOfDate> = new BehaviorSubject<AsOfDate>(null);

  get selectedAsOfDate$(): Observable<AsOfDate> {
    return this._selectedAsOfDate$.asObservable();
  }

  set selectedAsOfDate(asOfDate: AsOfDate) {
    if (asOfDate) {
      this.cookieService.setCookie(queryParams.AsOfDate, asOfDate?.Date, CookieType.SessionStorage);
      this._selectedAsOfDate$.next(asOfDate);
    }
  }

  get selectedAsOfDate(): AsOfDate {
    return this._selectedAsOfDate$.value;
  }

  public asOfDateStatusChanged = new EventEmitter<boolean>();

  constructor(
    private readonly http: HttpClient,
    private readonly appConfig: AppConfigProvider,
    private readonly cookieService: CookieService
  ) {
    this.appConfig.environment.subscribe(env => {
      this.apiUrl = env.API.DIMENSIONS;
      this.datasourcesURL = `${env.API.DIMENSIONS}/api/DataSources`;
      this.importSettingsURL = `${env.API.DIMENSIONS}/api/ImportSettings/ImportSettings`;
      this.importServiceURL = `${env.API.DIMENSIONS}/api/ImportUpload`;
      this.excelImportURL = `${env.API.DIMENSIONS}/api/Excel/import`;
    });
  }

  public uploadExcelForSingleDataSource(file: File, dataSourceID: number, asOfDate: string): Observable<HttpEvent<any>> {
    const formData = new FormData();
    formData.append('file', file);

    const options = {
      reportProgress: true,
      responseType: 'text' as const
    };
    const url = `${this.excelImportURL}/datasource/${dataSourceID}/${asOfDate}`;
    const request = new HttpRequest('POST', url, formData, options);

    return this.http.request(request);
  }

  public uploadExcelForMultipleDataSources(file: File, asOfDate: string): Observable<HttpEvent<any>> {
    const formData = new FormData();
    formData.append('file', file);

    const options = {
      reportProgress: true,
      responseType: 'text' as const
    };
    const url = `${this.excelImportURL}/datasources/${asOfDate}`;
    const request = new HttpRequest('POST', url, formData, options);

    return this.http.request(request);
  }

  public uploadCSVForMultipleDataSources(file: File, asOfDate: string): Observable<HttpEvent<any>> {
    const formData = new FormData();
    formData.append('file', file);

    const options = {
      reportProgress: true,
      responseType: 'text' as const
    };
    const url = `${this.excelImportURL}/datasources/csv/${asOfDate}`;
    const request = new HttpRequest('POST', url, formData, options);

    return this.http.request(request);
  }

  public getDataSourceTypes(): Observable<DataSourceType[]> {
    return this.http.get<DataSourceType[]>(`${this.datasourcesURL}/DataSourceTypes`).pipe(tap(ds => (this.DataSourceTypesCache = ds)));
  }

  public getDataSourceTypeColumns(): Observable<DataSourceTypeColumns[]> {
    return this.http.get<DataSourceTypeColumns[]>(`${this.datasourcesURL}/DataSourceTypeColumns`);
  }

  public getRequiredModulesForDataSources(dataSourceTypeID: number): Observable<string[]> {
    return this.http.get<string[]>(`${this.datasourcesURL}/types/${dataSourceTypeID}/modules`);
  }

  public getDataSources(): Observable<DataSource[]> {
    return this.http.get<DataSource[]>(this.datasourcesURL);
  }

  public createDataSource(request: DataSource): Observable<boolean> {
    try {
      return this.http.post<boolean>(this.datasourcesURL, request);
    } catch (error) {
      return of(null);
    }
  }

  public updateDataSource = (dataSourceID: number, request: DataSourceUpdateRequest) =>
    this.http.post<boolean>(`${this.datasourcesURL}/${dataSourceID}`, request).toPromise();

  public toggleDataSourceStatus = (dataSourceID: number, request: DataSourceToggleRequest) =>
    this.http
      .post<boolean>(`${this.datasourcesURL}/toggle/${dataSourceID}`, request)
      .pipe(tap(_ => this.asOfDateStatusChanged.emit(true)))
      .toPromise();

  public getImportSettings = (): Promise<IBlobStorageSettings> => this.http.get<IBlobStorageSettings>(this.importSettingsURL).toPromise();

  public getImportDetails = (date: string, dataSourceId: number = null): Promise<ImportedDataSource[]> => {
    const params =
      dataSourceId !== null
        ? new HttpParams().set('date', date).set('dataSourceId', dataSourceId.toString())
        : new HttpParams().set('date', date);

    return this.http
      .get<ImportedDataSource[]>(`${this.importServiceURL}/GetDataSourceTracking`, { params })
      .pipe(
        switchMap(importDetails => {
          return new Observable<ImportedDataSource[]>(observer => {
            observer.next(importDetails.map(this.createImportDetail));
            observer.complete();
          });
        })
      )
      .toPromise<ImportedDataSource[]>();
  };

  public initiateImport = (request: ImportRequest): Promise<ImportedDataSource> =>
    this.http
      .post<ImportedDataSource>(`${this.importServiceURL}/StartFileImportProcess`, request)
      .pipe(
        map(importedDataSource => {
          if (request.DataSourceID === -1) { // if the request is for a datasource that doesn't exist, return the response as is
            return importedDataSource;
          } else {
            return this.createImportDetail(importedDataSource); // otherwise, create the import detail
          }
        })
      )
      .toPromise();

  public initiateEmpyreanImport = (importID: number) =>
    this.http.post(`${this.importServiceURL}/StartEmpyreanImportProcess?importID=${importID}`, null);

  public getQueue = (): Observable<ImportTask[]> =>
    this.http.get<IImportTask[]>(`${this.importServiceURL}/queue`).pipe(map(tasks => tasks.map(task => new ImportTask(task))));

  public getCancelReasons = (): Promise<CancelReason[]> =>
    this.http.get<CancelReason[]>(`${this.importServiceURL}/GetCancelReasons`).toPromise();

  public cancelImport = (cancellation: ImportUploadCancellation): Promise<boolean> =>
    this.http.post<boolean>(`${this.importServiceURL}/Cancel`, cancellation).toPromise();

  public deleteImport = (dataSourceId: number, date: string): Promise<boolean> => {
    const params = new HttpParams().set('dataSourceId', dataSourceId.toString()).set('date', date);

    return this.http.delete<boolean>(this.importServiceURL, { params }).toPromise();
  };

  public deleteUpload = (dataSourceId: number, date: string): Promise<boolean> => {
    const params = new HttpParams().set('dataSourceId', dataSourceId.toString()).set('date', date);

    return this.http.delete<boolean>(`${this.importServiceURL}/DeleteUpload`, { params }).toPromise();
  };

  public getErrorsSummary = async (importId: number, forceRefresh = false): Promise<ImportErrorsSummary> => {
    while (this.fetchingImportIds.has(importId)) {
      await sleep(100);
    }

    if (!this.errorsSummaryCache.has(importId) || forceRefresh) {
      this.fetchingImportIds.add(importId);

      const summary = await this.http
        .get<ImportErrorsSummary>(`${this.importServiceURL}/import-errors-summary?importId=${importId}`)
        .pipe(
          map(_summary => ({
            ..._summary,
            errors: _summary.errors.map(error => ({ ...error, ErrorType: error.IsError ? 'Error' : 'Warning' }))
          }))
        )
        .toPromise();

      this.errorsSummaryCache.set(importId, summary);
      this.fetchingImportIds.delete(importId);
    }

    return this.errorsSummaryCache.get(importId);
  };

  public getErrorLogCsvZipBlob(dataSource: ImportedDataSource) {
    return this.http
      .get(`${this.importServiceURL}/import-errors-zip-blob/?importId=${dataSource?.DataImportID}`, {
        responseType: 'arraybuffer',
        observe: 'response'
      })
      .pipe(map(response => new Blob([response.body], { type: 'application/octet-stream' })));
  }

  public getErrorLogZipUrl(importId: number) {
    return this.http
      .get<ErrorZip>(`${this.importServiceURL}/import-errors-zip/?importId=${importId}`)
      .pipe(map(this.createErrorZip))
      .toPromise();
  }

  public generateErrorLogZipUrl(importId: number) {
    return this.http
      .get<ErrorZip>(`${this.importServiceURL}/generate-import-errors-zip/?importId=${importId}`)
      .pipe(map(this.createErrorZip))
      .toPromise();
  }

  public cleanUp = () => {
    this.clearErrorsCache();
    this.clearZipTimeouts();
  };

  private readonly clearErrorsCache = () => this.errorsCache.clear();

  private readonly clearZipTimeouts = () => {
    [...zipTimeouts.entries()].forEach(([key, value]) => clearTimeout(value));
  };

  private readonly createImportDetail = (importDetail: ImportedDataSource): ImportedDataSource => {
    const detail = new ImportedDataSource(importDetail);

    detail.ImportDate = detail.ImportDate ? new Date(detail.ImportDate) : null;
    detail.ErrorsSummary = this.errorsSummaryCache.get(importDetail.DataImportID) ?? null;
    return detail;
  };

  private readonly createErrorZip = (errorZip: ErrorZip): ErrorZip => {
    const url = errorZip?.url ? `${this.apiUrl}/${errorZip.url}` : null;
    return new ErrorZip(errorZip.importId, url, new Date(errorZip.expireAt), errorZip?.isGeneratingZip);
  };
}
