<app-notifications></app-notifications>
<div id="side_navigation_inner">
  <emp-navigation-header modalTitle="Dataverse" [pinned]="pinned" [@fadeInOut]="navigationElements"></emp-navigation-header>
  <div class="sub-heading" *ngIf="active">{{ customerName }}</div>
  <emp-navigation-divider></emp-navigation-divider>

  <div class="body" [@fadeInOut]="navigationElements" *ngIf="navigationElements">
    <app-as-of-date-selection (dateSelected)="onDateSelected($event)"></app-as-of-date-selection>
    <emp-navigation-divider></emp-navigation-divider>
    <emp-model-data-navigation></emp-model-data-navigation>
    <emp-navigation-divider></emp-navigation-divider>
    <emp-navigation-element [element]="element" *ngFor="let element of navigationElements"></emp-navigation-element>
  </div>

  <emp-navigation-profile [@fadeInOut]="navigationElements"></emp-navigation-profile>
</div>
