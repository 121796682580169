import * as GC from '@grapecity/spread-sheets';

export const addDatePickerStyleToCell = (sheet: GC.Spread.Sheets.Worksheet, row: number, col: number) => {
  const showTimeStyle = getDatePickerStyle();
  sheet.setStyle(row, col, showTimeStyle);
  sheet.getCell(row, col).hAlign(GC.Spread.Sheets.HorizontalAlign.left).formatter('MM/DD/YYYY');
};

export const addSelectDropDownToCell = (
  sheet: GC.Spread.Sheets.Worksheet,
  row: number,
  col: number,
  items: IDropdownItem[],
  options?: ISpreadDropdownOptions | object
) => {
  if (options && options.hasOwnProperty('items') && items) {
    throw new Error('Cannot provide options items and data values to multiselect');
  }

  const originalStyle = sheet.getStyle(row, col);
  const style = getDropDownStyle({ ...options, items }, originalStyle);

  // const comboBox = new GC.Spread.Sheets.CellTypes.ComboBox();
  // comboBox.editorValueType(GC.Spread.Sheets.CellTypes.EditorValueType.value);
  // comboBox.items(items);
  // sheet.getCell(row, col).cellType(comboBox);

  // // Additional default style options
  // style.locked = false;
  // style.cellPadding = '0 5';
  // style.vAlign = GC.Spread.Sheets.VerticalAlign.center;
  // style.hAlign = GC.Spread.Sheets.HorizontalAlign.left;

  sheet.setStyle(row, col, style);
};

export const addSelectDropDownToCellWithSearch = (
  sheet: GC.Spread.Sheets.Worksheet,
  row: number,
  col: number,
  items: IDropdownItem[],
  options?: ISpreadDropdownOptions | object
) => {
  if (options && options.hasOwnProperty('items') && items) {
    throw new Error('Cannot provide options items and data values to multiselect');
  }

  const originalStyle = sheet.getStyle(row, col);
  const style = getDropDownStyleWithSearch({ ...options, items }, originalStyle);

  // // Additional default style options
  // style.locked = false;
  // style.cellPadding = '0 5';
  // style.vAlign = GC.Spread.Sheets.VerticalAlign.center;

  sheet.setStyle(row, col, style);
};

export const getDatePickerStyle = (): GC.Spread.Sheets.Style => {
  const showTimeStyle = new GC.Spread.Sheets.Style();
  showTimeStyle.cellButtons = [
    {
      position: GC.Spread.Sheets.ButtonPosition.right,
      imageType: GC.Spread.Sheets.ButtonImageType.ellipsis,
      visibility: GC.Spread.Sheets.ButtonVisibility.onSelected,
      command: 'openDateTimePicker',
      useButtonStyle: false
    }
  ];
  showTimeStyle.dropDowns = [
    {
      type: GC.Spread.Sheets.DropDownType.dateTimePicker,
      option: {
        startDay: 7,
        calendarPage: 3,
        showTime: false
      }
    }
  ];
  return showTimeStyle;
};

export const getDropDownStyle = (option: ISpreadDropdownOptions | any, style: GC.Spread.Sheets.Style): GC.Spread.Sheets.Style => {
  if (!style) {
    style = new GC.Spread.Sheets.Style();
  }

  style.cellButtons = [
    {
      imageType: GC.Spread.Sheets.ButtonImageType.ellipsis,
      visibility: GC.Spread.Sheets.ButtonVisibility.onSelected,
      command: 'openList',
      useButtonStyle: false
    }
  ];

  style.dropDowns = [{ type: GC.Spread.Sheets.DropDownType.list, option }];
  style.formatter = spreadFormatter.text;

  return style;
};

export const getDropDownStyleWithSearch = (option: ISpreadDropdownOptions | any, style: GC.Spread.Sheets.Style): GC.Spread.Sheets.Style => {
  if (!style) {
    style = new GC.Spread.Sheets.Style();
  }

  style.cellButtons = [
    {
      imageType: GC.Spread.Sheets.ButtonImageType.ellipsis,
      visibility: GC.Spread.Sheets.ButtonVisibility.onSelected,
      command: 'openList',
      useButtonStyle: false
    },
    {
      useButtonStyle: false,
      imageType: GC.Spread.Sheets.ButtonImageType.search,
      position: GC.Spread.Sheets.ButtonPosition.right,
      command: 'search'
    }
  ];

  style.dropDowns = [{ type: GC.Spread.Sheets.DropDownType.list, option }];
  style.formatter = spreadFormatter.text;

  return style;
};

export interface ISpreadDropdownOptions {
  multiSelect?: boolean;
  items?: IDropdownItem[] | any[];
}

export interface IDropdownItem {
  text?: string;
  value: any;
}

/**
 *
 * @param dropdownText Provided Text to use in Drop Down
 * @param multiString Determines if need to consider several strings in same Drop Down (i.e. FirstName + LastName)
 * @returns fixed string with 3 dots (...) in case string is more than indicated size
 */
export const fixLargeTextDropdown = (dropdownText: string, multiString: boolean = false) => {
  const singleStringSize = 43;
  const multiStringSize = 23;
  const stringSize = multiString ? multiStringSize : singleStringSize;
  const text = dropdownText.trim();

  return text.length > stringSize ? `${text.substring(0, stringSize - 3)}...` : text;
};

export enum spreadFormatter {
  text = '@',

  currency = '_($* #,##0.00_);_($* (#,##0.00);_($* "-"??_);_(@_)',
  ACCOUNTING = '_(* #,##0_);_([Red]* (#,##0);_(* "-"??_);_(@_)',
  CURRENCY = '* ###,##0;[Red]* (###,##0)',
  CURRENCY_TWO_DECIMALS = '#,##0.00;[Red]#,##0.00',

  twoDecimals = '0.00',
  sixDecimals = '0.000000',

  MM_DD_YYYY = 'MM/DD/YYYY',
  MONTH_YEAR = 'mmm-yyyy',

  PERCENTAGE = '0.0### %_);[Red](0.0### %)', // 4 decimals
  PERCENTAGE_2_DECIMALS = '0.0# %_);[Red](0.0# %)', // 2 decimals
  PERCENTAGE_4_DECIMALS = '0.0000 %_);[Red](0.0000 %)', // 4 decimals;
  PERCENTAGE_ROUNDED = '0 %_);[Red](0 %)', // Rounded
  PERCENTAGE_NON_NEGATIVE = '0.0# %_)',
  PERCENTAGE_DASH_FOR_ZERO = '0%;-0%;"-"'
}

export const spreadFormatters2 = {};
