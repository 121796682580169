/**
 * Represents a reporting date of the customers available data.
 */
export interface IAsOfDate {
  /** Date string */
  Date: string;
  /** Whether the AsOfDate is locked for editing or not */
  IsLocked: boolean;
  /** Whether the GL Recon results for the AsOfDate are locked for editing or not */
  IsLockedRecon: boolean;
  /** Whether the FTP results for the AsOfDate are locked for editing or not */
  IsLockedFTP: boolean;
  /** Indicates if all the data has been imported for the date */
  ImportState: ImportState;
}

/**
 * Describes the import state of an AsOfDate.
 */
export type ImportState = 'COMPLETE' | 'INCOMPLETE';

/**
 * Describes the import state of an as of date.
 * - COMPLETE means all data sources have been successfully imported for the date
 * - INCOMPLETE means that not all data sources have not been imported for the date
 */
export const IMPORT_STATES = {
  COMPLETE: 'COMPLETE',
  INCOMPLETE: 'INCOMPLETE'
};

/**
 * Represents a reporting date of the customers available data.
 */
export class AsOfDate implements IAsOfDate {
  /** Date string */
  public Date: string;
  /** Whether the AsOfDate is locked for editing or not */
  public IsLocked: boolean;
  /** Whether the GL Recon results for the AsOfDate are locked for editing or not */
  public IsLockedRecon: boolean;
  /** Whether the FTP results for the AsOfDate are locked for editing or not */
  public IsLockedFTP: boolean;
  /** Whether the GL Reconciliation calculation is Dirty for this date */
  public IsGlReconDirty: boolean;
  /** Indicates if all the data has been imported for the date */
  public ImportState: ImportState;
  /** Indicates if Last Day of the month data has been imported for the date */
  public HasEndOfMonth: boolean;
  /** Returns formatted date string for display */
  public get niceDate() {
    return getNiceAsOfDate(this.Date);
  }

  constructor(...args: any[]) {
    Object.assign(this, ...args);
  }
}

/**
 * Returns a nicely formatted date string based on the input date.
 * @param date - The input date string in the format 'YYYY-MM-DD'.
 * @returns A nicely formatted date string in the format 'Month Year'.
 */
const getNiceAsOfDateString = (date: string): string => {
  if (!date) {
    return null;
  }
  const month = +date.substring(5, 7);
  const year = +date.substring(0, 4);
  const day = +date.substring(8);
  return new Date(Date.UTC(year, month - 1, day)).toLocaleDateString('en-us', { year: 'numeric', month: 'long' });
};

/**
 * Returns a nicely formatted date string based on the input date.
 * @param date - The input date string in the format 'YYYY-MM-DD'.
 * @returns A nicely formatted date string in the format 'Month Year'.
 */
const getNiceAsOfDateDate = (date: Date): string => {
  return date ? date.toLocaleDateString('en-us', { year: 'numeric', month: 'long' }) : null;
};

/**
 * Returns a nicely formatted date string based on the input date.
 * @param date - The input date string or Date object.
 * @returns A nicely formatted date string in the format 'Month Year'.
 */
export const getNiceAsOfDate = (date: string | Date): string => {
  return typeof date === 'string' ? getNiceAsOfDateString(date) : getNiceAsOfDateDate(date);
};

/**
 * Returns the default active As of Date according to business logic of the as of date import state.
 * @param asOfDates - List of all available As Of Dates.
 * @returns The default active As Of Date.
 */
export const findDefaultActiveAOD = (asOfDates: AsOfDate[]): AsOfDate => {
  if (!asOfDates || (asOfDates && asOfDates.length === 0)) {
    return null;
  }
  const activeAOD =
    asOfDates.find(asOfDate => asOfDate.ImportState === IMPORT_STATES.INCOMPLETE) ||
    ([].concat(asOfDates.filter(asOfDate => asOfDate.ImportState === IMPORT_STATES.COMPLETE).pop()) as unknown as AsOfDate);
  return activeAOD || null;
};

/**
 * Returns the year and month of the next as of date by finding the most recent date from the list provided.
 * Used by data-source.component to add the next as of date.
 * @param asOfDates - The list of available as of dates.
 * @returns The next year and month.
 */
export const getNextMonthAndYear = (asOfDates: AsOfDate[]): { month: number; year: number } => {
  if (!asOfDates || (asOfDates && asOfDates.length === 0)) {
    return null;
  }
  const mostRecentAOD = findMostRecentAOD(asOfDates);
  if (mostRecentAOD) {
    const baseDate = new Date(mostRecentAOD.Date);
    // -- UTC month starts at 0 for January
    // -- that's why when the month === 11, we set next month to 1
    // -- that's also why we add 2 to get the next month
    const nextMonth: number = baseDate.getUTCMonth() < 11 ? baseDate.getUTCMonth() + 2 : 1;
    const nextYear: number = baseDate.getMonth() < 11 ? baseDate.getUTCFullYear() : baseDate.getUTCFullYear() + 1;
    return { month: nextMonth, year: nextYear };
  } else {
    return null;
  }
};

/**
 * Returns the most recent As Of Date from a list of available as of dates.
 * Used by data-source.component to add the next as of date.
 * @param asOfDates - The list of available as of dates.
 * @returns The latest date from the array of As of Dates provided.
 */
export const findMostRecentAOD = (asOfDates: AsOfDate[]): AsOfDate => {
  if (!asOfDates || (asOfDates && asOfDates.length === 0)) {
    return null;
  }
  const copyOfAODArray = [].concat(asOfDates);
  // -- sort dates chronologically descending
  copyOfAODArray.sort((a, b) => (a.Date > b.Date ? 1 : -1));
  return copyOfAODArray.pop();
};

export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

/**
 * Returns the full name of the month based on the input date.
 * @param date - The input date.
 * @returns The full name of the month.
 */
export const getMonthName = (date: Date) => monthNames[date.getMonth()];

/**
 * Returns the short name of the month based on the input date.
 * @param date - The input date.
 * @param locale - The locale to use for formatting the date. Default is 'en-US'.
 * @returns The short name of the month.
 */
export const getShortMonthName = (date: Date, locale = 'en-US') => date.toLocaleDateString(locale, { month: 'short' });

/**
 * Returns the long name of the month based on the input date.
 * @param date - The input date.
 * @param locale - The locale to use for formatting the date. Default is 'en-US'.
 * @returns The long name of the month.
 */
export const getLongMonthName = (date: Date, locale = 'en-US') => date.toLocaleDateString(locale, { month: 'long' });
