import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigProvider } from '@app/app.config.provider';
import { AppConfigAPI } from '@models/appconfig.d';
import { AccountMapping, AccountMappingSave } from '@models/recon-setting/account-mapping';
import { LocalStorageKeys } from '@models/storage/local-storage-keys';
import { ICanComponentDiscardLocalStorageAndDeactivate } from '@services/auth-guard/can-component-discard-localstorage-guard.service';
import { LocalStorageRepository } from '@services/local-storage/local-storage';
import { deepClone } from '@shared/clone';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ReconSettingAccountMappingService extends LocalStorageRepository implements ICanComponentDiscardLocalStorageAndDeactivate {
  private GLReconURL: string; // Get Active Users
  private environment: AppConfigAPI;
  private initialDatabaseCopy: AccountMapping[];
  private readonly latestDatabaseCopy: AccountMapping[];
  constructor(private readonly http: HttpClient, private readonly appConfig: AppConfigProvider) {
    super(LocalStorageKeys.GL_RECON_ACCOUNT_MAPPING, 'Recon Settings Account Mapping');
    this.appConfig.environment.subscribe(env => {
      this.environment = env.API;
      this.GLReconURL = `${this.environment.DIMENSIONS}/api/Recon`;
    });
  }

  public getLatestDatabaseCopy(): AccountMapping[] {
    return this.latestDatabaseCopy;
  }

  public getReconAccountMapping(): Observable<AccountMapping[]> {
    const url = `${this.GLReconURL}/ReconSetting/AccountMapping`;
    return this.http.get<AccountMapping[]>(url);
  }

  public saveReconAccountMappings(saveRequest: AccountMappingSave): Observable<void> {
    const url = `${this.GLReconURL}/ReconSetting/AccountMapping`;
    return this.http.post<void>(url, saveRequest);
  }

  public setDatabaseReconSettingCopy(accountMapping: AccountMapping[]) {
    this.initialDatabaseCopy = deepClone(accountMapping);
  }

  public isAccountMappingEqualToDBCopy(setting: AccountMapping): boolean {
    const itemInDatabase = this.initialDatabaseCopy?.find(other => other.GeneralLedgerId === setting.GeneralLedgerId);

    const comparisonKeys = ['ReconAccountNumber', 'InstrumentType'];

    // Rest of properties
    if (itemInDatabase && setting) {
      for (const [key, value] of Object.entries(setting)) {
        if (comparisonKeys.includes(key) && value !== itemInDatabase[key]) {
          // any of the keys in comparisonKeys has changed
          return false;
        }
      }
      // no key has changed
      return true;
    }
    // OnDatabase or LegalEntity does not exist anymore
    return false;
  }
}
