import * as GC from '@grapecity/spread-sheets';
import { spreadFormatter } from './spread-styles';

export const excelTextSeparators = {
  row: `\n`,
  col: `\t`
};

export interface ICellRange {
  row: number;
  col: number;
  rowCount: number;
  colCount: number;
}

export interface IEditHistory {
  currentIndex: number;
  data: any[];

  selection: ICellRange[];
}

export const colours = {
  BLACK: '#424242',
  BLUE: '#DCE9F8',
  BRIGHT_BLUE: '#C5E5FF',
  BRIGHT_GREEN: '#59B565',
  DARK_BLUE: '#0072C6',
  DARK_GREEN: '#2E7D32',
  DARK_GREY: '#CFCFCF',
  DARKER_GREY: '#7e8c99',
  GREY: 'RGB(128,128,128)',
  GREEN: '#4CAF50',
  LIGHT_BLUE: '#D9E1F2',
  LIGHT_GREEN: '#E9F6EC',
  LIGHT_GREY: '#EAEDEF',
  LIGHT_RED: 'RGBA(255,0,0,0.15)',
  LIGHT_RED2: '#FFE5E5',
  PALE_BLUE: '#CDE6F7',
  PALE_BLUE2: '#CDE6F7B5',
  RED: 'RGBA(255,0,0,1)',
  SUBTLE_DARK_GRAY: 'RGBA(0,0,0,0.35)',
  SUBTLE_GRAY: 'RGBA(0,0,0,0.2)',
  MENU_BG: '#EEF1F6',
  MENU_BG2: '#E3E6EF',
  MAIN: '2B495E',

  WHITE: '#FFFFFF',
  WHITE2: '#F9F9F9',

  PROPOSED: '#5E91DD',
  REJECTED: '#D75363',
  ACTIVE: '#72BF44',
  INACTIVE: '#FFCD40',
  ACTIVE_BG: '#72BF4420',
  PROPOSED_BG: '#D8E5F7',
  REJECTED_BG: '#F7D9DD',
  INACTIVE_BG: '#FFCD4020',

  // * ┌──────────────────────────┐
  // * │ mnemonicBackground       │
  // * ├──────────────────────────┼────────────────────────┬────────────────────────┐
  // * │ thirdDimensionBackground │ rowBackground          │ rowBackground          │
  // * ├──────────────────────────┼────────────────────────┼────────────────────────┤
  // * │ columnBackground         │ resultsBackground      │ resultsBackground      │
  // * │ columnLightBackground    │ resultsLightBackground │ resultsLightBackground │
  // * └──────────────────────────┴────────────────────────┴────────────────────────┘
  // *

  Lookups: {
    columnBackground: 'rgb(102,139,166)',
    columnLightBackground: 'rgb(129,159,182)',
    rowBackground: 'rgb(129,159,182)',
    thirdDimensionBackground: 'rgb(234,234,234)',
    mnemonicBackground: 'rgb(226,239,218)',
    resultsBackground: 'rgb(234,234,234)',
    resultsLightBackground: 'rgb(255,255,255)'
  }
};

export const borders = {
  BLACK_THIN: new GC.Spread.Sheets.LineBorder(colours.BLACK, GC.Spread.Sheets.LineStyle.thin),
  BLACK_MEDIUM: new GC.Spread.Sheets.LineBorder(colours.BLACK, GC.Spread.Sheets.LineStyle.medium),
  BLACK_THICK: new GC.Spread.Sheets.LineBorder(colours.BLACK, GC.Spread.Sheets.LineStyle.thick),
  BRIGHT_BLUE_MEDIUM: new GC.Spread.Sheets.LineBorder('#00BFFF', GC.Spread.Sheets.LineStyle.medium),
  DARK_GREY_THIN: new GC.Spread.Sheets.LineBorder('#CFCFCF', GC.Spread.Sheets.LineStyle.thin),
  GREEN_NORMAL: new GC.Spread.Sheets.LineBorder('#59B55A', GC.Spread.Sheets.LineStyle.medium),
  GREY_NORMAl: new GC.Spread.Sheets.LineBorder(
    colours.DARK_GREY, // is the same than the DARK_GREY_THIN
    GC.Spread.Sheets.LineStyle.medium
  ),
  RED: new GC.Spread.Sheets.LineBorder('#FF2222', GC.Spread.Sheets.LineStyle.medium),
  LIGHT_BLUE: new GC.Spread.Sheets.LineBorder('#7CB5EC', GC.Spread.Sheets.LineStyle.medium),
  LIGHT_NAVY: new GC.Spread.Sheets.LineBorder('#788B98', GC.Spread.Sheets.LineStyle.medium),
  NAVY_NORMAL: new GC.Spread.Sheets.LineBorder('#2B495E', GC.Spread.Sheets.LineStyle.medium),
  ORANGE_DASHED: new GC.Spread.Sheets.LineBorder('#FF6700', GC.Spread.Sheets.LineStyle.mediumDashDot),
  PALE_BLUE_MEDIUM: new GC.Spread.Sheets.LineBorder('#788b98', GC.Spread.Sheets.LineStyle.medium),
  SUBTLE_DARK: new GC.Spread.Sheets.LineBorder(colours.SUBTLE_DARK_GRAY, GC.Spread.Sheets.LineStyle.medium),
  SUBTLE: new GC.Spread.Sheets.LineBorder(colours.SUBTLE_GRAY, GC.Spread.Sheets.LineStyle.thin),
  ALM_EXPORT_TABLE: new GC.Spread.Sheets.LineBorder(colours.MENU_BG2, GC.Spread.Sheets.LineStyle.thin),
  colour: (colour: string) => new GC.Spread.Sheets.LineBorder(colour, GC.Spread.Sheets.LineStyle.medium)
};

export const SPREAD_FONT_NAME = 'Source Sans Pro';
export const SPREAD_FONT_SIZE = 11;

export const spreadFont = {
  title: `bold ${SPREAD_FONT_SIZE + 1}pt ${SPREAD_FONT_NAME}`,
  bold: `bold ${SPREAD_FONT_SIZE}pt ${SPREAD_FONT_NAME}`,
  normal: `normal ${SPREAD_FONT_SIZE}pt ${SPREAD_FONT_NAME}`,
  italics: `italic ${SPREAD_FONT_SIZE}pt ${SPREAD_FONT_NAME}`
};

export const hostStyle = { width: '100%', height: '100%' };
export const spreadBackColor = 'white';

export const defaultBookOptions = {
  allowContextMenu: true,
  allowCopyPasteExcelStyle: true,
  allowExtendPasteRange: false,
  allowUndo: true,
  allowUserDragDrop: false,
  allowUserEditFormula: false,
  autoFitType: GC.Spread.Sheets.AutoFitType.cellWithHeader,
  defaultDragFillType: GC.Spread.Sheets.Fill.AutoFillType.copyCells,
  enableFormulaTextbox: false,
  highlightInvalidData: true,
  newTabVisible: false,
  scrollbarMaxAlign: true,
  scrollbarShowMax: true,
  scrollIgnoreHidden: true,
  showDragFillSmartTag: false,
  showHorizontalScrollbar: true,
  showScrollTip: GC.Spread.Sheets.ShowScrollTip.none,
  tabNavigationVisible: false,
  tabStripVisible: false
} as GC.Spread.Sheets.IWorkbookOptions;

export interface ISpreadColumn {
  key: string;
  displayName: string;
  width?: number;
  formatter?: spreadFormatter;
  isProtected?: boolean;
}

export type ISpreadColumnMap<T> = {
  [key in keyof T]: number;
};

export const getColumnMapping = <T>(columns: ISpreadColumn[]): ISpreadColumnMap<T> =>
  columns.reduce((acc, cur, index) => ({ ...acc, [cur.key]: index }), {} as { [key in keyof T]: number });

export const boolToYesNoDictionary = [
  { booleanValue: true, yesNoValue: 'Yes' },
  { booleanValue: false, yesNoValue: 'No' }
];

export const boolToYNDictionary = [
  { booleanValue: true, YNValue: 'Y' },
  { booleanValue: false, YNValue: 'N' }
];

const changeStyleBorder = (style: GC.Spread.Sheets.Style, borderStyle: GC.Spread.Sheets.LineBorder) => {
  style.borderLeft = borderStyle;
  style.borderRight = borderStyle;
  style.borderTop = borderStyle;
  style.borderBottom = borderStyle
}

export const getLockedCellStyle = (): GC.Spread.Sheets.Style => {
  const style = new GC.Spread.Sheets.Style();
  style.name = "locked_style";
  style.backColor = colours.LIGHT_GREY;
  style.locked = true;
  const borderStyle = borders.SUBTLE;
  changeStyleBorder(style, borderStyle);
  return style;
}

export const getDefaultCellStyle = (overrideForeColor: string = "black"): GC.Spread.Sheets.Style => {
  const style = new GC.Spread.Sheets.Style();
  style.name = "default_style";
  style.backColor = colours.WHITE;
  style.foreColor = overrideForeColor;
  const borderStyle = borders.SUBTLE;
  changeStyleBorder(style, borderStyle);
  return style;
};

export const getModifiedCellStyle = (): GC.Spread.Sheets.Style => {
  const style = new GC.Spread.Sheets.Style();
  style.name = "modified_style";
  style.backColor = colours.BLUE;
  const borderStyle = borders.SUBTLE;
  changeStyleBorder(style, borderStyle);
  return style;
}

export const getModifiedLightBlueCellStyle = (): GC.Spread.Sheets.Style => {
  const style = new GC.Spread.Sheets.Style();
  style.name = "modified_light_blue_style";
  style.backColor = colours.LIGHT_BLUE;
  style.foreColor = colours.DARK_BLUE;
  const borderStyle = borders.SUBTLE;
  changeStyleBorder(style, borderStyle);
  return style;
};

export const getInvalidRowStyle = (overrideForeColor: string = colours.BLACK): GC.Spread.Sheets.Style => {
  const style = new GC.Spread.Sheets.Style();
  style.name = "invalid_row_style";
  style.foreColor = overrideForeColor;
  style.backColor = colours.LIGHT_RED2;
  const borderStyle = borders.SUBTLE;
  changeStyleBorder(style, borderStyle);
  return style;
}

export const getInvalidCellStyle = (): GC.Spread.Sheets.Style => {
  const style = new GC.Spread.Sheets.Style();
  style.name = "invalid_cell_style";
  style.foreColor = colours.RED;
  style.backColor = colours.LIGHT_RED2;
  const borderStyle = borders.RED;
  changeStyleBorder(style, borderStyle);
  return style;
}
