import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigProvider } from '@app/app.config.provider';
import { BehaviorSubject, Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BIService {
  private biUrl: string;
  public readonly aitUpdatesTooltipMessage: string = 'Reporting updates are in progress';
  constructor(private readonly http: HttpClient, private readonly appConfig: AppConfigProvider) {
    this.appConfig.environment.pipe(take(1)).subscribe(env => {
      this.biUrl = `${env.API.DIMENSIONS}/api/bi`;
    });
  }
  private readonly _updateAITProcessRunning$ = new BehaviorSubject<boolean>(true);
  public get getAITProcessRunningObservable() {
    return this._updateAITProcessRunning$.asObservable();
  }
  private set _setAITProcessRunning(running: boolean) {
    this._updateAITProcessRunning$.next(running);
  }
  public updateAITProcessRunning(running: boolean): void {
    this._setAITProcessRunning = running;
  }

  public updateAllInstrumentsTable(asOfDate: string): Observable<Object> {
    return this.http.put(`${this.biUrl}/ait/${asOfDate}`, null);
  }

  public deleteAllInstrumentsTableData(asOfDate: string): Observable<Object> {
    return this.http.delete(`${this.biUrl}/ait/${asOfDate}`);
  }
}
