import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ITreeConfig } from '@models//tree/tree-config';

@Component({
  selector: 'app-tree-panel',
  templateUrl: './tree-panel.component.html',
  styleUrls: ['./tree-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TreePanelComponent {
  public isAllNodesExpanded: boolean;
  @Input() public hierarchyName: ITreeConfig[];
  @Input() public treeConfigs: ITreeConfig[];
  @Input() public isToggleHidden?: boolean;
  @Output() public isDeleting = new EventEmitter<boolean>();

  public onToggleSwitch(isExpandedAll: boolean) {
    this.isAllNodesExpanded = isExpandedAll;
  }
  public isDeletingUnallocated(event) {
    this.isDeleting.emit(event);
  }
}
