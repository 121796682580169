import Swal, { SweetAlertResult } from 'sweetalert2';

export async function openMaxNumOfRecordsDialog(): Promise<SweetAlertResult<any>> {
  return await Swal.fire({
    icon: 'warning',
    html:
      `Number of results for the chosen dimensions exceeds 1000.` +
      `<p>Please reduce the selected number of columns or choose different dimensions.`,
    heightAuto: false,
    showCancelButton: false,
    reverseButtons: true,
    allowEscapeKey: false
  });
}
