import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DropdownlistFilterComponent } from '@core/kendo/dropdownlist-filter/dropdownlist-filter.component';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { DropDownListModule, DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { GridModule } from '@progress/kendo-angular-grid';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { ToolBarModule } from '@progress/kendo-angular-toolbar';

const kendoModules = [
  ButtonsModule,
  CommonModule,
  DateInputsModule,
  DialogsModule,
  DropDownListModule,
  DropDownsModule,
  FormsModule,
  LayoutModule,
  ToolBarModule,
  GridModule
];

@NgModule({
  declarations: [DropdownlistFilterComponent],
  imports: kendoModules,
  exports: [...kendoModules, DropdownlistFilterComponent]
})
export class KendoModule {}
