import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfigProvider } from '@app/app.config.provider';
import { OpenIdConnectService } from '@services/openid/openid-connect.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html'
})
export class UserProfileComponent implements OnInit {
  @Input() userAreaOpen = false;
  public appVersion: string;

  constructor(
    public readonly auth: OpenIdConnectService,
    private readonly router: Router,
    private readonly appConfig: AppConfigProvider
) {
    this.appConfig.environment.subscribe(env => {
      this.appVersion = env.VERSION.BUILDNUMBER;
    });
  }

  ngOnInit(): void {}

  public async logout() {
    const canDeactivate = await this.router.navigate(['/signout-oidc']);
    if (canDeactivate) {
      await this.auth.triggerSignOut();
    }
  }
}
