import { Component, OnDestroy, OnInit } from '@angular/core';
import { IHoverNotification } from '@models/hover-notification';
import { NotificationService } from '@services/notification/notification.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-hover-notification',
  templateUrl: './hover-notification.component.html',
  styleUrls: ['./hover-notification.component.scss']
})
export class HoverNotificationComponent implements OnInit, OnDestroy {
  public destroyed$ = new Subject();
  public notification: IHoverNotification;

  constructor(private readonly notifications: NotificationService) {}

  public ngOnInit() {
    this.notifications.hoverMessage$.pipe(takeUntil(this.destroyed$)).subscribe(notification => (this.notification = notification || null));
  }

  public ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
