import { Component, OnInit } from '@angular/core';
import { OpenIdConnectService } from '@services/openid/openid-connect.service';

@Component({
  selector: 'app-silent-renew-redirect',
  templateUrl: './silent-renew-redirect.component.html'
})
export class SilentRenewRedirectComponent implements OnInit {
  constructor(private readonly openIdConnectService: OpenIdConnectService) {}

  public ngOnInit() {
    this.openIdConnectService.handleSilentCallback();
  }
}
