import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { ITreeConfig } from '@models/tree/tree-config';
import { DeletionButtonService, UNALLOCATED_TYPES } from '@services/tree/deletion-button.service';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-deletion-button',
  templateUrl: './deletion-button.component.html',
  styleUrls: ['./deletion-button.component.scss']
 
})
export class DeletionButtonComponent implements OnInit, OnDestroy, OnChanges {
  public isPressed = false;
  @Input() protected treeConfig: ITreeConfig[];
  @Input() protected hierarchyName: string;
  @Output() public isDeleting: EventEmitter<boolean> = new EventEmitter();
  public btnTxt;
  public unallocatedFlag = false;
  public unallocatedObs;
  public unallocatedCheckObs;
  public title;
  private readonly subscribtions: Subscription = new Subscription();

  constructor(private readonly deleteService: DeletionButtonService, private readonly cd: ChangeDetectorRef) { 
    deleteService.deletionButton = this;
  }

  public ngOnInit(): void {
    this.btnTxt = UNALLOCATED_TYPES[this.hierarchyName?.toLocaleLowerCase()] === 2 ? 'Remove Unallocated Departments' : 'Remove Unallocated Accounts';
    this.title = UNALLOCATED_TYPES[this.hierarchyName?.toLocaleLowerCase()] === 2 ? 'Remove Unallocated Departments that are not tied to imported data'
                                                                                   : 'Remove Unallocated Accounts that are not tied to imported data';
  }

  public ngOnDestroy(){
    this.subscribtions.unsubscribe();
  }

  public ngOnChanges(changes: SimpleChanges){
    if (changes.hierarchyName && changes.hierarchyName.currentValue) {
      this.refresh();
    }
  }


  public refresh(){
    this.isPressed = false;
    this.subscribtions.add(this.deleteService.checkUnallocated(this.hierarchyName).subscribe( (ud) => {
      this.unallocatedFlag = ud;
      // -- parent TreePanelComponent has OnPush change detection
      this.cd.detectChanges();
    }));
  }

  public isVisible(){
    return this.unallocatedFlag && !this.isPressed;
  }

  public deleteUnalocatedAccounts(){
    this.isDeleting.emit(true);
    const deleteTypeName = UNALLOCATED_TYPES[this.hierarchyName?.toLocaleLowerCase()];
    this.unallocatedObs = this.deleteService.deleteUnalocatedItems(deleteTypeName);
    this.unallocatedObs.subscribe(async ( da ) => {
      await this.unallocatedDeletionPopUp(da.length ? da.length : 0);
      this.isDeleting.emit(false);
    });
    this.subscribtions.add(this.unallocatedObs);
    this.isPressed = true;
  }

  public async unallocatedDeletionPopUp(accountDeleted: number): Promise<boolean> {
    const promise: Promise<boolean> = new Promise<boolean>((resolve, reject) => {
      Swal.fire({
        title: UNALLOCATED_TYPES[this.hierarchyName.toLocaleLowerCase()] === 2 ? `Remove Unallocated Departments Complete` : `Remove Unallocated Accounts Complete`,
        icon: 'info',
        html: accountDeleted > 0 &&  UNALLOCATED_TYPES[this.hierarchyName.toLocaleLowerCase()] !== 2 ?
              `<p>All unallocated accounts that are not tied to import data have been deleted.</p>
              <br>
              <p><strong>Number of Deleted Accounts: ${accountDeleted}</strong></p>`
              :`<p>No unallocated accounts that are not tied to import data have been deleted.</p>
              <br>
              <p><strong>Number of Deleted Accounts: ${accountDeleted}</strong></p>`,
        showCancelButton: false,
        confirmButtonText: 'Close',
        reverseButtons: true,
        allowEscapeKey: true,
        customClass: {
          confirmButton: 'swal_confirm_btn'
        },
        buttonsStyling: true
      })
        .then(result => {
          if (result && result.value) {
            resolve(true);
          } else {
            resolve(false);
          }
        })
        .catch(err => {
          reject(true);
        });
    });

    return promise;
  }

}
