<!-- Toggle Switch -->
<div class="main-container">
  <div class="toggle-switch clearfix">
    <div class="toggle-switch-option">
      <input type="radio" class="toggle-switch-radio" id="tree-expand" name="tree-view-controller" (click)="expandAll()" />
      <label for="tree-expand">Expand All</label>
    </div>
    <div class="toggle-switch-option">
      <input type="radio" class="toggle-switch-radio" id="tree-collapse" name="tree-view-controller" checked="" (click)="collapseAll()" />
      <label for="tree-collapse">Collapse All</label>
    </div>
  </div>
</div>
