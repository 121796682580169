import { Flags } from '@models/assignments/assignments';

export class AssignmentFlag {
  public flag: Flags;
  public dataSourceId: number;
  public assignmentValue: number;
  public useOtherValue: string;
}

export enum selectedRadioValues {
  ALMImportedValue = 'ImportedValue',
  ALMAssignmentRuleset = 'AssignmentRuleset',
  ALMAssignmentValue = 'AssignmentValue',
  ALMSingleValue = 'SingleValue',

  BUDImportedValue = 'ImportedBudValue',
  BUDAssignmentRuleset = 'AssignmentBudRuleset',
  BUDAssignmentValue = 'AssignmentBudValue',
  BUDSingleValue = 'SingleBudValue',

  GLBSImportedValue = 'ImportedGLBSValue',
  GLBSAssignmentRuleset = 'AssignmentGLBSRuleset',
  GLBSAssignmentValue = 'AssignmentGLBSValue',
  GLBSSingleValue = 'SingleGLBSValue',

  GLISImportedValue = 'ImportedGLISValue',
  GLISAssignmentRuleset = 'AssignmentGLISRuleset',
  GLISAssignmentValue = 'AssignmentGLISValue',
  GLISSingleValue = 'SingleGLISValue',

  SubProdImportedValue = 'ImportedSubProductValue',
  SubProdAssignmentRuleset = 'AssignmentSubProductRuleset',
  SubProdAssignmentValue = 'AssignmentSubProductValue',
  SubProdSingleValue = 'SingleSubProductValue'
}

export const ASSIGNMENT_VALUE = ['ALMAccountNum', 'BUDAccountNum', 'GL_BS_Acct', 'GL_IS_Acct', 'SubProductID'];

export enum localStorageLoc {
  ALMColumns = 11,
  ALMNonAlias = 12,
  ALMAlias = 13,
  ALMAssignmentValue = 14,
  ALMSingleValue = 15,
  ALMSingleValueBS = 151,
  ALMSingleValueIS = 152,

  BUDColumns = 21,
  BUDNonAlias = 22,
  BUDAlias = 23,
  BUDAssignmentValue = 24,
  BUDSingleValue = 25,
  BUDSingleValueBS = 251,
  BUDSingleValueIS = 252,

  GLBSColumns = 31,
  GLBSNonAlias = 32,
  GLBSAlias = 33,
  GLBSAssignmentValue = 34,
  GLBSSingleValue = 35,

  GLISColumns = 41,
  GLISNonAlias = 42,
  GLISAlias = 43,
  GLISAssignmentValue = 44,
  GLISSingleValue = 45,

  SPColumns = 51,
  SPNonAlias = 52,
  SPAlias = 53,
  SPAssignmentValue = 54,
  SPSingleValue = 55
}
