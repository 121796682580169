import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { IEmpTab } from '@core/UI/emp-tab-strip/emp-tab-strip.component';
import { ModalType } from '@core/navigation/navigation-elements';
import { INavigationExtendedElement } from '@core/navigation/navigation-interfaces';
import { Assignment } from '@models/assignments/assignments';
import { IPageValidation } from '@models/page-validation/page-validation';
import { IPage, PAGE_IDS } from '@models/page/page';
import { SelectEvent } from '@progress/kendo-angular-layout/dist/es2015/tabstrip/tabstrip-events';
import { ICanComponentDeactivate } from '@services/auth-guard/can-component-deactivate-guard.service';
import { DataSourcesService } from '@services/data-sources/data-sources.service';
import { ModalScreenService, assignment } from '@services/modal-screen/modal-screen-service';
import { NotificationService } from '@services/notification/notification.service';
import { BodyOutputType, Toast, ToastType, ToasterService } from '@services/toaster/toaster.service';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { ModalScreenBaseComponent, PAGE_TITLE, TAB_IDS } from './modal-screen-base/modal-screen-base.component';
import { PageValidationsComponent } from './page-validations/page-validations.component';
import { AssignmentDimension, DataSourceTypeIds } from '@models/modal-screen/assignment-ruleset/assignment-ruleset-model';
import { AssignmentRulesetsService } from '@services/modal-screen/assignment-ruleset/assignment-ruleset-service';
import { DIMENSIONS_DROPDOWN, TYPES_OF_DATASOURCES, getDropDownValues } from './modal-screen.data';

@Component({
  selector: 'app-modal-screen',
  templateUrl: './modal-screen.component.html',
  styleUrls: ['./modal-screen.component.scss']
})
export class ModalScreenComponent extends ModalScreenBaseComponent implements OnInit, OnDestroy, ICanComponentDeactivate {
  public pageId: number = PAGE_IDS.ALMAccount;
  public tabId: number = TAB_IDS.ALM_ACCOUNT;
  private readonly _page$ = new BehaviorSubject<IPage>({ title: PAGE_TITLE, isLoading: false });

  @ViewChild(PageValidationsComponent)
  private readonly pageValidations: PageValidationsComponent;

  @ViewChild(RouterOutlet) public outlet: RouterOutlet;
  activeTabId: number;
  activeSubTabId: number;
  tabIndex: number;
  subTabSelection: number;
  tab: IEmpTab;
  pageValidation: IPageValidation;
  tooltip: string;
  PageValidations: PageValidationsComponent;
  assignmentDimension: AssignmentDimension[];

  public get page() {
    return this._page$.getValue();
  }

  public get page$() {
    return this._page$.asObservable();
  }

  private readonly modalType: ModalType;
  private readonly subscriptions: Subscription = new Subscription();

  private readonly _cancelAllRequests$ = new Subject<void>();

  public isAdmin = false;
  public isPayrollAdmin = false;
  public isReady = false;
  public menuItems;
  public menuItemsCopy: INavigationExtendedElement;
  public modalTitle = '';
  public selectedTabIndex = -1;
  public DataSourceTypeID = 0;
  public isNMD = false;
  public isGL = false;
  public isAncillary = false;
  private activeTab: IEmpTab;
  public firstLoadedAssignment: number;

  public assignment: Assignment[] = [];

  public idSelected: number;
  public storageId: number;

  public almLocalStorageKey: number;
  public budLocalStorageKey: number;
  public glbsLocalStorageKey: number;
  public glisLocalStorageKey: number;
  public subpLocalStorageKey: number;

  public pages = { ...[this.pageId], isLoading: true };
  public defaultTab = this.tabs[TAB_IDS.ALM_ACCOUNT];
  public defaultAPTab = this.tabs[TAB_IDS.GL_IS_ACCOUNT];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private readonly router: Router,
    private readonly dataSourcesService: DataSourcesService,
    private readonly route: ActivatedRoute,
    private readonly notificationService: NotificationService,
    private readonly modalScreenService: ModalScreenService,
    private readonly toasterService: ToasterService,
    private readonly assignmentRulesetService: AssignmentRulesetsService,
    private cdr: ChangeDetectorRef
  ) {
    super();
    this.modalTitle = this.data.modalTitle;
    this.DataSourceTypeID = this.data.DataSourceTypeID;
    this.modalType = this.data.modalType;
    this.firstLoadedAssignment = this.data.Value;
  }

  get selectedAsOfDate(): string {
    return this.dataSourcesService.selectedAsOfDate?.Date;
  }

  public canDeactivate(): boolean | Promise<boolean> | Observable<boolean> {
    return true;
  }

  public discardLocalStorage(): void {
    this.modalScreenService.clearFromLocalStorage(this.storageId);
  }

  public componentName(): string {
    return 'Modal-screen';
  }

  isDiscardButtonDisabled(): any {
    throw new Error('Method not implemented.');
  }

  public async ngOnInit(): Promise<void> {
    this.route.paramMap.subscribe(async paramMap => {
      this.idSelected = Number(paramMap.get('id'));
      this.storageId = this.idSelected;

      this.data.assignment.forEach(element => {
        if (element.DataSourceID === this.idSelected) {
          this.DataSourceTypeID = element.DataSourceTypeID;
        }
      });

      const tabIndex = Number(paramMap.get('tab'));
      await this.initializeTabs(tabIndex);
      this.identifyTabs(this.DataSourceTypeID); // identify tabs based on DatasourceTypeid
      assignment.length = 0;
      assignment.push(...this.data.assignment);
      this.router.navigate([this.activeTab.routerLink]);
    });
    this.generateLocalStorageKey(this.storageId);
    this.initListeners();
    this.getuserfield();
  }

  public callPageValid() {
    // Call method from PageValidation Componet
    this.pageValidations?._initPageValidationTab(this.idSelected);
  }

  // Initiate tab selections when opens the Modal screen and navigate through the Navigation Panel
  private initializeTabs(tabIndex: number) {
    if (
      this.firstLoadedAssignment !== TAB_IDS.ALM_ACCOUNT ||
      (tabIndex === TAB_IDS.ALM_ACCOUNT && this.DataSourceTypeID != DataSourceTypeIds.AncillaryProducts)
    ) {
      // Select the first tab which is ALMAccountNum
      const selectedTab = this.tabs[tabIndex];
      this.activeTabId = selectedTab.id;
      this.activeTab = selectedTab;
      // resetting after first load so that navigation panel logic could work
      this.firstLoadedAssignment = TAB_IDS.ALM_ACCOUNT;
    } else if (tabIndex !== TAB_IDS.ALM_ACCOUNT && this.DataSourceTypeID != DataSourceTypeIds.AncillaryProducts) {
      // Select the default tab
      this.activeTabId = this.defaultTab.id;
      this.activeTab = this.defaultTab;
    } else {
      // Select the GL_IS_Acct tab for Ancillary Product
      this.activeTabId = this.defaultAPTab.id;
      this.activeTab = this.defaultAPTab;
    }
  }

  private async getuserfield() {
    this.assignmentDimension = await this.assignmentRulesetService.getAssignmentDimensions(this.idSelected);

    const isPropertyTrue = (property: string) => property === 'True';

    this.assignmentDimension.forEach(inputObj => {
      Object.keys(inputObj).forEach(key => {
        if (isPropertyTrue(inputObj[key])) {
          const qwKey = Object.keys(TYPES_OF_DATASOURCES).find(qwKey => TYPES_OF_DATASOURCES[qwKey] === TYPES_OF_DATASOURCES[key]);
          if (qwKey) {
            const mappedArray = getDropDownValues().get(TYPES_OF_DATASOURCES[qwKey]);
            if (mappedArray) {
              const valLength = mappedArray.length;
              const mapObject = { value: valLength.toString(), text: inputObj.UserFieldName, table: '0' };

              if (!mappedArray.some(item => item.text === mapObject.text)) {
                DIMENSIONS_DROPDOWN.set(TYPES_OF_DATASOURCES[qwKey], [...mappedArray, mapObject]);
              }
            }
          }
        }
      });
    });
  }

  //  to handle and react to the data emitted from the child component,
  //  it serves as a callback function for processing and updating the state of the parent component
  public handleDataEvent(data: { tab: IEmpTab; pageValidation: IPageValidation; tooltip: string }) {
    const tab = data.tab;
    const pageValidation = data.pageValidation;
    const tooltip = data.tooltip;

    this.pageValidations?.setTabDataEvent(this.tabs, tab, pageValidation, tooltip);

    // Manually trigger change detection after making changes to avoid ExpressionChangedAfterItHasBeenCheckedError
    this.cdr.detectChanges();
  }

  public onTabSelectedEvent = async (event: SelectEvent) => {
    event.preventDefault();
    const selectedTab = this.tabs[event.index];
    if (!selectedTab.disabled) {
      const success = await this.router.navigate([selectedTab.routerLink]);
      if (success) {
        this.activeTabId = selectedTab.id;
      }
    }
  };

  private async generateLocalStorageKey(storageId: number): Promise<void> {
    this.almLocalStorageKey = storageId * 1000 + 1;
    this.budLocalStorageKey = storageId * 1000 + 2;
    this.glbsLocalStorageKey = storageId * 1000 + 3;
    this.glisLocalStorageKey = storageId * 1000 + 4;
    this.subpLocalStorageKey = storageId * 1000 + 5;
  }

  private identifyTabs(DataSourceTypeID: number): void {
    this.isNMD = DataSourceTypeID === DataSourceTypeIds.NonMaturityDeposits;
    this.isGL = DataSourceTypeID === DataSourceTypeIds.GL;
    this.isAncillary = DataSourceTypeID === DataSourceTypeIds.AncillaryProducts;
  }

  public showToast = (body: string, title: string, type: ToastType = 'success') => {
    const toast: Toast = { type, title, body, showCloseButton: true, timeout: 5000, bodyOutputType: BodyOutputType.TrustedHtml };
    this.toasterService.pop(toast);
  };

  private initListeners(): void {
    this.subscriptions.add(
      this.router.events.subscribe(e => {
        if (e instanceof NavigationEnd) {
          setTimeout(() => {
            if (e.urlAfterRedirects.includes('account-assignment')) {
              this.route.paramMap.subscribe(paramMap => {
                this.idSelected = Number(paramMap.get('id'));
                this.callPageValid();
                this.storageId = this.idSelected;
              });
              this.data.assignment.forEach(element => {
                if (element.DataSourceID === this.idSelected) {
                  this.DataSourceTypeID = element.DataSourceTypeID;
                }
              });

              this.identifyTabs(this.DataSourceTypeID);
              this.generateLocalStorageKey(this.storageId);
            }
          }, 100);
        }
      })
    );
  }

  public ngOnDestroy(): Promise<void> {
    this._cancelAllRequests$.next();
    this.subscriptions.unsubscribe();
    this.notificationService.hoverMessage$.next(null);
    return;
  }

  private hasLocalStorage(): boolean {
    const balanceLocalStorage = this.modalScreenService.fetchFromLocalStorage(this.almLocalStorageKey);
    const incomeLocalStorage = this.modalScreenService.fetchFromLocalStorage(this.budLocalStorageKey);
    if (balanceLocalStorage || incomeLocalStorage) {
      return true;
    }
    return false;
  }
}
