<div class="dialog-container">
  <div class="dialog-header">
    <div class="title">
      <h2>
        {{ header }}
      </h2>
    </div>
    <div class="search-box" cdkTrapFocus>
      <input #inputElement type="text" [(ngModel)]="searchText" placeholder="Search" tabindex="1" cdkFocusRegionStart />
    </div>
    <span class="separator"></span>
  </div>
  <div class="dialog-body thin-scrollbar">
    <div class="item-list" *ngIf="filteredItems.length > 0">
      <ul>
        <li *ngFor="let item of filteredItems">
          <div [title]="item.text" class="item-button" [class.selected]="isSelected(item)" (click)="toggleSelection(item)">
            {{ item.text }}
          </div>
        </li>
      </ul>
    </div>
    <div class="no-list" *ngIf="filteredItems.length === 0">No available items</div>
  </div>
  <div class="dialog-footer">
    <button
      [disabled]="filteredItems.length === 0"
      class="btn_normal btn_wide btn_primary"
      *ngIf="allowMultiSelection"
      (click)="toggleSelectAll()"
    >
      {{ selectAllButtonText }}
    </button>
    <button class="close_modal close close-modal-button btn_normal btn_wide btn_negative" type="button" (click)="closeDialog()">
      {{ isSearchModified ? 'Discard' : 'Close' }}
    </button>
    <button [disabled]="!isSearchModified" class="btn_normal btn_wide btn_green" type="button" (click)="confirmDialog()">Confirm</button>
  </div>
</div>
