import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigProvider } from '@app/app.config.provider';
import { IFTPResultSummaryRow } from '@models/ftp/ftp-results-summary';
import { FTPStatus, IFTPStatus } from '@models/ftp/ftp-status';
import { SharedService } from '@services/shared/shared-service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FtpStatusService {
  private ftpStatusUrl: string;
  constructor(
    private readonly http: HttpClient,
    private readonly appConfig: AppConfigProvider,
    private readonly sharedService: SharedService
  ) {
    this.appConfig.environment.pipe(take(1)).subscribe(env => {
      this.ftpStatusUrl = `${env.API.DIMENSIONS}/api/FTPStatus`;
    });
  }

  private get asOfDate$() {
    return this.sharedService.selectedAsOfDate$;
  }

  public readonly _ftpResults$ = new BehaviorSubject<IFTPResultSummaryRow[]>(null);
  public get ftpResults$() {
    return this._ftpResults$.asObservable();
  }
  public get ftpResults() {
    return this._ftpResults$.getValue();
  }
  private set _ftpResults(ftpSummary: IFTPResultSummaryRow[]) {
    this._ftpResults$.next(ftpSummary);
  }

  private _updateFTPResultsSummary(asOfDate: string): Observable<IFTPResultSummaryRow[]> {
    return this.http.post<IFTPResultSummaryRow[]>(`${this.ftpStatusUrl}/results-summary`, { asOfDate }).pipe(catchError(error => of(null)));
  }

  public refreshFTPsummary(): void {
    this._ftpResults = null;
    this.asOfDate$
      .pipe(
        take(1),
        switchMap(aod => this._updateFTPResultsSummary(aod.Date))
      )
      .subscribe(ftpResults => {
        this._ftpResults = ftpResults;
      });
  }

  public updateAllFTPResultsSummary(): void {
    this.refreshFTPsummary();
  }

  public fetchFTPStatus(asOfDate: string): Observable<FTPStatus> {
    return this.http.get<IFTPStatus>(`${this.ftpStatusUrl}?asOfDate=${asOfDate}`).pipe(
      catchError(error => of(null)),
      map(status => (status ? new FTPStatus(status) : null))
    );
  }

  public fetchFTPResultsSummary(): void {
    this._ftpResults = null;
    this.asOfDate$
      .pipe(
        take(1),
        switchMap(aod => this.getFTPResultsSummary(aod.Date))
      )
      .subscribe(ftpResults => {
        this._ftpResults = ftpResults;
      });
  }

  public getFTPResultsSummary(asOfDate: string): Observable<IFTPResultSummaryRow[]> {
    return this.http
      .get<IFTPResultSummaryRow[]>(`${this.ftpStatusUrl}/results-summary?asOfDate=${asOfDate}`)
      .pipe(catchError(error => of(null)));
  }
}
