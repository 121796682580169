// tslint:disable: no-string-literal
import { Component, OnDestroy, OnInit } from '@angular/core';
import { IEmpTab } from '@core/UI/emp-tab-strip/emp-tab-strip.component';
import { IPageValidation } from '@models/page-validation/page-validation';
import { PAGE_IDS } from '@models/page/page';
import { fadeInOut } from '@shared/animations';

const BASE_PATH = 'account-assignment/assignments';

export const PAGE_TITLE: string = '';
export enum TAB_IDS {
  ALM_ACCOUNT = 0,
  BUD_ACCOUNT = 1,
  GL_BS_ACCOUNT = 2,
  GL_IS_ACCOUNT = 3,
  SUB_PRODUCT = 4
}

export enum SUB_TAB_IDS {
  ALMBalanceSheet = 0,
  ALMIncomeStatement = 1,
  BUDBalanceSheet = 2,
  BUDIncomeStatement = 3
}

export interface ISubTabs {
  id: number;
  subid?: number;
  name: string;
  routerLink: string;
  status?: 'validation-error';
  isModified?: boolean;
  disabled?: boolean;
  pageId?: number;
  pageIds?: number[];
  pageValidation?: IPageValidation;
  tooltip?: string;
}

export const TABS: IEmpTab[] = [
  {
    id: TAB_IDS.ALM_ACCOUNT,
    name: 'ALM Account #',
    routerLink: `${BASE_PATH}/alm-account`,
    pageId: PAGE_IDS.ALMAccount
  },
  {
    id: TAB_IDS.BUD_ACCOUNT,
    name: 'BUD Account #',
    routerLink: `${BASE_PATH}/bud-account`,
    pageId: PAGE_IDS.BUDAccount
  },
  {
    id: TAB_IDS.GL_BS_ACCOUNT,
    name: 'GL BS Accounts',
    routerLink: `${BASE_PATH}/glbs-account`,
    pageId: PAGE_IDS.GLBSAccount
  },
  {
    id: TAB_IDS.GL_IS_ACCOUNT,
    name: 'GL IS Accounts',
    routerLink: `${BASE_PATH}/glis-account`,
    pageId: PAGE_IDS.GLISAccount
  },
  {
    id: TAB_IDS.SUB_PRODUCT,
    name: 'Sub Product #',
    routerLink: `${BASE_PATH}/sub-product`,
    pageId: PAGE_IDS.SubProduct
  }
];

export const SUBTABS: ISubTabs[] = [
  {
    subid: SUB_TAB_IDS.ALMBalanceSheet,
    id: TAB_IDS.ALM_ACCOUNT,
    name: 'BalanceSheet',
    routerLink: `${BASE_PATH}/alm-account/balanceSheet`,
    pageId: PAGE_IDS.BalanceSheetAlmBud
  },
  {
    subid: SUB_TAB_IDS.ALMIncomeStatement,
    id: TAB_IDS.ALM_ACCOUNT,
    name: 'IncomeStatement',
    routerLink: `${BASE_PATH}/alm-account/incomeStatement`,
    pageId: PAGE_IDS.IncomeStatementAlmBud
  },
  {
    subid: SUB_TAB_IDS.BUDBalanceSheet,
    id: TAB_IDS.BUD_ACCOUNT,
    name: 'BalanceSheet',
    routerLink: `${BASE_PATH}/alm-account/balanceSheet`,
    pageId: PAGE_IDS.BalanceSheetAlmBud
  },
  {
    subid: SUB_TAB_IDS.BUDIncomeStatement,
    id: TAB_IDS.BUD_ACCOUNT,
    name: 'IncomeStatement',
    routerLink: `${BASE_PATH}/alm-account/incomeStatement`,
    pageId: PAGE_IDS.IncomeStatementAlmBud
  }
];

@Component({
  selector: 'modal-screen-base',
  template: ``,
  animations: [fadeInOut(300)]
})
export abstract class ModalScreenBaseComponent implements OnInit, OnDestroy {
  public abstract readonly pageId: number;
  public abstract readonly tabId: number;

  public tabs: IEmpTab[] = TABS;

  public subTab: ISubTabs[] = SUBTABS;

  constructor() {}

  public ngOnInit(): void {
    return;
  }

  public async ngOnDestroy() {
    return;
  }
}
