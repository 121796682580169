<div
  id="btn_view_user_container"
  class="dropdown_content min_width"
  [class.show]="userAreaOpen"
  style="bottom: 5px; left: calc(var(--navigation-width) - 5px); top: unset"
>
  <div class="dropdown_content_body">
    <div id="small-version-display">v.{{ appVersion }}</div>
    <ul id="user-details-list" class="user-details-list"></ul>
    <div class="clearfix" *ngIf="auth.userAvailable">
      <a (click)="logout()" class="btn_normal btn_green" id="btn_logout"><span class="btn_text">Logout</span></a>
    </div>
  </div>
</div>
