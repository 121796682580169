import { AfterViewInit, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AppConfigProvider } from '@app/app.config.provider';
import { AppConfigModules } from '@models/appconfig';
import { EmpyreanApplicationName, isUserLevelForApplication } from '@models/user-level';
import { ApplicationUrlService } from '@services/application-switcher/application-url.service';
import { CookieService } from '@services/cookies/cookie.service';
import { AsOfDate } from '@services/cookies/query-params';
import { DataSourcesService } from '@services/data-sources/data-sources.service';
import { OpenIdConnectService } from '@services/openid/openid-connect.service';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'app-application-switcher',
  templateUrl: './application-switcher.component.html',
  styleUrls: ['./application-switcher.component.scss']
})
export class ApplicationSwitcherComponent implements AfterViewInit, OnChanges {
  @Input() public quickLinksOpen = false;
  public moduleUrls: AppConfigModules;

  public get EmpyreanApplicationName() {
    return EmpyreanApplicationName;
  }
  public get asOfDate$() {
    return this.dataSourcesService.selectedAsOfDate$;
  }

  constructor(
    private readonly appConfig: AppConfigProvider,
    public openIdConnectService: OpenIdConnectService,
    private readonly urlService: ApplicationUrlService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly dataSourcesService: DataSourcesService,
    private readonly cookieService: CookieService
  ) {
    combineLatest([this.appConfig.environment, this.asOfDate$]).subscribe(([env, asOfDate]) => {
      const asOfDateString = asOfDate?.Date;
      const modules = { ...env.MODULES };
      if (asOfDateString) {
        modules.PROFITABILITY += `?${AsOfDate}=${asOfDateString}`;
      }
      this.moduleUrls = modules;
    });
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.quickLinksOpen) {
      this.retrieveUrlsForCustomer();
    }
  }

  ngAfterViewInit(): void {
    this.retrieveUrlsForCustomer();
  }

  private retrieveUrlsForCustomer() {
    this.urlService.getUrls().subscribe(urls => {
      let hasChanges = false;
      for (const url of urls) {
        if (url.moduleUrl && this.moduleUrls.hasOwnProperty(url.name.toUpperCase())) {
          this.moduleUrls[url.name.toUpperCase()] = url.moduleUrl;
          hasChanges = true;
        }
      }
      if (hasChanges) {
        this.changeDetector.detectChanges();
        // console.log(this.moduleUrls);
      }
    });
  }

  public isUrlAvailable(moduleName: EmpyreanApplicationName): boolean {
    return this.moduleUrls.hasOwnProperty(moduleName) && this.moduleUrls[moduleName] !== '';
  }

  public isApplicationAvailableToUser(moduleName: EmpyreanApplicationName): boolean {
    return isUserLevelForApplication(moduleName, this.openIdConnectService.userLevelList);
  }

  public isApplicationSwitcherEnabled(moduleName: EmpyreanApplicationName): boolean {
    return this.isApplicationAvailableToUser(moduleName) && this.isUrlAvailable(moduleName);
  }

  public applicationSwitcherTooltip(moduleName: EmpyreanApplicationName): string | null {
    if (!this.isUrlAvailable(moduleName)) {
      return 'This module is not available, please contact your Empyrean representative for more information.';
    }
    if (!this.isApplicationAvailableToUser(moduleName)) {
      return 'You are not authorized to access this module.';
    }

    return null;
  }
}
