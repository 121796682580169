<div class="head">
  <div class="left">
    <div class="as-of-date-container" [class.show]="selectedAsOfDate">
      <div class="as-of-date-title">As of Date:</div>
      <div class="as-of-date">{{ selectedAsOfDate | date : 'MMMM y' }}</div>
    </div>
  </div>
</div>
<div class="body">
  <kendo-tabstrip [keepTabContent]="true" class="kendo-container" (tabSelect)="onTabSelectedEvent($event)">
    <kendo-tabstrip-tab
      *ngIf="!isAncillary"
      [selected]="activeTabId === tabs[0].id"
      [class.next]="tabs[0].pageValidation?.IsSetup === false"
      [class.inactive]="tabs[0].pageValidation?.IsSetup === null"
      [class.warn]="tabs[0].pageValidation?.IsValid === false"
    >
      <ng-template kendoTabTitle>
        <div
          kendoTooltip
          [title]="tabs[0].tooltip"
          class="flex-container horizontal"
          [class.warn]="tabs[0].pageValidation?.IsValid === false"
          [class.next]="tabs[0].pageValidation?.IsSetup === false"
        >
          <div class="tab-icon left" *ngIf="tabs[0].pageValidation?.IsValid === false">
            <i class="fas fa-triangle-exclamation"></i>
          </div>
          <span class="tab-title"> ALMAccountNum </span>
          <div class="tab-icon right" *ngIf="tabs[0].pageValidation?.IsSetup === false">
            <i class="fas fa-wrench"></i>
          </div>
          <div class="tab-icon right" *ngIf="tabs[0].pageValidation && tabs[0].pageValidation.IsSetup === null">
            <i class="fas fa-lock"></i>
          </div>
        </div>
      </ng-template>
      <ng-template kendoTabContent>
        <alm-account
          [localStorageKey]="almLocalStorageKey"
          [dataSourceId]="idSelected"
          [selectAlm]="activeTabId"
          [datasourcetypeid]="DataSourceTypeID"
          class="alm-account"
        >
        </alm-account>
      </ng-template>
    </kendo-tabstrip-tab>

    <kendo-tabstrip-tab
      *ngIf="!isAncillary"
      [selected]="activeTabId === tabs[1].id"
      [class.next]="tabs[1].pageValidation?.IsSetup === false"
      [class.inactive]="tabs[1].pageValidation?.IsSetup === null"
      [class.warn]="tabs[1].pageValidation?.IsValid === false"
    >
      <ng-template kendoTabTitle>
        <div
          kendoTooltip
          [title]="tabs[1].tooltip"
          class="flex-container horizontal"
          [class.warn]="tabs[1].pageValidation?.IsValid === false"
          [class.next]="tabs[1].pageValidation?.IsSetup === false"
        >
          <div class="tab-icon left" *ngIf="tabs[1].pageValidation?.IsValid === false">
            <i class="fas fa-triangle-exclamation"></i>
          </div>
          <span class="tab-title"> BUDAccountNum </span>
          <div class="tab-icon right" *ngIf="tabs[1].pageValidation?.IsSetup === false">
            <i class="fas fa-wrench"></i>
          </div>
          <div class="tab-icon right" *ngIf="tabs[1].pageValidation && tabs[1].pageValidation.IsSetup === null">
            <i class="fas fa-lock"></i>
          </div>
        </div>
      </ng-template>
      <ng-template kendoTabContent>
        <bud-account
          [localStorageKey]="budLocalStorageKey"
          [dataSourceId]="idSelected"
          [selectBUD]="activeTabId"
          [datasourcetypeid]="DataSourceTypeID"
          class="bud-account"
        >
        </bud-account>
      </ng-template>
    </kendo-tabstrip-tab>

    <kendo-tabstrip-tab
      *ngIf="!isGL && !isAncillary"
      [selected]="activeTabId === tabs[2].id"
      [class.next]="tabs[2].pageValidation?.IsSetup === false"
      [class.inactive]="tabs[2].pageValidation?.IsSetup === null"
      [class.warn]="tabs[2].pageValidation?.IsValid === false"
    >
      <ng-template kendoTabTitle>
        <div
          kendoTooltip
          [title]="tabs[2].tooltip"
          class="flex-container horizontal"
          [class.warn]="tabs[2].pageValidation?.IsValid === false"
          [class.next]="tabs[2].pageValidation?.IsSetup === false"
        >
          <div class="tab-icon left" *ngIf="tabs[2].pageValidation?.IsValid === false">
            <i class="fas fa-triangle-exclamation"></i>
          </div>
          <span class="tab-title"> GL_BS_Acct </span>
          <div class="tab-icon right" *ngIf="tabs[2].pageValidation?.IsSetup === false">
            <i class="fas fa-wrench"></i>
          </div>
          <div class="tab-icon right" *ngIf="tabs[2].pageValidation && tabs[2].pageValidation.IsSetup === null">
            <i class="fas fa-lock"></i>
          </div>
        </div>
      </ng-template>
      <ng-template kendoTabContent>
        <glbs-account [localStorageKey]="glbsLocalStorageKey" [dataSourceId]="idSelected" [selectGLBS]="activeTabId" class="glbs-account">
        </glbs-account>
      </ng-template>
    </kendo-tabstrip-tab>

    <kendo-tabstrip-tab
      *ngIf="!isGL"
      [selected]="activeTabId === tabs[3].id"
      [class.next]="tabs[3].pageValidation?.IsSetup === false"
      [class.inactive]="tabs[3].pageValidation?.IsSetup === null"
      [class.warn]="tabs[3].pageValidation?.IsValid === false"
    >
      <ng-template kendoTabTitle>
        <div
          kendoTooltip
          [title]="tabs[3].tooltip"
          class="flex-container horizontal"
          [class.warn]="tabs[3].pageValidation?.IsValid === false"
          [class.next]="tabs[3].pageValidation?.IsSetup === false"
        >
          <div class="tab-icon left" *ngIf="tabs[3].pageValidation?.IsValid === false">
            <i class="fas fa-triangle-exclamation"></i>
          </div>
          <span class="tab-title"> GL_IS_Acct </span>
          <div class="tab-icon right" *ngIf="tabs[3].pageValidation?.IsSetup === false">
            <i class="fas fa-wrench"></i>
          </div>
          <div class="tab-icon right" *ngIf="tabs[3].pageValidation && tabs[3].pageValidation.IsSetup === null">
            <i class="fas fa-lock"></i>
          </div>
        </div>
      </ng-template>
      <ng-template kendoTabContent>
        <glis-account [localStorageKey]="glisLocalStorageKey" [dataSourceId]="idSelected" [selectGLIS]="activeTabId" class="glis-account">
        </glis-account>
      </ng-template>
    </kendo-tabstrip-tab>

    <kendo-tabstrip-tab
      *ngIf="isNMD"
      [selected]="activeTabId === tabs[4].id"
      [class.next]="tabs[4].pageValidation?.IsSetup === false"
      [class.inactive]="tabs[4].pageValidation?.IsSetup === null"
      [class.warn]="tabs[4].pageValidation?.IsValid === false"
    >
      <ng-template kendoTabTitle>
        <div
          kendoTooltip
          [title]="tabs[4].tooltip"
          class="flex-container horizontal"
          [class.warn]="tabs[4].pageValidation?.IsValid === false"
          [class.next]="tabs[4].pageValidation?.IsSetup === false"
        >
          <div class="tab-icon left" *ngIf="tabs[4].pageValidation?.IsValid === false">
            <i class="fas fa-triangle-exclamation"></i>
          </div>
          <span class="tab-title"> SubProductId </span>
          <div class="tab-icon right" *ngIf="tabs[4].pageValidation?.IsSetup === false">
            <i class="fas fa-wrench"></i>
          </div>
          <div class="tab-icon right" *ngIf="tabs[4].pageValidation && tabs[4].pageValidation.IsSetup === null">
            <i class="fas fa-lock"></i>
          </div>
        </div>
      </ng-template>
      <ng-template kendoTabContent *ngIf="!isGL">
        <sub-product-account
          [localStorageKey]="subpLocalStorageKey"
          [dataSourceId]="idSelected"
          [selectSP]="activeTabId"
          class="subp-account"
        >
        </sub-product-account>
      </ng-template>
    </kendo-tabstrip-tab>
  </kendo-tabstrip>
</div>

<div kendoDialogContainer></div>
<app-page-validations (tabDataEvent)="handleDataEvent($event)"></app-page-validations>
