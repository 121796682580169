export class Assignment {
  public AsOfDate: string;
  public DataImportID: number;
  public DataSourceID: number;
  public DataSourceTypeID: number;
  public DataSourceType: string;
  public selectedDSTypeID: number;
  public Errors: number;
  public Filename: string;
  public ImportDate: Date;
  public IsUploading = false;
  public Name: string;
  public Names: string;
  public NormalizationEndTime: Date;
  public NormalizationStartTime: Date;
  public NormalizationSuccess: boolean;
  public StartAsOfDate: string;
  public SumOfBalances: number;
  public Threshold: number;
  public TotalRows: number;
  public UserIdCancelled: number;
  public UserIdCreated: number;
  public UserNameCreated: string;
  public Status: string;
  public ParentSid: number;
  public id: number;
  public Value: string;

  public isValid?: boolean;

  public Values: string[];
  public LastUpdatedDates: Date[];
  public LastAppliedDates: Date[];
  public RulesetCompletes: string[];


  public LastUpdated?: Date;
  public LastApplied?: Date;
  public RulesetComplete?: string;
  public IsEnabled = true;
  public HasData = true;

  public Children: number[] = [];

  public Item: any = null;

  public NumberOfLeafDescendants: number = null;
  public hasGrandchildren: boolean;

  public numberOfChildren = () => this.Children?.length ?? 0;

  public isLeaf: boolean = false;
  static errorsArray: any[];
  static isValid: boolean;

  constructor(...args: any[]) {
    Object.assign(this, ...args);
  }
}

export enum Flags {
  ImportedValue = 0,
  Complete = 1,
  Missing = 2,
  AssignmentValue = 3,
  SingleValue = 4
}

export enum assignmentValues {
  ALMAccountNum = 0,
  BUDAccountNum = 1,
  GL_BS_Acct = 2,
  GL_IS_Acct = 3,
  SubProductID = 4
}
