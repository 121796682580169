import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigProvider } from '@app/app.config.provider';
import { AppConfigAPI } from '@models/appconfig';
import { AsOfDate, IAsOfDate } from '@models/as-of-date/as-of-date';
import { IHTTPError } from '@models/error/error';
import { GLAccountTypes } from '@models/general-ledger/gl-account-types';
import { InstrumentType } from '@models/general-ledger/instrument-type';
import { DataverseTypeCode } from '@models/type-codes/dataverse-type-codes';
import { DataSourcesService } from '@services/data-sources/data-sources.service';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, mergeMap, skip, switchMap, take, tap, toArray } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class SharedService {
  private environment: AppConfigAPI;
  private sharedURL: string;

  public readonly addAsOfDateClicked$: Subject<boolean> = new Subject();
  /** Instrument Types */
  /** Will load on first use of getter and then be available in memory thereafter */
  private readonly _instrumentTypes$ = new BehaviorSubject<InstrumentType[]>(null);
  public get instrumentTypes$() {
    const instrumentTypes = this._instrumentTypes$.getValue();
    return instrumentTypes ? this._instrumentTypes$.asObservable().pipe(take(1)) : this._loadInstrumentTypes();
  }

  private set _instrumentTypes(types: InstrumentType[]) {
    this._instrumentTypes$.next(types);
  }

  private readonly _dataverseTypeCodes$ = new BehaviorSubject<DataverseTypeCode[]>(null);
  public get dataverseTypeCodes$() {
    const dataverseTypeCodes = this._dataverseTypeCodes$.getValue();
    return dataverseTypeCodes ? this._dataverseTypeCodes$.asObservable().pipe(take(1)) : this._loadDataverseTypeCodes();
  }

  private set _dataverseTypeCodes(codes: DataverseTypeCode[]) {
    this._dataverseTypeCodes$.next(codes);
  }

  private readonly _customerModules$ = new BehaviorSubject<string[]>(null);
  public get customerModules$() {
    const customerModules = this._customerModules$.getValue();
    return customerModules ? this._customerModules$.asObservable().pipe(take(1)) : this._loadCustomerModules();
  }

  private set _customerModules(codes: string[]) {
    this._customerModules$.next(codes);
  }

  /** The active As Of Date */
  public get selectedAsOfDate$() {
    return this.dataSourcesService.selectedAsOfDate$.pipe(
      // -- work around for duplicate emissions from source
      distinctUntilChanged(),
      filter(aod => aod !== null)
    );
  }

  private readonly _firstPageLoaded$ = new BehaviorSubject<boolean>(false);

  /** Indicates if the first page on application init has completed loading */
  public get firstPageLoaded$(): Observable<boolean> {
    return this._firstPageLoaded$.asObservable();
  }
  /** Indicates if the first page on application init has completed loading */
  public get firstPageLoaded(): boolean {
    return this._firstPageLoaded$.getValue();
  }

  constructor(
    private readonly http: HttpClient,
    private readonly appConfig: AppConfigProvider,
    private readonly dataSourcesService: DataSourcesService
  ) {
    this.appConfig.environment.subscribe(env => {
      this.environment = env.API;
      this.sharedURL = `${this.environment.DIMENSIONS}/api/Shared`;
    });
  }

  private _loadInstrumentTypes(): Observable<InstrumentType[]> {
    try {
      return this.http.get<InstrumentType[]>(`${this.sharedURL}/InstrumentTypes`).pipe(
        catchError(error => of(error)),
        tap(result => {
          // -- If no error, set the list locally
          if (result && !result.error) {
            this._instrumentTypes = result;
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  public getInsrumentTypes(): Observable<InstrumentType[]> {
    return this.instrumentTypes$;
  }

  private _loadDataverseTypeCodes(): Observable<DataverseTypeCode[]> {
    try {
      return this.http.get<DataverseTypeCode[]>(`${this.sharedURL}/DataverseTypeCodes`).pipe(
        catchError(error => of(error)),
        tap(result => {
          // -- If no error, set the list locally
          if (result && !result.error) {
            this._dataverseTypeCodes = result;
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  public getDataversetTypeCodes(): Observable<DataverseTypeCode[]> {
    return this.dataverseTypeCodes$;
  }

  public getCustomerModules(): Observable<string[]> {
    return this.customerModules$;
  }

  private _loadCustomerModules(): Observable<string[]> {
    try {
      return this.http.get<DataverseTypeCode[]>(`${this.sharedURL}/modules`).pipe(
        catchError(error => of(error)),
        tap(result => {
          // -- If no error, set the list locally
          if (result && !result.error) {
            this._customerModules = result;
          }
        })
      );
    } catch (error) {
      return of(error);
    }
  }

  public getGLAccountTypes(): Observable<GLAccountTypes[]> {
    try {
      return this.http.get<GLAccountTypes[]>(`${this.sharedURL}/GLAccountTypes`);
    } catch (error) {
      return of(null);
    }
  }

  /**
   * Gets a list of the customers available as of dates
   */
  public getAsOfDates(): Observable<AsOfDate[] | IHTTPError> {
    return this.http.get<IAsOfDate[]>(`${this.sharedURL}/AsOfDates`).pipe(
      mergeMap(asOfDate => asOfDate),
      map(asOfDate => new AsOfDate(asOfDate)),
      toArray(),
      catchError(error => of({ error }))
    );
  }

  /**
   * Adds a new as of date to customers available as of dates. If it's prior to the first `As Of Date` in the
   * system, it will also fill the gap.
   * @param  year Year of the as of date to add
   * @param  month Month of the as of date to add
   */
  public addAsOfDate(year: number, month: number): Observable<AsOfDate[]> {
    const body = { year, month };
    return this.http
      .post<IAsOfDate[]>(`${this.sharedURL}/AsOfDates`, body)
      .pipe(map(asOfDates => asOfDates.map(asOfDate => new AsOfDate(asOfDate))));
  }

  /**
   * Locks or unlocks an 'AsOfDate'.
   */
  private _lockUnlockAsOfDate(/** YYYY-MM-DD */ dateString: string, url: string): Observable<AsOfDate> {
    return this.http.post<boolean>(url, { AsOfDate: dateString }).pipe(
      // -- notify subscribers that as of date status was changed on the DB
      tap(_ => this.dataSourcesService.asOfDateStatusChanged.emit(true)),
      // -- wait for selectedAsOfDate to get refreshed automatically via subscription
      // -- skip current value emission, and take the next emission
      switchMap(_ => this.dataSourcesService.selectedAsOfDate$.pipe(skip(1), take(1)))
    );
  }

  public lockImport(dateString: string): Observable<AsOfDate> {
    return this._lockUnlockAsOfDate(dateString, `${this.sharedURL}/AsOfDates/import/lock`);
  }
  public unlockImport(dateString: string): Observable<AsOfDate> {
    return this._lockUnlockAsOfDate(dateString, `${this.sharedURL}/AsOfDates/import/unlock`);
  }
  public lockRecon(dateString: string): Observable<AsOfDate> {
    return this._lockUnlockAsOfDate(dateString, `${this.sharedURL}/AsOfDates/recon/lock`);
  }
  public unlockRecon(dateString: string): Observable<AsOfDate> {
    return this._lockUnlockAsOfDate(dateString, `${this.sharedURL}/AsOfDates/recon/unlock`);
  }
  public lockFTP(dateString: string): Observable<AsOfDate> {
    return this._lockUnlockAsOfDate(dateString, `${this.sharedURL}/AsOfDates/ftp/lock`);
  }
  public unlockFTP(dateString: string): Observable<AsOfDate> {
    return this._lockUnlockAsOfDate(dateString, `${this.sharedURL}/AsOfDates/ftp/unlock`);
  }

  public setUnsetGlReconDirty(/** YYYY-MM-DD */ dateString: string, isGlReconDirty: boolean) {
    const params = new HttpParams().set('AsOfDate', dateString).set('IsGlReconDirty', isGlReconDirty.toString());

    return this.http.get<boolean>(`${this.sharedURL}/AsOfDates/dirty`, { params }).toPromise();
  }

  public addAsOfDateClicked(): void {
    this.addAsOfDateClicked$.next(true);
  }

  /** Tells the application that the first page has finsihed loading its data */
  public markFirstPageLoaded() {
    if (!this.firstPageLoaded) {
      this._firstPageLoaded$.next(true);
    }
  }
}
