import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CookieType } from './cookie-type';

@Injectable({
  providedIn: 'root'
})
export class CookieService {
  public cookieCollection: BehaviorSubject<{ [key: string]: string }> = new BehaviorSubject<{ [key: string]: string }>({});

  constructor() {}

  public setSessionStorage(key: string, value: string) {
    window.sessionStorage.setItem(key, value);
  }

  public processQueryString(url: URL) {
    for (const [key, value] of url.searchParams.entries()) {
      this.setSessionStorage(key, value);
    }
  }

  public processCookies() {
    const cookies = this.cookieCollection.getValue();
    // read cookies

    // read session storage
    for (const key of Object.keys(window.sessionStorage)) {
      cookies[key] = window.sessionStorage.getItem(key);
    }

    this.cookieCollection.next(cookies);
    return this.cookieCollection;
  }

  public setCookieInCollection(key: string, value: string) {
    const cookies = this.cookieCollection.getValue();
    cookies[key] = value;
    this.cookieCollection.next(cookies);
  }

  public setCookie(key: string, value: string, type: CookieType) {
    switch (type) {
      case CookieType.SessionStorage:
        this.setSessionStorage(key, value);
        break;
      case CookieType.LocalStorage:
      case CookieType.BrowserCookie:
      default:
        break;
    }
    this.setCookieInCollection(key, value);
  }
}
