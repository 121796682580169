/** Page object to be used with Page Templates */
export interface IPage {
  title: string;
  isLoading: boolean;
}

export interface ISidePanelPage extends IPage {
  isPanelLoading: boolean;
}

export enum PAGE_IDS {
  AccountMapping = 1,
  ReconAccounts = 2,
  GLOnlyAccounts = 3,
  DatesImported = 4,
  DailyBalanceSheet = 5,
  DailyIncomeStatement = 6,
  BalanceSheet = 7,
  IncomeStatement = 8,
  Options = 9,
  Department = 10,
  DepartmentLE = 11,
  LegalEntity = 12,
  ReconDepartments = 13,
  AccountMappingBS = 14,
  ReconAccountsBS = 15,
  GLOnlyAccountsBS = 16,
  AccountMappingIS = 17,
  ReconAccountsIS = 18,
  GLOnlyAccountsIS = 19,
  CalculatedFields = 20,
  Assignments = 21,
  ResultsReport = 22,
  ALMAccount = 23,
  BUDAccount = 24,
  GLBSAccount = 25,
  GLISAccount = 26,
  SubProduct = 27,
  BalanceSheetAlmBud = 28,
  IncomeStatementAlmBud = 29,
  AccountRuleset = 0
}
