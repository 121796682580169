import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';

const green = '#72bf44';

export const slideIn = trigger('slideIn', [
  transition('* <=> *', [
    query(
      ':enter',
      [
        style({ opacity: 0, transform: 'translate3d(-100%, 0, 0)' }),
        stagger(16, [animate('0.10s', style({ opacity: 1, transform: 'translate3d(0, 0, 0)' }))])
      ],
      { optional: true }
    )
  ])
]);

export const highlight = (timeout = 1000) =>
  trigger('highlight', [
    transition(':leave', [animate(timeout / 3, keyframes([style({ position: 'fixed', opacity: 0, offset: 0 })]))]),
    transition('* => *', [
      animate(
        timeout,

        keyframes([
          style({ opacity: 0.75, color: green, offset: 0 }),
          style({ opacity: 1, color: green, offset: 0.3 }),
          style({ opacity: 1, color: '*', offset: 1 })
        ])
      )
    ])
  ]);

export const highlightBackground = (timeout = 1000) =>
  trigger('highlightBackground', [
    transition(':leave', [animate(timeout / 3, keyframes([style({ position: 'fixed', opacity: 0, offset: 0 })]))]),
    transition('* => *', [
      animate(
        timeout,

        keyframes([
          style({ opacity: 0.75, backgroundColor: green, offset: 0 }),
          style({ opacity: 1, backgroundColor: green, offset: 0.3 }),
          style({ opacity: 1, backgroundColor: '*', offset: 1 })
        ])
      )
    ])
  ]);

export const blurInOut = (timeout = 600) =>
  trigger('blurInOut', [
    transition(':leave', [
      animate(
        timeout / 3,
        keyframes([
          style({ filter: 'blur(0px)', transform: 'scale(1)', offset: 0 }),
          style({ filter: 'blur(2px)', opacity: 0, transform: 'scale(2)', offset: 1 })
        ])
      )
    ]),

    transition('* => *', [
      animate(
        timeout,
        keyframes([
          // Blur in
          // style({ filter: 'blur(2px)', transform: 'scale(2)', offset: 0 }),
          // style({ filter: 'blur(0px)', transform: 'scale(1)', offset: 1 })

          // Rotate in
          // style({ transform: 'scale3d(1, 1, 1) rotate3d(0, 0, 1, -90deg', offset: 0 }),
          // style({ transform: 'scale3d(1.25, 1.25, 1) rotate3d(0, 0, 1, 25deg)', offset: 0.3 }),
          // style({ transform: 'scale3d(0.75, 0.75, 1) rotate3d(0, 0, 1, -15deg)', offset: 0.4 }),
          // style({ transform: 'scale3d(1.15, 1.15, 1)', offset: 0.5 }),
          // style({ transform: 'scale3d(0.95, 0.95, 1) rotate3d(0, 0, 0, 0)', offset: 0.65 }),
          // style({ transform: 'scale3d(1.05, 1.05, 1) ', offset: 0.75 }),
          // style({ transform: 'scale3d(1, 1, 1)', offset: 1 })

          // Stick in
          style({ transform: 'translate3d(10px, -10px, 0)', opacity: 0, offset: 0 }),
          style({ transform: 'translate3d(-5px, 5px, 0)', opacity: 1, offset: 0.2 }),
          style({ transform: 'translate3d(3px, -5px, 0)', offset: 0.4 }),
          style({ transform: 'translate3d(0px, 0px, 0) scale3d(1.05,1.05,1)', offset: 0.6 }),
          style({ transform: 'scale3d(1,1,1)', offset: 1 })
        ])
      )
    ])
  ]);

export const fadeInOut = (timeout = 300) =>
  trigger('fadeInOut', [
    transition(':leave', [animate(`${timeout}ms ease-out`, keyframes([style({ opacity: 0, offset: 1 })]))]),
    transition('* => *', [animate(`${timeout}ms ease-in`, keyframes([style({ opacity: 0, offset: 0 }), style({ opacity: 1, offset: 1 })]))])
  ]);

export const filterAnimation = (timeout = 300) =>
  trigger('filterAnimation', [
    transition(':enter, * => 0, * => -1', []),
    transition(':increment', [
      query(
        ':enter',
        [style({ opacity: 0, width: 50 }), stagger(50, [animate(`${timeout}ms ease-out`, style({ opacity: 1, width: '*' }))])],
        { optional: true }
      )
    ]),
    transition(':decrement', [query(':leave', [stagger(50, [animate(`${timeout}ms ease-out`, style({ opacity: 0, width: 50 }))])])])
  ]);

export const filterAnimationTable = (timeout = 300) =>
  trigger('filterAnimationTable', [
    transition(':enter, * => 0, * => -1', []),
    transition(':increment', [
      query(
        ':enter',
        [
          style({ opacity: 0, transform: 'translate3d(-50%,0,0)' }),
          stagger(50, [animate(`${timeout}ms ease-out`, style({ opacity: 1, transform: 'translate3d(0,0,0)' }))])
        ],
        { optional: true }
      )
    ]),
    transition(':decrement', [query(':leave', [stagger(50, [animate(`${timeout}ms ease-out`, style({ opacity: 0, width: 0 }))])])])
  ]);
