import { Directive, EventEmitter, HostListener, Input, OnDestroy, Output } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AsOfDate } from '@models/as-of-date/as-of-date';
import { SharedService } from '@services/shared/shared-service';
import { BodyOutputType, Toast, ToastType, ToasterService } from '@services/toaster/toaster.service';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { AddAsOfDateComponent, INewAsOfDateParams } from './add-asofdate.component';

@Directive({ selector: '[empAddAsOfDate]' })
export class AppAddAsOfDateDirective implements OnDestroy {
  @Input() public asOfDates: AsOfDate[] = [];
  @Input() public isPrior = false;
  @Output() public asOfDatesUpdated = new EventEmitter<AsOfDate[]>();

  private readonly subscriptions: Subscription[] = [];
  private dialogRef: MatDialogRef<AddAsOfDateComponent>;

  constructor(public dialog: MatDialog, private readonly sharedService: SharedService, private readonly toasterService: ToasterService) {}

  public ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  @HostListener('click', ['$event']) public async onClick(event): Promise<void> {
    event.stopPropagation();
    event.preventDefault();

    // this.openDialog();

    if (this.asOfDates.length === 0 || this.isPrior) {
      this.openDialog();
    } else {
      const lastAsOfDate = this.asOfDates[this.asOfDates.length - 1];

      // Add 1 month to last as of date
      const lastDate = new Date(lastAsOfDate.Date);
      lastDate.setDate(1);
      lastDate.setMonth(lastDate.getMonth() + 1);

      const month = lastDate.getMonth() + 1;
      const year = lastDate.getFullYear();

      await this.saveNewAsOfDate({ month, year });
    }
  }

  private openDialog(): void {
    const dialogOptions = {
      width: '410px',
      maxWidth: '100vw',
      maxHeight: '100vh',
      panelClass: ['ui-dialog'],
      disableClose: false,
      closeOnNavigation: false,
      data: {
        asOfDates: this.asOfDates,
        isPrior: this.isPrior
      }
    };

    this.dialogRef = this.dialog.open(AddAsOfDateComponent, dialogOptions);
    this.dialogRef
      .afterClosed()
      .pipe(
        filter(monthAndYear => monthAndYear !== null && monthAndYear !== undefined),
        distinctUntilChanged()
      )
      .subscribe(async monthAndYear => {
        // console.log({ monthAndYear });
        await this.saveNewAsOfDate(monthAndYear);
      });
  }

  private async saveNewAsOfDate(monthAndYear: INewAsOfDateParams): Promise<void> {
    const { month, year } = monthAndYear;

    const savedAsOfDates: AsOfDate[] = await this.sharedService
      .addAsOfDate(year, month)
      .toPromise()
      .catch(error => {
        this.showToast(`Error creating As of Date\n${error?.error}`, 'Error', 'error');
        return null;
      });

    if (savedAsOfDates) {
      return this.asOfDatesUpdated.emit(savedAsOfDates);
    }

    // Error handling
    const asOfDates = await this.sharedService
      .getAsOfDates()
      .toPromise()
      .catch(() => null);

    if (asOfDates) {
      this.asOfDatesUpdated.emit(asOfDates);
    }
  }

  private readonly showToast = (body: string, title: string, type: ToastType = 'success', toastTimeout = 5000) => {
    const toast: Toast = {
      type,
      title,
      body: body.replace(/\n/g, '<br />'),
      bodyOutputType: BodyOutputType.TrustedHtml,
      showCloseButton: true,
      timeout: toastTimeout,
      progressBar: true,
      progressBarDirection: 'increasing'
    };
    this.toasterService.pop(toast);
  };
}
