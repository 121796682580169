import { DataSource } from './data-sources';

// Saving import from Kendo's `ValueAxis` which is 151.4k zipped
export type ValueAxis = { min: number; max: number; plotBands: { from: number; to: number; color: string; opacity: number }[] };

export class ImportedDataSource extends DataSource {
  public AsOfDate: string;
  public BalancesM3Avg: number;
  public BalancesPercent: number;
  public BlankRows: number;
  public CancelDate: Date;
  public CancelReason: number;
  public CleanupEndTime: Date;
  public CleanupStartTime: Date;
  public CleanupSuccess: boolean;
  public DataImportID: number;
  public DataSourceAsOfDate: string;
  public DataSourceID: number;
  public DataSourceTypeID: number;
  public EmpyreanImportEndTime: Date;
  public EmpyreanImportStartTime: Date;
  public EmpyreanImportSuccess: boolean;
  // public ErrorList: ImportError[];
  public ErrorsSummary: ImportErrorsSummary;
  public Errors: number;
  public Filename: string;
  public DisplayFilename: string;
  public ImportDate: Date;
  public IsUploading = false;
  public Name: string;
  public NormalizationEndTime: Date;
  public NormalizationStartTime: Date;
  public NormalizationSuccess: boolean;
  public ProcessedRows: number;
  public RecordsM3Avg: number;
  public RecordsPercent: number;
  public StartAsOfDate: string;
  public Status: ImportState;
  public StatusDescription: string;
  public SumOfBalances: number;
  public Threshold: number;
  public TotalRows: number;
  public UploadEndTime: Date;
  public UploadStartTime: Date;
  public UploadSuccess: boolean;
  public UserIdCancelled: number;
  public UserIdCreated: number;
  public UserNameCancelled: string;
  public UserNameCreated: string;
  public ValidationEndTime: Date;
  public ValidationStartTime: Date;
  public ValidationSuccess: boolean;
  public ValidRows: number;
  public Warnings: number;
  public hasImportableRows: boolean;

  public LastUpdated?: Date;
  public IsEnabled = true;
  public HasData = true;

  public ProgressValues: ValueAxis = null;
  public FileUploadProgress: number = null;
  public EstimatedRemainingTime: number = null;

  constructor(...args: any[]) {
    super();
    Object.assign(this, ...args);
  }

  public isRunning = () => RUNNING_STATES.includes(this.Status);

  public isCancellable = () => CANCELLABLE_STATES.includes(this.Status);

  public isDisableable = () => DISABLEABLE_STATES.includes(this.Status) || (this.Status === 7 && !this.hasImportableRows);

  public getHasImportableRows = () => this?.DataImportID && this?.TotalRows === this?.ProcessedRows && this?.TotalRows > this?.Errors;

  public calculateProgressValues = () => (this.ProgressValues = calculateProgressValues(this));
}

const calculateProgressValues = (dataSource: ImportedDataSource): ValueAxis => {
  if (!dataSource?.DataImportID) {
    return null;
  }

  const color = { incomplete: '#eceef3', success: '#44b44e', warning: '#ffce55', error: '#f25544' };

  const { Errors, ProcessedRows, TotalRows, ValidRows } = dataSource;
  const processedRows = ProcessedRows ?? 0;
  const unprocessedRows = TotalRows - processedRows;
  const validRows = ValidRows ?? processedRows - Errors;
  const invalidRows = processedRows - validRows;
  const warnings = Math.max(0, invalidRows - Errors);

  const config: ValueAxis = { min: 0, max: 100, plotBands: [] };

  // Calculate % with a minimum of 10% if > 0
  let adjustedErrorPercent = adjustPercentagesForDisplay((Errors / TotalRows) * 100);
  let adjustedWarningPercent = adjustPercentagesForDisplay((warnings / TotalRows) * 100);
  let adjustedSuccessPercent = adjustPercentagesForDisplay((validRows / TotalRows) * 100);
  let adjustedIncompletePercent = adjustPercentagesForDisplay((unprocessedRows / TotalRows) * 100);

  // Adjust % to add up to 100, taking from/adding to incomplete, success, warning, or error in that order of priority
  const delta = 100 - (adjustedErrorPercent + adjustedWarningPercent + adjustedSuccessPercent + adjustedIncompletePercent);
  if (delta !== 0) {
    if (delta + adjustedIncompletePercent >= 10) {
      adjustedIncompletePercent += delta;
    } else if (delta + adjustedSuccessPercent >= 10) {
      adjustedSuccessPercent += delta;
    } else if (delta + adjustedWarningPercent >= 10) {
      adjustedWarningPercent += delta;
    } else if (delta + adjustedErrorPercent >= 10) {
      adjustedErrorPercent += delta;
    }
  }

  // Set up status bar boundaries
  const firstPlotBandBoundary = adjustedErrorPercent;
  const secondPlotBandBoundary = firstPlotBandBoundary + adjustedWarningPercent;
  const thirdPlotBandBoundary = secondPlotBandBoundary + adjustedSuccessPercent;

  // Error (red)
  if (adjustedErrorPercent > 0) {
    config.plotBands.push({ from: 0, to: firstPlotBandBoundary, color: color.error, opacity: 1 });
  }

  // Warnings (orange)
  if (adjustedWarningPercent > 0) {
    config.plotBands.push({ from: firstPlotBandBoundary, to: secondPlotBandBoundary, color: color.warning, opacity: 1 });
  }

  // Success (green)
  if (adjustedSuccessPercent > 0) {
    config.plotBands.push({ from: secondPlotBandBoundary, to: thirdPlotBandBoundary, color: color.success, opacity: 1 });
  }

  // Incomplete (grey)
  if (adjustedIncompletePercent > 0) {
    config.plotBands.push({ from: thirdPlotBandBoundary, to: 100, color: color.incomplete, opacity: 1 });
  }

  return config;
};

const adjustPercentagesForDisplay = (percentage: number): number => {
  if (percentage > 0) {
    return Math.max(10, Math.floor(percentage));
  }
  return 0;
};

type ErrorType = 'Error' | 'Warning';

export class ImportError {
  public Row: number;
  public Column: string;
  public Message: string;
  public IsError: boolean;
  public Instrument: string;
  public InstrumentID: string;
  public ProvidedValue: string;

  public ErrorType?: ErrorType;
}

export class ImportErrorsSummaryItem {
  public Column: string;
  public IsError: boolean;
  public Message: string;
  public RowCount: number;
}

export class ImportErrorsSummary {
  public errorCount: number;
  public warningCount: number;
  public errors: ImportErrorsSummaryItem[];
}

export const zipTimeouts = new Map<number, ReturnType<typeof setTimeout>>();

export class ErrorZip {
  public importId: number;
  public url: string;
  public expireAt: Date;
  public isGeneratingZip?: boolean;

  constructor(importId: number, url: string, expireAt: Date, isGeneratingZip?: boolean) {
    this.importId = importId;
    this.url = url;
    this.isGeneratingZip = Boolean(isGeneratingZip);

    if (this.url && !isGeneratingZip) {
      this.expireAt = expireAt;

      const seconds = Math.round((this.expireAt.getTime() - Date.now()) / 1000);

      if (seconds > 0) {
        if (zipTimeouts.has(importId)) {
          clearTimeout(zipTimeouts.get(importId));
        }

        // Set timeout to set url to null on expiration
        zipTimeouts.set(
          importId,
          setTimeout(() => {
            this.url = null;
            this.expireAt = null;
          }, seconds * 1000)
        );
      }
    }
  }
}

export enum ImportState {
  NotImported = 0,
  Uploading = 1,
  UploadFailed = 2,
  Cleaning = 3,
  CleanupFailed = 4,
  Validating = 5,
  ValidationFailed = 6,
  AwaitingImport = 7,
  Importing = 8,
  ImportFailed = 9,
  Normalizing = 10,
  NormalizationFailed = 11,
  Imported = 12,
  Cancelled = 13,
  InvalidFile = 14,
  Unknown = -42
}

const RUNNING_STATES = [
  ImportState.Uploading,
  ImportState.Cleaning,
  ImportState.Validating,
  ImportState.Importing,
  ImportState.Normalizing
];

const CANCELLABLE_STATES = [ImportState.Cleaning, ImportState.Validating, ImportState.Uploading];

const DISABLEABLE_STATES = [
  ImportState.NotImported,
  ImportState.UploadFailed,
  ImportState.CleanupFailed,
  ImportState.ValidationFailed,
  ImportState.ImportFailed,
  ImportState.NormalizationFailed,
  ImportState.Cancelled,
  ImportState.InvalidFile
];

const FAILED_STATES = [
  ImportState.UploadFailed,
  ImportState.CleanupFailed,
  ImportState.ValidationFailed,
  ImportState.ImportFailed,
  ImportState.NormalizationFailed,
  ImportState.InvalidFile
];
