export class DataSourceType {
  public DataSourceTypeID: number;
  public DataSourceTypeDesc: string;
  public Group: DataSourceTypeGroup;
  public ImportOrder: number;
}

export class DataSourceTypeColumns {
  public DataSourceTypeID: number;
  public RequiredColumns: string[];
  public ColumnAliases: ColumnAlias[];
  public ValidColumns: string[];
}

export class ColumnAlias {
  public ColumnName: string;
  public Aliases: string[];
}

export class DataSource extends DataSourceType {
  public DataSourceID: number;
  public Name: string;
  public Description: string;
  /** Date earliest as of date that the datasource is required for */
  public StartAsOfDate: string;
}

export class DataSourceUpdateRequest {
  public Name: string;
  public Description: string;
}

export class DataSourceToggleRequest {
  public IsEnabled: boolean;
  public AsOfDate: string;
}

export class DataSourcesRequest {
  public datasources: DataSource[];
}

export class ImportRequest {
  public DataSourceID: number;
  public Filename: string;
  public AsOfDate: string;
  public DisplayName: string;
}

export class InitiateImportRequest {
  public Filename: string;
  public ImportID: number;
  public DataSourceID: number;
  public Headers: string;
  public StorageAccount: string;
  public UseAzure: boolean;
}

export enum DataSourceTypes {
  GeneralLedger = 1,
  LLC = 2,
  TimeDeposits = 3,
  NMDeposits = 4,
  Investments = 5,
  Swaps = 6,
  Derivatives = 7,
  Borrowings = 8,
  FixedAssets = 9,
  Transactions = 10,
  GLTransactions = 11,
  AncillaryProducts = 12,
  ScheduledCashFlow = 13,
  Employees = 14,
  CCustomerRelationship = 15,
  RetailCustomer = 16,
  Payroll = 17,
  PrepaidExpense = 18
}

export enum DepositsAllowedDataSources {
  //TimeDeposits = DataSourceTypes.TimeDeposits,
  NonMaturityDeposits = DataSourceTypes.NMDeposits
}

export enum DataSourceTypeGroup {
  GL = 'GL',
  INSTRUMENTS = 'INSTRUMENTS',
  TRANSACTIONS = 'TRANSACTIONS',
  OTHER = 'OTHER'
}
