import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as GC from '@grapecity/spread-sheets';
import { AppConfig } from '@models/appconfig';
import { ReplaySubject } from 'rxjs';
import { tap } from 'rxjs/operators';

const INTERCEPTOR_SKIP_HEADER = 'X-Skip-Interceptor';

@Injectable()
export class AppConfigProvider {
  public environment: ReplaySubject<AppConfig> = new ReplaySubject(1);

  constructor(private readonly http: HttpBackend) {}

  public load() {
    const headers = new HttpHeaders().set(INTERCEPTOR_SKIP_HEADER, '');
    const httpClient = new HttpClient(this.http);

    return httpClient
      .get('../config.json', { headers })
      .pipe(
        tap((configFile: AppConfig) => {
          this.environment.next(configFile);
          GC.Spread.Sheets.LicenseKey = configFile.SPREAD.LICENSE;
        })
      )
      .toPromise();
  }
}
