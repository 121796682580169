<div
  id="btn_quicklinks_container"
  class="dropdown_content min_width"
  [class.show]="quickLinksOpen"
  style="top: 5px; left: calc(var(--navigation-width) - 5px)"
>
  <div class="dropdown_content_body">
    <h3 class="dropdown_title">Applications</h3>
    <div class="clearfix">
      <ul id="application_list">
        <li>
          <app-application-switcher-item
            applicationName="Liquidity Modeling"
            [moduleName]="EmpyreanApplicationName.Liquidity"
            moduleUrl="{{ moduleUrls.LIQUIDITY }}"
            iconClass="icon_liquidity_modeling"
          ></app-application-switcher-item>
        </li>
        <li>
          <app-application-switcher-item
            applicationName="Deposit Analytics"
            [moduleName]="EmpyreanApplicationName.Deposits"
            moduleUrl="{{ moduleUrls.DEPOSITS }}"
            iconClass="icon_summary"
          ></app-application-switcher-item>
        </li>

        <li>
          <app-application-switcher-item
            applicationName="Dataverse"
            [moduleName]="EmpyreanApplicationName.Dimensions"
            moduleUrl="{{ moduleUrls.DIMENSIONS }}"
            applicationSubtitle="{{ moduleUrls.DIMENSIONS ? null : '(Coming soon)' }}"
            iconClass="fas fa-sitemap"
          ></app-application-switcher-item>
        </li>
        <li>
          <app-application-switcher-item
            applicationName="Planning"
            [moduleName]="EmpyreanApplicationName.Planning"
            moduleUrl="{{ moduleUrls.PLANNING }}"
            applicationSubtitle="{{ moduleUrls.PLANNING ? null : '(Coming soon)' }}"
            iconClass="fas fa-calculator"
          ></app-application-switcher-item>
        </li>
        <li>
          <app-application-switcher-item
            applicationName="Profitability"
            [moduleName]="EmpyreanApplicationName.Profitability"
            moduleUrl="{{ moduleUrls.PROFITABILITY }}"
            applicationSubtitle="{{ moduleUrls.PROFITABILITY ? null : '(Coming soon)' }}"
            iconClass="fa fa-magnifying-glass-chart"
          ></app-application-switcher-item>
        </li>

        <!-- System Administrator Only -->
        <li>
          <app-application-switcher-item
            applicationName="User Management"
            [moduleName]="EmpyreanApplicationName.UserManager"
            moduleUrl="{{ moduleUrls.USERMANAGER }}"
            iconClass="icon_user"
          ></app-application-switcher-item>
        </li>
      </ul>
    </div>
  </div>
</div>
