import { Injectable } from '@angular/core';
import * as GC from '@grapecity/spread-sheets';
import { IHoverNotification } from '@models/hover-notification';
import { IValidable } from '@shared/interfaces/spread-cell-validation-interfaces';
import { NotificationService } from './notification.service';

export interface IPointerInfo<T> {
  item: T;
  row: number;
  col: number;
  hitTestType: GC.Spread.Sheets.SheetArea;
}

@Injectable({ providedIn: 'root' })
export class SpreadHoverNotificationService {
  constructor(private readonly notificationService: NotificationService) {}

  /**
   *
   * @param overwriteNotificationMap Pass an object where the key has the format ${row}\_${col}\_${hitTestType}
   */
  public onMouseMove(
    event,
    sheet: GC.Spread.Sheets.Worksheet,
    model: IValidable[],
    errorNotificationTitle: string,
    overwriteNotificationMap: { [key: string]: IHoverNotification } = null
  ): void {
    if (!sheet || !event || !model) {
      this.clear();
      return;
    }

    const { row, col, hitTestType }: GC.Spread.Sheets.IHitTestInformation = this.getCellUnderMousePointer(event, sheet);
    const item = model?.[row] ?? null;

    if (overwriteNotificationMap?.hasOwnProperty(`${row}_${col}_${hitTestType}`)) {
      this.notificationService.hoverMessage$.next(overwriteNotificationMap[`${row}_${col}_${hitTestType}`]);
    } else if (item && (!item.isValid || item.infoArray?.length > 0) && col >= 0) {
      let hoverNotification: IHoverNotification;
      if (item.errorsArray?.length > 0 && item.errorsArray.some(x => x.col === col)) {
        hoverNotification = this.createErrorHover(errorNotificationTitle, row, col, item);
      } else if (item.infoArray?.length > 0 && item.infoArray.some(x => x.col === col)) {
        hoverNotification = this.createInfoHover(row, col, item);
      } else {
        this.clear();
        return;
      }
      this.notificationService.hoverMessage$.next(hoverNotification);
    } else if (item !== undefined) {
      this.clear();
    }
  }

    /** Shows a notification, hides notification on null*/
    public show(notification: IHoverNotification) {
      if (notification) {
        this.notificationService.hoverMessage$.next(notification);
      } else {
        this.clear();
      }
    }

    /** Returns the model item and cell data that matches the cell under the pointer in the worksheet */
    public getPointerInfo<T>(event: MouseEvent, sheet: GC.Spread.Sheets.Worksheet, model: T[]): IPointerInfo<T> {
      if (!sheet || !event || !model) {
        return null;
      }

      const { row, col, hitTestType } = this.getCellUnderMousePointer(event, sheet);
      return {
        item: model?.[row] ?? null,
        row,
        col,
        hitTestType
      };
    }

  private createInfoHover(row: number, col: number, item: IValidable): IHoverNotification {
    return {
      title: `${(item.infoArray || []).find(x => x.col === col).title}`,
      value: `row ${row + 1}`,
      body: `${(item.infoArray || []).find(x => x.col === col).info}`,
      type: 'info'
    };
  }

  private createErrorHover(title: string, row: number, col: number, item: IValidable): IHoverNotification {
    return {
      title,
      value: `row ${row + 1}`,
      body: `${(item.errorsArray || [])
        .filter(e => e.col === col)
        .map(e => `• ${e.error}`)
        .join('\n')}`,
      type: 'error'
    };
  }

  private getCellUnderMousePointer(event, sheet: GC.Spread.Sheets.Worksheet): GC.Spread.Sheets.IHitTestInformation {
    const spreadElement = document.querySelector('gc-spread-sheets');

    if (!spreadElement) {
      this.clear();
      return { row: -1, col: -1 };
    }

    const viewportOffset = spreadElement.getBoundingClientRect();

    const x = event.pageX - viewportOffset.left;
    const y = event.pageY - viewportOffset.top;
    return sheet.hitTest(x, y);
  }

  public clear = () => this.notificationService.hoverMessage$.next(null);
}
