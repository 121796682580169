import { Component, ElementRef, Inject, QueryList, ViewChildren } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DataSourcesService } from '@services/data-sources/data-sources.service';
import { SharedService } from '@services/shared/shared-service';
import { AsOfDate } from '@models/as-of-date/as-of-date';
import { map, take } from 'rxjs/operators';
import { BodyOutputType, Toast, ToastType, ToasterService } from '@services/toaster/toaster.service';
import { TermPoint, DimensionSetup } from './dimension-setup';
import { AssignmentRulesetsService } from '@services/modal-screen/assignment-ruleset/assignment-ruleset-service';
import {
  assignmentRuleset,
  AssignmentRuleset,
  AssignmentDimension,
  COLUMNS,
  aliasValues,
  noAliasValues
} from '@models/modal-screen/assignment-ruleset/assignment-ruleset-model';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { openMaxNumOfRecordsDialog } from '@components/modal-screen/assignment-reset-dialog/assignment-max-records-dialog';
import { SharedAssignmentService } from '@services/modal-screen/assignment-ruleset/account-assignment-shared';
import { assignmentValues } from '@models/assignments/assignments';
import { ASSIGNMENT_VALUE } from '@models/modal-screen/assignment-ruleset/account-model';

type DropdownValue = { text: string; value: string };

@Component({
  selector: 'app-assignment-ruleset',
  templateUrl: './assignment-ruleset.component.html',
  styleUrls: ['./assignment-ruleset.component.scss']
})
export class AssignmentRulesetComponent {
  @ViewChildren('termInput') private readonly termInputs: QueryList<ElementRef>;

  public dataSourceName: string;
  public assignmentValue: string;
  public assignment: any;
  public rows: any;
  public dimensionSetupFormGroup: FormGroup;
  public dataSourceType: number = 0;
  public source: number;
  public defaultValue: DropdownValue;
  public dropdownValues: any[] = [];
  public selectedDropdownVal: { [key: string]: any } = {};
  public selectedDropdownValAlias: { [key: string]: any } = {};
  public assignmentRuleset: AssignmentRuleset[];
  public assignmentRulesetArray: any[] = [];
  public terms: TermPoint[] = [];
  public savedData: string;
  public selectedValue: string;
  public isModified: boolean = false;
  public lengthOfDropDownBefore: number = 0;
  public lengthOfDropDownAfter: number = 0;
  public discardChangesDialogOpen = false;
  public assignmentDimension: AssignmentDimension[];
  public selectedValues: string[] = [];
  public listLength: number;
  public dropdowns: any[] = [{}];

  get selectedAsOfDate(): AsOfDate {
    return this.dataSourceService.selectedAsOfDate;
  }

  public get asOfDate$() {
    return this.sharedService.selectedAsOfDate$.pipe(map(aod => aod.Date));
  }

  public get asOfDateOnce$() {
    return this.asOfDate$.pipe(take(1));
  }
  

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private readonly formBuilder: FormBuilder,
    private readonly dataSourceService: DataSourcesService,
    private readonly sharedService: SharedService,
    private readonly toasterService: ToasterService,
    private readonly router: Router,
    private readonly assignmentRulesetService: AssignmentRulesetsService,
    private readonly location: Location,
    private readonly sharedAssignmentService: SharedAssignmentService,
    private readonly dialogRef: MatDialogRef<AssignmentRulesetComponent>
  ) {
    this.assignmentValue = this.data.assignmentValue;
    this.assignment = this.data.assignment;
  }

  public async ngOnInit() {
    this.dimensionSetupFormGroup = new FormGroup({
      Months: new FormControl()
    });

    this.terms = this.data.ycSetup?.default_terms ? (this.data.ycSetup.default_terms as TermPoint[]) : [{ Position: 1, Months: 0 }];
    this.data.assignment.forEach(element => {
      element.DataSourceID === this.data.dataSourceId ? (this.dataSourceName = element.Name) : null;
      element.DataSourceID === this.data.dataSourceId ? (this.dataSourceType = element.DataSourceTypeID) : null;
    });

    this.defaultValue = this.data.dimesionsList[0];

    this.initForm();

    this.loadDropdownValues();

    if (this.dropdowns.length > 0 && this.dropdowns[0].selectedValue != null) {
      for (let i = 0; i < this.dropdowns.length; i++) {
        noAliasValues.length = 0;
        aliasValues.length = 0;
        this.selectedDropdownVal[i] = this.dropdowns[i].selectedValue.text;
        noAliasValues.push(...Object.values(this.selectedDropdownVal));

        this.dropdowns[i].selectedValue.table === '1'
          ? (this.selectedDropdownValAlias[i] = 'p.' + this.dropdowns[i].selectedValue.text)
          : (this.selectedDropdownValAlias[i] = 'a.' + this.dropdowns[i].selectedValue.text);

        aliasValues.push(...Object.values(this.selectedDropdownValAlias));
      }
    }
  }

  loadDropdownValues() {
    let storedValues;
    switch (this.data.assignmentValue) {
      case ASSIGNMENT_VALUE[assignmentValues.ALMAccountNum]:
        storedValues = JSON.parse(localStorage.getItem(this.data.dataSourceId + 'ALM') || '[]');
        break;
      case ASSIGNMENT_VALUE[assignmentValues.BUDAccountNum]:
        storedValues = JSON.parse(localStorage.getItem(this.data.dataSourceId + 'BUD') || '[]');
        break;
      case ASSIGNMENT_VALUE[assignmentValues.GL_BS_Acct]:
        storedValues = JSON.parse(localStorage.getItem(this.data.dataSourceId + 'GLBS') || '[]');
        break;
      case ASSIGNMENT_VALUE[assignmentValues.GL_IS_Acct]:
        storedValues = JSON.parse(localStorage.getItem(this.data.dataSourceId + 'GLIS') || '[]');
        break;
      case ASSIGNMENT_VALUE[assignmentValues.SubProductID]:
        storedValues = JSON.parse(localStorage.getItem(this.data.dataSourceId + 'SP') || '[]');
        break;
    }
    this.dropdowns = storedValues.length > 0 ? storedValues : [{ label: 'Dropdown 1', selectedValue: null }];
    this.data.dimesionsList = this.data.dimesionsList.filter(
      item => !this?.dropdowns.some(otherItem => otherItem.selectedValue?.text === item?.text)
    );
    this.selectedValues = this.dropdowns.map(item => item.selectedValue);
  }

  saveDropdownValues() {
    switch (this.data.assignmentValue) {
      case ASSIGNMENT_VALUE[assignmentValues.ALMAccountNum]:
        localStorage.setItem(this.data.dataSourceId + 'ALM', JSON.stringify(this.dropdowns));
        break;
      case ASSIGNMENT_VALUE[assignmentValues.BUDAccountNum]:
        localStorage.setItem(this.data.dataSourceId + 'BUD', JSON.stringify(this.dropdowns));
        break;
      case ASSIGNMENT_VALUE[assignmentValues.GL_BS_Acct]:
        localStorage.setItem(this.data.dataSourceId + 'GLBS', JSON.stringify(this.dropdowns));
        break;
      case ASSIGNMENT_VALUE[assignmentValues.GL_IS_Acct]:
        localStorage.setItem(this.data.dataSourceId + 'GLIS', JSON.stringify(this.dropdowns));
        break;
      case ASSIGNMENT_VALUE[assignmentValues.SubProductID]:
        localStorage.setItem(this.data.dataSourceId + 'SP', JSON.stringify(this.dropdowns));
        break;
    }
  }

  private initForm(): void {
    const formSetup = this.createFormSetup();
    this.createFormGroup(formSetup);
  }

  private createFormSetup(): DimensionSetup {
    const formSetup: DimensionSetup = new DimensionSetup();
    formSetup.Source = this.source;
    return formSetup;
  }

  public readonly closeDialog = () => {
    this.dialogRef.close();
  };

  public onDropdownSelected(newValue: any, i: number): void {
    noAliasValues.length = 0;
    aliasValues.length = 0;

    if (!this.dropdowns[i]) {
      this.dropdowns[i] = { label: 'Dropdown ' + `${i + 1}`, selectedValue: newValue };
    }

    if (i === 0) {
      this.dropdowns[i] = { label: 'Dropdown ' + `${i + 1}`, selectedValue: newValue };
    }

    this.saveDropdownValues();

    if (newValue && this.dropdownValues.indexOf(newValue) === -1) {
      this.selectedDropdownVal[i] = newValue.text;
      noAliasValues.push(...Object.values(this.selectedDropdownVal));
    }

    newValue.table === '1'
      ? (this.selectedDropdownValAlias[i] = 'p.' + newValue.text)
      : (this.selectedDropdownValAlias[i] = 'a.' + newValue.text);

    aliasValues.push(...Object.values(this.selectedDropdownValAlias));

    this.data.dimesionsList = this.sharedAssignmentService.getAvailableDimensions(
      i,
      newValue,
      this.data.dimesionsList,
      this.selectedValues
    );
    this.listLength = this.data.dimesionsList.length;
  }

  public readonly getAssignmentRuleset = async (): Promise<void> => {
    this.discardChangesDialogOpen = false;
    COLUMNS.length = 0;
    assignmentRuleset.length = 0;
    this.assignmentRulesetArray = [];
    this.assignmentRuleset = [];

    switch (this.data.assignmentValue) {
      case ASSIGNMENT_VALUE[assignmentValues.ALMAccountNum]:
        this.assignmentRuleset = await this.assignmentRulesetService
          .getALMAccountAssignmentRuleset(noAliasValues, aliasValues, this.data.dataSourceId, this.selectedAsOfDate.Date)
          .toPromise()
          .catch(async err => {
            this.showToast(`Error loading assignment status request`, 'Error', 'error');
            console.warn(err);
            return [];
          });
        break;
      case ASSIGNMENT_VALUE[assignmentValues.BUDAccountNum]:
        this.assignmentRuleset = await this.assignmentRulesetService
          .getBUDAccountAssignmentRuleset(noAliasValues, aliasValues, this.data.dataSourceId, this.selectedAsOfDate.Date)
          .toPromise()
          .catch(async err => {
            this.showToast(`Error loading assignment status request`, 'Error', 'error');
            console.warn(err);
            return [];
          });
      case ASSIGNMENT_VALUE[assignmentValues.GL_BS_Acct]:
        this.assignmentRuleset = await this.assignmentRulesetService
          .getGLBSAccountAssignmentRuleset(noAliasValues, aliasValues, this.data.dataSourceId, this.selectedAsOfDate.Date)
          .toPromise()
          .catch(async err => {
            this.showToast(`Error loading assignment status request`, 'Error', 'error');
            console.warn(err);
            return [];
          });
        break;
      case ASSIGNMENT_VALUE[assignmentValues.GL_IS_Acct]:
        this.assignmentRuleset = await this.assignmentRulesetService
          .getGLISAccountAssignmentRuleset(noAliasValues, aliasValues, this.data.dataSourceId, this.selectedAsOfDate.Date)
          .toPromise()
          .catch(async err => {
            this.showToast(`Error loading assignment status request`, 'Error', 'error');
            console.warn(err);
            return [];
          });
        break;
      case ASSIGNMENT_VALUE[assignmentValues.SubProductID]:
        this.assignmentRuleset = await this.assignmentRulesetService
          .getSubProductAccountAssignmentRuleset(noAliasValues, aliasValues, this.data.dataSourceId, this.selectedAsOfDate.Date)
          .toPromise()
          .catch(async err => {
            this.showToast(`Error loading assignment status request`, 'Error', 'error');
            console.warn(err);
            return [];
          });
        break;
    }

    if (this.assignmentRuleset.length > 0) {
      for (const assignment of this.assignmentRuleset) {
        if (assignment.Columns) {
          this.assignmentRulesetArray.push(assignment.Columns);
        }
      }

      if (this.assignmentRuleset[0].Columns) {
        let displayName;
        const columnConfig = Object.keys(this.assignmentRuleset[0].Columns).map(key => {
          switch (key) {
            case 'number_of_records':
              displayName = '# of Records';
              break;
            case 'Assignment':
              displayName = `Assignment${this.data.assignmentValue}`;
              break;
            default:
              displayName = key;
          }
          return {
            name: key,
            displayName: displayName,
            key: key,
            width: key.length
          };
        });
        COLUMNS.push(...columnConfig);
      }
      assignmentRuleset.push(...this.assignmentRulesetArray);
    }
    if (assignmentRuleset.length > 1000) {
      await openMaxNumOfRecordsDialog();
    } else {
      this.closePopup();
      this.closeDialog();
    }
  };

  private onNavigationError(err): void {
    console.error(err);
  }

  public openCloseDiscardChangesDialog(): boolean {
    this.discardChangesDialogOpen = !this.discardChangesDialogOpen;
    return this.discardChangesDialogOpen;
  }

  public async closePopup() {
    const navParam = [{ outlets: { 'navigation-modal-outlet': ['empty'] } }];

    await this.router
      .navigate(['account-assignment/assignments'])
      .catch(this.onNavigationError)
      .finally(() => {
        this.dialogRef.close();

        let url = '';
        {
          url = this.router.url;
        }
        this.location.replaceState(url);
      });
  }

  public showToast = (body: string, title: string, type: ToastType = 'success') => {
    const toast: Toast = { type, title, body, showCloseButton: true, timeout: 5000, bodyOutputType: BodyOutputType.TrustedHtml };
    this.toasterService.pop(toast);
  };

  public onRemoveTerm(item: FormControl): void {
    this.terms.splice(item.value.Position - 1, 1);
    this.defaultTermsControl.removeAt(item.value.Position - 1);
    this.defaultTermsControl.markAsTouched();
    this.sortDefaultTermsByMonths();

    const keys = Object.keys(this.selectedDropdownVal);
    const keyToRemove = keys[item.value.Position - 1];
    const { [keyToRemove]: removedValue, ...newObject } = this.selectedDropdownVal;

    const updatedObject: { [key: string]: any } = {};
    let newIndex = 0;
    for (const key of Object.keys(newObject)) {
      updatedObject[newIndex.toString()] = newObject[key];
      newIndex++;
    }

    const keysAlias = Object.keys(this.selectedDropdownValAlias);
    const keyToRemoveAlias = keysAlias[item.value.Position - 1];
    const { [keyToRemoveAlias]: removedValueAlias, ...newObjectAlias } = this.selectedDropdownValAlias;

    const updatedObjectAlias: { [key: string]: any } = {};
    let newIndexAlias = 0;
    for (const key of Object.keys(newObjectAlias)) {
      updatedObjectAlias[newIndexAlias.toString()] = newObjectAlias[key];
      newIndexAlias++;
    }

    this.dropdowns.splice(item.value.Position - 1, 1);

    this.selectedDropdownVal = updatedObject;
    this.selectedDropdownValAlias = updatedObjectAlias;

    noAliasValues.length = 0;
    aliasValues.length = 0;

    noAliasValues.push(...Object.values(this.selectedDropdownVal));
    aliasValues.push(...Object.values(this.selectedDropdownValAlias));
    this.saveDropdownValues();

    const indexToRemoved = this.selectedValues.indexOf(item.value.Months);
    this.data.dimesionsList = this.sharedAssignmentService.onRemoveTerm(indexToRemoved, this.data.dimesionsList, this.selectedValues);
    if (indexToRemoved !== -1) this.selectedValues.splice(indexToRemoved, 1);
    this.listLength = this.data.dimesionsList.length;
  }

  private sortDefaultTermsByMonths(): void {
    this.defaultTermsControl.controls.forEach((control, idx) => {
      control.value.Position = idx + 1;
    });
    for (let i = 0; i < this.terms.length; i++) {
      this.terms[i].Position = i + 1;
    }
  }

  public onAddTermPoint(): void {
    this.isModified = false;
    this.lengthOfDropDownBefore = 0;
    this.lengthOfDropDownAfter = 0;
    const newTerm = new TermPoint();
    newTerm.Position = this.terms.length + 1;
    const idx = newTerm.Position;
    this.terms.push(newTerm);
    this.defaultTermsControl.push(
      this.formBuilder.group({
        Position: [newTerm.Position, []],
        Months: [null, [Validators.required]]
      })
    );

    setTimeout(() => this.termInputs.last?.nativeElement.focus(), 0);

    if (!this.dropdowns[idx - 1]) {
      this.dropdowns[idx - 1] = { label: 'Dropdown ' + `${idx - 1}`, selectedValue: null };
    }
  }

  public get defaultTermsControl(): FormArray {
    return this.dimensionSetupFormGroup.controls.default_terms as FormArray;
  }

  private createFormGroup(formSetup: DimensionSetup): void {
    this.dimensionSetupFormGroup = new FormGroup({
      default_terms: new FormArray([])
    });
    this.createDefaultTermsFormArray();
  }

  private createDefaultTermsFormArray(): void {
    for (let i = 0; i < this.terms.length; i++) {
      this.defaultTermsControl.push(
        this.formBuilder.group({
          Position: [i + 1, []],
          Months: [{ value: this.terms[i].Months }, [Validators.required]]
        })
      );
    }
  }
}
