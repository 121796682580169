import { Renderer2 } from '@angular/core';

export const mapObjectToComponentFields = (component: any, item: {}) => {
  Object.keys(item || {}).forEach(k => {
    if (component[k] === undefined) {
      component[k] = item[k];
    }
  });
};

export const positionPopupElement = (popupElement: any, sourceButton: any, renderer: Renderer2, event?: Event) => {
  sourceButton = event ? event.target : sourceButton;

  const itemPosition = sourceButton.getBoundingClientRect();
  const btnWidth = itemPosition.width;

  if (popupElement.classList.contains('position_right')) {
    renderer.setStyle(popupElement, 'left', `${itemPosition.left - (310 - btnWidth)}px`);
  } else {
    renderer.setStyle(popupElement, 'left', `${itemPosition.left}px`);
  }

  if (popupElement.classList.contains('position_top')) {
    const actualBottom = itemPosition.top - popupElement.getBoundingClientRect().height;
    renderer.setStyle(popupElement, 'top', `${actualBottom - 10}px`);
  } else {
    renderer.setStyle(popupElement, 'top', `${itemPosition.top + 32}px`);
  }
};
