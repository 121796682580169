<div class="tree-panel-container">
  <app-toggle-switch class="tree-expand-switch" *ngIf="!isToggleHidden" (isExpandedAll)="onToggleSwitch($event)"></app-toggle-switch>
  <app-deletion-button [hierarchyName]="treeConfigs[0]?.name" (isDeleting)="isDeletingUnallocated($event)"></app-deletion-button>
  <div *ngFor="let treeConfig of treeConfigs" class="trees-container">
    <ng-container *ngIf="!treeConfig.isHidden">
      <app-tree
        [nodes]="treeConfig.nodes"
        [name]="treeConfig.name"
        [tooltipKey]="treeConfig.tooltipKey"
        [isAllExpanded]="isAllNodesExpanded"
        [filterHint]="treeConfig.filterHint"
      ></app-tree>
    </ng-container>
  </div>
</div>
