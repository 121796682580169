import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigProvider } from '@app/app.config.provider';
import { AppConfigAPI } from '@models/appconfig';
import { LocalStorageKeys } from '@models/storage/local-storage-keys';
import { LocalStorageRepository } from '@services/local-storage/local-storage';
import { catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { AssignmentRuleset, AssignmentDimension } from '@models/modal-screen/assignment-ruleset/assignment-ruleset-model';
import { deepClone } from '@shared/clone';
import { InstrumentType } from '@models/modal-screen/assignment-ruleset/assignment-ruleset-model';

@Injectable({ providedIn: 'root' })
export class AssignmentRulesetsService extends LocalStorageRepository {
  private GLReconURL: string; // Get Active Users
  private otherAssignment: string;
  private singleVal: string;
  private environment: AppConfigAPI;
  private initialDatabaseCopy: AssignmentRuleset[];

  public constructor(private readonly http: HttpClient, private readonly appConfig: AppConfigProvider) {
    super(LocalStorageKeys.MODALSCREEN, 'modal-screen');
    this.appConfig.environment.subscribe(env => {
      this.environment = env.API;
      this.GLReconURL = `${this.environment.DIMENSIONS}/api/AssignmentRuleset`;
      this.otherAssignment = `${this.environment.DIMENSIONS}/api/AssignmentUseOther`;
      this.singleVal = `${this.environment.DIMENSIONS}/api/AssignmentUseSingleValue`;
    });
  }

  public getAssignmentRuleset(
    noAliasValues: any[],
    aliasValues: any[],
    dataSourceId: number,
    date: string,
    assignment: string
  ): Observable<AssignmentRuleset[]> {
    try {
      let params = new HttpParams();
      params = params.append('noAliasValues', noAliasValues.join(','));
      params = params.append('aliasValues', aliasValues.join(','));
      params = params.append('dataSourceId', dataSourceId);
      params = params.append('date', date);
      params = params.append('assignment', assignment);
      return this.http.get<AssignmentRuleset[]>(`${this.GLReconURL}/GetAssignmentRuleset`, { params: params });
    } catch (error) {
      return of(null);
    }
  }

  public getALMAccountAssignmentRuleset(
    noAliasValues: any[],
    aliasValues: any[],
    dataSourceId: number,
    date: string
  ): Observable<AssignmentRuleset[]> {
    return this.getAssignmentRuleset(noAliasValues, aliasValues, dataSourceId, date, 'AlmAccountNum');
  }

  public getBUDAccountAssignmentRuleset(
    noAliasValues: any[],
    aliasValues: any[],
    dataSourceId: number,
    date: string
  ): Observable<AssignmentRuleset[]> {
    return this.getAssignmentRuleset(noAliasValues, aliasValues, dataSourceId, date, 'BudAccountNum');
  }

  public getGLBSAccountAssignmentRuleset(
    noAliasValues: any[],
    aliasValues: any[],
    dataSourceId: number,
    date: string
  ): Observable<AssignmentRuleset[]> {
    return this.getAssignmentRuleset(noAliasValues, aliasValues, dataSourceId, date, 'GL_BS_Acct');
  }

  public getGLISAccountAssignmentRuleset(
    noAliasValues: any[],
    aliasValues: any[],
    dataSourceId: number,
    date: string
  ): Observable<AssignmentRuleset[]> {
    return this.getAssignmentRuleset(noAliasValues, aliasValues, dataSourceId, date, 'GL_IS_Acct');
  }

  public getSubProductAccountAssignmentRuleset(
    noAliasValues: any[],
    aliasValues: any[],
    dataSourceId: number,
    date: string
  ): Observable<AssignmentRuleset[]> {
    return this.getAssignmentRuleset(noAliasValues, aliasValues, dataSourceId, date, 'SubProductID');
  }

  public setDatabaseAssignmentRulesetCopy(accountMapping: AssignmentRuleset[]) {
    this.initialDatabaseCopy = deepClone(accountMapping);
  }

  public isAccountRulesetEqualToDBCopy(setting: AssignmentRuleset, item: any): boolean {
    const myInstance = new AssignmentRuleset();
    myInstance[item] = 'aa';
    const itemInDatabase = this.initialDatabaseCopy?.find(other => other[item] === setting[item]);
    const comparisonKeys = ['Assignment', 'Name'];

    // Rest of properties
    if (itemInDatabase && setting) {
      for (const [key, value] of Object.entries(setting)) {
        if (comparisonKeys.includes(key) && value !== itemInDatabase[key]) {
          // any of the keys in comparisonKeys has changed
          return false;
        }
      }
      // no key has changed
      return true;
    }
    // OnDatabase or Assignment does not exist anymore
    return false;
  }

  public saveAssignmentRuleset(saveRequest: AssignmentValueSave): Observable<void> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const url = `${this.GLReconURL}/SaveAssignmentRuleset`;
    return this.http.post<void>(url, saveRequest, httpOptions);
  }

  public updateOtherAssignment(saveRequest: AssignmentValueSave): Observable<void> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const url = `${this.otherAssignment}/UpdateOtherAssignment`;
    return this.http.post<void>(url, saveRequest, httpOptions);
  }

  public updateUsingSingleValue(saveRequest: AssignmentValueSave): Observable<void> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const url = `${this.singleVal}/UpdateUsingSingleValue`;
    return this.http.post<void>(url, saveRequest, httpOptions);
  }

  public getAssignmentDimensions = async (dataSourceTypeId: number): Promise<AssignmentDimension[]> => {
    return await this.http
      .get<AssignmentDimension[]>(`${this.GLReconURL}/GetAssignmentDimensions?dataSourceTypeId=${dataSourceTypeId}`)
      .pipe(
        catchError(error => {
          console.error('Error fetching data:', error);
          return of(null);
        })
      )
      .toPromise()
      .then(result => {
        if (result !== null && result !== undefined) {
          return result;
        } else {
          console.error('Unexpected null or undefined result from API');
          return [];
        }
      })
      .catch(error => {
        console.error('Error converting to Promise:', error);
        return [];
      });
  };

  public getIstrumentTypesByDataSourceType(): Observable<InstrumentType[]> {
    const url = `${this.singleVal}/GetIstrumentTypesByDataSourceType`;
    return this.http.get<InstrumentType[]>(url);
  }
}

export class AssignmentValueSave {
  public Mappings: string;
  public noAliasValues: any[];
  public aliasValues: any[];
  public dataSourceId: number;
  public date: string;
  public otherAssignment: string;
  public assignmentValue: string;
  public singleValue: number;
  public singleValueText: string;
  public isBalanceSheet: boolean;
}

export const savedData: string[] = [];
