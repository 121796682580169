<kendo-dropdownlist
  [data]="data"
  (valueChange)="onChange($event)"
  [defaultItem]="defaultItem"
  [value]="selectedValue"
  [valuePrimitive]="true"
  [textField]="textField"
  [valueField]="valueField"
>
  <ng-template kendoDropDownListValueTemplate let-dataItem>
    <ng-container *ngIf="hasText(dataItem)">
      {{ getItemText(dataItem) }}
    </ng-container>
    <ng-container *ngIf="!hasText(dataItem)">
      <em>{{ getItemText(dataItem) }}</em>
    </ng-container>
  </ng-template>

  <ng-template kendoDropDownListItemTemplate let-dataItem>
    <ng-container *ngIf="hasText(dataItem)">
      {{ getItemText(dataItem) }}
    </ng-container>
    <ng-container *ngIf="!hasText(dataItem)">
      <em>{{ getItemText(dataItem) }}</em>
    </ng-container>
  </ng-template>
</kendo-dropdownlist>
