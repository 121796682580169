<div role="dialog" class="modal-data" (window:resize)="onResize($event)">
  <div class="dialog_left_col" id="navigation_modal">
    <div id="side_navigation_inner">
      <empyrean-navigation-modal-header [modalTitle]="modalTitle"></empyrean-navigation-modal-header>
      <empyrean-navigation-section class="margin-bottom-auto">
        <empyrean-navigation-element *ngFor="let elm of menuItems" [item]="elm"></empyrean-navigation-element>
      </empyrean-navigation-section>
    </div>
  </div>

  <div class="dialog_right_col" [class.hidden]="!isReady">
    <router-outlet
      class="app-template-container"
      #outlet="outlet"
      name="navigation-modal-outlet"
      (activate)="onActivate($event)"
      (deactivate)="onDeactivate()"
    ></router-outlet>
  </div>
</div>
