import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigProvider } from '@app/app.config.provider';
import { Customer } from '@models/user/customer';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UserService {
  private USERS_API: string;

  constructor(private readonly http: HttpClient, private readonly appConfig: AppConfigProvider) {
    this.appConfig.environment.subscribe(env => {
      this.USERS_API = `${env.API.DIMENSIONS}/api`;
    });
  }

  public getCustomer(): Observable<Customer> {
    return this.http.get<Customer>(`${this.USERS_API}/users/customer`);
  }
}
