<li class="empyrean-navigation-item-container" [class.disabled]="item.isDisabled">
  <div kendoTooltip position="right">
    <a
      class="navigation-link"
      [class.placeholder]="item?.routerLink?.includes('placeholder')"
      [class.root]="item?.level === 0"
      [class.warn]="item?.elementState === 'invalid'"
      [class.next]="item?.elementState === 'not setup'"
      [class.inactive]="item?.elementState === 'not required'"
      [routerLink]="!item?.isDisabled ? item?.routerLink || null : null"
      [attr.disabled]="!item?.isDisabled ? null : ''"
      [routerLink]="routerLink"
      (click)="clickAction ? clickAction() : toggleChildren()"
      class="nav_link nav_link_workspace alertClass"
      title="{{ label }}"
      [class.status_active]="isItemActive()"
      [attr.title]="item?.tooltip ? item.tooltip : null"
    >
      <span class="icon">
        <i [ngClass]="[item?.iconClass || item?.stateClass || 'fa-solid fa-square']"></i>
      </span>
      <span class="text ellipsis">{{ item?.label }}</span>
      <span class="status"></span>
      <span class="expand-icon" *ngIf="item?.children">
        <i class="fas fa-chevron-right" [class.expanded]="item?.isExpanded"></i>
      </span>
    </a>
  </div>
</li>
