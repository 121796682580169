import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { OpenIdConnectService } from '@services/openid/openid-connect.service';

@Component({
  selector: 'app-error401',
  templateUrl: './error401.component.html',
  styleUrls: ['./error401.component.scss']
})
export class Error401Component implements OnInit {
  constructor(private readonly _location: Location, private readonly auth: OpenIdConnectService) {}

  ngOnInit() {}

  public goBack() {
    this._location.back();
  }

  public signin() {
    this.auth.triggerSignOut();
    this.auth.triggerSignIn();
  }
}
