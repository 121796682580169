import * as GC from '@grapecity/spread-sheets';

export const suspendDoAndResume = async (sheet: GC.Spread.Sheets.Worksheet, taskToPerform: CallableFunction) => {
  if (sheet) {
    try {
      // suspendAll(sheet);
      sheet.suspendPaint();
      sheet.suspendEvent();

      // Initiates the task
      await Promise.resolve(taskToPerform());
      // Once is done, resumes
      // resumeAll(sheet);
      sheet.resumeEvent();
      sheet.resumePaint();
    } catch (err) {
      // Don't print out error to user as it's outside of their control
      // console.warn('Error in suspendDoAndResume. Please, use a sheet and a valid function as parameter.', err);
    }
  }
};
