export const tryParseNumber = (value: any) => (isNaN(+value) || value === '' ? value : +value);
export const round = (num: number, n: number = 2): number => Math.round(num * 10 ** n) / 10 ** n;

export type NumberOrString = number | string;
export type StringOrNumber = string | number;

/**
 * Returns a text representation of number using the highest available multiplier.
 * @example numberToString(600528, 1) => '600.5K'
 */
export const numberToString = (input: number, decimals = 2): string => {
  // https://en.wikipedia.org/wiki/Names_of_large_numbers

  // +-------+---------------+----------------------+--------------------------+--------+-----------+
  // | Value | Short-Scale   | Long-Scale           | Traditional-European     | Symbol | SI-Prefix |
  // |       | (American)    | (European)           |                          |        |           |
  // +=======+===============+======================+==========================+========+===========+
  // | 10^3  | Thousand      | Thousand             | Thousand                 |    K   | Kilo-     |
  // | 10^6  | Million       | Million              | Million                  |    M   | Mega-     |
  // | 10^9  | Billion       | Thousand million     | Milliard                 |    G   | Giga-     |
  // | 10^12 | Trillion      | Billion              | Billion                  |    T   | Tera-     |
  // | 10^15 | Quadrillion   | Thousand billion     | Billiard                 |    P   | Peta-     |
  // | 10^18 | Quintillion   | Trillion             | Trillion                 |    E   | Exa-      |
  // | 10^21 | Sextillion    | Thousand trillion    | Trilliard                |    Z   | Zetta-    |
  // | 10^24 | Septillion    | Quadrillion          | Quadrillion              |    Y   | Yotta-    |
  // +-------+---------------+----------------------+--------------------------+--------+-----------+

  // const suffixes = ['K', 'M', 'G', 'T', 'P', 'E','Z','Y']; // Long Numeric Scale (European)
  const suffixes = ['K', 'M', 'B', 'T', 'P', 'E', 'Z', 'Y']; // Short Numeric Scale (American)

  const isNegative = input < 0;

  if (Number.isNaN(input)) {
    return null;
  }
  input = Math.abs(input);

  if (input < 1000) {
    return `${round(input, decimals)}`;
  }

  const exp = Math.floor(Math.log(input) / Math.log(1000));

  const formattedInput = round(input / 1000 ** exp, decimals) + suffixes[exp - 1];
  return isNegative ? `(${formattedInput})` : formattedInput;
};

/** Checks if string value represents a valid integer number */
export const isInteger = (value: string) => /^-?\d+$/.test(value);
