import { NavigationElement } from '@core/navigation/navigation-interfaces';
import { PAGE_IDS } from '@models/page/page';
import { depositLevels, dvOnlyUserLevels, profUserLevels } from '@models/user-level';

/** Traverse navigationElements and set the nesting level for each element  */
const setLevels = (elements: NavigationElement[], level: number = 0): NavigationElement[] =>
  elements.map(e => {
    e.level ??= level;
    if (e.children) {
      e.children = setLevels(e.children, level + 1);
    }
    return e;
  });

export enum ModalType {
  ModelSettings = 2
}

export const importAndCalculateElements: NavigationElement[] = setLevels([
  {
    iconClass: 'fa-solid fa-database',
    label: 'Import & Calculate',
    isExpanded: true,
    roles: [...dvOnlyUserLevels, ...profUserLevels, ...depositLevels],
    children: [
      {
        label: 'Data Import',
        routerLink: 'import-and-calculate/data-import',
        iconClass: 'fa-solid fa-1 rounded-number continues',
        isDataverseOnly: true,
        roles: [...dvOnlyUserLevels, ...depositLevels]
      },
      {
        label: 'GL Reconciliation',
        routerLink: 'import-and-calculate/gl-reconciliation',
        iconClass: 'fa-solid fa-2 rounded-number continues',
        isDataverseOnly: true,
        roles: [...dvOnlyUserLevels]
      },
      {
        label: 'Funds Transfer Pricing',
        routerLink: 'import-and-calculate/funds-transfer-pricing',
        iconClass: 'fa-solid fa-3 rounded-number ',
        isDataverseOnly: true,
        roles: [...dvOnlyUserLevels]
      }
    ]
  }
]);

export const navigationElements: NavigationElement[] = setLevels([
  {
    label: 'General Ledger',
    isExpanded: false,
    iconClass: 'fa-solid fa-feather-pointed',
    roles: [...dvOnlyUserLevels, ...profUserLevels],
    children: [
      {
        label: 'Balance Sheet',
        routerLink: 'general-ledger/balance-sheet',
        pageId: PAGE_IDS.BalanceSheet,
        roles: [...dvOnlyUserLevels, ...profUserLevels]
      },
      {
        label: 'Income Statement',
        routerLink: 'general-ledger/income-statement',
        pageId: PAGE_IDS.IncomeStatement,
        roles: [...dvOnlyUserLevels, ...profUserLevels]
      },
      {
        label: 'Daily GL',
        isExpanded: false,
        roles: [...dvOnlyUserLevels, ...profUserLevels],
        children: [
          {
            label: 'Dates Imported',
            routerLink: 'general-ledger/daily-gl/dates-imported',
            roles: [...dvOnlyUserLevels, ...profUserLevels]
          },
          {
            label: 'Daily Balance Sheet',
            routerLink: 'general-ledger/daily-gl/daily-balance-sheet',
            pageId: PAGE_IDS.DailyBalanceSheet,
            roles: [...dvOnlyUserLevels, ...profUserLevels]
          },
          {
            label: 'Daily Income Statement',
            routerLink: 'general-ledger/daily-gl/daily-income-statement',
            pageId: PAGE_IDS.DailyIncomeStatement,
            roles: [...dvOnlyUserLevels, ...profUserLevels]
          }
        ]
      }
    ]
  },
  {
    label: 'GL Recon Settings',
    isExpanded: false,
    iconClass: 'fas fa-cogs',
    roles: [...dvOnlyUserLevels, ...profUserLevels],
    children: [
      {
        label: 'Options',
        routerLink: 'gl-recon-settings/gl-recon-options',
        pageId: PAGE_IDS.Options,
        roles: [...dvOnlyUserLevels, ...profUserLevels]
      },
      {
        label: 'Account Mapping',
        routerLink: 'gl-recon-settings/account-mapping',
        pageId: PAGE_IDS.AccountMapping,
        roles: [...dvOnlyUserLevels, ...profUserLevels]
      },
      {
        label: 'Recon Accounts',
        routerLink: 'gl-recon-settings/recon-accounts',
        pageId: PAGE_IDS.ReconAccounts,
        roles: [...dvOnlyUserLevels, ...profUserLevels]
      },
      {
        label: 'GL Only Accounts',
        routerLink: 'gl-recon-settings/gl-only-accounts',
        pageId: PAGE_IDS.GLOnlyAccounts,
        roles: [...dvOnlyUserLevels, ...profUserLevels]
      },
      {
        label: 'Recon Departments',
        routerLink: 'gl-recon-settings/recon-departments',
        pageId: PAGE_IDS.ReconDepartments,
        roles: [...dvOnlyUserLevels, ...profUserLevels]
      }
    ]
  },
  {
    label: 'Hierarchies & Dimensions',
    isExpanded: false,
    iconClass: 'fas fa-sitemap',
    roles: [...dvOnlyUserLevels, ...profUserLevels],
    children: [
      {
        label: 'Organizational',
        roles: [...dvOnlyUserLevels, ...profUserLevels],
        children: [
          {
            label: 'Department',
            routerLink: 'hierarchies-and-dimensions/organizational/department',
            pageId: PAGE_IDS.Department,
            roles: [...dvOnlyUserLevels, ...profUserLevels]
          },
          {
            label: 'Department Mapping',
            roles: [...dvOnlyUserLevels, ...profUserLevels],
            children: [
              {
                label: 'Department > Legal Entity',
                routerLink: 'hierarchies-and-dimensions/organizational/department-legal-entity',
                pageId: PAGE_IDS.DepartmentLE,
                roles: [...dvOnlyUserLevels, ...profUserLevels]
              }
            ]
          },
          {
            label: 'Legal Entity',
            routerLink: 'hierarchies-and-dimensions/view-only/legal-entity',
            pageId: PAGE_IDS.LegalEntity,
            roles: [...dvOnlyUserLevels, ...profUserLevels]
          }
        ]
      }
    ]
  }
  // {
  //   label: 'Account Assignment',
  //   isExpanded: false,
  //   iconClass: 'fa-solid fa-file-pen',
  //   roles: [...dvOnlyUserLevels, ...profUserLevels],
  //   children: [
  //     {
  //       label: 'Calculated Fields',
  //       routerLink: 'account-assignment/calculated-fields',
  //       pageId: PAGE_IDS.CalculatedFields,
  //       roles: [...dvOnlyUserLevels, ...profUserLevels]
  //     },
  //     {
  //       label: 'Assignments',
  //       routerLink: 'account-assignment/assignments',
  //       pageId: PAGE_IDS.Assignments,
  //       roles: [...dvOnlyUserLevels, ...profUserLevels]
  //     },
  //     {
  //       label: 'Results Report',
  //       routerLink: 'account-assignment/results-report',
  //       pageId: PAGE_IDS.ResultsReport,
  //       roles: [...dvOnlyUserLevels, ...profUserLevels]
  //     }
  //   ]
  // }
]);
