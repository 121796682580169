import { Component, Input, OnInit } from '@angular/core';
import { EmpyreanApplicationName, isUserLevelForApplication } from '@models/user-level';
import { OpenIdConnectService } from '@services/openid/openid-connect.service';

@Component({
  selector: 'app-application-switcher-item',
  templateUrl: './application-switcher-item.component.html',
  styleUrls: ['./application-switcher-item.component.scss']
})
export class ApplicationSwitcherItemComponent implements OnInit {
  @Input() moduleUrl: string;
  @Input() applicationName: string;
  @Input() moduleName: EmpyreanApplicationName;
  @Input() iconClass: string;
  @Input() applicationSubtitle: string;

  constructor(private readonly openIdConnectService: OpenIdConnectService) {}

  ngOnInit() {}

  public isUrlAvailable = (): boolean => this.moduleUrl && this.moduleUrl !== '';

  public isApplicationAvailableToUser = (): boolean => isUserLevelForApplication(this.moduleName, this.openIdConnectService.userLevelList);

  public isApplicationSwitcherEnabled = (): boolean => this.isApplicationAvailableToUser() && this.isUrlAvailable();

  public applicationSwitcherTooltip = (): string | null => {
    if (!this.isUrlAvailable()) {
      return 'This module is not available, please contact your Empyrean representative for more information.';
    }
    if (!this.isApplicationAvailableToUser()) {
      return 'You are not authorized to access this module.';
    }
    return null;
  };
}
