import { Component, HostBinding } from '@angular/core';

@Component({
  selector: 'emp-navigation-divider',
  template: '<ng-container></ng-container>'
})
export class NavigationDividerComponent {
  // @HostBinding('class') public classes = 'nav_divider margin-top-0';
  @HostBinding('class') public classes = 'nav_divider ';
}
