import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatCalendarCellClassFunction, MatDatepicker } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AsOfDate } from '@models/as-of-date/as-of-date';
import * as moment from 'moment';
import { Moment } from 'moment';

export interface INewAsOfDateParams {
  month: number;
  year: number;
}

@Component({
  selector: 'emp-add-asofdate',
  templateUrl: './add-asofdate.component.html',
  styleUrls: ['./add-asofdate.component.scss']
})
export class AddAsOfDateComponent implements OnInit {
  @ViewChild(MatDatepicker) public picker: MatDatepicker<Date>;

  public formGroup: FormGroup;
  public readonly minDate: Moment = null;
  public maxDate: Moment = null;
  public selectedMonth: number = null;
  public selectedYear: number = null;
  public firstDate: Moment = null;
  public lastDate: Moment = null;
  public monthsToCreate: number = null;
  public previousMonth: Moment = null;
  public nextMonth: Moment = null;

  get asOfDates(): AsOfDate[] {
    return this.data?.asOfDates ?? [];
  }

  get selectedDate(): Moment {
    return this.formGroup.get('date')?.value as Moment;
  }

  constructor(
    private readonly fb: FormBuilder,
    private readonly dialogRef: MatDialogRef<AddAsOfDateComponent>,
    @Inject(MAT_DIALOG_DATA)
    private readonly data: {
      asOfDates: AsOfDate[];
    }
  ) {
    this.minDate = moment().month(0).day(1).subtract(100, 'years');
  }

  public ngOnInit(): void {
    if (this.asOfDates.length > 0) {
      this.firstDate = moment(this.asOfDates[0].Date);
      this.lastDate = moment(this.asOfDates[this.asOfDates.length - 1].Date);
    } else {
      this.firstDate = moment();
      this.lastDate = moment();
    }

    this.previousMonth = this.firstDate.clone().subtract(1, 'months');
    this.nextMonth = this.lastDate.clone().add(1, 'months');

    this.maxDate = this.previousMonth;
    this.selectedYear = this.maxDate.year();
    this.selectedMonth = this.maxDate.month() + 1;

    this.formGroup = this.fb.group({
      date: [this.maxDate, [Validators.required]]
    });
  }

  public getErrorMessage() {
    const control = this.formGroup.controls.date;
    if (control.hasError('matDatepickerParse')) {
      return `Invalid Date`;
    }
    if (control.hasError('required')) {
      return `Date is required`;
    }
    if (control.hasError('matDatepickerMin')) {
      return `Date must be post ${this.minDate.format('MMMM yyyy')}`;
    }
    if (control.hasError('matDatepickerMax')) {
      return `Date must be prior to ${this.firstDate.format('MMMM yyyy')}`;
    }
  }

  public saveNewAsOfDate(): void {
    const monthAndYear: INewAsOfDateParams = {
      month: this.selectedMonth,
      year: this.selectedYear
    };
    this.closeDialog(monthAndYear);
  }

  public closeDialog(monthAndYear: INewAsOfDateParams = null): void {
    this.dialogRef.close(monthAndYear);
  }

  public chosenMonthHandler(date: Moment, datepicker: MatDatepicker<any>) {
    this.selectedMonth = date.month() + 1;
    this.selectedYear = date.year();

    this.formGroup.patchValue({ date });

    // Calculate number of months between selected date and maxDate
    this.monthsToCreate =
      date.isBefore(this.firstDate)
        ? this.getMonthsToCreate(date, this.previousMonth)
        : this.getMonthsToCreate(this.nextMonth, date);

    datepicker.close();
  }

  /**
   * Returns number of months between 2 dates, including both and ignoring the day.
   *
   * If `date2` is prior to `date1`, returns `0`.
   *  ```ts
   * getMonthsToCreate(moment('2021-01-31'), moment('2021-01-31')); // 1
   * getMonthsToCreate(moment('2021-01-31'), moment('2021-02-28')); // 2
   * getMonthsToCreate(moment('2021-01-31'), moment('2021-02-01')); // 2
   * ```
   *
   */
  public getMonthsToCreate(date1: Moment, date2: Moment): number {
    const year1 = date1.year();
    const year2 = date2.year();
    const month1 = date1.month();
    const month2 = date2.month();
    const result = (year2 - year1) * 12 + (month2 - month1) + 1;

    if (result < 1) {
      console.warn('date1 > date2', { date1, date2 });
      return 0;
    }

    return result;
  }

  public usedDates = (date: Moment | null): boolean => {
    if (!date) {
      return false;
    }

    // get last day of the month for the date
    const lastDayOfMonth = date.clone().add(1, 'months').date(0);
    return lastDayOfMonth.isBefore(this.firstDate) || lastDayOfMonth.isAfter(this.lastDate);
  };

  public dateClass: MatCalendarCellClassFunction<Moment> = (cellDate: Moment, view) => {
    // Only highligh dates inside the month view.
    if (view === 'multi-year') {
      const dateLastDay = cellDate.clone().add(1, 'months').date(0);

      return dateLastDay.isBefore(this.firstDate) || dateLastDay.isAfter(this.lastDate) ? '' : 'existing-date';
    }
    return '';
  };
}
