import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@environments/environment';
import { NavigationService } from '@services/navigation/navigation.service';
import { OpenIdConnectService } from '@services/openid/openid-connect.service';

@Component({
  selector: 'app-signin-oidc',
  templateUrl: './signin-oidc.component.html',
  styleUrls: ['./signin-oidc.component.scss']
})
export class SigninOidcComponent implements OnInit {
  constructor(
    private readonly openIDConnectService: OpenIdConnectService,
    private readonly router: Router,
    private readonly navigationService: NavigationService
  ) {}

  public ngOnInit() {
    this.openIDConnectService.userLoaded$.subscribe(async userLoaded => {
      if (userLoaded) {
        const user = this.openIDConnectService.user;
        const initialUrl = user.state?.initialUrl;
        if (initialUrl) this.navigationService.storeUrl(initialUrl);
        await this.router.navigate([this.navigationService.getInitialUrl()]);
      } else {
        if (!environment.production) {
          console.log("An error happened: user wasn't loaded");
        }
      }
    });
    this.openIDConnectService.handleCallBack();
    //this.openIDConnectService.handleCallBack(initialUrl);
  }
}
