import { Component, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import Swal, { SweetAlertResult } from 'sweetalert2';

export interface ICanComponentDeactivate extends Component {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
  discardLocalStorage: () => void;
  componentName: () => string;
}

@Injectable({ providedIn: 'root' })
export class CanComponentDeactivateGuard implements CanDeactivate<ICanComponentDeactivate> {
  public activeComponent: ICanComponentDeactivate;

  public async canDeactivate(
    component: ICanComponentDeactivate,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): Promise<boolean> {
    return this.canComponentDeactivate(component);
  }

  public async canComponentDeactivate(component: ICanComponentDeactivate): Promise<boolean> {
    if (!component) {
      console.error(`No component found`);
      return true;
    }

    if (component.canDeactivate()) {
      return true;
    }

    const discard = await this.canDeactivatePopupTab(component.componentName());
    if (discard) {
      component.discardLocalStorage();
    }
    return discard;
  }

  public canDeactivatePopupTab = (componentName: string): Promise<boolean> =>
    new Promise<boolean>((resolve, reject) => {
      Swal.fire({
        title: `${componentName}\nUnsaved Changes`,
        icon: 'warning',
        text: `Changes have been made to ${componentName}.\nDo you want to discard these changes?`,
        showCancelButton: true,
        confirmButtonText: 'Discard Changes',
        cancelButtonText: 'Go Back',
        reverseButtons: true,
        allowEscapeKey: false,
        heightAuto: false
      })
        .then((result: SweetAlertResult<any>) => {
          resolve(result.isConfirmed);
        })
        .catch(err => {
          reject(true);
        });
    });


    public canDeactivatePopupTabAR = (componentName: string): Promise<boolean> =>
    new Promise<boolean>((resolve, reject) => {
      Swal.fire({
        title: `Assignment Rulesets\nUnsaved Changes`,
        icon: 'warning',
        text: `Changes have been made to ${componentName}.\nDo you want to discard these changes?`,
        showCancelButton: true,
        confirmButtonText: 'Discard Changes',
        cancelButtonText: 'Go Back',
        reverseButtons: true,
        allowEscapeKey: false,
        heightAuto: false
      })
        .then((result: SweetAlertResult<any>) => {
          resolve(result.isConfirmed);
        })
        .catch(err => {
          reject(true);
        });
    });
}
