import { Injectable } from '@angular/core';
import { INavigationExtendedElement, NavigationElement, NavigationElementState } from '@core/navigation/navigation-interfaces';
import { AsOfDate } from '@models/as-of-date/as-of-date';
import { IPageValidation } from '@models/page-validation/page-validation';
import { DataSourcesService } from '@services/data-sources/data-sources.service';
import { DependencyService } from '@services/dependency/dependency.service';
import { OpenIdConnectService } from '@services/openid/openid-connect.service';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';

const NAV_EXPIRATION_MINUTES = 30;

type NavigationSettings = { [key: string]: boolean | string };

@Injectable({ providedIn: 'root' })
export class NavigationService {
  public isNavigationBarActive$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isDateSelectorOpen$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private readonly _pageUrl$: BehaviorSubject<string> = new BehaviorSubject<string>(null!);

  public navigationElements: NavigationElement[];

  constructor(
    private readonly dsService: DataSourcesService,
    private readonly dependencyService: DependencyService,
    private readonly oidc: OpenIdConnectService
  ) {
    this._startNavigationMenuState();
  }

  public isModelSettingsClosed$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public modelSettingsElements$: BehaviorSubject<INavigationExtendedElement[]> = new BehaviorSubject<INavigationExtendedElement[]>(null);

  public get modelSettingsElements(): INavigationExtendedElement[] {
    return this.modelSettingsElements$.value;
  }

  public set modelSettingsElements(value) {
    this.modelSettingsElements$.next(value);
  }

  public get isModelSettingsClosed(): boolean {
    return this.isModelSettingsClosed$.value;
  }

  public set isModelSettingsClosed(value) {
    this.isModelSettingsClosed$.next(value);
  }

  public setNavigationBarActive(active: boolean): void {
    this.isNavigationBarActive$.next(active);
  }

  public setIsDateSelectorOpen(isOpen: boolean): void {
    this.isDateSelectorOpen$.next(isOpen);
  }

  public storeExpandStatus = (element: NavigationElement) => {
    const key = `${element.level}-${element.label}`;
    const navigation: NavigationSettings = JSON.parse(localStorage.getItem('navigation') ?? '{}');

    if (element.isExpanded) {
      navigation[key] = true;
    } else {
      delete navigation[key];
    }

    this.saveNavigationSettings(navigation);
  };

  get pageUrl$(): Observable<string> {
    return this._pageUrl$.asObservable();
  }

  set pageUrl(pageUrl: string) {
    this._pageUrl$.next(pageUrl);
  }

  get pageUrl(): string {
    return this._pageUrl$.value;
  }

  public get pageUrlNotNull$() {
    return this.pageUrl$.pipe(
      // -- work around for duplicate emissions from source
      distinctUntilChanged(),
      filter(pageUrl => pageUrl !== null)
    );
  }

  public storeUrl = (url: string) => {
    const navigation: NavigationSettings = JSON.parse(localStorage.getItem('navigation') ?? '{}');
    navigation.url = url;

    // Set expiration to 30 minutes
    navigation.expiration = new Date(Date.now() + NAV_EXPIRATION_MINUTES * 60 * 1000).toISOString();

    this.saveNavigationSettings(navigation);
  };

  /** Gets Url stored in local storage (if not stored) or default '' */
  public getInitialUrl = (): string => {
    const navigation = this.getNavigationSettings();

    return (navigation?.url as string) ?? '';
  };

  public setExpandStatus = (elements: NavigationElement[]) => {
    if (!elements) {
      return;
    }

    const navigation = this.getNavigationSettings();
    if (!navigation?.expiration) {
      return;
    }

    elements.forEach(element => {
      const key = `${element.level}-${element.label}`;
      element.isExpanded = navigation[key] === true;

      this.setExpandStatus(element?.children);
    });
  };

  private readonly getNavigationSettings = (): NavigationSettings => {
    const navigation: NavigationSettings = JSON.parse(localStorage.getItem('navigation') ?? '{}');
    const expiration = new Date((navigation?.expiration as string) ?? '');

    if (!expiration || expiration < new Date()) {
      localStorage.removeItem('navigation');
      return null;
    }

    return navigation;
  };

  private readonly saveNavigationSettings = (navigation: NavigationSettings) => {
    if (Object.keys(navigation).length === 0) {
      localStorage.removeItem('navigation');
    } else {
      localStorage.setItem('navigation', JSON.stringify(navigation));
    }
  };

  /** Starts a subscription that updates the navigation elements when dependecies are updated */
  private _startNavigationMenuState() {
    const source = combineLatest([this.dependencyService._pageValidations$, this.dsService.selectedAsOfDate$]).pipe(debounceTime(500)); //.subscribe();
    source.subscribe(([pageValidations, asofdate]) => {
      this._setElementStates(this.navigationElements, { pageValidations, asofdate });
    });
  }

  /** recursively sets the current state of the nav elements tree */
  private _setElementStates(elements: NavigationElement[], deps: { pageValidations: IPageValidation[]; asofdate: AsOfDate }) {
    if (elements) {
      elements.forEach(element => {
        if (element && element.children) {
          // -- set children state first
          this._setElementStates(element?.children, deps);
        }
        this._setElementState(element, deps);
        element.stateClass = this.getIconClass(element).join(' ');
        element.tooltip = this.getTooltip(element);
      });
    }
  }

  /** sets an elements current state */
  private _setElementState(element: NavigationElement, deps: { pageValidations: IPageValidation[]; asofdate: AsOfDate }) {
    const { pageValidations } = deps;
    if (pageValidations && this.oidc.isAdminUser()) {
      let pageIsValid: boolean = true;
      let pageIsAllocated: boolean = true;
      let pageIsSetup: boolean = true;
      let pageIsActive = true;
      if (element.children && element.children.length > 0) {
        pageIsValid = !element.children.some(child => child.elementState === 'invalid' || child.elementState === 'invalid unallocated');
        // pageIsAllocated = !element.children.some( child => child.pageIsAllocated === false);
        // pageIsSetup = !element.children.some( child => child.pageIsSetup === false);
      } else {
        const pageId = element.pageId;
        const pageValidation = pageValidations.find(v => v.PageId === pageId);
        pageIsValid = pageValidation?.IsValid === false ? false : true;
        pageIsAllocated = pageValidation?.IsAllocated === false ? false : true;
        pageIsSetup = pageValidation?.IsSetup === false ? false : true;
        pageIsActive = pageValidation?.IsSetup === null ? false : true;
      }
      let state: NavigationElementState = 'ok';
      if (pageIsSetup === true && pageIsValid === false) {
        // -- page is invalid and has unallocated
        state = pageIsAllocated ? 'invalid' : 'invalid unallocated';
      } else if (pageIsSetup === true && pageIsAllocated === false) {
        // -- page has unallocated items, but not required to allocated
        state = 'unallocated';
      } else if (pageIsSetup === false) {
        // -- page is ready to be set up
        state = 'not setup';
      } else if (pageIsActive === false) {
        // -- page has dependencies not met so cannot be set up or page set up not required based on other settings
        state = 'not required';
      }
      element.elementState = state;
    } else {
      element.elementState = 'ok';
    }
  }

  public getIconClass(element: NavigationElement): string[] {
    const state = element.elementState;
    let stateClasses: Array<string> = [];
    const isALeaf = !element.children ? true : false;
    if ((isALeaf && state === 'invalid') || state === 'invalid unallocated') {
      stateClasses.push('fa-solid', 'fa-triangle-exclamation');
    } else if (!element.iconClass) {
      stateClasses.push('fa-solid', 'fa-square');
    }
    return stateClasses;
  }

  public getTooltip(element: NavigationElement): string {
    const state = element.elementState;
    let tooltip: string = '';
    if (state !== 'disabled') {
      switch (state) {
        case 'invalid':
          tooltip = `Validation Errors must be fixed in ${element.label}`;
          break;
        case 'invalid unallocated':
          tooltip = `New items must be setup in ${element.label}`;
          break;
        case 'unallocated':
          tooltip = `New items can be setup in ${element.label}`;
          break;
        case 'not setup':
          tooltip = `Click to Setup ${element.label}`;
          break;
        case 'not required':
          tooltip = `${element.label} is disabled because other pages must be setup first`;
          break;
        default:
          break;
      }
    }
    return tooltip;
  }
}
