// tslint:disable: curly
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfigProvider } from '@app/app.config.provider';
import { IPageValidation } from '@models/page-validation/page-validation';
import { BehaviorSubject, Observable, of, combineLatest } from 'rxjs';
import { catchError, distinctUntilChanged, filter, switchMap, take, timeout } from 'rxjs/operators';
import { SharedService } from '@services/shared/shared-service';

@Injectable({
  providedIn: 'root'
})
export class DependencyService {
  private pageValidationUrl: string;

  public readonly _pageValidations$ = new BehaviorSubject<IPageValidation[]>(null);
  public get pageValidations$() {
    return this._pageValidations$.asObservable();
  }
  public get pageValidations() {
    return this._pageValidations$.getValue();
  }
  private set _pageValidations(pageValidtions: IPageValidation[]) {
    this._pageValidations$.next(pageValidtions);
  }
  private get asOfDate$() {
    return this.sharedService.selectedAsOfDate$;
  }
  private get firstPageLoaded$() {
    return this.sharedService.firstPageLoaded$;
  }

  constructor(
    private readonly http: HttpClient,
    private readonly appConfig: AppConfigProvider,
    private readonly sharedService: SharedService
  ) {
    this.appConfig.environment.subscribe(env => {
      this.pageValidationUrl = `${env.API.DIMENSIONS}/api/PageValidation/GetPageValidationDV`;
    });
    this._initializeSubs();
  }

  private _initializeSubs() {
    // -- Update all page validations after the first page loads and any time the as of date changes
    // -- if firstPageLoaded$ doesn't emit in 5 seconds, just assume the page loaded
    const aod$ = this.asOfDate$;
    const ready$ = this.firstPageLoaded$.pipe(
      filter(loaded => loaded),
      timeout(5000),
      catchError(_ => of(true)),
      take(1)
    );
    combineLatest([ready$, aod$])
      .pipe(switchMap(([_, aod]) => this._fetchAllPageValidation(aod.Date)))
      .subscribe(pageValidations => {
        this._pageValidations = pageValidations;
      });
  }

  /** Fetches and returns all page validations */
  private _fetchAllPageValidation(asofdate: string): Observable<IPageValidation[]> {
    return this.http.get(`${this.pageValidationUrl}?asofdate=${asofdate}`).pipe(catchError(_ => of(null)));
  }

  private fetchValidations(asofdate: string): Observable<IPageValidation[]> {
    return this.http.get<IPageValidation[]>(`${this.pageValidationUrl}?asofdate=${asofdate}`);
  }

  public async fetchPageValidations(asofdate: string): Promise<IPageValidation[]> {
    return await this.fetchValidations(asofdate).toPromise().then(res => res);
  }

  /** Fetches and updates all page validations */
  public refreshAllPageValidation(): void {
    this.asOfDate$
      .pipe(
        take(1),
        switchMap(aod => this._fetchAllPageValidation(aod.Date))
      )
      .subscribe(pageValidations => {
        this._pageValidations = pageValidations;
      });
  }


  /** Sets the latest Page Validation for all subscribers */
  public notifyPageValidation() {
    //console.log('Page Validation Updated:', pageValidations)
    //this._pageValidations = pageValidations;
    this.refreshAllPageValidation();
  }

  public markPageModfied(pageId: number, isModified = true) {
    this._updatePageValidation(pageId, { IsModified: isModified });
  }

  public markPageValid(pageId: number, isValid = true) {
    this._updatePageValidation(pageId, { IsValid: isValid });
  }

  private _updatePageValidation(pageId: number, updates: Partial<IPageValidation>) {
    if (!pageId || !updates) return;
    const allPageValidations = this.pageValidations;
    const targetPVIdx = allPageValidations ? allPageValidations.findIndex(pv => (pv.PageId = pageId)) : null;
    if (targetPVIdx > -1) {
      const newPageValidations = [].concat(allPageValidations);
      const targetPV = allPageValidations[targetPVIdx];
      const newPV = { ...targetPV, ...updates };
      newPageValidations.splice(targetPVIdx, 1, newPV);
      this._pageValidations = newPageValidations;
    }
  }
}
