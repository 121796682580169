/**
 * Waits for `ms` miliseconds.
 * @example
 * await sleep(1000);
 */
export const sleep = (ms = 1000) =>
  new Promise<void>(resolve => {
    setTimeout(() => {
      resolve();
    }, ms);
  });
