import { Injectable } from '@angular/core';

function _window(): any {
  // return the global native browser window object
  return window;
}

@Injectable({ providedIn: 'root' })
export class ResizeService {
  private resizeTimer: NodeJS.Timeout = null;

  get nativeWindow(): any {
    return _window();
  }

  public emitResize(delay = 0) {
    if (this.resizeTimer) {
      clearTimeout(this.resizeTimer);
      this.resizeTimer = null;
    }

    this.resizeTimer = setTimeout(() => this.nativeWindow.dispatchEvent(new Event('resize')), delay);
  }
}
