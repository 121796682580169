<a
  class="application_link"
  [attr.href]="isApplicationSwitcherEnabled() ? moduleUrl : null"
  [attr.disabled]="!isApplicationSwitcherEnabled()"
  [class.disabled]="!isApplicationSwitcherEnabled()"
  [attr.title]="applicationSwitcherTooltip()"
>
  <span class="application_icon"><span class="{{ iconClass }}"></span></span>
  <span class="application_name">{{ applicationName }}</span>
  <span class="application_subtitle">{{ applicationSubtitle }}</span>
</a>
