export const deepClone = <T>(value: T): T => {
  const type = typeof value;
  switch (type) {
    case 'object':
      if (null === value || undefined === value) {
        return value;
      }
      let result = void 0;
      if (value instanceof Date) {
        result = new Date();
        result.setTime(value.getTime());
        return result;
      }

      if (value instanceof RegExp) {
        result = newRegExp(value);
        return result;
      }

      if (value instanceof Map) {
        result = new Map(value);
        return result;
      }

      if (value instanceof Set) {
        result = new Set(value);
        return result;
      }

      result = JSON.parse(JSON.stringify(value));
      fixTypes(value, result);

      return result;
    default:
      return value;
  }
};

const fixPropertyValue = (original, copy, key) => {
  const originalValue = original[key];
  const originalType = typeof originalValue;
  switch (originalType) {
    case 'object':
      if (null === originalValue || undefined === originalValue) {
        copy[key] = originalValue;
      } else if (originalValue instanceof Date) {
        const newValue = new Date();
        newValue.setTime(originalValue.getTime());
        copy[key] = newValue;
      } else if (originalValue instanceof RegExp) {
        copy[key] = newRegExp(originalValue);
      } else if (originalValue instanceof Map) {
        copy[key] = new Map(originalValue);
      } else if (originalValue instanceof Set) {
        copy[key] = new Set(originalValue);
      } else {
        fixTypes(originalValue, copy[key]);
      }
      break;
    case 'number':
      if (isNaN(originalValue)) {
        copy[key] = NaN;
      } else if (originalValue === Infinity) {
        copy[key] = Infinity;
      }
      break;
    default:
      break;
  }
};

const fixTypes = (original, copy) => {
  if (original instanceof Array) {
    for (let index = 0; index < original.length; index++) {
      fixPropertyValue(original, copy, index);
    }
  } else {
    const keys = Object.getOwnPropertyNames(original);
    keys.forEach(key => {
      fixPropertyValue(original, copy, key);
    });
  }
};

const newRegExp = value => {
  const regexpText = String(value);
  const slashIndex = regexpText.lastIndexOf('/');
  return new RegExp(regexpText.slice(1, slashIndex), regexpText.slice(slashIndex + 1));
};
