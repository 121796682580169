import { Component, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';
import { INavigationCanOpen } from '../navigation-interfaces';

@Component({
  selector: 'emp-navigation-profile',
  templateUrl: './navigation-profile.component.html',
  styleUrls: ['./navigation-profile.component.scss']
})
export class NavigationProfileComponent implements INavigationCanOpen {
  @Output() public popUpOpened = new EventEmitter();

  @HostListener('document:click', ['$event']) public onClickOut(event): void {
    if (!this.element) {
      return;
    }
    if (!this.element.contains(event.target) && this.isOpen) {
      this.isOpen = !this.isOpen;
      this.popUpOpened.emit(this.isOpen ? this : null);
    }
  }
  public isOpen = false;
  public element;

  constructor(el: ElementRef) {
    this.element = el.nativeElement;
  }

  public toggleUserArea(event) {
    this.isOpen = !this.isOpen;

    this.popUpOpened.emit(this.isOpen ? this : null);
  }
}
