import { EventEmitter } from '@angular/core';
import { UserLevel } from '@models/user-level';

export interface IMenuItem {
  routerLink?: string;
  outlet?: string;
  label?: string;
  icon?: string;
  command?: string;
  canDeactivate: boolean;
  disabled?: boolean;
  Selected?: boolean;
  lite?: boolean;
  activeLinkExactMatch?: boolean;
}

export interface INavigationCanOpen {
  isOpen: boolean;
  popUpOpened: EventEmitter<any>;
}

export interface INavigationOptionsElement {
  value: string;
  label: string;
  routerLink?: string;
  clickAction?(args?: any): any;
}

export type NavigationElementState = 'ok' | 'not setup' | 'not required' | 'invalid' | 'invalid unallocated' | 'unallocated' | 'disabled';
export type NavigationElementDisabledReason = 'no date' | 'calcs incomplete';

export type NavigationElement = {
  label: string;
  children?: NavigationElement[];
  elementState?: NavigationElementState;
  routerLink?: string;
  isExpanded?: boolean;
  isSelected?: boolean;
  isDisabled?: boolean;
  level?: number;
  pageId?: number;
  stateClass?: string;
  pageIsAllocated?: boolean;
  pageIsSetup?: boolean;
  pageIsValid?: boolean;
  pageIsAllocatedIS?: boolean;
  pageIsSetupIS?: boolean;
  pageIsValidIS?: boolean;
  pageIsActive?: boolean;
  tooltip?: string;
  iconClass?: string;
  alertClass?: string;
  navigationParam?: any[];
  value?: number;
  menu?: NavigationMenu;
  dataSourceId?: number;
  roles: UserLevel[];

  /** When `true`, element will be disabled if global setting `ProfLite = 0` */
  isDataverseOnly?: boolean;
};

export enum NavigationMenu {
  Main = 'Main',
  ModelSettings = 'Assignments'
}

export interface INavigationChildrenElement {
  active?: boolean;
  alertClass?: string;
  children?: INavigationChildrenElement[];
  iconClass?: string;
  isDisabled?: boolean;
  label: string;
  menu?: NavigationMenu;
  navigationParam?: any[];
  routerLink?: string;
  clickAction?(args?: any): any;
}

export interface INavigationExtendedElement extends NavigationElement {
  label: string;
  navigationParam?: any[];
  routerLink?: string;
  clickAction?(args?: any): any;
}

export const getNumberOfDescendants = (element: NavigationElement): number => {
  if (!element?.children || !element?.isExpanded) {
    return 0;
  }

  return element?.children.reduce((acc, child) => acc + getNumberOfDescendants(child) + 1, 0);
};
