import { Location } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router, RouterOutlet } from '@angular/router';
import { OpenIdConnectService } from '@services/openid/openid-connect.service';
import { CanComponentDeactivateGuard, ICanComponentDeactivate } from '@services/auth-guard/can-component-deactivate-guard.service';
import { clone } from '@shared/arrays';
import { isEscape } from '@shared/key-codes';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ModalType } from '../navigation-elements';
import { INavigationExtendedElement } from '../navigation-interfaces';

@Component({
  selector: 'empyrean-navigation-modal',
  templateUrl: './navigation-modal.component.html',
  styleUrls: ['./navigation-modal.component.scss']
})
export class NavigationModalComponent implements OnInit, OnDestroy {
  @ViewChild(RouterOutlet) public outlet: RouterOutlet;

  public component: ICanComponentDeactivate = null;
  private readonly modalType: ModalType;
  private readonly subscriptions: Subscription = new Subscription();

  public isAdmin = false;
  public isPayrollAdmin = false;
  public isReady = false;
  public menuItems;
  public selectedMenuItems;
  public menuItemsCopy: INavigationExtendedElement;
  public modalTitle = '';
  public DataSourceName = '';
  public DataSourceTypeID = 0;
  public value = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private readonly canDeactivateService: CanComponentDeactivateGuard,
    private readonly dialogRef: MatDialogRef<NavigationModalComponent>,
    private readonly location: Location,
    private readonly auth: OpenIdConnectService,
    private readonly router: Router
  ) {
    this.modalTitle = this.data.modalTitle;
    this.menuItemsCopy = clone(this.menuItems);
    this.modalType = this.data.modalType;
    this.DataSourceName=this.data.DataSourceName;
    this.DataSourceTypeID = this.data.DataSourceTypeID;
    this.value = this.data.value;
 }

  public async ngOnInit(): Promise<void> {
    this.isReady = false;
    this.menuItems = this.prepareMenuItems(this.data.menuOptions);
   if (this.modalType === ModalType.ModelSettings) {
      this.selectedMenuItems = this.menuItems.find(dbItem => dbItem.label === this.DataSourceName);
      await this.onMenuItemClicked(this.selectedMenuItems);
    }

    this.initSubscriptions();
  }

  public ngOnDestroy(): void {
    this.outlet.deactivate();
    this.subscriptions.unsubscribe();
  }

  private initSubscriptions(): void {
    // Keyboard Events - Escape key
    this.subscriptions.add(
      this.dialogRef
        .keydownEvents()
        .pipe(filter(isEscape))
        .subscribe(async (event: KeyboardEvent) => {
          // Prevent closing dialog if Escape key was pressed on any Spread dropdown
          // @ts-ignore:next-line
          const path = event.path.map(element => element.className) || [];
          if (path[0] !== 'gc-list-control') {
            await this.closeModal();
          }
        })
    );
  }

  private onNavigationError(err): void {
    console.error(err);
  }

  public async onMenuItemClicked(navigationElm: INavigationExtendedElement): Promise<void> {
    this.isReady = false;

    // #49407 - Check for next navigation param in case for Payroll only role: 'Planning Methodologies' - 'Payroll' - 'Salary Settings'
    const navigationParam = navigationElm?.navigationParam ;
    if (navigationParam) {
      await this.router
        .navigate(navigationParam)
        .catch(this.onNavigationError)
        .finally(() => (this.isReady = true));
    }
  }

  public async closeModal(): Promise<void> {
    if (typeof this.component?.canDeactivate === 'function') {
      if (this.component.canDeactivate()) {
        await this.closeDialog();
      } else {
        const discardChanges = await this.canDeactivateService.canDeactivatePopupTab(this.component.componentName());
        if (discardChanges) {
          this.component.discardLocalStorage();
          await this.closeDialog();
        }
      }
    } else {
      await this.closeDialog();
    }
  }

  public prepareMenuItems(menuItems: INavigationExtendedElement[]): INavigationExtendedElement[] {
    menuItems.forEach(menuItem => {
      if (menuItem.navigationParam) {
        menuItem.clickAction = () => this.onMenuItemClicked(menuItem);
      }
      menuItem.routerLink = null;

      if (menuItem.children) {
        // @ts-ignore
        menuItem.children = this.prepareMenuItems(menuItem.children);
      }
    });
    return menuItems;
  }

  public onResize(event): void {
    this.dialogRef.updateSize('calc(100% - 1rem)', 'calc(100% - 1rem)');
  }

  public onActivate(component): void {
    this.component = component;
    // <17044> Activated twice when switching from one modal to the other (Version Settings <==> Model Settings)
  }

  public onDeactivate(): void {
    // this.outletRef.deactivate();
  }

  public refreshNavPanel(menuItems: INavigationExtendedElement[]): void {
    this.menuItems = this.prepareMenuItems(menuItems);
  }

  private async closeDialog(): Promise<void> {
    const navParam = [{ outlets: { 'navigation-modal-outlet': ['empty'] } }];
    const navNull = [{ outlets: { 'navigation-modal-outlet': null } }];

    /**
     * Defect <17044>: (Workaround) When closing the dialog, do a pre-navigation to EmptyComponent (without
     * guards), so when the outlet is reactivated, the previous component isn't reused and its previous guard
     * isn't triggered
     */

    await this.router
      .navigate(navParam, { skipLocationChange: true })
      .catch(this.onNavigationError)
      .finally(() => {
        this.dialogRef.close();

        const url = this.router.url.replace(/\(navigation-modal-outlet:.*\)/, '');
        this.location.replaceState(url);
      });
  }
}
