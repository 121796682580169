import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { ISubTabs, ModalScreenBaseComponent, TAB_IDS } from '../modal-screen-base/modal-screen-base.component';
import { PAGE_IDS } from '@models/page/page';
import { Subscription } from 'rxjs';
import { DependencyService } from '@services/dependency/dependency.service';
import { IEmpTab } from '@core/UI/emp-tab-strip/emp-tab-strip.component';
import { IPageValidation } from '@models/page-validation/page-validation';

@Component({
  selector: 'app-page-validations',
  template: ''
})
export class PageValidationsComponent extends ModalScreenBaseComponent implements OnInit, OnDestroy {
  public pageId: number = PAGE_IDS.ALMAccount;
  public tabId: number = TAB_IDS.ALM_ACCOUNT;
  private readonly subscriptions: Subscription = new Subscription();

  @Output() tabDataEvent = new EventEmitter<{ tab: IEmpTab; pageValidation: IPageValidation; tooltip: string }>();

  constructor(private readonly dependencyService: DependencyService) {
    super();
  }

  /** Sets page validation on each SUB TAB whenever page validations gets updated */
  public async _initPageValidationSub(idSelected: number) {
    this.subscriptions.add(
      this.dependencyService.pageValidations$.subscribe(pageValidations => {
        this.subTab = this.subTab.map(tab => {
          const pageValidation =
            pageValidations?.find(pv => pv.DataSourceID === idSelected && pv.TabID === tab.id && pv.SubTabID === tab.subid) || undefined;
          const tooltip = pageValidation ? this.getTooltipTab(tab, pageValidation) : undefined;
          this.tabDataEvent.emit({ tab, pageValidation, tooltip });
          return {
            ...tab,
            pageValidation,
            tooltip
          };
        });
      })
    );
  }

  /** Sets page validation on each TAB whenever page validations gets updated */
  public async _initPageValidationTab(idSelected?: number) {
    this.subscriptions.add(
      this.dependencyService.pageValidations$.subscribe(pageValidations => {
        this.tabs = this.tabs.map(tab => {
          const pageValidation = pageValidations?.find(pv => pv.DataSourceID === idSelected && pv.TabID === tab.id) || undefined;
          const tooltip = pageValidation ? this.getTooltipTab(tab, pageValidation) : undefined;

          this.tabDataEvent.emit({ tab, pageValidation, tooltip });
          return {
            ...tab,
            pageValidation,
            tooltip
          };
        });
      })
    );
  }

  public getTooltipTab(tab, pageValidation): string {
    let tooltip: string = null;
    const { IsSetup, IsAllocated, IsValid } = pageValidation;
    if (IsSetup === false) {
      tooltip = `Click to Setup ${tab.name}`;
    }
    if (IsSetup === true && IsAllocated === false) {
      tooltip = `New items can be setup in ${tab.name}`;
    }
    if (IsSetup === true && IsAllocated === false && IsValid === false) {
      tooltip = `New items must be setup in ${tab.name}`;
    } else if (IsSetup === true && IsValid === false) {
      tooltip = `Validation Errors must be fixed in ${tab.name}`;
    }
    if (IsSetup === null) {
      tooltip = `${tab.name} is disabled because other pages must be setup first`;
    }
    return tooltip;
  }

  // set page validation for tabs of AA modal screen
  public setTabDataEvent(tabs: IEmpTab[], tab: IEmpTab, pageValidation: IPageValidation, tooltip: string) {
    if (!(pageValidation == null)) {
      const tabId = pageValidation?.TabID;
      tabs.find(x => x.id === tabId).pageValidation = pageValidation;
      tabs.find(x => x.id === tabId).tooltip = tooltip;
    } // this code is to reset the previously set tab validation to null when pagevalidation is null for that DS
    else if (pageValidation == null && tab.pageValidation != null) {
      const tabId = tab.pageValidation?.TabID;
      tabs.find(x => x.id === tabId).pageValidation = pageValidation;
      tabs.find(x => x.id === tabId).tooltip = tooltip;
    }
  }

  // set page validation for subtabs of GL on AA modal screen
  public setSubTabDataEvent(subTabs: ISubTabs[], tab: IEmpTab, pageValidation: IPageValidation, tooltip: string) {
    if (!(pageValidation == null)) {
      const subId = pageValidation.SubTabID;
      subTabs.find(x => x.subid === subId).pageValidation = pageValidation;
      subTabs.find(x => x.subid === subId).tooltip = tooltip;
    } // this code is to reset the previously set subtab validation to null when pagevalidation is null for that DS
    else if (pageValidation == null && tab.pageValidation != null) {
      const subId = tab.pageValidation?.SubTabID;
      subTabs.find(x => x.subid === subId).pageValidation = pageValidation;
      subTabs.find(x => x.subid === subId).tooltip = tooltip;
    }
  }
}
