import { IColumnError } from '@shared/interfaces/spread-cell-validation-interfaces';

type DataType = 'FLOAT' | 'BIGINT' | 'INT' | 'BOOLEAN' | 'TEXT';
export const MAX_IDLE_TIME = 'MAX_IDLE_TIME';

export class GlobalSetting {
  public settingName: string;
  public settingDisplayName: string;
  public settingValue: string;
  public dataType: DataType;
  public settingsSubGroup: string;
  public settingsGroup: string;

  public errors?: GlobalSettingValidationError = {};
  public isModified?: boolean;
  public isValid?: boolean;
  public errorsArray: IColumnError[];
  public disabledMessage: string = null;
  public toggleMessage: string = null;

  constructor(setting?: Partial<GlobalSetting>) {
    Object.assign(this, setting);

    this.settingDisplayName = this.settingDisplayName ?? this.settingName;

    this.errors = setting?.errors ?? {};
    this.isModified = this.isModified ?? false;
    this.isValid = this.isValid ?? true;
  }
}

export type GlobalSettingBase = Pick<GlobalSetting, 'settingName' | 'settingValue' | 'dataType'>;

export type GlobalSettingValidationError = {
  [key in keyof GlobalSettingBase]?: string[];
};
